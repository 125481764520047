export const clientUrls = {
  home: "/",
  homeWithLanguage: "/:lang?",
  services: "/services",
  // servicesWithLanguage: "/:lang/services",
  subservices: "/services/:param",
  subservicesWithLanguage: "/:lang/services/:param",
  subReactPage: "/services/player-support/react",
  subReactPagesWithLanguage: "/:lang/services/player-support/react",
  subResolvePage: "/services/player-support/resolve",
  subResolvePagesWithLanguage: "/:lang/services/player-support/resolve",
  pentesting: "/services/quality-assurance/penetration-testing",
  pentestingWithLanguage:
    "/:lang/services/quality-assurance/penetration-testing",
  about: "/about",
  aboutWithLanguage: "/:lang/about",
  companyProfile: "/companyprofile",
  companyProfileWithLanguage: "/:lang/companyprofile",
  projects: "/projects",
  projectsWithLanguage: "/:lang/projects",
  projectsDescription: "/projects/:param",
  projectsDescriptionWithLanguage: "/:lang/projects/:param",
  careers: "/careers",
  careersWithLanguage: "/:lang/careers",
  news: "/news",
  newsWithLanguage: "/:lang/news",
  newsDescription: "/news/:param",
  newsDescriptionWithLanguage: "/:lang/news/:param",
  labs: "/lab",
  labsWithLanguage: "/:lang/lab",
  labsDescription: "/lab/:param",
  labsDescriptionWithLanguage: "/:lang/lab/:param",
  contact: "/contact",
  contactWithLanguage: "/:lang/contact",
  websiteTermsOfUsage: "/website-terms-of-usage",
  websiteTermsOfUsageWithLanguage: "/:lang/website-terms-of-usage",
  privacyPolicy: "/privacy-policy",
  privacyPolicyWithLanguage: "/:lang/privacy-policy",
  modernSlaveryStatement: "/modern-slavery-statement",
  modernSlaveryStatementWithLanguage: "/:lang/modern-slavery-statement",
  itSupport: "/itsupport",
};
