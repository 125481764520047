import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setColor } from "../../store/reducers/ColorReducer";
import CommonTopBanner from "./CommonTopBanner";
import ParagraphSection from "./ParagraphSection";
import SwiperSlideCommon from "../SwiperSlideCommon";
import AAACoDev from "./AAACoDev";
import Achievements from "./Achievements";
import ProjectsShowSection from "../home/ProjectsShowSection";
import NewsEvents from "../home/NewsEvents";
import { clientUrls } from "../../constants/ClientUrls";
import OurServices from "../home/OurServices";
import Footer from "../Footer";

const Localization = ({ data }) => {
  const dispatch = useDispatch();
  const { lang } = useParams();

  useEffect(() => {
    document.title = data?.servicePageData?.localizationSEOTitle
      ? data?.servicePageData?.localizationSEOTitle
      : "Video Game Localization Services | Side";
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  useEffect(() => {
    dispatch(
      setColor({
        primary: data?.serviceColors[0]?.colorCode,
        secondary: data?.serviceColors[0]?.secondaroyColor,
        tertiary: data?.serviceColors[0]?.tertiaryColor,
      })
    );
  }, [data, lang]);

  const {
    servicePageData,
    achivements,
    subServices,
    localizationServices,
    machineTranslationServices,
    projects,
    textToSpeach,
    labs,
    services,
    locations,
    type,
  } = data;

  const { bannerSection_heading1Text, fromTheLabText, visitTheLabText } =
    servicePageData;

  const machineServiceSliderData = [
    {
      serviceName:
        machineTranslationServices &&
        machineTranslationServices[0]?.machineTranslationText,
      subServices: machineTranslationServices?.map((item) => ({
        serviceButtonText: item.machineTranslationButtonText,
        subServiceName: item.machineTranslationName,
        subServiceDescription: item.machineTranslationDescription,
      })),
    },
  ];
  const textToSpeachSliderData = [
    {
      serviceName: textToSpeach && textToSpeach[0]?.textToSpeechText,
      subServices: textToSpeach?.map((item) => ({
        serviceButtonText: item.textToSpeechButton,
        subServiceName: item.textToSpeechHeading,
        subServiceDescription: item.textToSpeechParagraph,
      })),
    },
  ];

  return (
    <>
      <section>
        <CommonTopBanner
          servicePageData={servicePageData}
          color={{
            primary: data?.serviceColors[0]?.colorCode,
            tertiary: data?.serviceColors[0]?.tertiaryColor,
          }}
        />
        <ParagraphSection
          servicePageData={servicePageData}
          color={{
            primary: data?.serviceColors[0]?.colorCode,
            tertiary: data?.serviceColors[0]?.tertiaryColor,
          }}
        />
        <div className="relative">
          {/* <div className="hidden sm:block absolute sm:-bottom-[10%] lg:-bottom-[15%] -right-[20%] lg:-right-[15%] xl:-right-[12%] 2x:right-0 z-[-1]">
            <img
              src={servicePageData?.serviceTypeBgImage}
              alt="localization-bg-top"
              className="sm:w-4/5 lg:w-3/4 2xl:w-full  h-auto"
            />
          </div> */}
          <div className="absolute bottom-0 sm:bottom-[-3rem] right-0 z-[-1] w-[25rem] sm:w-[35rem] lg:w-[30rem] xl:w-[40rem]">
            <img
              src={servicePageData?.serviceTypeBgImage}
              alt="localization-bg-top"
            />
          </div>
          {/* <div className="mt-20 sm:mt-28 lg:mt-52">
            {subServices?.map((service, index) => {
              return (
                <SwiperSlideCommon
                  service={service}
                  key={index}
                  color={{
                    primary: data?.serviceColors[0]?.colorCode,
                    tertiary: data?.serviceColors[0]?.tertiaryColor,
                  }}
                />
              );
            })}
          </div> */}
          <AAACoDev
            gameServices={localizationServices}
            color={{
              primary: data?.serviceColors[0]?.colorCode,
              tertiary: data?.serviceColors[0]?.tertiaryColor,
            }}
          />
        </div>

        <div className="mt-20 sm:mt-28 lg:mt-52">
          {machineServiceSliderData?.map((service, index) => {
            return (
              <SwiperSlideCommon
                service={service}
                key={index}
                color={{
                  primary: data?.serviceColors[0]?.colorCode,
                  tertiary: data?.serviceColors[0]?.tertiaryColor,
                }}
                showHeading={true}
              />
            );
          })}
        </div>

        <div
          className="bg-no-repeatk bg-left-topk sm:-mt-20k lg:-mt-32k bg-containk relative"
          // style={{
          //   backgroundImage: `url(${servicePageData?.achivementBgImage})`,
          // }}
        >
          <div className="absolute top-[-3rem] sm:top-[25rem] lg:top-[-5rem] left-[-11rem] xs:left-[-13rem] sm:left-[-40rem] md:left-[-35rem] lg:left-[-16rem] z-[-1] block">
            <img
              src={servicePageData?.achivementBgImage}
              alt="side-ptw"
              className="w-[75%] md:w-[70%] lg:w-[75%] xl:w-[55%]"
            />
          </div>
          <div className="hidden sm:block sm:h-36 lg:h-[13rem]"></div>
          <Achievements
            servicePageData={servicePageData}
            achievements={achivements}
            color={data?.serviceColors[0]?.colorCode}
            lang={lang}
          />
          <div className="my-24 sm:my-32  lg:my-[14rem]">
            <ProjectsShowSection
              homeData={servicePageData}
              projectsData={projects}
              colors={{
                color: data?.serviceColors[0]?.secondaroyColor,
              }}
              isBtnBottom={true}
              redirectionFrom={type}
            />
          </div>
        </div>

        <NewsEvents
          header1={bannerSection_heading1Text}
          header2={fromTheLabText}
          headerButtonText={visitTheLabText}
          cardsData={labs}
          colors={{
            color: data?.serviceColors[0]?.secondaroyColor,
            tertiaryColor: data?.serviceColors[0]?.tertiaryColor,
          }}
          url={clientUrls.labs}
          toLabs={true}
          type={type}
        />
        <OurServices
          servicesData={services}
          header1={servicePageData?.exploreText}
          header2={servicePageData?.moreText}
          colors={{ color: data?.serviceColors[0]?.colorCode }}
          lang={lang}
        />
      </section>
      <Footer />
    </>
  );
};

export default Localization;
