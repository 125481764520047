import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import PIL from "../PIL";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "swiper/css/mousewheel"; // Import Swiper's mousewheel CSS
import { Mousewheel } from "swiper/modules";
import { clientUrls } from "../../constants/ClientUrls";
import { navigateTo } from "../../constants/GeneralConstants";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, ArrowRight } from "../CommonComponents";
const WeMakeWaves = ({ servicePageData, locationsData }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [btnDisabledLogic, setBtnDisabledLogic] = useState(1);
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const [isHoverPrev, setIsHoverPrev] = useState(false);
  const [isHoverNext, setIsHoverNext] = useState(false);
  const handleNext = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      setActiveIndex((prevIndex) => prevIndex + 1);
      swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      setActiveIndex((prevIndex) => prevIndex - 1);
      swiper.slidePrev();
    }
  };
  const toggleMousewheel = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      if (window.innerWidth >= 640) {
        swiper.mousewheel.enable();
      } else {
        swiper.mousewheel.disable();
      }
    }
  };

  useEffect(() => {
    toggleMousewheel();
    window.addEventListener("resize", toggleMousewheel);
    return () => {
      window.removeEventListener("resize", toggleMousewheel);
    };
  }, []);
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <section className="container" ref={ref}>
      <p className="text-[18px] 4xl:text-[29px] text-[#61E063] mb-2 font-medium sm:hidden font-dela">
        {servicePageData?.studiosText}
      </p>

      <div className="uppercase text-[#61E063] mb-6 4xl:mb-10 flex justify-between items-end">
        <h2 className="text-4xl sm:text-[60px] md:text-[72px] 2xl:text-[79.2px] 3xl:text-[93px] 4xl:text-[129px] leading-[1]">
          <span className="block">{servicePageData?.weMakeText}</span>
          <span className="block">{servicePageData?.wavesText}</span>
        </h2>
        <div className="hidden sm:flex gap-4">
          <div
            className="group"
            onMouseEnter={() => {
              setIsHoverPrev(activeIndex === 0 ? false : true);
            }}
            onMouseLeave={() => {
              setIsHoverPrev(false);
            }}
            onTouchStart={() => {
              setIsHoverPrev(activeIndex === 0 ? false : true);
            }}
            onTouchEnd={() => {
              setIsHoverPrev(false);
            }}
          >
            <button
              onClick={handlePrev}
              disabled={activeIndex === 0}
              className={`w-[44px] h-[44px] 2xl:w-14 2xl:h-14  4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] border-[#61e063]  rounded-md pl-2  ${
                isHoverPrev ? "arrow-btn-top-left" : ""
              } 
                ${
                  activeIndex === 0
                    ? "opacity-30 pointer-events-none"
                    : "opacity-100"
                } `}
              style={{
                backgroundColor: isHoverPrev ? "#61e063" : "",
              }}
            >
              <ArrowLeft color="#61e063" hover={isHoverPrev} />
            </button>
          </div>
          <div
            className="group"
            onMouseEnter={() => {
              setIsHoverNext(
                activeIndex === locationsData?.length - btnDisabledLogic
                  ? false
                  : true
              );
            }}
            onMouseLeave={() => {
              setIsHoverNext(false);
            }}
            onTouchStart={() => {
              setIsHoverNext(
                activeIndex === locationsData?.length - btnDisabledLogic
                  ? false
                  : true
              );
            }}
            onTouchEnd={() => {
              setIsHoverNext(false);
            }}
          >
            <button
              onClick={handleNext}
              disabled={
                activeIndex === locationsData?.length - btnDisabledLogic
              }
              className={`w-[44px] h-[44px] 2xl:w-14 2xl:h-14  4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] border-[#61e063] ${
                isHoverNext ? "arrow-btn-bottom-right" : ""
              } rounded-md pl-2 group 
         ${
           activeIndex === locationsData?.length - btnDisabledLogic
             ? "opacity-30 pointer-events-none"
             : "opacity-100 "
         } `}
              style={{
                backgroundColor: isHoverNext ? "#61e063" : "",
              }}
            >
              <ArrowRight color="#61e063" hover={isHoverNext} />
            </button>
          </div>
        </div>
      </div>
      <Swiper
        ref={swiperRef}
        className="h-full w-full flex !overflow-visible"
        speed={1000}
        effect="fade"
        breakpoints={{
          320: {
            slidesPerView: 1.2,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 2.1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2.6,
            spaceBetween: 20,
          },

          1024: {
            slidesPerView: 3.1,
            spaceBetween: 35,
          },
          1280: {
            slidesPerView: 3.3,
            spaceBetween: 35,
          },
          1530: {
            slidesPerView: 3.4,
            spaceBetween: 50,
          },
        }}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.activeIndex);
          if (swiper.params.slidesPerView > 3) {
            setBtnDisabledLogic(3);
            return;
          } else if (swiper.params.slidesPerView > 2) {
            setBtnDisabledLogic(2);
          }
        }}
        modules={[Mousewheel]} // Add the Scrollbar module
        mousewheel={{ forceToAxis: true, sensitivity: 3, thresholdDelta: 1 }}
        freeMode={true}
      >
        {locationsData?.map((location, index) => {
          return (
            <SwiperSlide
              className="relative flex flex-col !h-[24rem] xs:!h-[25rem] sm:!h-[23rem] md:!h-[22rem] xl:!h-[25rem] 2xl:!h-[29rem] 3xl:!h-[33rem] 4xl:!h-[42rem] rounded-md group"
              key={location.location}
              style={{ zIndex: -index }}
            >
              <motion.div
                initial={{ x: ` -${index * 111}%` }}
                animate={{ x: inView ? 0 : `-${index * 111}%` }}
                transition={{
                  duration: 2,
                  type: "spring",
                  stiffness: 100,
                  damping: 30,
                  delay: 0.8,
                }}
                className="w-full h-full cursor-pointer"
                onClick={() => {
                  sessionStorage.setItem(
                    "scrollTo",
                    location?.locationName?.toLowerCase()
                  );
                  navigate(navigateTo(clientUrls.contact));
                }}
              >
                <div className="h-3/4 relative rounded-t-md">
                  <PIL
                    lowQualitySrc={
                      location?.locationImageLowQuality ||
                      location?.locationImage
                    }
                    highQualitySrc={location?.locationImage}
                    alt={location?.locationLink}
                    className="h-full w-full object-fill rounded-t-md"
                  />
                </div>
                <div className="absolute top-0 right-0 h-12 w-12 4xl:h-16 4xl:w-16 z-[2] hidden group-hover:flex justify-center items-center bg-[#1A1A1A] rounded-bl-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="55"
                    viewBox="0 0 24 24"
                    strokeWidth="3"
                    stroke="#61E063"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                    />
                  </svg>
                </div>
                <div className="flex items-center h-1/5 bg-[#46464680] group-hover:bg-[#61e063]  rounded-b-md backdrop-blur-sm mt-1">
                  <h3 className="text-[24px] 2xl:text-[28px] 3xl:text-[32px] 4xl:text-[43.2px] pl-4 text-white group-hover:text-[#1A1A1A]">
                    {location?.locationName}
                  </h3>
                </div>
              </motion.div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default WeMakeWaves;
