import { useEffect, useState } from "react";
import { getLanguage } from "../constants/GeneralConstants";
import { getServicesPageData } from "../services/ApiService";
import "../css/pagescss/Services.css";

import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import AudioProduction from "../components/services/AudioProduction";
import PlayerSupport from "../components/services/PlayerSupport";
import GameDevelopment from "../components/services/GameDevelopment";
import PageNotFound from "../components/PageNotFound";
import QualityAssurance from "../components/services/QualityAssurance";
import Localization from "../components/services/Localization";
import Datasets from "../components/services/Datasets";
import ArtProduction from "../components/services/ArtProduction";
import LocalizationQA from "../components/services/LocalizationQA";

const Services = () => {
  const [servicesPageData, setServicesPageData] = useState(null);
  const [loader, setLoader] = useState(true);
  const { param, lang } = useParams();

  useEffect(() => {
    callServicesPageData(getLanguage());
  }, [lang, param]);

  const callServicesPageData = async (language) => {
    setLoader(true);
    try {
      let data = await getServicesPageData(language, {
        type: param,
      });
      setLoader(false);
      setServicesPageData(data);
      // updateTitle(data?.servicePageData);
    } catch (err) {
      console.log(err);
    }
  };

  // const updateTitle = (data) => {
  //   console.log(data);
  //   if (param === "audio-production") {
  //     document.title = data.audioProdSEOTitle;
  //   } else if (param === "player-support") {
  //     document.title = data.playerSEOTitle;
  //   } else if (param === "game-development") {
  //     document.title = data.gameDevSEOTitle;
  //   } else if (param === "quality-assurance") {
  //     document.title = data.qaSEOTitle;
  //   } else if (param === "localization") {
  //     document.title = data.localizationSEOTitle;
  //   } else if (param === "art-production") {
  //     document.title = data.artProdSEOTitle;
  //   } else if (param === "localization-qa") {
  //     document.title = data.localizationQASEOTitle;
  //   } else if (param === "datasets") {
  //     document.title = data.datasetsSEOTitle;
  //   }
  // };

  const servicesArray = [
    "audio-production",
    "player-support",
    "game-development",
    "quality-assurance",
    "localization",
    "art-production",
    "localization-qa",
    "datasets",
  ];

  if (servicesArray.includes(param)) {
    if (loader) {
      return <Loader />;
    }
  }
  let component;
  switch (param) {
    case "audio-production":
      component = <AudioProduction data={servicesPageData} />;
      break;
    case "player-support":
      component = <PlayerSupport PlayerData={servicesPageData} />;
      break;
    case "game-development":
      component = <GameDevelopment GameDevelopmentdata={servicesPageData} />;
      break;
    case "quality-assurance":
      component = <QualityAssurance data={servicesPageData} />;
      break;
    case "localization":
      component = <Localization data={servicesPageData} />;
      break;
    case "art-production":
      component = <ArtProduction data={servicesPageData} />;
      break;
    case "localization-qa":
      component = <LocalizationQA LocalizationQAdata={servicesPageData} />;
      break;
    case "datasets":
      component = <Datasets data={servicesPageData} />;
      break;
    default:
      component = <PageNotFound />;
  }

  return <main>{component}</main>;
};

export default Services;
