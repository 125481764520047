// ParagraphSection.jsx
import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateTo } from "../../constants/GeneralConstants";
import { clientUrls } from "../../constants/ClientUrls";
import FormatText from "../FormatText";
import ButtonSlant from "../ButtonSlant";

const ParagraphSection = ({ servicePageData, color }) => {
  const navigate = useNavigate();
  const {
    topSection_headingText,
    topSection_introParagraph1Text,
    topSection_introParagraph2Text,
    topSection_getInTouchText,
  } = servicePageData || {};

  return (
    <section className="container text-white lg:pl-12 3xl:pl-16">
      <div className="w-full sm:w-[98%] lg:w-[75%]">
        {/* <h2 className="uppercase text-[32px] lg:text-[38.5px] xl:text-[48px] 2xl:text-[57.6px] 3xl:text-[72px] 4xl:text-[86.4px] leading-[1] text-white w-[21.5rem] sm:w-auto lg:w-[43rem] 2xl:w-[40rem] 3xl:w-[48rem] mb-4 sm:mb-6"> */}
        <h2 className="uppercase text-[32px] lg:text-[38.5px] xl:text-[48px] 2xl:text-[53px] 3xl:text-[68px] 4xl:text-[82px] leading-[1] text-white w-auto mb-4 sm:mb-6">
          {topSection_headingText}
        </h2>
        <p className="mb-4 sm:mb-6 font-18-18-18 leading-[120%] sm:font-medium">
          <FormatText text={topSection_introParagraph1Text} />
        </p>
        <p className="mb-4 sm:mb-6 font-18-18-18 leading-[120%] sm:font-medium">
          <FormatText text={topSection_introParagraph2Text} />
        </p>

        <ButtonSlant
          slantClass="bottom-right-slant"
          divClass="w-full sm:w-fit"
          buttonClass="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] px-5 py-2.5 4xl:py-3 4xl:px-4 border-[3px] font-dela rounded-md w-full sm:w-fit "
          textColor={color.primary}
          textColorHover={color.tertiary}
          bgColorHover={color.primary}
          borderColor={color.primary}
          buttonText={topSection_getInTouchText}
          onClick={(e) => navigate(navigateTo(clientUrls.contact))}
        />
      </div>
    </section>
  );
};

export default ParagraphSection;
