import { useEffect, useState } from "react";
import { getLanguage, navigateTo } from "../constants/GeneralConstants";
import { getCompanyProfileData } from "../services/ApiService";
import Buttons from "../components/Buttons";
import { clientUrls } from "../constants/ClientUrls";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import { setColor } from "../store/reducers/ColorReducer";
import { useDispatch } from "react-redux";
import FormatText from "../components/FormatText";
import ButtonSlant from "../components/ButtonSlant";
import { SlantImg } from "../components/CommonComponents";

const CompanyProfile = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const [companyProfileData, setCompanyProfileData] = useState(null);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [parentCompanyDetails, setParentCompanyDetails] = useState([]);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  useEffect(() => {
    dispatch(
      setColor({
        primary: "#381665",
        secondary: "#9647FF",
        tertiary: "#ffffff",
        footerSecondary: "#FF5DD2",
      })
    );
  }, [companyProfileData]);
  useEffect(() => {
    callCompanyProfileData(getLanguage());
  }, [lang]);

  const callCompanyProfileData = async (language) => {
    try {
      let data = await getCompanyProfileData(language);
      setCompanyProfileData(data?.companyProfileStaticData);
      setCompanyDetails(data?.companyDetails);
      setParentCompanyDetails(data?.parentCompanyDetails);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };
  if (loader) {
    return <Loader />;
  }
  return (
    <>
      <section className="cp-page">
        {/* top banner section starts here  */}

        <section className="bg-[#381665] text-[#FF5DD2] mobile-about-slantk relative">
          <div className="hidden lg:block w-auto h-full absolute top-0 right-0 ">
            <img
              src={companyProfileData?.bannerSection_bgImageURL}
              alt="banner image"
              className=" h-full w-auto "
            />
          </div>
          <div className="hidden sm:block lg:hidden h-full absolute top-0 right-0">
            <img
              // src="/images/cp-banner-tab.png"
              src={companyProfileData?.bannerSection_bgImageTabURL}
              alt="banner image"
              className=" h-full w-auto "
            />
          </div>
          <div className="block sm:hidden w-full h-[18rem]k h-auto absolute bottom-0">
            <img
              // src="/images/cp-banner-mobile.png"
              src={companyProfileData?.bannerSection_bgImageMobURL}
              alt="banner image"
              className=" h-full w-full"
            />
          </div>
          <div className="absolute right-0 -bottom-[1px] z-[10]">
            <SlantImg className="h-[100px] sm:h-auto" />
          </div>
          <div className="container mx-auto h-[47rem] xxs:h-[55rem] sm:h-[25rem] md:h-[35rem] lg:h-[34rem] xl:h-[35rem] 2xl:h-[38rem] 3xl:!h-[42rem] 4xl:h-[55rem] mb-16 sm:mb-20  lg:mb-28 rounded-lg  flex flex-col sm:flex-row items-center gap-8 3xl:gap-16">
            <div className="pt-[7rem] sm:pt-[3rem] 2xl:pt-[5rem]">
              <h3
                className="font-30-36-60 uppercase leading-[1] w-[60%]"
                // className="font-18-18-18 uppercase"
              >
                {companyProfileData?.bannerSection_companyProfileText}
              </h3>

              <h2 className="text-[36px] sm:text-[42px] md:text-[48px] lg:text-[57.6px] xl:text-[64px] 2xl:text-[78px]  3xl:text-[100px] 4xl:text-[121.6px] leading-[100%] sm:leading-[90%] mb-7 lg:mb-8 3xl:mb-16 uppercase">
                <span className="block">
                  {companyProfileData?.bannerSection_whatText}{" "}
                  {companyProfileData?.bannerSection_makesText}
                </span>
                <span className="block">
                  {companyProfileData?.bannerSection_usText}{" "}
                  {companyProfileData?.bannerSection_uniqueText}
                </span>
              </h2>
              <p className="text-white mb-4 sm:mb-6 font-18-18-18 leading-[120%] sm:font-medium sm:w-[60%]">
                <FormatText
                  text={companyProfileData?.bannerSection_bannerParagraphText}
                />
              </p>
              <ButtonSlant
                onClick={() => navigate(navigateTo(clientUrls.about))}
                buttonText={companyProfileData?.bannerSection_aboutUsText}
                buttonClass="uppercase font-18-18-18 text-[#FF5DD2] font-bold border-[#FF5DD2] border-[2px] px-6 py-2 4xl:py-4 4xl:px-8 rounded-md  text-map-button font-dela"
                divClass="w-fit"
                textColorHover="#1a1a1a"
                bgColorHover="#ff5dd2"
                slantClass="bottom-right-slant"
              />
            </div>
          </div>
        </section>
        {/* top banner section ends here  */}

        <div className="container  mb-20 sm:mb-28 lg:mb-40 ">
          <h2 className="uppercase text-patner-header leading-[1] text-white mb-4 sm:mb-8 ">
            {companyProfileData?.companyDetailsText}
          </h2>
          <div className="company-details">
            {companyDetails.map((company, index) => {
              return (
                <div
                  key={index}
                  className="sm:flex mb-4 text-white text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px]"
                >
                  <div className="w-full sm:w-1/3 text-[#FF5DD2] font-[800] ">
                    {company.companyTitle}
                  </div>
                  <div className="w-full sm:w-1/2">
                    {company.companyDescription}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="container  mb-20 sm:mb-28 lg:mb-20 ">
          <h2 className="uppercase text-patner-header leading-[1] text-white mb-4 sm:mb-8 ">
            {companyProfileData?.parentCompanyText}
          </h2>
          <div className="company-details">
            {parentCompanyDetails.map((company, index) => {
              return (
                <div
                  key={index}
                  className="sm:flex mb-4 text-white text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px]"
                >
                  <div className="w-full sm:w-1/3 text-[#FF5DD2] font-[800]">
                    {company.parentCompanyTitle}
                  </div>
                  <div
                    className={`${
                      index === 0 ? "underline hover:cursor-pointer" : ""
                    } w-full sm:w-1/2`}
                    onClick={() => {
                      if (index === 0) {
                        window.open("https://www.phd.inc/en", "_blank");
                      }
                    }}
                  >
                    {company.parentCompanyDescription}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CompanyProfile;
