import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../css/componentscss/Footer.css";
import { navigateTo } from "../constants/GeneralConstants";
import { clientUrls } from "../constants/ClientUrls";
import ButtonSlant from "../components/ButtonSlant";
import { useEffect, useState } from "react";
import { SlantImg } from "./CommonComponents";
const Footer = () => {
  const navigate = useNavigate();
  const footerData = useSelector((state) => state.language.footerData);
  const footerOptions = footerData.footerData;
  const animatedText =
    footerOptions?.animatedText.length > 0 ? footerOptions?.animatedText : [];
  const servicesMenu = footerData.servicesMenu;
  const { primary, secondary, tertiary, footerSecondary } = useSelector(
    (state) => state.color.color
  );
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % animatedText.length);
    }, 1000);

    return () => clearInterval(interval);
  }, [animatedText?.length]);

  const today = new Date();
  const formattedDate = today
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
    .replace(/ /g, "-");

  // const {socialMedia_linkedinIcon,socialMedia_linkedinIcon,socialMedia_instagramURL,socialMedia_facebookIcon,socialMedia_youtubeIcon} = icons

  // console.log(footerData);

  const pageScrollTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 300);
  };
  return (
    <>
      {/* Footer Component goes here */}
      {/* final ui  */}
      <footer className="sm:my-8 lg:my-0 lg:py-12 sm:px-4 relative sm:mx-[1.5rem]">
        <div
          className="w-full rounded-lg relative sm:slant-bottom-right-footerk px-8 py-16 mt-[5rem] text-left slant-top-footerk slant-bottom-footerk"
          style={{ backgroundColor: primary }}
        >
          <div className="absolute -right-[1px] -bottom-[1px] hidden sm:block">
            <SlantImg className="h-[60px] sm:h-[75px] lg:h-[85px]" />
          </div>
          <div className="absolute top-[-1px] left-[-1px] z-[10] rotate-180 sm:hidden">
            <SlantImg className="h-[70px] sm:h-[75px] lg:h-[80px]" />
          </div>
          <div className="lg:flex lg:justify-between w-full lg:w-[95%] mx-auto">
            <div className="w-full md:w-1/3 lg:w-[21%] xl:w-1/3 mb-6 lg:mb-0">
              <Link
                to={navigateTo(clientUrls.home)}
                className="lg:flex items-center"
              >
                <img
                  src={footerOptions?.footerSideLogo}
                  className="me-3 h-[70px] sm:h-[85px] xl:h-[110px] 1.5xl:h-[160px]"
                  alt="side-ptw"
                  onClick={pageScrollTop}
                />
              </Link>
            </div>
            <div className="flex flex-col sm:flex-row lg:hidden sm:gap-5 sm:mb-4">
              <h3
                className="text-[32px] sm:text-[27px] md:text-[32px] xl:text-[32px] 2xl-text-[35px] 3xl:text-[41px] 4xl:text-[57px] overflow-visible whitespace-nowrap w-[55%] font-dela-gothic"
                style={{
                  color: footerSecondary ? footerSecondary : tertiary,
                  fontWeight:
                    animatedText[index]?.shoutOutAnimateText === "공유하세요!"
                      ? 900
                      : 400,
                }}
              >
                {/* N'HÉSITEZ PAS! */}
                {/* {footerOptions?.shoutOutText} */}
                {animatedText.length > 0
                  ? animatedText[index]?.shoutOutAnimateText
                  : ""}
              </h3>
              <div className="btn-white-wrapper1 box">
                <ButtonSlant
                  buttonClass="font-16-16-16  px-4 py-[0.6rem] 3xl:py-[0.8rem] 4xl:py-3 4xl:px-5 border-[2px] 3xl:border-[3px] 4xl:border-[4px] text-base  xl:text-lg 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[30px] rounded-[5px] font-dela mt-3 w-"
                  slantClass="bottom-right-slant"
                  textColor={footerSecondary ? footerSecondary : tertiary}
                  textColorHover={primary}
                  bgColorHover={footerSecondary ? footerSecondary : tertiary}
                  borderColor={footerSecondary ? footerSecondary : tertiary}
                  onClick={() => {
                    pageScrollTop();
                    navigate(navigateTo(clientUrls.contact));
                  }}
                  buttonText={footerOptions?.getInTouchText}
                />
              </div>
            </div>
            <hr
              className="my-5 border  sm:hidden"
              style={{
                borderColor: footerSecondary ? footerSecondary : tertiary,
              }}
            ></hr>
            <div className="w-full sm:w-full lg:w-[79%] xl:w-2/3">
              <div className="sm:flex lg:flex-wrap">
                <div className="w-full sm:w-[25%] md:w-[28%] xl:w-1/3 sm:p-2">
                  {/* <div className="about-ul"> */}
                  <ul
                    className="relative font-18-18-18  lg:float-right sm:pr-[2rem] lg:pr-[1rem] xl:pr-[4rem] font-semibold sm:font-normal w-fit"
                    style={{ color: tertiary }}
                  >
                    <hr
                      className="hidden sm:block right-borderl w-[1px] h-full border-none border-r  absolute sm-only:-right-0 md:right-0 lg:right-1 xl:right-5 transform rotate-180"
                      style={{
                        backgroundColor: footerSecondary
                          ? footerSecondary
                          : tertiary,
                      }}
                    ></hr>

                    <li className="mb-4 transition-all duration-500 hover:scale-[1.08] w-fit">
                      <Link
                        to={navigateTo(clientUrls.projects)}
                        className=""
                        onClick={pageScrollTop}
                      >
                        {footerOptions?.projectsText}
                      </Link>
                    </li>
                    <li className="mb-4 transition-all duration-500 hover:scale-[1.08] w-fit">
                      <Link
                        to={navigateTo(clientUrls.about)}
                        className=""
                        onClick={pageScrollTop}
                      >
                        {footerOptions?.aboutText}
                      </Link>
                    </li>
                    <li className="mb-4 transition-all duration-500 hover:scale-[1.08] w-fit">
                      <Link
                        to={navigateTo(clientUrls.careers)}
                        className=""
                        onClick={pageScrollTop}
                      >
                        {footerOptions?.careersText}
                      </Link>
                    </li>
                    <li className="mb-4 transition-all duration-500   hover:scale-[1.08]">
                      <Link
                        to={navigateTo(clientUrls.news)}
                        className=""
                        onClick={pageScrollTop}
                      >
                        {footerOptions?.newsText}
                      </Link>
                    </li>
                    <li className="mb-4 transition-all duration-500   hover:scale-[1.08] w-fit">
                      <Link
                        to={navigateTo(clientUrls.labs)}
                        className=""
                        onClick={pageScrollTop}
                      >
                        {footerOptions?.labText}
                      </Link>
                    </li>
                  </ul>
                  <hr
                    className="my-2 border  sm:hidden"
                    style={{
                      borderColor: footerSecondary ? footerSecondary : tertiary,
                    }}
                  ></hr>
                  {/* </div> */}
                </div>
                <div className="w-full sm:w-[75%] md:w-[72%] xl:w-2/3 sm:p-2">
                  <p
                    className="font-18-18-18 font-black sm:font-semibold mb-4 sm:px-4"
                    style={{ color: tertiary }}
                  >
                    {footerOptions?.servicesText}
                  </p>
                  {/* <ul
                    className="font-18-18-18 font-black sm:font-semibold mb-4 sm:px-2"
                    style={{ color: tertiary }}
                  >
                    <li>{footerOptions?.servicesText}</li>
                  </ul> */}
                  <ul
                    className="font-18-18-18 sm:columns-2 font-semibold sm:font-normal"
                    style={{ color: tertiary }}
                  >
                    {servicesMenu?.map((service, index) => (
                      <li
                        key={index}
                        className="mb-4 transition-all duration-500 hover:scale-[1.08]  sm:px-4 w-fit"
                      >
                        <Link
                          to={navigateTo(
                            `${clientUrls.services}/${service.serviceURL}`
                          )}
                          onClick={pageScrollTop}
                        >
                          {service.serviceName}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr
            className="my-2 border  sm:hidden"
            style={{
              borderColor: footerSecondary ? footerSecondary : tertiary,
            }}
          ></hr>
          <div className="lg:flex lg:justify-between w-full lg:w-[95%] mx-auto flex flex-wrap">
            <div className="w-full sm:w-[30%] xl:w-1/4 ">
              <div className="mb-6 sm:mb-0 hidden sm:block">
                <h3
                  className="text-[32px] xl:text-[32px] 2xl-text-[35px] 3xl:text-[41px] 4xl:text-[57px] sm:hidden lg:block overflow-visible whitespace-nowrap font-dela-gothic"
                  style={{
                    color: footerSecondary ? footerSecondary : tertiary,
                    fontWeight:
                      animatedText[index]?.shoutOutAnimateText === "공유하세요!"
                        ? 900
                        : 400,
                  }}
                >
                  {/* 공유하세요! */}
                  {/* {footerOptions?.shoutOutText} */}
                  {animatedText.length > 0
                    ? animatedText[index]?.shoutOutAnimateText
                    : ""}
                </h3>
                <div className="btn-white-wrapper1 box sm:hidden lg:block">
                  <ButtonSlant
                    buttonClass="font-16-16-16  px-4 py-[0.6rem] 3xl:py-[0.8rem] 4xl:py-3 4xl:px-5 border-[2px] 3xl:border-[3px] 4xl:border-[4px] text-base  xl:text-lg 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[30px] rounded-[5px] font-dela mt-3"
                    slantClass="bottom-right-slant"
                    textColor={footerSecondary ? footerSecondary : tertiary}
                    textColorHover={primary}
                    bgColorHover={footerSecondary ? footerSecondary : tertiary}
                    borderColor={footerSecondary ? footerSecondary : tertiary}
                    onClick={() => {
                      pageScrollTop();
                      navigate(navigateTo(clientUrls.contact));
                    }}
                    buttonText={footerOptions?.getInTouchText}
                    divClass="w-fit"
                  />
                </div>
                {/* social media starts here  */}
                <div className="h-auto lg:pt-8 flex items-center justify-start sm:gap-1 xl:gap-4 2xl:gap-5 3xl:gap-6 lg:flex-wrap ">
                  <Link
                    to={footerOptions?.socialMedia_linkedinURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="rounded-lg flex items-center justify-center  transition-all ease-in-out   duration-500 hover:scale-[1.1]"
                  >
                    <svg
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[32px] h-[33px] 2xl:w-[35px] 2xl:h-[36px] 3xl:w-[40px] 3xl:h-[41px]"
                    >
                      <path
                        d="M26 5.88379H6C5.44772 5.88379 5 6.3315 5 6.88379V26.8838C5 27.4361 5.44772 27.8838 6 27.8838H26C26.5523 27.8838 27 27.4361 27 26.8838V6.88379C27 6.3315 26.5523 5.88379 26 5.88379Z"
                        stroke={footerSecondary ? footerSecondary : tertiary}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15 14.8838V22.8838"
                        stroke={footerSecondary ? footerSecondary : tertiary}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 14.8838V22.8838"
                        stroke={footerSecondary ? footerSecondary : tertiary}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15 18.3838C15 17.4555 15.3687 16.5653 16.0251 15.9089C16.6815 15.2525 17.5717 14.8838 18.5 14.8838C19.4283 14.8838 20.3185 15.2525 20.9749 15.9089C21.6313 16.5653 22 17.4555 22 18.3838V22.8838"
                        stroke={footerSecondary ? footerSecondary : tertiary}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 12.3838C11.8284 12.3838 12.5 11.7122 12.5 10.8838C12.5 10.0554 11.8284 9.38379 11 9.38379C10.1716 9.38379 9.5 10.0554 9.5 10.8838C9.5 11.7122 10.1716 12.3838 11 12.3838Z"
                        fill={footerSecondary ? footerSecondary : tertiary}
                      />
                    </svg>
                  </Link>

                  <Link
                    to={footerOptions?.socialMedia_instagramURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" rounded-lg flex items-center  justify-center transition-all ease-in-out   duration-500 hover:scale-[1.1]"
                  >
                    <svg
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[32px] h-[33px] 2xl:w-[35px] 2xl:h-[36px] 3xl:w-[40px] 3xl:h-[41px]"
                    >
                      <path
                        d="M16 21.8838C18.7614 21.8838 21 19.6452 21 16.8838C21 14.1224 18.7614 11.8838 16 11.8838C13.2386 11.8838 11 14.1224 11 16.8838C11 19.6452 13.2386 21.8838 16 21.8838Z"
                        stroke={footerSecondary ? footerSecondary : tertiary}
                        stroke-width="2"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M21.5 5.38379H10.5C7.18629 5.38379 4.5 8.07008 4.5 11.3838V22.3838C4.5 25.6975 7.18629 28.3838 10.5 28.3838H21.5C24.8137 28.3838 27.5 25.6975 27.5 22.3838V11.3838C27.5 8.07008 24.8137 5.38379 21.5 5.38379Z"
                        stroke={footerSecondary ? footerSecondary : tertiary}
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M22.5 11.8838C23.3284 11.8838 24 11.2122 24 10.3838C24 9.55536 23.3284 8.88379 22.5 8.88379C21.6716 8.88379 21 9.55536 21 10.3838C21 11.2122 21.6716 11.8838 22.5 11.8838Z"
                        fill={footerSecondary ? footerSecondary : tertiary}
                      />
                    </svg>
                  </Link>
                  <Link
                    to={footerOptions?.socialMedia_facebookURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 rounded-lg flex items-center justify-center transition-all ease-in-out   duration-500 hover:scale-[1.1]"
                  >
                    <svg
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[32px] h-[33px] 2xl:w-[35px] 2xl:h-[36px] 3xl:w-[40px] 3xl:h-[41px]"
                    >
                      <path
                        d="M16 28.8838C22.6274 28.8838 28 23.5112 28 16.8838C28 10.2564 22.6274 4.88379 16 4.88379C9.37258 4.88379 4 10.2564 4 16.8838C4 23.5112 9.37258 28.8838 16 28.8838Z"
                        stroke={footerSecondary ? footerSecondary : tertiary}
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21 11.8838H19C18.2044 11.8838 17.4413 12.1999 16.8787 12.7625C16.3161 13.3251 16 14.0881 16 14.8838V28.8838"
                        stroke={footerSecondary ? footerSecondary : tertiary}
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 18.8838H20"
                        stroke={footerSecondary ? footerSecondary : tertiary}
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Link>
                  <Link
                    to={footerOptions?.socialMedia_youtubeURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" rounded-lg flex items-center  justify-center transition-all ease-in-out   duration-500 hover:scale-[1.1]"
                  >
                    <svg
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[32px] h-[33px] 2xl:w-[35px] 2xl:h-[36px] 3xl:w-[40px] 3xl:h-[41px]"
                    >
                      <path
                        d="M20 16.8838L14 12.8838V20.8838L20 16.8838Z"
                        stroke={footerSecondary ? footerSecondary : tertiary}
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3 16.8838C3 20.6032 3.38427 22.7855 3.67638 23.9071C3.75447 24.2134 3.90442 24.4967 4.11382 24.7335C4.32322 24.9704 4.58603 25.1539 4.88049 25.2689C9.0651 26.877 16 26.8328 16 26.8328C16 26.8328 22.9348 26.877 27.1195 25.2689C27.4139 25.1539 27.6767 24.9704 27.8861 24.7336C28.0955 24.4967 28.2455 24.2134 28.3236 23.9071C28.6157 22.7855 29 20.6032 29 16.8838C29 13.1643 28.6157 10.982 28.3236 9.86042C28.2455 9.5541 28.0956 9.27079 27.8862 9.03397C27.6768 8.79715 27.414 8.61364 27.1195 8.49863C22.9349 6.89053 16 6.93469 16 6.93469C16 6.93469 9.06518 6.89053 4.88053 8.49861C4.58607 8.61362 4.32325 8.79713 4.11385 9.03395C3.90446 9.27077 3.7545 9.55408 3.67641 9.8604C3.38428 10.982 3 13.1643 3 16.8838Z"
                        stroke={footerSecondary ? footerSecondary : tertiary}
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Link>
                  <Link
                    to={footerOptions?.socialMedia_xURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" rounded-lg flex items-center  justify-center transition-all ease-in-out   duration-500 hover:scale-[1.1]"
                  >
                    <svg
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-[32px] h-[33px] 2xl:w-[35px] 2xl:h-[36px] 3xl:w-[40px] 3xl:h-[41px]"
                    >
                      <path
                        d="M4.05854 4.88379L13.3256 18.1229L4 28.8838H6.1L14.2622 19.4597L20.8585 28.8838H28L18.2134 14.902L26.8915 4.88379H24.7951L17.2768 13.5613L11.2037 4.88379H4.05854ZM7.14634 6.5333H10.428L24.9159 27.2304H21.6341L7.14634 6.5333Z"
                        fill={footerSecondary ? footerSecondary : tertiary}
                      />
                    </svg>
                  </Link>
                </div>
                {/* social media ends here  */}
              </div>
            </div>

            <div className="w-full sm:w-[70%] xl:w-3/4 lg:flex justify-end items-end">
              <div
                className="sm:flex sm:flex-wrap gap-8 sm:gap-2 xl:gap-6 sm:grid-cols-2 justify-end my-2  text-[16px] sm-only:text-[9px] md:text-[12px] 4xl:text-base leading-[1] font-semibold sm:font-normal sm:text-center items-center sm:pt-4 lg:pt-0"
                style={{ color: footerSecondary ? footerSecondary : tertiary }}
              >
                <p className="transition-all duration-500   hover:scale-[1.08] mb-4 sm:mb-0 w-fit">
                  <Link
                    className="font-semibold sm:font-medium"
                    to={footerOptions?.termsAndConditionsURL}
                  >
                    {footerOptions?.termsAndConditionsText}
                  </Link>
                </p>
                <p className="transition-all duration-500   hover:scale-[1.08] mb-4 sm:mb-0 w-fit">
                  <Link
                    className="font-semibold sm:font-medium"
                    to={footerOptions?.privacyPolicyURL}
                  >
                    {footerOptions?.privacyPolicyText}
                  </Link>
                </p>
                <p className="transition-all duration-500   hover:scale-[1.08] mb-4 sm:mb-0 w-fit">
                  <Link
                    className="font-semibold sm:font-medium"
                    to={footerOptions?.policiesURL}
                  >
                    {footerOptions?.policiesText}
                  </Link>
                </p>
                <p className="transition-all duration-500   hover:scale-[1.08] mb-4 sm:mb-0 w-fit">
                  <Link
                    className="font-semibold sm:font-medium"
                    target="_blank"
                    to={footerOptions?.anAstraeusCompanyURL}
                  >
                    {footerOptions?.anAstraeusCompanyText}
                  </Link>
                </p>

                {/* <p className="hidden lg:block">Build Date : {formattedDate}</p> */}
                <p className="transition-all duration-500   hover:scale-[1.08] font-semibold sm:font-medium mb-4 sm:mb-0 w-fit">
                  {footerOptions?.allRightsReservedText}
                </p>
              </div>
            </div>
            {/* mobile social media starts here  */}
            <div className="h-auto lg:pt-8 flex sm:hidden items-center justify-start gap-6 xxs:gap-8 xs:gap-12">
              <Link
                to={footerOptions?.socialMedia_linkedinURL}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-lg flex items-center justify-center  transition-all ease-in-out   duration-500 hover:scale-[1.1]"
              >
                <svg
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-[32px] h-[33px] 2xl:w-[35px] 2xl:h-[36px] 3xl:w-[40px] 3xl:h-[41px]"
                >
                  <path
                    d="M26 5.88379H6C5.44772 5.88379 5 6.3315 5 6.88379V26.8838C5 27.4361 5.44772 27.8838 6 27.8838H26C26.5523 27.8838 27 27.4361 27 26.8838V6.88379C27 6.3315 26.5523 5.88379 26 5.88379Z"
                    stroke={footerSecondary ? footerSecondary : tertiary}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 14.8838V22.8838"
                    stroke={footerSecondary ? footerSecondary : tertiary}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11 14.8838V22.8838"
                    stroke={footerSecondary ? footerSecondary : tertiary}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 18.3838C15 17.4555 15.3687 16.5653 16.0251 15.9089C16.6815 15.2525 17.5717 14.8838 18.5 14.8838C19.4283 14.8838 20.3185 15.2525 20.9749 15.9089C21.6313 16.5653 22 17.4555 22 18.3838V22.8838"
                    stroke={footerSecondary ? footerSecondary : tertiary}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11 12.3838C11.8284 12.3838 12.5 11.7122 12.5 10.8838C12.5 10.0554 11.8284 9.38379 11 9.38379C10.1716 9.38379 9.5 10.0554 9.5 10.8838C9.5 11.7122 10.1716 12.3838 11 12.3838Z"
                    fill={footerSecondary ? footerSecondary : tertiary}
                  />
                </svg>
              </Link>

              <Link
                to={footerOptions?.socialMedia_instagramURL}
                target="_blank"
                rel="noopener noreferrer"
                className=" rounded-lg flex items-center  justify-center transition-all ease-in-out   duration-500 hover:scale-[1.1]"
              >
                <svg
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-[32px] h-[33px] 2xl:w-[35px] 2xl:h-[36px] 3xl:w-[40px] 3xl:h-[41px]"
                >
                  <path
                    d="M16 21.8838C18.7614 21.8838 21 19.6452 21 16.8838C21 14.1224 18.7614 11.8838 16 11.8838C13.2386 11.8838 11 14.1224 11 16.8838C11 19.6452 13.2386 21.8838 16 21.8838Z"
                    stroke={footerSecondary ? footerSecondary : tertiary}
                    stroke-width="2"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M21.5 5.38379H10.5C7.18629 5.38379 4.5 8.07008 4.5 11.3838V22.3838C4.5 25.6975 7.18629 28.3838 10.5 28.3838H21.5C24.8137 28.3838 27.5 25.6975 27.5 22.3838V11.3838C27.5 8.07008 24.8137 5.38379 21.5 5.38379Z"
                    stroke={footerSecondary ? footerSecondary : tertiary}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.5 11.8838C23.3284 11.8838 24 11.2122 24 10.3838C24 9.55536 23.3284 8.88379 22.5 8.88379C21.6716 8.88379 21 9.55536 21 10.3838C21 11.2122 21.6716 11.8838 22.5 11.8838Z"
                    fill={footerSecondary ? footerSecondary : tertiary}
                  />
                </svg>
              </Link>
              <Link
                to={footerOptions?.socialMedia_facebookURL}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 rounded-lg flex items-center justify-center transition-all ease-in-out   duration-500 hover:scale-[1.1]"
              >
                <svg
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-[32px] h-[33px] 2xl:w-[35px] 2xl:h-[36px] 3xl:w-[40px] 3xl:h-[41px]"
                >
                  <path
                    d="M16 28.8838C22.6274 28.8838 28 23.5112 28 16.8838C28 10.2564 22.6274 4.88379 16 4.88379C9.37258 4.88379 4 10.2564 4 16.8838C4 23.5112 9.37258 28.8838 16 28.8838Z"
                    stroke={footerSecondary ? footerSecondary : tertiary}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 11.8838H19C18.2044 11.8838 17.4413 12.1999 16.8787 12.7625C16.3161 13.3251 16 14.0881 16 14.8838V28.8838"
                    stroke={footerSecondary ? footerSecondary : tertiary}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 18.8838H20"
                    stroke={footerSecondary ? footerSecondary : tertiary}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>
              <Link
                to={footerOptions?.socialMedia_youtubeURL}
                target="_blank"
                rel="noopener noreferrer"
                className=" rounded-lg flex items-center  justify-center transition-all ease-in-out   duration-500 hover:scale-[1.1]"
              >
                <svg
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-[32px] h-[33px] 2xl:w-[35px] 2xl:h-[36px] 3xl:w-[40px] 3xl:h-[41px]"
                >
                  <path
                    d="M20 16.8838L14 12.8838V20.8838L20 16.8838Z"
                    stroke={footerSecondary ? footerSecondary : tertiary}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3 16.8838C3 20.6032 3.38427 22.7855 3.67638 23.9071C3.75447 24.2134 3.90442 24.4967 4.11382 24.7335C4.32322 24.9704 4.58603 25.1539 4.88049 25.2689C9.0651 26.877 16 26.8328 16 26.8328C16 26.8328 22.9348 26.877 27.1195 25.2689C27.4139 25.1539 27.6767 24.9704 27.8861 24.7336C28.0955 24.4967 28.2455 24.2134 28.3236 23.9071C28.6157 22.7855 29 20.6032 29 16.8838C29 13.1643 28.6157 10.982 28.3236 9.86042C28.2455 9.5541 28.0956 9.27079 27.8862 9.03397C27.6768 8.79715 27.414 8.61364 27.1195 8.49863C22.9349 6.89053 16 6.93469 16 6.93469C16 6.93469 9.06518 6.89053 4.88053 8.49861C4.58607 8.61362 4.32325 8.79713 4.11385 9.03395C3.90446 9.27077 3.7545 9.55408 3.67641 9.8604C3.38428 10.982 3 13.1643 3 16.8838Z"
                    stroke={footerSecondary ? footerSecondary : tertiary}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>
              <Link
                to={footerOptions?.socialMedia_xURL}
                target="_blank"
                rel="noopener noreferrer"
                className=" rounded-lg flex items-center  justify-center transition-all ease-in-out   duration-500 hover:scale-[1.1]"
              >
                <svg
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-[32px] h-[33px] 2xl:w-[35px] 2xl:h-[36px] 3xl:w-[40px] 3xl:h-[41px]"
                >
                  <path
                    d="M4.05854 4.88379L13.3256 18.1229L4 28.8838H6.1L14.2622 19.4597L20.8585 28.8838H28L18.2134 14.902L26.8915 4.88379H24.7951L17.2768 13.5613L11.2037 4.88379H4.05854ZM7.14634 6.5333H10.428L24.9159 27.2304H21.6341L7.14634 6.5333Z"
                    fill={footerSecondary ? footerSecondary : tertiary}
                  />
                </svg>
              </Link>
            </div>
            {/* mobile social media ends here  */}
          </div>
        </div>
      </footer>

      {/* final ui  */}

      {/* Footer Component ends here */}
    </>
  );
};

export default Footer;
