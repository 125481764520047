import { Provider } from "react-redux";
import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import store from "./store/store";
import "./css/componentscss/Common.css";
import "./css/componentscss/CommonFont.css";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Services from "./pages/Services";
import LabsList from "./pages/Labs/LabsList";
import About from "./pages/About";
import Careers from "./pages/Careers";
import ProjectsList from "./pages/Projects/ProjectsList";
import NewsList from "./pages/News/NewsList";
import Contact from "./pages/Contact";
import { clientUrls } from "./constants/ClientUrls";
import CookieModal from "./components/CookieModal";
import PageNotFound from "./components/PageNotFound";
import CompanyProfile from "./pages/CompanyProfile";
import { getLanguage } from "./constants/GeneralConstants";
import ProjectDescription from "./pages/Projects/ProjectDescription";
import NewsDescription from "./pages/News/NewsDescription";
import LabsDescription from "./pages/Labs/LabsDescription";
import ReactPage from "./pages/react/ReactPage";
import Resolve from "./components/services/Resolve";
import PenTesting from "./components/services/PenTesting";
import ItSupport from "./pages/itsupport/ItSupport";
import NetworkError from "./components/NetworkError";
import Legal from "./components/Legal";

const ProtectedRoute = ({ children }) => {
  // const isAuthenticated = localStorage.getItem("authenticated") === "true";
  const isAuthenticated = true;
  return isAuthenticated ? children : <Navigate to="/" />;
};

const App = () => {
  const [showModal, setShowModal] = useState(false);
  // const [isAuthenticated, setIsAuthenticated] = useState(
  //   localStorage.getItem("authenticated") === "true"
  // );
  const [isOnline, setIsOnline] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const location = useLocation();
  useEffect(() => {
    // setIsAuthenticated(localStorage.getItem("authenticated") === "true");
    setIsAuthenticated(true);
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setShowModal(true);
    }
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowModal(false);
  };

  const handleReject = () => {
    localStorage.setItem("cookieConsent", "false");
    setShowModal(false);
  };

  const onClosingModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    document.body.classList.remove("heading-dela", "heading-noto-bold");
    if (["en", "fr-ca"].includes(getLanguage())) {
      document.body.classList.add("heading-dela");
    } else {
      document.body.classList.add("heading-noto-bold");
    }
  }, [getLanguage(), location]);
  if (!isOnline) {
    return (
      <>
        <Provider store={store}>
          <Header />
          <NetworkError />
          <Footer />
        </Provider>
      </>
    );
  }
  return (
    <Provider store={store}>
      {isAuthenticated ? (
        <>
          <ScrollToTop />
          <Header />
          <Routes>
            {/* Protected Routes */}
            <Route
              exact
              path={clientUrls.home}
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.homeWithLanguage}
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.subservices}
              element={
                <ProtectedRoute>
                  <Services />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.subservicesWithLanguage}
              element={
                <ProtectedRoute>
                  <Services />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.about}
              element={
                <ProtectedRoute>
                  <About />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.aboutWithLanguage}
              element={
                <ProtectedRoute>
                  <About />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.companyProfile}
              element={
                <ProtectedRoute>
                  <CompanyProfile />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.companyProfileWithLanguage}
              element={
                <ProtectedRoute>
                  <CompanyProfile />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.careers}
              element={
                <ProtectedRoute>
                  <Careers />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.careersWithLanguage}
              element={
                <ProtectedRoute>
                  <Careers />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.projects}
              element={
                <ProtectedRoute>
                  <ProjectsList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.projectsWithLanguage}
              element={
                <ProtectedRoute>
                  <ProjectsList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.projectsDescription}
              element={
                <ProtectedRoute>
                  <ProjectDescription />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.projectsDescriptionWithLanguage}
              element={
                <ProtectedRoute>
                  <ProjectDescription />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.news}
              element={
                <ProtectedRoute>
                  <NewsList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.newsWithLanguage}
              element={
                <ProtectedRoute>
                  <NewsList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.newsDescription}
              element={
                <ProtectedRoute>
                  <NewsDescription />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.pentesting}
              element={
                <ProtectedRoute>
                  <PenTesting />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.newsDescriptionWithLanguage}
              element={
                <ProtectedRoute>
                  <NewsDescription />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.labs}
              element={
                <ProtectedRoute>
                  <LabsList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.labsWithLanguage}
              element={
                <ProtectedRoute>
                  <LabsList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.labsDescription}
              element={
                <ProtectedRoute>
                  <LabsDescription />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.labsDescriptionWithLanguage}
              element={
                <ProtectedRoute>
                  <LabsDescription />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.contact}
              element={
                <ProtectedRoute>
                  <Contact />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.contactWithLanguage}
              element={
                <ProtectedRoute>
                  <Contact />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.subReactPage}
              element={
                <ProtectedRoute>
                  <ReactPage />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.subReactPagesWithLanguage}
              element={
                <ProtectedRoute>
                  <ReactPage />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.subResolvePage}
              element={
                <ProtectedRoute>
                  <Resolve />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.subResolvePagesWithLanguage}
              element={
                <ProtectedRoute>
                  <Resolve />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.pentesting}
              element={
                <ProtectedRoute>
                  <PenTesting />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.pentestingWithLanguage}
              element={
                <ProtectedRoute>
                  <PenTesting />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.websiteTermsOfUsage}
              element={
                <ProtectedRoute>
                  <Legal />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.websiteTermsOfUsageWithLanguage}
              element={
                <ProtectedRoute>
                  <Legal />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.privacyPolicy}
              element={
                <ProtectedRoute>
                  <Legal />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.privacyPolicyWithLanguage}
              element={
                <ProtectedRoute>
                  <Legal />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.modernSlaveryStatement}
              element={
                <ProtectedRoute>
                  <Legal />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path={clientUrls.modernSlaveryStatementWithLanguage}
              element={
                <ProtectedRoute>
                  <Legal />
                </ProtectedRoute>
              }
            />
            <Route exact path={clientUrls.itSupport} element={<ItSupport />} />

            <Route exact path="*" element={<PageNotFound />} />
          </Routes>
          {/* <Footer /> */}
          {showModal && (
            <CookieModal
              onAccept={handleAccept}
              onReject={handleReject}
              onClose={onClosingModal}
            />
          )}
        </>
      ) : (
        // Render only Password Page for unauthenticated users
        <Routes>
          {/* <Route exact path="/" element={<PasswordPage />} /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </Provider>
  );
};

export default App;
