import { useEffect, useRef, useState } from "react";
import {
  getProjectsFilterData,
  getProjectsListData,
  getProjectsPageData,
} from "../../services/ApiService";
import { getLanguage } from "../../constants/GeneralConstants";
import "../../css/pagescss/ProjectsList.css";
import ProjectsShowSection from "../../components/home/ProjectsShowSection";
import Pagination, { scrollToRef } from "../../components/Pagination";
import ProjectCard from "./ProjectCard";
import Loader from "../../components/Loader";
import { useDispatch } from "react-redux";
import { setColor } from "../../store/reducers/ColorReducer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Testimonials from "../../components/Testimonials";
import ButtonSlant from "../../components/ButtonSlant";
import { SlantImg } from "../../components/CommonComponents";

const ProjectsList = () => {
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const mobileScrollRef = useRef(null);
  const subFilterRef = useRef(null);
  const dispatch = useDispatch();
  const { lang } = useParams();
  const fromServicePage = sessionStorage.getItem("service");
  const location = useLocation();
  const [projectsPageData, setProjectsPageData] = useState(null);
  const [projectsFilterData, setProjectsFilterData] = useState(null);
  const [projectsListData, setProjectsListData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [services, setServices] = useState(
    fromServicePage && fromServicePage != "undefined" ? [fromServicePage] : []
  );
  const [genre, setGenre] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [resetTrigger, setResetTrigger] = useState(false);
  const [turnMobileOptionsMenu, setTurnMobileOptionsMenu] = useState(false);

  const [selectedSubFilters, setSelectedSubFilters] = useState(
    fromServicePage && fromServicePage != "undefined" ? [fromServicePage] : []
  );
  const [projectsLoader, setProjectsLoader] = useState(true);
  const [hoverStates, setHoverStates] = useState({});
  const [showSortFilterOptions, setShowShortFilterOptions] = useState(false);
  const [sorting, setSorting] = useState("N2O");

  const [showSortFilterOptionsMobile, setShowShortFilterOptionsMobile] =
    useState(false);
  const [activeShortFilter, setActiveShortFilter] = useState("");
  const shortingFilterRef = useRef(null);
  const shortingFilterRefMobile = useRef(null);
  const handleHoverChange = (key, isHover) => {
    setHoverStates((prevStates) => ({
      ...prevStates,
      [key]: isHover,
    }));
  };
  useEffect(() => {
    const handleBeforeUnload = () => {
      window.sessionStorage.removeItem("service");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.sessionStorage.removeItem("service");
    };
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    callProjectsPageData(getLanguage());
    callProjectsFilterData(getLanguage());
  }, [lang]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  const handleClickOutside = (event) => {
    if (
      subFilterRef.current &&
      !subFilterRef.current.contains(event.target) &&
      !event.target.closest(".platforms") &&
      !event.target.closest(".services") &&
      !event.target.closest(".genre")
    ) {
      setTimeout(() => {
        toggleDropdown(undefined);
      }, 150);
    }
    if (
      shortingFilterRef.current &&
      !shortingFilterRef.current.contains(event.target)
    ) {
      setShowShortFilterOptions(false);
    }
    if (
      shortingFilterRefMobile.current &&
      !shortingFilterRefMobile.current.contains(event.target)
    ) {
      setShowShortFilterOptionsMobile(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      // setServices([]);
      // setSelectedSubFilters([]);
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [subFilterRef]);

  useEffect(() => {
    callProjectsListData(getLanguage());
  }, [services, genre, platforms, sorting, lang]);

  const callProjectsListData = async (language, page = 1) => {
    setProjectsLoader(true);
    let obj = {
      sorting: sorting,
      services: services,
      dimensions: [],
      style: [],
      genre: genre,
      platforms: platforms,
      pageno: page,
    };
    try {
      let data = await getProjectsListData(language, obj);
      setProjectsListData(data?.projects);
      setTotalPages(Math.ceil(data?.totalrecords / 12));
      setProjectsLoader(false);
    } catch (err) {
      // setProjectsLoader(false);

      console.log(err);
    }
  };
  const callProjectsFilterData = async (language) => {
    try {
      let data = await getProjectsFilterData(language);
      setProjectsFilterData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const callProjectsPageData = async (language) => {
    setLoader(true);
    try {
      let data = await getProjectsPageData(language);
      document.title = data?.projectPageData?.projectSEOTitle;
      setProjectsPageData(data);

      setActiveShortFilter(data?.projectPageData?.newestToOldestText);

      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const onPaginationChange = (page) => {
    setCurrentPage(page);
    callProjectsListData(getLanguage(), page);
    setTimeout(() => {
      scrollToRef(window.innerWidth >= 640 ? scrollRef : mobileScrollRef);
    }, 100);
  };

  const serviceColors = [
    {
      post_id: 214,
      serviceName: "AUDIO PRODUCTION",
      serviceLink: "audio-production",
      colorCode: "#004780",
      secondaroyColor: "#2AD9FF",
      tertiaryColor: "#68FFD2",
    },
  ];
  useEffect(() => {
    dispatch(
      setColor({
        primary: "#004780",
        secondary: "#68FFD2",
        tertiary: "#ffffff",
        footerSecondary: "#2AD9FF",
      })
    );
  }, [dispatch]);
  const handleSubFilterClick = (e, subFilter) => {
    const classes = ["!border-[#68FFD2]", "!text-[#68FFD2]"];
    classes.forEach((cls) => e.target.classList.toggle(cls));

    let tempOptions;
    let tempSubFilters;

    if (openDropdown === "services") {
      tempOptions = services.includes(subFilter?.id)
        ? services.filter((id) => id !== subFilter.id)
        : [...services, subFilter?.id];
      setServices(tempOptions);
    }
    if (openDropdown === "genre") {
      tempOptions = genre.includes(subFilter?.id)
        ? genre.filter((id) => id !== subFilter.id)
        : [...genre, subFilter?.id];
      setGenre(tempOptions);
    }
    if (openDropdown === "platforms") {
      tempOptions = platforms.includes(subFilter?.id)
        ? platforms.filter((id) => id !== subFilter.id)
        : [...platforms, subFilter?.id];
      setPlatforms(tempOptions);
    }
    tempSubFilters = selectedSubFilters.includes(subFilter?.id)
      ? selectedSubFilters.filter((id) => id !== subFilter.id)
      : [...selectedSubFilters, subFilter?.id];
    setSelectedSubFilters(tempSubFilters);
  };

  const resetSelections = () => {
    const hasSelections =
      services.length > 0 ||
      genre.length > 0 ||
      platforms.length > 0 ||
      selectedFilter?.id;

    if (hasSelections) {
      navigate(location.pathname, { state: null });
      setServices([]);
      setGenre([]);
      setPlatforms([]);
      setSelectedFilter(projectsFilterData?.filters[0]);
      setSelectedSubFilters([]);
      setResetTrigger((prev) => !prev);
      window.sessionStorage.removeItem("service");
    }
  };

  const toggleDropdown = (dropdownId) => {
    setOpenDropdown((prev) => {
      const newState = prev === dropdownId ? null : dropdownId;
      return newState;
    });
  };

  if (loader) {
    return <Loader />;
  }

  const { projectPageData, projects, testimonials } = projectsPageData;
  return (
    <>
      <section className="project-page">
        <section
          className="bg-[#004780] flex relative pt-28 pb-24 lg:pb-12 mb-16 lg:mb-28 3xl:h-[50rem] 4xl:h-[55rem] slant-bottom-footer"
          // style={{
          //   backgroundImage: `url(${projectPageData?.bannerBgImage})`,
          // }}
        >
          {/* desktop  */}
          <div className="hidden lg:block absolute top-0 right-0 z-[-1] sm:w-[24rem] md:w-[26rem] lg:w-[30rem] xl:w-[35rem] 2xl:w-[40rem] 3xl:w-[50rem] ">
            <img src={projectPageData?.bannerBgImage} alt="side-ptw" />
          </div>
          {/* tablet  */}
          <div className="hidden sm:block lg:hidden absolute top-0 right-0 z-[-1] sm:w-[22rem] md:w-[24rem]">
            <img src={projectPageData?.bannerBgImageTab} alt="side-ptw" />
          </div>
          {/* mobile  */}
          <div className="block sm:hidden  absolute bottom-0 right-0 z-[-1] w-[27.5rem] xs:w-[31rem]">
            <img src={projectPageData?.bannerBgImageMob} alt="side-ptw" />
          </div>
          <div className="absolute right-0 -bottom-[1px] z-[10]">
            <SlantImg className="h-[100px] sm:h-auto" />
          </div>
          <ProjectsShowSection
            homeData={projectPageData}
            projectsData={projects}
            colors={{
              color: serviceColors[0]?.secondaroyColor,
            }}
            isProjectPage={true}
          />
        </section>
        {/* desktop filter starts here  */}
        <section ref={scrollRef} className="container hidden sm:block">
          <div className="flex capitalize mt-8 justify-between">
            <ul className="flex flex-wrap lg:flex-nowrap gap-4">
              <li className="list-none sm:hidden mr-8">
                {/* <button className="flex sm:hidden items-center justify-betweenk text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#68FFD2] border border-[#68FFD2]  bottom-right-slant-hover-sm hover:bg-[#68FFD2] hover:text-black px-5 py-2 rounded w-fit">
                  All
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2"
                  >
                    <path
                      d="M8.125 13.75L11.875 17.5L15.625 13.75"
                      stroke="#68FFD2"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                      stroke="#68FFD2"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button> */}
              </li>

              <li className="list-none relative services group">
                <ButtonSlant
                  onClick={(e) => {
                    toggleDropdown("services");
                  }}
                  buttonClass={`hidden sm:flex items-center  text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  border border-[#68FFD2] px-5 py-2 rounded w-fit ${
                    openDropdown === "services" || services.length > 0
                      ? "bg-[#68ffd2] bottom-right-slant-sm"
                      : ""
                  }`}
                  divClass="group"
                  buttonText={projectPageData?.servicesText}
                  textColor={
                    openDropdown === "services" || services.length > 0
                      ? "#1a1a1a"
                      : "#68ffd2"
                  }
                  textColorHover="#1a1a1a"
                  bgColor={
                    openDropdown === "services" || services.length > 0
                      ? "#68ffd2"
                      : ""
                  }
                  bgColorHover="#68ffd2"
                  slantClass="bottom-right-slant-sm"
                  onHoverChange={(isHover) =>
                    handleHoverChange("services", isHover)
                  }
                >
                  {services.length > 0 && (
                    <p className="ml-2  bg-[#1a1a1a] text-[#68FFD2] rounded-full h-auto w-[40px] sm:w-[27px] lg:w-[24px] xl:w-[36px] 3xl:w-[46px]">
                      {services.length}
                    </p>
                  )}
                  {openDropdown === "services" || services.length > 0 ? (
                    hoverStates?.services ||
                    openDropdown === "services" ||
                    services.length > 0 ? (
                      openDropdown != "services" ? (
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2"
                        >
                          <path
                            d="M8.125 13.75L11.875 17.5L15.625 13.75"
                            stroke="#1a1a1a"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                            stroke="#1a1a1a"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2 platforms"
                        >
                          <path
                            d="M8.625 6.25L12.375 2.5L16.125 6.25"
                            stroke="#1a1a1a"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                            stroke="#1a1a1a"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      )
                    ) : (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2 platforms"
                      >
                        <path
                          d="M8.625 6.25L12.375 2.5L16.125 6.25"
                          stroke="#68ffd2"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                          stroke="#68ffd2"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    )
                  ) : hoverStates?.services ? (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#1a1a1a"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#1a1a1a"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#68FFD2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#68FFD2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </ButtonSlant>
              </li>
              <li className="list-none relative platforms group">
                <ButtonSlant
                  onClick={(e) => {
                    toggleDropdown("platforms");
                  }}
                  buttonClass={`hidden sm:flex items-center  text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  border border-[#68FFD2] px-5 py-2 rounded w-fit ${
                    openDropdown === "platforms" || platforms.length > 0
                      ? "bg-[#68ffd2] bottom-right-slant-sm"
                      : ""
                  }`}
                  divClass="group"
                  buttonText={projectPageData?.platformText}
                  textColor={
                    openDropdown === "platforms" || platforms.length > 0
                      ? "#1a1a1a"
                      : "#68ffd2"
                  }
                  textColorHover="#1a1a1a"
                  bgColor={
                    openDropdown === "platforms" || platforms.length > 0
                      ? "#68ffd2"
                      : ""
                  }
                  bgColorHover="#68ffd2"
                  slantClass="bottom-right-slant-sm"
                  onHoverChange={(isHover) =>
                    handleHoverChange("platforms", isHover)
                  }
                >
                  {platforms.length > 0 && (
                    <p className="ml-2  bg-[#1a1a1a] text-[#68FFD2] rounded-full h-auto w-[40px] sm:w-[27px] lg:w-[24px] xl:w-[36px] 3xl:w-[46px]">
                      {platforms.length}
                    </p>
                  )}

                  {openDropdown === "platforms" || platforms.length > 0 ? (
                    hoverStates?.platforms ||
                    openDropdown === "platforms" ||
                    platforms.length > 0 ? (
                      openDropdown !== "platforms" ? (
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2"
                        >
                          <path
                            d="M8.125 13.75L11.875 17.5L15.625 13.75"
                            stroke="#1a1a1a"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                            stroke="#1a1a1a"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2 platforms"
                        >
                          <path
                            d="M8.625 6.25L12.375 2.5L16.125 6.25"
                            stroke="#1a1a1a"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                            stroke="#1a1a1a"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      )
                    ) : (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2 platforms"
                      >
                        <path
                          d="M8.625 6.25L12.375 2.5L16.125 6.25"
                          stroke="#68ffd2"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                          stroke="#68ffd2"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    )
                  ) : hoverStates?.platforms ? (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#1a1a1a"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#1a1a1a"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#68FFD2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#68FFD2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </ButtonSlant>
              </li>
              <li className="list-none genre group">
                <ButtonSlant
                  onClick={(e) => {
                    toggleDropdown("genre");
                  }}
                  buttonClass={`hidden sm:flex items-center  text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  border border-[#68FFD2] px-5 py-2 rounded w-fit ${
                    openDropdown === "genre" || genre.length > 0
                      ? "bg-[#68ffd2] bottom-right-slant-sm"
                      : ""
                  }`}
                  divClass="group"
                  buttonText={projectPageData?.genreText}
                  textColor={
                    openDropdown === "genre" || genre.length > 0
                      ? "#1a1a1a"
                      : "#68ffd2"
                  }
                  textColorHover="#1a1a1a"
                  bgColor={
                    openDropdown === "genre" || genre.length > 0
                      ? "#68ffd2"
                      : ""
                  }
                  bgColorHover="#68ffd2"
                  slantClass="bottom-right-slant-sm"
                  onHoverChange={(isHover) =>
                    handleHoverChange("genre", isHover)
                  }
                >
                  {genre.length > 0 && (
                    <p className="ml-2 bg-[#1a1a1a] text-[#68FFD2] rounded-full h-auto w-[40px] sm:w-[27px] lg:w-[24px] xl:w-[36px] 3xl:w-[46px]">
                      {genre.length}
                    </p>
                  )}

                  {openDropdown === "genre" || genre.length > 0 ? (
                    hoverStates?.genre ||
                    openDropdown === "genre" ||
                    genre.length > 0 ? (
                      openDropdown !== "genre" ? (
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2"
                        >
                          <path
                            d="M8.125 13.75L11.875 17.5L15.625 13.75"
                            stroke="#1a1a1a"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                            stroke="#1a1a1a"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2 platforms"
                        >
                          <path
                            d="M8.625 6.25L12.375 2.5L16.125 6.25"
                            stroke="#1a1a1a"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                            stroke="#1a1a1a"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      )
                    ) : (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2 platforms"
                      >
                        <path
                          d="M8.625 6.25L12.375 2.5L16.125 6.25"
                          stroke="#68ffd2"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                          stroke="#68ffd2"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    )
                  ) : hoverStates?.genre ? (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#1a1a1a"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#1a1a1a"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#68FFD2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#68FFD2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </ButtonSlant>
              </li>
              {selectedSubFilters.length > 0 && (
                //  || selectedFilterText !== projectPageData?.newestToOldestText
                <li className="list-none relative group">
                  <button
                    onClick={(e) => resetSelections()}
                    className="relative flex items-center hover-reset-svg text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer text-[#68FFD2] group-hover:text-white px-2 py-2 rounded w-fit"
                  >
                    {projectPageData?.resetText}
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="ml-2"
                    >
                      <polyline points="1 4 1 10 7 10" />
                      <path
                        d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"
                        stroke="#68FFD2"
                      />
                    </svg>
                  </button>
                </li>
              )}
            </ul>
            <div className="justify-end relative">
              <button
                ref={shortingFilterRef}
                onClick={(e) => {
                  setShowShortFilterOptions(!showSortFilterOptions);
                }}
                className="flex items-center justify-between text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px]  cursor-pointer  text-[#1a1a1a] bg-[#68FFD2] px-2 lg:px-5 py-2 rounded w-fit h-fit"
              >
                <span className="hidden lg:block">{activeShortFilter}</span>
                <svg
                  width="22"
                  height="22"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="lg:ml-3"
                >
                  <path
                    stroke="#1a1a1a"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                  />
                </svg>
              </button>
            </div>
          </div>
          {showSortFilterOptions && (
            <div className="hidden sm:flex justify-end mt-4 relative">
              <div className=" z-10 w-screen max-w-[15.5rem] rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 backdrop-blur-lg  lg:max-w-[18rem] xl:max-w-[20rem] 3xl:max-w-[25rem] 4xl:max-w-[26rem]  overflow-auto scrollable custom-scroll-filter ">
                {projectsFilterData?.filters?.map((option) => {
                  return (
                    <div
                      className={`flex group items-center justify-between cursor-pointer hover:bg-[#68FFD2] rounded ${
                        activeShortFilter === option.value
                          ? "pointer-events-none"
                          : ""
                      }`}
                      onClick={() => {
                        setSorting(option?.id);
                        setActiveShortFilter(option?.value);
                        setShowShortFilterOptions(false);
                        if (currentPage > 1) {
                          setCurrentPage(1);
                        }
                      }}
                    >
                      <div
                        className="cursor-pointer flex justify-between group-hover:text-black  gap-x-2.5 p-2 text-[15px] 2xl:text-[18px] 3xl:text-[22px] 4xl:text-[24px] w-fit font-semibold   hover:opacity-100 rounded-md
                   text-white
                "
                        style={{
                          color:
                            activeShortFilter === option.value ? "#68FFD2" : "",
                        }}
                      >
                        {option?.value}
                      </div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-10 w-10 p-2 object-contain"
                      >
                        <path
                          d="M13.75 11.875L17.5 8.125L13.75 4.375"
                          stroke="#1A1A1A"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                          stroke="#1A1A1A"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {/* new ui desktop filter starts here  */}
          {openDropdown && openDropdown !== "filter" && (
            <div
              className="flex flex-row my-5 gap-3 flex-wrap "
              ref={subFilterRef}
            >
              {projectsFilterData[openDropdown]
                ?.sort((a, b) => a.value.localeCompare(b.value))
                .map((subFilter) => (
                  <button
                    key={subFilter?.value}
                    className={`border-2 text-[#dcdcdc] border-[#dcdcdc] hover:border-[#68FFD2] rounded-[50px] hover:text-[#68FFD2] py-1 px-3 text-lg ${
                      selectedSubFilters.includes(subFilter?.id)
                        ? "!border-[#68ffd2] !text-[#68ffd2]"
                        : ""
                    }`}
                    onClick={(e) => {
                      handleSubFilterClick(e, subFilter);
                    }}
                  >
                    {subFilter?.value}
                  </button>
                ))}
            </div>
          )}
          {/* new ui desktop filter starts here  */}
        </section>
        {/* desktop filters ends here  */}
        {/* mobile filter starts here  */}
        <section ref={mobileScrollRef} className="container sm:hidden">
          <div className="flex capitalize mt-8 justify-between">
            <ul className="flex flex-wrap gap-2">
              <li className="list-none ">
                <button
                  onClick={(e) =>
                    setTurnMobileOptionsMenu(!turnMobileOptionsMenu)
                  }
                  className={`flex sm:hidden items-center justify-betweenk  text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#68FFD2] border-2 border-[#68FFD2]  ${
                    turnMobileOptionsMenu ? "buttom-right-slant-sm" : ""
                  }  px-5 py-2 rounded w-fit`}
                  style={{
                    color: turnMobileOptionsMenu ? "#1a1a1a" : "",
                    backgroundColor: turnMobileOptionsMenu ? "#68ffd2" : "",
                  }}
                >
                  {projectPageData?.filterText}
                  {turnMobileOptionsMenu ? (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 platforms"
                    >
                      <path
                        d="M8.625 6.25L12.375 2.5L16.125 6.25"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#68FFD2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#68FFD2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>
              </li>
              {selectedSubFilters.length > 0 && (
                //  || selectedFilterText !== projectPageData?.newestToOldestText
                <li className="list-none group">
                  <button
                    onClick={(e) => resetSelections()}
                    className="flex items-center hover-reset-svg text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer text-[#68FFD2] group-hover:text-white px-2 py-2 rounded w-fit"
                  >
                    {projectPageData?.resetText}
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="ml-2"
                    >
                      <polyline points="1 4 1 10 7 10" />
                      <path
                        d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"
                        stroke="#68FFD2"
                      />
                    </svg>
                    {/* <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#68FFD2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#68FFD2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg> */}
                  </button>
                </li>
              )}
            </ul>
            <div className="justify-end relative">
              <button
                ref={shortingFilterRefMobile}
                onClick={(e) => {
                  setShowShortFilterOptionsMobile(!showSortFilterOptionsMobile);
                }}
                className="flex items-center justify-between text-[18px]  cursor-pointer  text-[#1a1a1a] bg-[#68FFD2] px-5 lg:px-5 py-[11px] rounded w-fit h-fit"
              >
                <svg
                  width="22"
                  height="22"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="lg:ml-3"
                >
                  <path
                    stroke="#1a1a1a"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                  />
                </svg>
              </button>
              {showSortFilterOptionsMobile && (
                <div className="relative">
                  <div className=" z-10 w-screen max-w-[15.5rem] rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 right-[2px] top-4 backdrop-blur-lg   absolute  overflow-auto scrollable custom-scroll-filter ">
                    {projectsFilterData?.filters?.map((el) => {
                      return (
                        <div
                          className={`flex  group items-center justify-between cursor-pointer hover:bg-[#68FFD2] rounded left-[-11.5rem] ${
                            activeShortFilter === el?.value
                              ? "pointer-events-none"
                              : ""
                          }`}
                          onClick={() => {
                            setSorting(el?.id);
                            setActiveShortFilter(el?.value);
                            setShowShortFilterOptionsMobile(false);
                            if (currentPage > 1) {
                              setCurrentPage(1);
                            }
                          }}
                        >
                          <div
                            className="cursor-pointer flex justify-between group-hover:text-black  gap-x-2.5 p-2 text-[15px]  w-fit font-semibold   hover:opacity-100 rounded-md
                   text-white
                "
                            style={{
                              color:
                                activeShortFilter === el.value ? "#68FFD2" : "",
                            }}
                          >
                            {el.value}
                          </div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-10 w-10 p-2 object-contain"
                          >
                            <path
                              d="M13.75 11.875L17.5 8.125L13.75 4.375"
                              stroke="#1A1A1A"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                              stroke="#1A1A1A"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          {turnMobileOptionsMenu && (
            <div className="flex flex-wrap gap-y-3 mt-5">
              <li className="list-none w-full group">
                <button
                  onClick={(e) => {
                    e.stopPropagation();

                    toggleDropdown("services");
                  }}
                  className={`flex items-center justify-between  w-full text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#68FFD2] border-2 border-[#68FFD2]  ${
                    openDropdown === "services" || services.length > 0
                      ? "bottom-right-slant-sm"
                      : ""
                  } hover:bg-[#68FFD2] hover:text-black px-5 py-2 rounded`}
                  style={{
                    color:
                      openDropdown === "services" || services.length > 0
                        ? "#1a1a1a"
                        : "#68ffd2",
                    backgroundColor:
                      openDropdown === "services" || services.length > 0
                        ? "#68ffd2"
                        : "#1a1a1a",
                  }}
                >
                  {projectPageData?.servicesText}
                  {services.length > 0 && (
                    <p
                      className="ml-2 mr-2  rounded-full w-8 h-8 absolute right-[3rem]"
                      style={{
                        backgroundColor:
                          openDropdown === "services" || services.length > 0
                            ? "#1a1a1a"
                            : "",
                        color:
                          openDropdown === "services" || services.length > 0
                            ? "#68ffd2"
                            : "",
                      }}
                    >
                      {services?.length}
                    </p>
                  )}
                  {openDropdown === "services" || services.length > 0 ? (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 platforms"
                    >
                      <path
                        d="M8.625 6.25L12.375 2.5L16.125 6.25"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#68FFD2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#68FFD2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>
              </li>
              {openDropdown === "services" && (
                <div className="flex flex-row gap-3 flex-wrap">
                  {projectsFilterData?.services
                    ?.sort((a, b) => a.value.localeCompare(b.value))
                    .map((g) => (
                      <button
                        onClick={(e) => {
                          handleSubFilterClick(e, g);
                        }}
                        key={g?.value}
                        className={`services border-2 text-[#dcdcdc] border-[#dcdcdc]  rounded-[50px]   py-1 px-3  text-sm ${
                          services.includes(g?.id)
                            ? "!border-[#68ffd2] !text-[#68ffd2]"
                            : ""
                        }`}
                      >
                        {g.value}
                      </button>
                    ))}
                </div>
              )}

              <li className="list-none w-full group">
                <button
                  onClick={(e) => {
                    e.stopPropagation();

                    toggleDropdown("platforms");
                  }}
                  className={`flex items-center justify-between  w-full text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#68FFD2] border-2 border-[#68FFD2]  ${
                    openDropdown === "platforms" || platforms.length > 0
                      ? "bottom-right-slant-sm"
                      : ""
                  } hover:bg-[#68FFD2] hover:text-black px-5 py-2 rounded`}
                  style={{
                    color:
                      openDropdown === "platforms" || platforms.length > 0
                        ? "#1a1a1a"
                        : "#68ffd2",
                    backgroundColor:
                      openDropdown === "platforms" || platforms.length > 0
                        ? "#68ffd2"
                        : "#1a1a1a",
                  }}
                >
                  {projectPageData?.platformText}
                  {platforms.length > 0 && (
                    <p
                      className="ml-2 mr-2  rounded-full w-8 h-8 absolute right-[3rem]"
                      style={{
                        backgroundColor:
                          openDropdown === "platforms" || platforms.length > 0
                            ? "#1a1a1a"
                            : "",
                        color:
                          openDropdown === "platforms" || platforms.length > 0
                            ? "#68ffd2"
                            : "",
                      }}
                    >
                      {platforms?.length}
                    </p>
                  )}
                  {openDropdown === "platforms" || platforms.length > 0 ? (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 platforms"
                    >
                      <path
                        d="M8.625 6.25L12.375 2.5L16.125 6.25"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#68FFD2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#68FFD2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>
              </li>
              {openDropdown === "platforms" && (
                <div className="flex flex-row gap-3 flex-wrap">
                  {projectsFilterData?.platforms
                    ?.sort((a, b) => a.value.localeCompare(b.value))
                    .map((g) => (
                      <button
                        onClick={(e) => {
                          handleSubFilterClick(e, g);
                        }}
                        key={g?.value}
                        className={`platforms border-2 text-[#dcdcdc] border-[#dcdcdc] hover:border-[#68FFD2] rounded-[50px] hover:text-[#68FFD2]  py-1 px-3  text-sm ${
                          platforms.includes(g?.id)
                            ? "!border-[#68ffd2] !text-[#68ffd2]"
                            : ""
                        }`}
                      >
                        {g.value}
                      </button>
                    ))}
                </div>
              )}

              <li className="list-none w-full group">
                <button
                  onClick={(e) => {
                    e.stopPropagation();

                    toggleDropdown("genre");
                  }}
                  className={`flex items-center justify-between  w-full text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#68FFD2] border-2 border-[#68FFD2]  ${
                    openDropdown === "genre" || genre.length > 0
                      ? "bottom-right-slant-sm"
                      : ""
                  } hover:bg-[#68FFD2] hover:text-black px-5 py-2 rounded`}
                  style={{
                    color:
                      openDropdown === "genre" || genre.length > 0
                        ? "#1a1a1a"
                        : "#68ffd2",
                    backgroundColor:
                      openDropdown === "genre" || genre.length > 0
                        ? "#68ffd2"
                        : "#1a1a1a",
                  }}
                >
                  {projectPageData?.genreText}
                  {genre.length > 0 && (
                    <p
                      className="ml-2 mr-2  rounded-full w-8 h-8 absolute right-[3rem]"
                      style={{
                        backgroundColor:
                          openDropdown === "genre" || genre.length > 0
                            ? "#1a1a1a"
                            : "",
                        color:
                          openDropdown === "genre" || genre.length > 0
                            ? "#68ffd2"
                            : "",
                      }}
                    >
                      {genre?.length}
                    </p>
                  )}
                  {openDropdown === "genre" || genre.length > 0 ? (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 platforms"
                    >
                      <path
                        d="M8.625 6.25L12.375 2.5L16.125 6.25"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#68FFD2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#68FFD2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>
              </li>
              {openDropdown === "genre" && (
                <div className="flex flex-row gap-3 flex-wrap">
                  {projectsFilterData?.genre
                    ?.sort((a, b) => a.value.localeCompare(b.value))
                    .map((g) => (
                      <button
                        onClick={(e) => {
                          handleSubFilterClick(e, g);
                        }}
                        key={g?.value}
                        className={`genre border-2 text-[#dcdcdc] border-[#dcdcdc] hover:border-[#68FFD2] rounded-[50px] hover:text-[#68FFD2]  py-1 px-3 text-sm ${
                          genre.includes(g?.id)
                            ? "!border-[#68ffd2] !text-[#68ffd2]"
                            : ""
                        }`}
                      >
                        {g.value}
                      </button>
                    ))}
                </div>
              )}
            </div>
          )}
        </section>
        {/* mobile filter ends here  */}
        {projectsLoader ? (
          <Loader />
        ) : (
          <section className="container my-14 4xl:my-20 grid grid-cols-1 sm:grid-cols-2 gap-8 relative">
            {projectsListData?.length > 0 ? (
              projectsListData?.map((project, index) => (
                <ProjectCard key={index} project={project} />
              ))
            ) : (
              <div className="text-center absolute left-[50%] transform-center ">
                <p className="text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] text-[#68FFD2]">
                  No Projects Found
                </p>
              </div>
            )}
          </section>
        )}

        <div
          className="bg-projectk relative"
          // style={{
          //   backgroundImage:
          //     window.innerWidth > 639
          //       ? `url(${projectPageData?.testimonialBgImage})`
          //       : "",
          // }}
        >
          <div className="absolute -top-[7rem] right-0 z-[-1] hidden sm:block">
            <img
              src={projectPageData?.testimonialBgImage}
              alt="side-ptw"
              // className="w-full"
            />
          </div>
          {totalPages > 1 && (
            <section className="container mb-20 sm:mb-28 lg:mb-40 flex items-center justify-center pagination text-white text-[16px] 3xl:text-[22px] gap-4 mx-6">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPaginationValueChange={onPaginationChange}
                color="#68FFD2"
              />
            </section>
          )}
        </div>
        <section className="mt-[10rem]">
          <Testimonials
            testimonialLeftQuotations={
              projectPageData?.testimonialLeftQuotations
            }
            testimonialRightQuotations={
              projectPageData?.testimonialRightQuotations
            }
            testimonials={testimonials}
            bgcolor="#2ad9ff"
            textColor="#004780"
          />
        </section>

        {/* their ends here  */}
        <Footer />
      </section>
    </>
  );
};

export default ProjectsList;
