import React, { useState, forwardRef } from "react";

const ButtonSlant = forwardRef(
  (
    {
      slantClass,
      divClass = "",
      buttonClass,
      buttonText,
      textColor,
      textColorHover,
      bgColor = null,
      borderColor = null,
      onClick,
      bgColorHover,
      id,
      key,
      children = null,
      onHoverChange,
      disabled,
      tabIndex,
    },
    ref
  ) => {
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
      setIsHover(true);
      if (onHoverChange) {
        onHoverChange(true);
      }
    };

    const handleMouseLeave = () => {
      setIsHover(false);
      if (onHoverChange) {
        onHoverChange(false);
      }
    };

    return (
      <div
        key={key}
        className={`${divClass} cursor-pointer`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleMouseEnter}
        onTouchEnd={handleMouseLeave}
      >
        <button
          ref={ref}
          className={`${buttonClass} ${isHover ? slantClass : ""}`}
          style={{
            color: isHover ? textColorHover : textColor,
            backgroundColor: isHover ? bgColorHover : bgColor ? bgColor : "",
            borderColor: borderColor ? borderColor : "",
          }}
          onClick={onClick}
          id={id}
          disabled={disabled}
          tabIndex={tabIndex}
        >
          {buttonText}
          {children ? children : ""}
        </button>
      </div>
    );
  }
);

export default ButtonSlant;
