import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams, Link } from "react-router-dom";
import "../../css/pagescss/newsdesc.css";
import Footer from "../../components/Footer";
import {
  getLanguage,
  navigateTo,
  transformContent,
} from "../../constants/GeneralConstants";
import { getLabsDetailsData } from "../../services/ApiService";
import PIL from "../../components/PIL";
import Loader from "../../components/Loader";
import NewsEvents from "../../components/home/NewsEvents";
import { clientUrls } from "../../constants/ClientUrls";
import PageNotFound from "../../components/PageNotFound";
import { setColor } from "../../store/reducers/ColorReducer";
import { useDispatch } from "react-redux";
import FormatText from "../../components/FormatText";

const LabsDescription = () => {
  const navigate = useNavigate();
  const [labsDetails, setLabsDetails] = useState({});
  const [labsData, setLabsData] = useState({});
  const [labsDetailsStaticData, setLabsDetailsStaticData] = useState({});
  const [relatedLabs, setRelatedLabs] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [loader, setLoader] = useState(true);
  const { param, lang } = useParams();
  const [quotes, setQuotes] = useState([]);
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    callLabsDetailsAPI(getLanguage(), param);
  }, [param, lang]);

  const changeTestimonial = (d) => {
    if (d === "left") {
      setIndex((prevIndex) => (prevIndex - 1 + quotes.length) % quotes.length);
    } else if (d === "right") {
      setIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, []);
  useEffect(() => {
    dispatch(
      setColor({
        primary: "#61e063",
        secondary: "#61e063",
        tertiary: "#286233",
      })
    );
  }, [dispatch]);
  const callLabsDetailsAPI = async (language, param) => {
    setLoader(true);
    try {
      const data = await getLabsDetailsData(language, param);
      if (data.status === 404) {
        setLoader(false);
        setNotFound(true);
      } else {
        document.title = data?.labsData?.title;
        setLabsDetails(data?.labsData);
        setLabsData(data);
        setQuotes(data?.labsData?.quotes);
        setLabsDetailsStaticData(data?.labsPageStaticData);
        setRelatedLabs(data?.relatedLabs);
        setNotFound(false);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };
  const handlePrevious = () => {
    if (labsData?.previousLabsData) {
      const previousLabsURL = labsData?.previousLabsData?.labsURL;

      navigate(navigateTo(`${clientUrls.labs}/${previousLabsURL}`));
    }
  };

  const handleNext = () => {
    if (labsData?.nextLabsData) {
      const nextLabsURL = labsData?.nextLabsData?.labsURL;

      navigate(navigateTo(`${clientUrls.labs}/${nextLabsURL}`));
    }
  };
  const getFormattedDate = (d) => {
    const date = new Date(d);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  if (loader) {
    return <Loader />;
  }

  const getTags = (tag) => {
    if (typeof tag !== "string") {
      return [];
    }

    let tags = tag !== "" ? tag.split(",") : [];
    return tags.length > 0 && tags[0] !== "" ? tags : [];
  };

  return (
    <>
      {notFound ? (
        <PageNotFound />
      ) : (
        <section className="labs-desc">
          <div className="overflow-hidden relative">
            <div className="absolute top-0 right-[-7rem] sm:right-[-10rem] lg:right-0 w-full lg:w-[85%] xl:w-[70%] 3xl:w-[65%] z-[-1]">
              <img
                src={labsData?.labsPageStaticData?.labsDetailsBgImage}
                alt="bg img"
              />
            </div>

            <div className="container  relative mb-40 mt-40">
              <div>
                <div className="flex gap-8 text-[#fff] items-center">
                  <button
                    className="w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] rounded-md pl-2 group slide-btn-top-left-slant opacity-100 border-[#61e063] hover:bg-[#61e063] "
                    onClick={() => {
                      navigate(navigateTo(clientUrls.labs));
                    }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain group-hover:hidden"
                    >
                      <path
                        d="M6.25 11.875L2.5 8.125L6.25 4.375"
                        stroke="#61e063"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                        stroke="#61e063"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain hidden group-hover:block"
                    >
                      <path
                        d="M6.25 11.875L2.5 8.125L6.25 4.375"
                        stroke="#1A1A1A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                        stroke="#1A1A1A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>
                  <p className="text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px] uppercase">
                    {labsData?.labsPageStaticData?.backToLabsText}
                  </p>
                </div>
              </div>
              <div className="flex  flex-col sm:flex-row mt-10 lg:mt-20  gap-4">
                <div className="w-full">
                  <h2 className="text-[24px] sm:text-[30px] lg:text-[38.5px] xl:text-[48px] 2xl:text-[57.6px] 3xl:text-[72px] leading-[1] text-white uppercase pr-[1rem] ">
                    {labsDetails?.title}
                  </h2>
                </div>
                {/* <div className="w-full basis-1/3 custom-button flex lg:justify-end sm:justify-start ">
              <div className="border-[1px] rounded h-fit p-2 border-[#BEC0C5]">
                <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white uppercase">
                  Press release
                </p>
              </div>
            </div> */}
              </div>
              <div className="max-w-full mt-10 lg:mt-20 relative">
                {/* <img
                  className="w-full"
                  src={labsDetails?.bannerImage}
                  alt="labs-desc"
                /> */}
                <PIL
                  lowQaulitySrc={
                    labsDetails?.bannerImage
                      ? labsDetails?.bannerImage
                      : labsDetails?.bannerImage
                  }
                  highQualitySrc={labsDetails?.bannerImage}
                  className="w-full block h-auto max-w-full"
                  alt={labsDetails?.title}
                />
              </div>
              <div className="flex flex-col mt-10 lg:mt-20 xs:flex-col lg:flex-row  ">
                <div className="w-full lg:w-[70%] xl:w-[73%]">
                  {/* highlighted text */}
                  {labsDetails?.takeAway?.length > 0 && (
                    <div className=" relative overflow-hidden   bg-[#61e063] p-7 mb-10">
                      <img
                        alt="maskgroup"
                        className="hidden lg:block absolute opacity-[20%] right-[2%] top-[-38%]  z-[99] h-[35rem]"
                        src="/images/maskgroup.png"
                      />
                      <div className="relative z-[100]">
                        <h2 className="uppercase text-[#1A1A1A] mb-2 text-[24px] 2xl:text-[28.8px] 3xl:text-[36px] leading-[1]">
                          {labsDetailsStaticData?.takeawaysText}
                        </h2>
                        <ol className="space-y-2 list-decimal list-outside px-7 pt-4">
                          {labsDetails?.takeAway?.length > 0
                            ? labsDetails.takeAway.map((t, i) => {
                                return (
                                  <li className="text-[18px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-[#1a1a1a]">
                                    <span className="font-bold">
                                      {t.takeawaysTitle}
                                    </span>{" "}
                                    {t.takeawaysDescription}
                                  </li>
                                );
                              })
                            : null}
                        </ol>
                      </div>
                    </div>
                  )}
                  {/* only tablet tags and social media starts here  */}
                  <section className="hidden sm:block lg:hidden">
                    <div className="lg:w-[30%] xl:w-[27%]  md:w-full visible  xs-only:hidden flex-wrap py-4 lg:pl-8 xl:px-8 lg:py-4 flex lg:flex-col gap-[1rem] md:flex-row">
                      {/* tags */}
                      {(labsDetails?.tagsText?.length > 0 ||
                        labsDetails?.labsType?.length > 0) && (
                        <div className="sm:w-[50%] md:w-[60%] lg:w-full flex flex-wrap flex-col gap-2">
                          <p className="text-[16px] 2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] font-bold text-[#61e063] uppercase">
                            {labsDetailsStaticData?.tagsText}
                          </p>
                          <div className="flex flex-wrap gap-4">
                            {/* tag pills */}
                            {labsDetails?.tagsText?.length > 0
                              ? labsDetails?.tagsText?.map((t, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className="border-[1px] rounded h-fit p-2 border-[#BEC0C5]"
                                    >
                                      <p className="text-[16px] 2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white">
                                        {t}
                                      </p>
                                    </div>
                                  );
                                })
                              : null}

                            {/* labsType pills */}
                            {labsDetails?.labsType?.length > 0
                              ? labsDetails?.labsType.map((type, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="border-[1px] rounded h-fit p-2 border-[#BEC0C5]"
                                    >
                                      <p className="text-[16px] 2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white">
                                        {type}
                                      </p>
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      )}
                      <div className="hidden xs:block border-[#7E818A] border-l-2"></div>
                      <div className="sm:w-[40%] md:w-[35%] lg:w-full ">
                        {/* dates */}
                        <div className="flex w-full mt-4 space-between justify-between">
                          <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] font-bold basis-1/2k text-[#61e063] uppercase">
                            {labsDetailsStaticData?.dateText}
                          </p>
                          <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white basis-[65%] lg-ony:basis-[60%]">
                            {labsDetails?.publishedDate}
                          </p>
                        </div>
                        {labsDetails?.authorName && (
                          <div className="flex w-full mt-4 space-between justify-between">
                            <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] font-bold basis-1/2k text-[#61e063] uppercase">
                              {labsDetailsStaticData?.authorText}
                            </p>
                            <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white basis-[65%] lg-ony:basis-[60%]">
                              {labsDetails?.authorName}
                            </p>
                          </div>
                        )}

                        {/* border for big screen */}
                        <div className=" hidden lg:block mt-4 border-[#7E818A] border-b-2"></div>

                        {/* social icons */}

                        <div className="flex w-full justify-between my-8">
                          {labsDetails?.socialMedia?.map((item, id) => (
                            <Link
                              key={id}
                              to={item.socialMediaURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={item.socialMediaIcon}
                                alt={item.socialMediaName}
                              />
                            </Link>
                          ))}
                          {/* linkedin */}
                          {/* <svg
                        className="cursor-pointer"
                        width="39"
                        height="41"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M26 5.88379H6C5.44772 5.88379 5 6.3315 5 6.88379V26.8838C5 27.4361 5.44772 27.8838 6 27.8838H26C26.5523 27.8838 27 27.4361 27 26.8838V6.88379C27 6.3315 26.5523 5.88379 26 5.88379Z"
                          stroke="#61e063"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 14.8838V22.8838"
                          stroke="#61e063"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11 14.8838V22.8838"
                          stroke="#61e063"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 18.3838C15 17.4555 15.3687 16.5653 16.0251 15.9089C16.6815 15.2525 17.5717 14.8838 18.5 14.8838C19.4283 14.8838 20.3185 15.2525 20.9749 15.9089C21.6313 16.5653 22 17.4555 22 18.3838V22.8838"
                          stroke="#61e063"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11 12.3838C11.8284 12.3838 12.5 11.7122 12.5 10.8838C12.5 10.0554 11.8284 9.38379 11 9.38379C10.1716 9.38379 9.5 10.0554 9.5 10.8838C9.5 11.7122 10.1716 12.3838 11 12.3838Z"
                          fill="#61e063"
                        />
                      </svg> */}

                          {/* instagram */}
                          {/* <svg
                        className="cursor-pointer"
                        width="39"
                        height="41"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16 21.8838C18.7614 21.8838 21 19.6452 21 16.8838C21 14.1224 18.7614 11.8838 16 11.8838C13.2386 11.8838 11 14.1224 11 16.8838C11 19.6452 13.2386 21.8838 16 21.8838Z"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M21.5 5.38379H10.5C7.18629 5.38379 4.5 8.07008 4.5 11.3838V22.3838C4.5 25.6975 7.18629 28.3838 10.5 28.3838H21.5C24.8137 28.3838 27.5 25.6975 27.5 22.3838V11.3838C27.5 8.07008 24.8137 5.38379 21.5 5.38379Z"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M22.5 11.8838C23.3284 11.8838 24 11.2122 24 10.3838C24 9.55536 23.3284 8.88379 22.5 8.88379C21.6716 8.88379 21 9.55536 21 10.3838C21 11.2122 21.6716 11.8838 22.5 11.8838Z"
                          stroke="#61e063"
                        />
                      </svg> */}

                          {/* facebook */}
                          {/* <svg
                        className="cursor-pointer"
                        width="39"
                        height="41"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16 28.8838C22.6274 28.8838 28 23.5112 28 16.8838C28 10.2564 22.6274 4.88379 16 4.88379C9.37258 4.88379 4 10.2564 4 16.8838C4 23.5112 9.37258 28.8838 16 28.8838Z"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21 11.8838H19C18.2044 11.8838 17.4413 12.1999 16.8787 12.7625C16.3161 13.3251 16 14.0881 16 14.8838V28.8838"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 18.8838H20"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg> */}

                          {/* twitter */}

                          {/* <svg
                        className="cursor-pointer"
                        width="39"
                        height="41"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 16.8838L14 12.8838V20.8838L20 16.8838Z"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3 16.8838C3 20.6032 3.38427 22.7855 3.67638 23.9071C3.75447 24.2134 3.90442 24.4967 4.11382 24.7335C4.32322 24.9704 4.58603 25.1539 4.88049 25.2689C9.0651 26.877 16 26.8328 16 26.8328C16 26.8328 22.9348 26.877 27.1195 25.2689C27.4139 25.1539 27.6767 24.9704 27.8861 24.7336C28.0955 24.4967 28.2455 24.2134 28.3236 23.9071C28.6157 22.7855 29 20.6032 29 16.8838C29 13.1643 28.6157 10.982 28.3236 9.86042C28.2455 9.5541 28.0956 9.27079 27.8862 9.03397C27.6768 8.79715 27.414 8.61364 27.1195 8.49863C22.9349 6.89053 16 6.93469 16 6.93469C16 6.93469 9.06518 6.89053 4.88053 8.49861C4.58607 8.61362 4.32325 8.79713 4.11385 9.03395C3.90446 9.27077 3.7545 9.55408 3.67641 9.8604C3.38428 10.982 3 13.1643 3 16.8838Z"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg> */}
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* only tablet tags and social media ends here  */}

                  <div className="flex flex-col gap-4 text-white">
                    {/* <div className="whitespace-pre-wrap text-[18px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-normal  text-white cms-style">
                      {parse(
                        labsDetails?.labsDescription
                          ? labsDetails?.labsDescription
                          : ""
                      )}
                    </div> */}
                    <div className="whitespace-pre-wrap text-[18px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-normal text-white cms-style">
                      <FormatText text={labsDetails?.labsDescription} />
                    </div>
                    {/* with bg image starts here  */}
                    {labsDetails?.labsQuotesBgImage !== undefined &&
                      quotes.length > 0 && (
                        <section className="quote-section mt-16">
                          <div
                            className="bg-cover bg-center bg-no-repeat relative"
                            style={{
                              backgroundImage: `url(${labsDetails?.labsQuotesBgImage})`,
                            }}
                          >
                            <div className="qutoes-img absolute z-[-1] top-[-2.7rem]k left-4 sm:left-[3rem]">
                              <img
                                src={labsDetailsStaticData?.labsLeftQuoteImage}
                                alt="ptw-side"
                              />
                            </div>

                            <div className="flex items-center justify-center py-[2rem]">
                              <div className="text-center text-white">
                                {quotes[index].quoteImage !== null && (
                                  <img
                                    src={quotes[index].quoteImage}
                                    alt="profile"
                                    className="h-auto mx-auto"
                                  />
                                )}
                                <div className="min-h-[18rem] xxs:min-h-[21rem] xs:min-h-[19rem] sm:min-h-64 md:min-h-60 lg:min-h-52 2xl:min-h-56 3xl:min-h-64 text-[#fff]  px-8 sm:px-16 ">
                                  <FormatText
                                    text={quotes[index]?.quoteDescription}
                                  />
                                  <FormatText
                                    text={quotes[index]?.quoteAuthorDetails}
                                  />
                                </div>
                                {quotes.length > 1 && (
                                  <div className="flex gap-4 my-5 sm:my-14 px-8 sm:px-16 ">
                                    <button
                                      onClick={(e) => changeTestimonial("left")}
                                      className="border-[2px] border-[#fff] hover:bg-[#fff] rounded-[4px] p-2 slide-btn-top-left-slant group"
                                    >
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 2xl:w-6 2xl:h-6 object-contain group-hover:hidden"
                                      >
                                        <path
                                          d="M6.25 11.875L2.5 8.125L6.25 4.375"
                                          stroke="#fff"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                        <path
                                          d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                                          stroke="#fff"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                      </svg>
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 2xl:w-6 2xl:h-6 object-contain hidden group-hover:block"
                                      >
                                        <path
                                          d="M6.25 11.875L2.5 8.125L6.25 4.375"
                                          stroke="#1a1a1a"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                        <path
                                          d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                                          stroke="#1a1a1a"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                      </svg>
                                    </button>
                                    <button
                                      onClick={(e) =>
                                        changeTestimonial("right")
                                      }
                                      className="border-[2px] border-[#fff] hover:bg-[#fff] rounded-[4px] p-2  group slide-btn-bottom-right-slant"
                                    >
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 2xl:w-6 2xl:h-6 object-contain group-hover:hidden"
                                      >
                                        <path
                                          d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                          stroke="#fff"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                        <path
                                          d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                          stroke="#fff"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                      </svg>
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 2xl:w-6 2xl:h-6 object-contain hidden group-hover:block"
                                      >
                                        <path
                                          d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                          stroke="#1a1a1a"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                        <path
                                          d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                          stroke="#1a1a1a"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                      </svg>
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </section>
                      )}
                    {/* with bg image ends here  */}
                    {/* without bg image starts here  */}
                    {labsDetails?.labsQuotesBgImage === undefined &&
                      quotes.length > 0 && (
                        <section className="relative mt-8 text-white grid">
                          <div className="qutoes-img z-[1] absolute top-[-1.2rem]">
                            <img
                              src={labsDetailsStaticData?.labsLeftQuoteImage}
                              alt="ptw-art"
                            />
                          </div>
                          <p className="content py-4 leading-[120%] common-para-size font-medium ml-[4rem] sm:ml-[6rem]">
                            <FormatText
                              text={quotes[index]?.quoteDescription}
                            />
                          </p>
                          {quotes.length > 1 && (
                            <div className="flex gap-4 my-5 sm:my-14 px-8 sm:px-16 ">
                              <button
                                onClick={(e) => changeTestimonial("left")}
                                className="border-[2px] border-[#fff] hover:bg-[#fff] rounded-[4px] p-2 slide-btn-top-left-slant group"
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4 2xl:w-6 2xl:h-6 object-contain group-hover:hidden"
                                >
                                  <path
                                    d="M6.25 11.875L2.5 8.125L6.25 4.375"
                                    stroke="#fff"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                                    stroke="#fff"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4 2xl:w-6 2xl:h-6 object-contain hidden group-hover:block"
                                >
                                  <path
                                    d="M6.25 11.875L2.5 8.125L6.25 4.375"
                                    stroke="#1a1a1a"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                                    stroke="#1a1a1a"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                onClick={(e) => changeTestimonial("right")}
                                className="border-[2px] border-[#fff] hover:bg-[#fff] rounded-[4px] p-2  group slide-btn-bottom-right-slant"
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4 2xl:w-6 2xl:h-6 object-contain group-hover:hidden"
                                >
                                  <path
                                    d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                    stroke="#fff"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                    stroke="#fff"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4 2xl:w-6 2xl:h-6 object-contain hidden group-hover:block"
                                >
                                  <path
                                    d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                    stroke="#1a1a1a"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                    stroke="#1a1a1a"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          )}
                        </section>
                      )}
                    {/* <section className="relative my-8 text-white grid">
                      <div className="qutoes-img z-[1] absolute top-[-1.2rem]">
                        <img
                          src={labsDetailsStaticData?.labsLeftQuoteImage}
                          alt="ptw-art"
                        />
                      </div>
                      <p className="content py-4 leading-[120%] common-para-size font-medium ml-[4rem] sm:ml-[6rem]">
                        <FormatText text={quotes[0]?.quoteDescription} />
                      </p>
                      <div className="flex gap-4 my-5 px-8 sm:px-16 ">
                        <button
                          onClick={(e) => changeTestimonial("left")}
                          className="border-[2px] border-[#fff] hover:bg-[#fff] rounded-[4px] p-2 slide-btn-top-left-slant group"
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 2xl:w-6 2xl:h-6 object-contain group-hover:hidden"
                          >
                            <path
                              d="M6.25 11.875L2.5 8.125L6.25 4.375"
                              stroke="#fff"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                              stroke="#fff"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 2xl:w-6 2xl:h-6 object-contain hidden group-hover:block"
                          >
                            <path
                              d="M6.25 11.875L2.5 8.125L6.25 4.375"
                              stroke="#1a1a1a"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                              stroke="#1a1a1a"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </button>
                        <button
                          onClick={(e) => changeTestimonial("right")}
                          className="border-[2px] border-[#fff] hover:bg-[#fff] rounded-[4px] p-2  group slide-btn-bottom-right-slant"
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 2xl:w-6 2xl:h-6 object-contain group-hover:hidden"
                          >
                            <path
                              d="M13.75 11.875L17.5 8.125L13.75 4.375"
                              stroke="#fff"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                              stroke="#fff"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 2xl:w-6 2xl:h-6 object-contain hidden group-hover:block"
                          >
                            <path
                              d="M13.75 11.875L17.5 8.125L13.75 4.375"
                              stroke="#1a1a1a"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                              stroke="#1a1a1a"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </section> */}
                    {/* without bg image ends here  */}
                    {/* quote part */}
                    {/* <div className="whitespace-pre-wrap text-[18px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-normal  text-white cms-style">
                      {parse(
                        labsDetails?.labsDescriptionBottomSection
                          ? labsDetails?.labsDescriptionBottomSection
                          : ""
                      )}
                    </div> */}
                    <div className="whitespace-pre-wrap text-[18px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-normal leading-normal text-white cms-style">
                      <FormatText
                        text={labsDetails?.labsDescriptionBottomSection}
                      />
                    </div>
                  </div>
                </div>

                {/* for only desktop social icons and tag */}

                <div className="lg:w-[35%] xl:w-[27%]  md:w-full visible  xs-only:hidden flex-wrap py-4 lg:px-[2rem] lg:py-4 hidden lg:flex lg:flex-col gap-[1rem] md:flex-row">
                  {/* tags */}
                  {(labsDetails?.tagsText?.length > 0 ||
                    labsDetails?.labsType?.length > 0) && (
                    <div className="sm:w-[50%] md:w-[60%] lg:w-full flex flex-wrap flex-col gap-2 ">
                      <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] font-bold text-[#61e063] uppercase">
                        {labsDetailsStaticData?.tagsText}
                      </p>
                      <div className="flex flex-wrap items-center gap-4">
                        {/* tag pills */}
                        {labsDetails?.tagsText?.length > 0
                          ? labsDetails?.tagsText?.map((t, i) => {
                              return (
                                <div
                                  key={i}
                                  className="border-[1px] rounded h-fit p-2 border-[#BEC0C5]"
                                >
                                  <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white">
                                    {t}
                                  </p>
                                </div>
                              );
                            })
                          : null}

                        {/* labsType pills */}
                        {labsDetails?.labsType?.length > 0
                          ? labsDetails?.labsType.map((type, index) => {
                              return (
                                <div
                                  key={index}
                                  className="border-[1px] rounded h-fit p-2 border-[#BEC0C5]"
                                >
                                  <p className="text-[16px] 2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white">
                                    {type}
                                  </p>
                                </div>
                              );
                            })
                          : null}
                        {/* <div className="border-[1px] rounded h-fit p-2 border-[#BEC0C5]">
                        <p className=" text-[16px] 2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white">
                          London
                        </p>
                      </div>
                      <div className="border-[1px] rounded h-fit p-2 border-[#BEC0C5]">
                        <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white">
                          MoCap
                        </p>
                      </div>
                      <div className="border-[1px] rounded h-fit p-2 border-[#BEC0C5]">
                        <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white">
                          Side
                        </p>
                      </div>
                      <div className="border-[1px] rounded h-fit p-2 border-[#BEC0C5]">
                        <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white">
                          Imaginarium
                        </p>
                      </div>
                      <div className="border-[1px] rounded h-fit p-2 border-[#BEC0C5]">
                        <p className=" text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white">
                          Partnership
                        </p>
                      </div> */}
                      </div>
                    </div>
                  )}

                  <div className="hidden xs:block border-[#7E818A] border-l-2"></div>
                  <div className="sm:w-[35%] lg:w-full   ">
                    {/* dates */}
                    <div className="flex w-full mt-4 space-between justify-between">
                      <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] font-bold basis-1/2k text-[#61e063] uppercase">
                        {labsDetailsStaticData?.dateText}
                      </p>
                      <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white basis-[65%] lg-ony:basis-[60%]">
                        {labsDetails?.publishedDate}
                      </p>
                    </div>
                    {labsDetails?.authorName && (
                      <div className="flex w-full mt-4 space-between justify-between">
                        <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] font-bold basis-1/2k text-[#61e063] uppercase">
                          {labsDetailsStaticData?.authorText}
                        </p>
                        <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white basis-[65%] lg-ony:basis-[60%]">
                          {labsDetails?.authorName}
                        </p>
                      </div>
                    )}

                    {/* border for big screen */}
                    <div className=" hidden lg:block mt-4 border-[#7E818A] border-b-2"></div>

                    {/* social icons */}

                    <div className="flex w-full justify-between my-8">
                      {labsDetails?.socialMedia?.map((item, id) => (
                        <Link
                          key={id}
                          to={item.socialMediaURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={item.socialMediaIcon}
                            alt={item.socialMediaName}
                          />
                        </Link>
                      ))}
                      {/* linkedin */}
                      {/* <svg
                        className="cursor-pointer"
                        width="39"
                        height="41"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M26 5.88379H6C5.44772 5.88379 5 6.3315 5 6.88379V26.8838C5 27.4361 5.44772 27.8838 6 27.8838H26C26.5523 27.8838 27 27.4361 27 26.8838V6.88379C27 6.3315 26.5523 5.88379 26 5.88379Z"
                          stroke="#61e063"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 14.8838V22.8838"
                          stroke="#61e063"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11 14.8838V22.8838"
                          stroke="#61e063"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 18.3838C15 17.4555 15.3687 16.5653 16.0251 15.9089C16.6815 15.2525 17.5717 14.8838 18.5 14.8838C19.4283 14.8838 20.3185 15.2525 20.9749 15.9089C21.6313 16.5653 22 17.4555 22 18.3838V22.8838"
                          stroke="#61e063"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11 12.3838C11.8284 12.3838 12.5 11.7122 12.5 10.8838C12.5 10.0554 11.8284 9.38379 11 9.38379C10.1716 9.38379 9.5 10.0554 9.5 10.8838C9.5 11.7122 10.1716 12.3838 11 12.3838Z"
                          fill="#61e063"
                        />
                      </svg> */}

                      {/* instagram */}
                      {/* <svg
                        className="cursor-pointer"
                        width="39"
                        height="41"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16 21.8838C18.7614 21.8838 21 19.6452 21 16.8838C21 14.1224 18.7614 11.8838 16 11.8838C13.2386 11.8838 11 14.1224 11 16.8838C11 19.6452 13.2386 21.8838 16 21.8838Z"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M21.5 5.38379H10.5C7.18629 5.38379 4.5 8.07008 4.5 11.3838V22.3838C4.5 25.6975 7.18629 28.3838 10.5 28.3838H21.5C24.8137 28.3838 27.5 25.6975 27.5 22.3838V11.3838C27.5 8.07008 24.8137 5.38379 21.5 5.38379Z"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M22.5 11.8838C23.3284 11.8838 24 11.2122 24 10.3838C24 9.55536 23.3284 8.88379 22.5 8.88379C21.6716 8.88379 21 9.55536 21 10.3838C21 11.2122 21.6716 11.8838 22.5 11.8838Z"
                          stroke="#61e063"
                        />
                      </svg> */}

                      {/* facebook */}
                      {/* <svg
                        className="cursor-pointer"
                        width="39"
                        height="41"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16 28.8838C22.6274 28.8838 28 23.5112 28 16.8838C28 10.2564 22.6274 4.88379 16 4.88379C9.37258 4.88379 4 10.2564 4 16.8838C4 23.5112 9.37258 28.8838 16 28.8838Z"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21 11.8838H19C18.2044 11.8838 17.4413 12.1999 16.8787 12.7625C16.3161 13.3251 16 14.0881 16 14.8838V28.8838"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 18.8838H20"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg> */}

                      {/* twitter */}

                      {/* <svg
                        className="cursor-pointer"
                        width="39"
                        height="41"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 16.8838L14 12.8838V20.8838L20 16.8838Z"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3 16.8838C3 20.6032 3.38427 22.7855 3.67638 23.9071C3.75447 24.2134 3.90442 24.4967 4.11382 24.7335C4.32322 24.9704 4.58603 25.1539 4.88049 25.2689C9.0651 26.877 16 26.8328 16 26.8328C16 26.8328 22.9348 26.877 27.1195 25.2689C27.4139 25.1539 27.6767 24.9704 27.8861 24.7336C28.0955 24.4967 28.2455 24.2134 28.3236 23.9071C28.6157 22.7855 29 20.6032 29 16.8838C29 13.1643 28.6157 10.982 28.3236 9.86042C28.2455 9.5541 28.0956 9.27079 27.8862 9.03397C27.6768 8.79715 27.414 8.61364 27.1195 8.49863C22.9349 6.89053 16 6.93469 16 6.93469C16 6.93469 9.06518 6.89053 4.88053 8.49861C4.58607 8.61362 4.32325 8.79713 4.11385 9.03395C3.90446 9.27077 3.7545 9.55408 3.67641 9.8604C3.38428 10.982 3 13.1643 3 16.8838Z"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className=" sm:hidden   flex-wrap px-[1rem] flex lg:flex-col gap-[1rem] md:flex-row  p-4">
                {/* tags */}
                {(labsDetails?.tagsText?.length > 0 ||
                  labsDetails?.labsType?.length > 0) && (
                  <div className="flex flex-wrap flex-col gap-2 ">
                    <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] font-bold text-[#61e063] uppercase">
                      {labsDetailsStaticData?.tagsText}
                    </p>
                    <div className="flex flex-wrap gap-4">
                      {/* tag pills */}
                      {labsDetails?.tagsText?.length > 0
                        ? labsDetails?.tagsText?.map((t, i) => {
                            return (
                              <div
                                key={i}
                                className="border-[1px] rounded h-fit p-2 border-[#BEC0C5]"
                              >
                                <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white">
                                  {t}
                                </p>
                              </div>
                            );
                          })
                        : null}

                      {/* tag pills */}
                      {labsDetails?.labsType?.length > 0
                        ? labsDetails?.labsType?.map((type, index) => {
                            return (
                              <div
                                key={index}
                                className="border-[1px] rounded h-fit p-2 border-[#BEC0C5]"
                              >
                                <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white">
                                  {type}
                                </p>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                )}

                {/* for mobile date and social icons */}
                <div className="w-full">
                  {/* dates */}
                  <div className="flex w-full mt-4 space-between justify-between">
                    <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] font-bold basis-1/2k text-[#61e063] uppercase">
                      {labsDetailsStaticData?.dateText}
                    </p>
                    <p className=" text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white basis-[65%] lg-ony:basis-[60%]">
                      {labsDetails?.publishedDate}
                    </p>
                  </div>

                  {labsDetails?.authorName && (
                    <div className="flex w-full mt-4 space-between justify-between">
                      <p className="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] font-bold basis-1/2k text-[#61e063] uppercase">
                        {labsDetailsStaticData?.authorText}
                      </p>
                      <p className=" text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white basis-[65%] lg-ony:basis-[60%]">
                        {labsDetails?.authorName}
                      </p>
                    </div>
                  )}

                  {/* border for big screen */}
                  <div className=" sm:hidden  mt-4 border-[#7E818A] border-b-2"></div>

                  {/* social icons */}

                  <div className="flex w-full justify-between mt-4">
                    {/* linkedin */}
                    {labsDetails?.socialMedia?.map((item, id) => (
                      <Link
                        key={id}
                        to={item.socialMediaURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={item.socialMediaIcon}
                          alt={item.socialMediaName}
                        />
                      </Link>
                    ))}
                    {/* <svg
                      className="cursor-pointer"
                      width="39"
                      height="41"
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26 5.88379H6C5.44772 5.88379 5 6.3315 5 6.88379V26.8838C5 27.4361 5.44772 27.8838 6 27.8838H26C26.5523 27.8838 27 27.4361 27 26.8838V6.88379C27 6.3315 26.5523 5.88379 26 5.88379Z"
                        stroke="#61e063"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15 14.8838V22.8838"
                        stroke="#61e063"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 14.8838V22.8838"
                        stroke="#61e063"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15 18.3838C15 17.4555 15.3687 16.5653 16.0251 15.9089C16.6815 15.2525 17.5717 14.8838 18.5 14.8838C19.4283 14.8838 20.3185 15.2525 20.9749 15.9089C21.6313 16.5653 22 17.4555 22 18.3838V22.8838"
                        stroke="#61e063"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 12.3838C11.8284 12.3838 12.5 11.7122 12.5 10.8838C12.5 10.0554 11.8284 9.38379 11 9.38379C10.1716 9.38379 9.5 10.0554 9.5 10.8838C9.5 11.7122 10.1716 12.3838 11 12.3838Z"
                        fill="#61e063"
                      />
                    </svg> */}

                    {/* instagram */}
                    {/* <svg
                      className="cursor-pointer"
                      width="39"
                      height="41"
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 21.8838C18.7614 21.8838 21 19.6452 21 16.8838C21 14.1224 18.7614 11.8838 16 11.8838C13.2386 11.8838 11 14.1224 11 16.8838C11 19.6452 13.2386 21.8838 16 21.8838Z"
                        stroke="#61e063"
                        stroke-width="2"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M21.5 5.38379H10.5C7.18629 5.38379 4.5 8.07008 4.5 11.3838V22.3838C4.5 25.6975 7.18629 28.3838 10.5 28.3838H21.5C24.8137 28.3838 27.5 25.6975 27.5 22.3838V11.3838C27.5 8.07008 24.8137 5.38379 21.5 5.38379Z"
                        stroke="#61e063"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M22.5 11.8838C23.3284 11.8838 24 11.2122 24 10.3838C24 9.55536 23.3284 8.88379 22.5 8.88379C21.6716 8.88379 21 9.55536 21 10.3838C21 11.2122 21.6716 11.8838 22.5 11.8838Z"
                        stroke="#61e063"
                      />
                    </svg> */}

                    {/* facebook */}
                    {/* <svg
                      className="cursor-pointer"
                      width="39"
                      height="41"
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 28.8838C22.6274 28.8838 28 23.5112 28 16.8838C28 10.2564 22.6274 4.88379 16 4.88379C9.37258 4.88379 4 10.2564 4 16.8838C4 23.5112 9.37258 28.8838 16 28.8838Z"
                        stroke="#61e063"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21 11.8838H19C18.2044 11.8838 17.4413 12.1999 16.8787 12.7625C16.3161 13.3251 16 14.0881 16 14.8838V28.8838"
                        stroke="#61e063"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 18.8838H20"
                        stroke="#61e063"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg> */}

                    {/* twitter */}

                    {/* <svg
                      className="cursor-pointer"
                      width="39"
                      height="41"
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 16.8838L14 12.8838V20.8838L20 16.8838Z"
                        stroke="#61e063"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3 16.8838C3 20.6032 3.38427 22.7855 3.67638 23.9071C3.75447 24.2134 3.90442 24.4967 4.11382 24.7335C4.32322 24.9704 4.58603 25.1539 4.88049 25.2689C9.0651 26.877 16 26.8328 16 26.8328C16 26.8328 22.9348 26.877 27.1195 25.2689C27.4139 25.1539 27.6767 24.9704 27.8861 24.7336C28.0955 24.4967 28.2455 24.2134 28.3236 23.9071C28.6157 22.7855 29 20.6032 29 16.8838C29 13.1643 28.6157 10.982 28.3236 9.86042C28.2455 9.5541 28.0956 9.27079 27.8862 9.03397C27.6768 8.79715 27.414 8.61364 27.1195 8.49863C22.9349 6.89053 16 6.93469 16 6.93469C16 6.93469 9.06518 6.89053 4.88053 8.49861C4.58607 8.61362 4.32325 8.79713 4.11385 9.03395C3.90446 9.27077 3.7545 9.55408 3.67641 9.8604C3.38428 10.982 3 13.1643 3 16.8838Z"
                        stroke="#61e063"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`container flex -mt-12 lg:-mt-0 justify-end lg:justify-between gap-8 sm:gap-16 lg:gap-0 mb-32`}
            >
              <div className="flex gap-8 text-[#fff] items-center">
                <button
                  onClick={handlePrevious}
                  className={`w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] rounded-md pl-2 group slide-btn-top-left-slant opacity-100 border-[#61e063] hover:bg-[#61e063] ${
                    labsData?.previousLabsData?.labsURL
                      ? "opacity-100"
                      : "pointer-events-none opacity-30"
                  } ${
                    labsData?.nextLabsData?.labsURL
                      ? ""
                      : "mr-16 sm:mr-32 lg:mr-0"
                  }`}
                  // style="border-color: rgb(104, 255, 210);"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain group-hover:hidden"
                  >
                    <path
                      d="M6.25 11.875L2.5 8.125L6.25 4.375"
                      stroke="#61e063"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                      stroke="#61e063"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain hidden group-hover:block"
                  >
                    <path
                      d="M6.25 11.875L2.5 8.125L6.25 4.375"
                      stroke="#1A1A1A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                      stroke="#1A1A1A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <p className="text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px] hidden lg:block">
                  {labsData?.labsPageStaticData?.previousText}
                </p>
              </div>

              <div className={`flex gap-8 text-[#fff] items-center `}>
                <p className="text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px] hidden lg:block">
                  {labsData?.labsPageStaticData?.nextText}
                </p>
                <button
                  onClick={handleNext}
                  className={`w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] slide-btn-bottom-right-slant rounded-md pl-2 group opacity-100 border-[#61e063] hover:bg-[#61e063] ${
                    labsData?.nextLabsData?.labsURL
                      ? "opacity-100"
                      : "pointer-events-none opacity-30"
                  }`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain group-hover:hidden"
                  >
                    <path
                      d="M13.75 11.875L17.5 8.125L13.75 4.375"
                      stroke="#61e063"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                      stroke="#61e063"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain hidden group-hover:block"
                  >
                    <path
                      d="M13.75 11.875L17.5 8.125L13.75 4.375"
                      stroke="#1a1a1a"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                      stroke="#1a1a1a"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            {relatedLabs?.length > 0 && (
              <NewsEvents
                header1={""}
                header2={labsDetailsStaticData?.fromTheLabText}
                headerButtonText={labsDetailsStaticData?.viewAllText}
                cardsData={relatedLabs}
                colors={{
                  color: "#61e063",
                }}
                url={clientUrls.labs}
                toLabs={true}
              />
            )}
          </div>

          <Footer />
        </section>
      )}
    </>
  );
};

export default LabsDescription;
