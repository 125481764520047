import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./reducers/LanguageReducer";
import colorReducer from "./reducers/ColorReducer";
const store = configureStore({
  reducer: {
    language: languageReducer,
    color: colorReducer,
  },
});

export default store;
