import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import "../../css/pagescss/Home.css";
import { navigateTo } from "../../constants/GeneralConstants";
import { clientUrls } from "../../constants/ClientUrls";
import { useNavigate, useParams } from "react-router-dom";
import PIL from "../PIL";
import FormatText from "../FormatText";

const NewsSlider = ({ newsData, newsPageData, lang }) => {
  const colors = {
    color: "#FFF",
  };
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    const startInterval = () => {
      intervalRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          if (prevIndex === newsData.length - 1) {
            return 0;
          } else {
            return prevIndex + 1;
          }
        });
      }, 5000);
    };

    startInterval();

    return () => clearInterval(intervalRef.current);
  }, [newsData]);

  const handleProgressBarClick = (index) => {
    clearInterval(intervalRef.current);
    setCurrentIndex(index);
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex === newsData.length - 1) {
          return 0;
        } else {
          return prevIndex + 1;
        }
      });
    }, 5000);
  };
  const formatJapaneseText = (text, lang) => {
    if (lang === "ja" && text.includes("＆イベント")) {
      return text.replace("＆イベント", "＆\nイベント");
    }
    return text;
  };

  return (
    <>
      {newsData?.length > 0 ? (
        <section className="container mx-auto flex flex-col sm:flex-row sm:gap-16 sm:items-end lg:items-start">
          <div className="flex flex-col h-full gap-3 lg:gap-6 w-auto sm:w-1/2 lg:w-2/5 xl:w-[41%] justify-center sm:justify-end">
            <h2
              className="font-normal lg:w-auto font-36-36-48 !leading-[100%] mb-3 uppercase overflow-hidden whitespace-pre-line"
              style={{ color: "#ffffff" }}
            >
              {formatJapaneseText(
                newsPageData?.bannerSection_headingText,
                lang
              )}
            </h2>
            <p className="content text-white  font-18-18-18 mb-3  !leading-[120%]">
              <FormatText text={newsPageData?.bannerSection_paragraphText} />
            </p>
          </div>
          <div className="w-auto sm:w-1/2 lg:w-3/5 xl:w-[59%] flex flex-col items-center sm:items-end justify-start sm:justify-end1 lg:justify-end1 gap-8  relative">
            <div
              className="relative rounded-xl h-48 xxs:h-52 xs:h-64 sm:h-[10.4rem] md:h-52 lg:h-72 xl:h-[21.4rem] 1.5xl:h-[25.6rem] 2.5xl:h-[28.9rem] 3xl:h-[32.1rem] 3.5xl:h-[35.31rem] 4xl:h-[38.5rem] cursor-pointer w-full sm:flex sm:flex-col sm:justify-end lg:block"
              onClick={(e) =>
                navigate(
                  navigateTo(
                    `${clientUrls.news}/${newsData[currentIndex]?.newsLink}`
                  )
                )
              }
            >
              <motion.div
                className="w-full h-full rounded-[10px] relative group"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <PIL
                  className="h-full w-full object-fill rounded-[10px]"
                  alt={`news image ${currentIndex + 1}`}
                  lowQaulitySrc={newsData[currentIndex]?.newsImageLowQuality}
                  highQualitySrc={newsData[currentIndex]?.newsImage}
                />
                <div className="hidden cursor-pointer absolute top-0 right-0 bg-white h-10 w-10 sm:h-16 sm:w-16 4xl:h-20 4xl:w-20 group-hover:flex items-center justify-center z-[3] rounded-bl-lg rounded-tr-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill={colors.color}
                    viewBox="0 0 24 24"
                    strokeWidth="3"
                    stroke="#FF8200"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                    />
                  </svg>
                </div>
              </motion.div>

              <div className="absolute w-[100%] lg:w-[60%] left-0  bottom-0  p-4 sm:p-4 lg:py-[1.2rem] lg:px-[2rem] max-w-full text-[#1A1A1A] bg-white opacity-[0.78]  rounded-tr-[10px] rounded-bl-[8px] z-[2] backdrop-blur text-wrap break-words">
                <motion.h3
                  className="text-[#1A1A1A] font-18-18-18 font-normal uppercase max-w-full leading-[1]"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  {newsData[currentIndex]?.newsTitle}
                </motion.h3>
                <p></p>
              </div>
            </div>
            <div className="flex gap-2 justify-between items-center w-full z-20">
              {newsData?.map((_, index) => (
                <motion.span
                  key={index}
                  className="block h-[.35rem] 4xl:h-2 cursor-pointer  !flex-1 "
                  initial={{ backgroundColor: "#FFFFFF80" }}
                  onClick={() => {
                    handleProgressBarClick(index);
                  }}
                  animate={{
                    backgroundColor:
                      currentIndex === index ? colors?.color : "#381665",
                  }}
                  transition={{ duration: 0.3 }}
                ></motion.span>
              ))}
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default NewsSlider;
