import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../css/componentscss/Home/OurPatners.css";

const options = {
  loop: true,
  autoplay: true,
  autoplayHoverPause: true,
  autoplayTimeout: 3000,
  autoplaySpeed: 3000,
  margin: 20,
  nav: false,
  // dots: false,
  responsive: {
    0: {
      items: 2,
    },
    500: {
      items: 3,
    },
    768: {
      items: 4,
    },
    1024: {
      items: 7,
    },
  },
};

const OurPartners = ({ clientsData, homeData, aboutPage, lang }) => {
  const { ourText, partnersText, weWorkWithText, theBestText } = homeData;

  return (
    <section className="container my-24 md:my-20 lg:my-40">
      {aboutPage ? (
        <h2 className="font-normal common-title-size leading-[1] text-white z-[2]">
          <span className="">{weWorkWithText}</span>
          {lang === "zh-chs" ? "" : " "}
          <span className="text-[#2AD9FF]">{theBestText}</span>
        </h2>
      ) : (
        <h2 className="font-normal font-36-36-48k text-[26px] xxs:text-[32px] sm:text-[36px] lg:text-[48px] 1.5xl:text-[52px] 3xl:text-[56px] leading-[1] uppercase text-white z-[2]">
          <span className="block">
            {lang === "zh-chs"
              ? `${ourText}${partnersText}`
              : `${ourText} ${partnersText}`}
          </span>
        </h2>
      )}

      <div className="my-8">
        <div className="shadows hidden sm:block relative">
          <img
            src="/images/ShadowLeft.png"
            alt="side-ptw"
            className="object-cover leftShadow -left-[1px] 2xl:-left-[2px] top-0 sm:h-40 lg:h-60 w-40"
          />
          <img
            src="/images/ShadowRight.png"
            alt="side-ptw"
            className="object-cover rightShadow -right-[1px] 2xl:-right-[2px] top-0 sm:h-40 lg:h-60 w-40"
          />
        </div>
        <OwlCarousel {...options} className="clientsslider">
          {clientsData && clientsData.length > 0 ? (
            clientsData.map((partner, meta_value) => (
              <div key={meta_value} className="item">
                <img
                  src={partner.clientLogo}
                  alt="side-ptw"
                  className="h-auto border-[#7E818A] border py-3 px-7 mx-3"
                />
              </div>
            ))
          ) : (
            <p>No partners available.</p>
          )}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default OurPartners;
