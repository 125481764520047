import React from "react";

const Achievements = ({ servicePageData, achievements, color, lang }) => {
  return (
    <section className="container">
      {lang === "zh-chs" ? (
        <h2 className="uppercase text-[26px] xs:text-3xl sm:text-3xl md:text-4xl lg:text-[38px] xl:text-5xl 2xl:text-[52.8px] 3xl:text-[62.4px] 4xl:text-[86.4px] leading-[1] text-white mb-8 sm:mb-12">
          <span className="">{servicePageData?.weUnlockedText}</span>
          <span className="">
            {servicePageData?.someText}
            <span className="" style={{ color: color }}>
              {servicePageData?.epicAchievementsText}
            </span>
          </span>
        </h2>
      ) : (
        <h2 className="uppercase text-[26px] xs:text-3xl sm:text-3xl md:text-4xl lg:text-[38px] xl:text-5xl 2xl:text-[52.8px] 3xl:text-[62.4px] 4xl:text-[86.4px] leading-[1] text-white mb-8 sm:mb-12">
          <span className="block">{servicePageData?.weUnlockedText}</span>
          <span className="">
            {servicePageData?.someText}&nbsp;
            <span className="" style={{ color: color }}>
              {servicePageData?.epicAchievementsText}
            </span>
          </span>
        </h2>
      )}
      <div className="flex xs-only:flex-nowrap xs-only:overflow-auto hide-scroll gap-2 justify-between sm:grid sm:grid-cols-2 sm:gap-x-12 lg:grid-cols-3 sm:gap-y-8 sm:flex-wrap">
        {achievements?.map((achievement, meta_value) => {
          return (
            <div
              className="flex flex-col xs-only:flex-shrink-0 bg-[#ffffff03] text-white xs-only:w-[80%] p-8 backdrop-blur-sm "
              key={meta_value}
            >
              <img
                className="w-full h-auto mb-2 xs:mb-6 object-contain"
                src={achievement?.achievementImage}
                alt={achievement?.achievementTitle}
              />
              <p className="text-center text-xs xs:text-[18px] 2xl:text-xl 4xl:text-2xl xs:mb-2">
                {achievement?.achievementTitle}
              </p>
              <h3 className="text-center text-base xs:text-xl 2xl:text-2xl 4xl:text-3xl">
                {achievement?.achievementYear}
              </h3>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Achievements;
