import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setColor } from "../../store/reducers/ColorReducer";
import CommonTopBanner from "./CommonTopBanner";
import ParagraphSection from "./ParagraphSection";
import AAACoDev from "./AAACoDev";
import NewsEvents from "../home/NewsEvents";
import { clientUrls } from "../../constants/ClientUrls";
import OurServices from "../home/OurServices";
import Footer from "../Footer";
import SideSiteSEO from "../seo/SideSiteSEO";

const Datasets = ({ data }) => {
  const dispatch = useDispatch();
  const { lang } = useParams();
  useEffect(() => {
    document.title = data?.servicePageData?.datasetsSEOTitle
      ? data?.servicePageData?.datasetsSEOTitle
      : "Datasets and Data Collection | Side";
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  useEffect(() => {
    dispatch(
      setColor({
        primary: data?.serviceColors[0]?.colorCode,
        secondary: "#7E818A",
        tertiary: data?.serviceColors[0]?.tertiaryColor,
      })
    );
  }, [data, lang]);
  const { servicePageData, subServices, labs, services, type } = data || {};

  const { bannerSection_heading1Text, fromTheLabText, visitTheLabText } =
    servicePageData || {};

  return (
    <>
      <SideSiteSEO
        title={
          servicePageData?.datasetsSEOTitle
            ? servicePageData?.datasetsSEOTitle
            : "Datasets and Data Collection | Side"
        }
        description={
          servicePageData?.datasetsSEODescription
            ? servicePageData?.datasetsSEODescription
            : "Partner with Side for top-tier data collection services in 40+ languages and locales. Specializing in TTS, ASR, and speech technology with 10+ years of experience."
        }
      />
      <section>
        <CommonTopBanner
          servicePageData={servicePageData}
          color={{
            primary: data?.serviceColors[0]?.colorCode,
            tertiary: "#ffffff",
          }}
        />
        <ParagraphSection
          servicePageData={servicePageData}
          color={{
            primary: data?.serviceColors[0]?.colorCode,
            tertiary: data?.serviceColors[0]?.tertiaryColor,
          }}
        />

        <section className="co-dev-section relative my-20 sm:my-32 lg:my-40">
          <div className="absolute right-[-3rem] sm:right-0 top-[-3.5rem] sm:top-[-6.5rem] xl:top-[-10rem] z-[-1] sm-only:right-[-16rem] md-only:right-[-13rem]">
            <img
              src={servicePageData?.serviceTypeBgImage}
              alt="ptw-side"
              className="w-[90%] sm-only:w-[60%] md-only:w-[57%] lg:w-[50%] float-right"
            />
          </div>
          <AAACoDev
            gameServices={subServices}
            color={{
              primary: data?.serviceColors[0]?.colorCode,
              tertiary: data?.serviceColors[0]?.tertiaryColor,
            }}
            isQualityAssurance={true}
            isDatasets={true}
          />
        </section>
        <NewsEvents
          header1={bannerSection_heading1Text}
          header2={fromTheLabText}
          headerButtonText={visitTheLabText}
          cardsData={labs}
          colors={{
            color: data?.serviceColors[0]?.secondaroyColor,
            tertiaryColor: data?.serviceColors[0]?.tertiaryColor,
          }}
          url={clientUrls.labs}
          toLabs={true}
          type={type}
        />
        <section
          className="mt-12 lg:mt-12 bg-no-repeatk bg-left-topk relative"
          // style={{
          //   backgroundImage:
          //     window.innerWidth > 1023
          //       ? `url(${servicePageData?.exploreMoreBgImage})`
          //       : "",
          // }}
        >
          <div className="absolute -top-[7rem] left-0 z-[-1] hidden lg:block">
            <img
              src={servicePageData?.exploreMoreBgImage}
              alt="side-ptw"
              className="w-[68%]"
            />
          </div>
          <OurServices
            servicesData={services}
            header1={servicePageData?.exploreText}
            header2={servicePageData?.moreText}
            colors={{ color: data?.serviceColors[0]?.colorCode }}
            lang={lang}
          />
        </section>

        <Footer />
      </section>
    </>
  );
};

export default Datasets;
