import { useEffect, useRef, useState } from "react";
import { getLanguage } from "../constants/GeneralConstants";
import { getCareersPageData, getJobsData } from "../services/ApiService";
import "../css/pagescss/Careers.css";
import Footer from "../components/Footer";
import FormatText from "../components/FormatText";
import LeftScatterAnimation from "../components/about/LeftScatterAnimation";
import { useDispatch } from "react-redux";
import { setColor } from "../store/reducers/ColorReducer";
import CareersSWiperSlide from "../components/Careers/CareerSwiperSlide";
import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import ButtonSlant from "../components/ButtonSlant";
import Testimonials from "../components/Testimonials";
import { SlantImg } from "../components/CommonComponents";

const Careers = () => {
  const { lang } = useParams();
  const [careersData, setCareersPageData] = useState(null);
  const [activeSquad, setActiveSquad] = useState("");
  const [activeSquadDept, setActiveSquadDept] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loader, setLoader] = useState(true);
  const [jobsData, setJobsData] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setlocations] = useState([]);
  const [displayDepartment, setDisplayDepartments] = useState(false);
  const [displayDepartmentMobile, setDisplayDepartmentMobile] = useState(false);

  const [displayLocations, setDisplayLocations] = useState(false);
  const [displayLocationsMobile, setDisplayLocationsMobile] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [showOverlay, setShowOverlay] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const departmentRef = useRef(null);
  const locationRef = useRef(null);
  const departmentRefMobile = useRef(null);
  const locationRefMobile = useRef(null);
  const departmentButtonRef = useRef(null);
  const locationButtonRef = useRef(null);
  const departmentButtonMobileRef = useRef(null);
  const locationButtonMobileRef = useRef(null);
  const [hoverStates, setHoverStates] = useState({});
  const [loading, setLoading] = useState(false);
  const handleHoverChange = (key, isHover) => {
    setHoverStates((prevStates) => ({
      ...prevStates,
      [key]: isHover,
    }));
  };
  const handleClickOutside = (event) => {
    if (
      departmentRef.current &&
      !departmentRef.current.contains(event.target) &&
      departmentButtonRef.current &&
      !departmentButtonRef.current.contains(event.target)
    ) {
      setDisplayDepartments(false);
    }

    if (
      locationRef.current &&
      !locationRef.current.contains(event.target) &&
      locationButtonRef.current &&
      !locationButtonRef.current.contains(event.target)
    ) {
      setDisplayLocations(false);
    }

    if (
      departmentRefMobile.current &&
      !departmentRefMobile.current.contains(event.target) &&
      departmentButtonMobileRef.current &&
      !departmentButtonMobileRef.current.contains(event.target)
    ) {
      setDisplayDepartmentMobile(false);
    }

    if (
      locationRefMobile.current &&
      !locationRefMobile.current.contains(event.target) &&
      locationButtonMobileRef.current &&
      !locationButtonMobileRef.current.contains(event.target)
    ) {
      setDisplayLocationsMobile(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const handleImagePrev = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex - 1;
    });
  };
  const handleImageNext = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex + 1;
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setColor({
        primary: "#FF8200",
        secondary: "#FF8200",
        tertiary: "#1A1A1A",
      })
    );
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    callCareersPageData(getLanguage());
  }, [lang]);
  const color = {
    primary: "#FF8200",
    secondary: "#FF8200",
    tertiary: "#1A1A1A",
  };

  useEffect(() => {
    callJobsAPI();
  }, [lang]);

  const callJobsAPI = async () => {
    setLoading(true);
    try {
      let data = await getJobsData();
      setJobsData(data);
      setAllJobs(data);
      setlocations([...new Set(data.map((job) => job.country))]);
      setDepartments([
        ...new Set(
          data.map((job) => job.department).filter((department) => department)
        ),
        setLoading(false),
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  const callCareersPageData = async (language) => {
    try {
      let data = await getCareersPageData(language);
      document.title = data?.careersPageData?.careersSEOTitle;
      setCareersPageData(data);
      setActiveSquad(data?.squads[0]?.squadName);
      setActiveSquadDept(data?.squads[0]?.squadNameTextEng);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const applyFilters = (department, location, searchTerm) => {
    let filteredJobs = allJobs;

    if (department && department !== "All") {
      filteredJobs = filteredJobs.filter(
        (job) => job?.department?.toLowerCase() === department.toLowerCase()
      );
    }

    if (location && location !== "All") {
      filteredJobs = filteredJobs.filter(
        (job) => job?.country?.toLowerCase() === location.toLowerCase()
      );
    }

    if (searchTerm && searchTerm.trim() !== "") {
      filteredJobs = filteredJobs.filter(
        (job) =>
          job.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          job.department?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          job.country?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          job.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          job.state?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setJobsData(filteredJobs);
  };

  const filterWithDepartments = (d, btnFlag) => {
    if (btnFlag) {
      setSelectedDepartment(d);
      applyFilters(d, selectedLocation, searchValue);
    } else {
      if (d === selectedDepartment) {
        setSelectedDepartment("All");
        applyFilters("All", selectedLocation, searchValue);
      } else {
        setSelectedDepartment(d);
        applyFilters(d, selectedLocation, searchValue);
      }
    }

    setDisplayDepartments(false);
    setDisplayDepartmentMobile(false);
  };

  const filterWithLocations = (d) => {
    if (d === selectedLocation) {
      setSelectedLocation("All");
      applyFilters(selectedDepartment, "All", searchValue);
    } else {
      setSelectedLocation(d);
      applyFilters(selectedDepartment, d, searchValue);
    }
    setDisplayLocations(false);
    setDisplayLocationsMobile(false);
  };

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchValue(term);
    applyFilters(selectedDepartment, selectedLocation, term);
  };

  const scrollToPositions = () => {
    const el = document.getElementById("positions-section");
    // el.scrollIntoView({ behavior: "smooth", block: "start" });
    const yOffset = -80; // Adjust this value as needed
    const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  const handleResetClick = () => {
    setJobsData(allJobs);
    setSelectedDepartment("");
    setSelectedLocation("");
    setSearchValue("");
  };
  const { careersPageData, experiences, squads, values, testimonials } =
    careersData || {};
  const activeSquadData = squads?.find(
    (squad) => squad?.squadName === activeSquad
  );
  if (loader) {
    return <Loader />;
  }
  console.log("console---------", activeSquadDept);
  return (
    <>
      <section>
        {/* <TopBannerForOtherPages pageName="Careers" /> */}
        {/* top banner section starts here  */}
        <section className="bg-primary text-[#1a1a1a] relative mobile-about-slantk">
          <div className="hidden lg:block w-[35%] h-full absolute top-0 right-0 ">
            <img
              src={careersPageData?.bannerBgImage}
              alt="banner_image"
              className=" h-full w-full "
            />
          </div>
          <div className="hidden sm:block lg:hidden h-full absolute top-0 right-0">
            <img
              src={careersPageData?.bannerBgImageTab}
              alt="banner_image"
              className=" h-full w-full "
            />
          </div>
          <div className="block sm:hidden w-full h-[18rem]k absolute bottom-0">
            <img
              src={careersPageData?.bannerBgImageMob}
              alt="banner_image"
              className=" h-full w-full"
            />
          </div>
          <div className="absolute right-0 -bottom-[1px] z-[10]">
            <SlantImg className="h-[100px] sm:h-auto" />
          </div>
          {/* <div className="container mx-auto h-[60rem] sm:min-h-[35rem] md:min-h-[40rem] lg:min-h-[38rem] xl:min-h-[35rem] 2xl:min-h-[38rem] 2.5xl:min-h-[42px] 3xl:min-h-[50rem] 4xl:min-h-[54rem] h-fit mb-24 sm:mb-8  lg:mb-28 4xl:mb-[18rem] rounded-lg  flex flex-col sm:flex-row items-center gap-8 3xl:gap-16"> */}
          <div className="container mx-auto h-[67rem]  sm:h-fit mb-24 sm:mb-8  lg:mb-28 4xl:mb-[18rem] rounded-lg  flex flex-col sm:flex-row items-center gap-8 3xl:gap-16">
            <div className="pt-28 2xl:pt-36 3xl:pt-52 sm:pb-4 3xl:pb-20 ">
              <h3 className="font-18-18-18 uppercase">
                {careersPageData?.careersText}
              </h3>
              <h2 className="text-[36px] sm:text-[42px] md:text-[48px] lg:text-[57.6px] xl:text-[64px] 2xl:text-[76px]  3xl:text-[96px] 4xl:text-[121.6px] leading-[100%] sm:leading-[90%] mb-7 lg:mb-8 xl:mb-10 2xl:mb-12 uppercase w-[85%]">
                <span className="block">
                  {careersPageData?.joinOurSquadText}
                </span>
              </h2>
              <p className="text-[18px] sm:text-[16px] md:text-[18px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px] font-medium sm:w-[62%] md:w-[65%] mb-7 lg:mb-10 2xl:mb-12 3xl:mb-16">
                <FormatText text={careersPageData?.joinOurSquadContent} />
              </p>

              <ButtonSlant
                buttonText={careersPageData?.jobsOpeningText}
                buttonClass="uppercase text-lg text-[#1a1a1a] font-bold border-[#1a1a1a] border-[2px] px-6 py-3 4xl:py-4 4xl:px-8 rounded-md text-map-button font-dela"
                onClick={scrollToPositions}
                textColorHover="#ff8200"
                divClass="w-fit mb-4 2xl:mb-8"
                bgColorHover="#1a1a1a"
                slantClass=" bottom-right-slant"
              />
            </div>
          </div>
        </section>
        {/* top banner section ends here  */}
        {/* top banner section starts here  */}
        {/* <section className="bg-primary text-[#1a1a1a] slant-bottom-footer mobile-about-slant relative">
          <div className="hidden lg:block w-[35%] h-full absolute top-0 right-0 ">
            <img
              src={careersPageData?.bannerBgImage}
              alt="banner_image"
              className=" h-full w-full "
            />
          </div>
          <div className="hidden sm:block lg:hidden h-full absolute top-0 right-0">
            <img
              src={careersPageData?.bannerBgImageTab}
              alt="banner_image"
              className=" h-full w-full "
            />
          </div>
          <div className="block sm:hidden w-full h-[18rem] absolute bottom-0">
            <img
              src={careersPageData?.bannerBgImageMob}
              alt="banner_image"
              className=" h-full w-full"
            />
          </div>
          <div className="container mx-auto h-[57rem] sm:h-[30rem] md:h-[35rem] lg:h-[33rem] xl:h-[36rem] 2xl:h-[38rem] 3xl:!h-[42rem] 4xl:h-[52rem] mb-16 sm:mb-8  lg:mb-28 rounded-lg  flex flex-col sm:flex-row items-center gap-8 3xl:gap-16">
            <div className="pt-[7rem] sm:pt-[3rem] lg:pt-[3rem] xl:pt-[5rem]">
              <h3 className="text-sm sm:text-base md:text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] uppercase">
                {careersPageData?.careersText}
              </h3>
              <h2 className="text-[36px] sm:text-[42px] md:text-[48px] lg:text-[57.6px] xl:text-[64px] 2xl:text-[78px]  3xl:text-[100px] 4xl:text-[121.6px] leading-[100%] sm:leading-[90%] mb-7 lg:mb-10 3xl:mb-16 uppercase">
                <span className="block">
                  {careersPageData?.joinOurSquadText}
                </span>
              </h2>
              <p className="mb-7 lg:mb-10 3xl:mb-16 text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] leading-[120%] sm:font-medium sm:w-[65%]">
                <FormatText text={careersPageData?.joinOurSquadContent} />
              </p>
              <button
                buttonText="Jobs Openings"
                className="uppercase text-lg text-[#1a1a1a] font-bold border-[#1a1a1a] border-[2px] px-6 py-3 4xl:py-4 4xl:px-8 rounded-md bottom-right-slant-hover hover:bg-[#1a1a1a] hover:text-primary text-map-button font-dela"
                onClick={scrollToPositions}
              >
                {careersPageData?.jobsOpeningText}
              </button>
            </div>
          </div>
        </section> */}
        {/* top banner section ends here  */}
        {/* 2 columns section starts here  */}
        <div className="container text-white mb-20 sm:mb-28 lg:mb-40 flex justify-between flex-col-reverse lg:flex-row">
          <div className="w-full lg:w-[40%] p-2">
            <h2 className="uppercase text-[36px] 2xl:text-[43.2px] 3xl:text-[54px] leading-[1] text-white w-[21.5rem] sm:w-auto lg:w-[34rem] 2xl:w-[40rem] 3xl:w-[48rem] mb-4 sm:mb-6 ">
              <span className="block">
                {careersPageData?.experienceSection_headingText}
              </span>
            </h2>
            <p className="mb-4 sm:mb-6 text-[18px] 2xl:text-[21.6px]  3xl:text-[27px] leading-[133%] sm:leading-[110%] lg:leading-[120%]  font-medium sm:font-normal py-6">
              {careersPageData?.experienceSection_descriptionText}
            </p>
            <div className="flex gap-8 text-white lg:text-primary">
              <div>
                <h3 className="text-[36px] 2xl:text-[43.2px] 3xl:text-[54px] leading-[89%] tracking-[-3%] mb-3">
                  {careersPageData?.experienceSection_titlesValue}
                </h3>
                <p className="mb-4 sm:mb-6 text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] leading-[120%] sm:font-medium">
                  {careersPageData?.experienceSection_titleslabel}
                </p>
              </div>
              <div>
                <h3 className="text-[36px] 2xl:text-[43.2px] 3xl:text-[54px] leading-[89%] tracking-[-3%] mb-3">
                  {careersPageData?.experienceSection_peopleValue}
                </h3>
                <p className="mb-4 sm:mb-6 text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] leading-[120%] sm:font-medium">
                  {careersPageData?.experienceSection_peopleLabel}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[60%] self-center lg:mr-12">
            <LeftScatterAnimation
              images={
                experiences &&
                experiences[0] &&
                experiences?.map((el) => el?.experienceImageURL)
              }
            />
          </div>
        </div>
        {/* 2 columns section ends here  */}
        {/* Values starts here */}
        <CareersSWiperSlide
          values={values}
          color={{
            primary: "#FF8200",
            tertiary: "#1a1a1a",
          }}
          headerTitle={careersPageData?.valuesText}
        />

        {/* Values ends here */}

        {/* find your squad starts here  */}
        <section className="careers-dk relative">
          <div className="absolute bottom-[31rem] sm:bottom-[25rem] md:bottom-[16rem] lg:bottom-[5rem] xl:bottom-[-3rem] right-0 z-[-1] w-[85%] sm:w-[60%] lg:w-[55%] xl:w-[40%] 1.5xl:w-[41%] 2xl:w-[37%] 3xl:w-[33%]">
            <img
              src={careersPageData?.findYourSquadBgImage}
              alt="side-ptw"
              className=""
            />
          </div>
          <h2 className="container font-normal text-patner-header leading-[1] uppercase text-white z-[2]">
            <span className="block">{careersPageData?.findYourSquadText}</span>
          </h2>
          <div className="container mt-20 sm:flex sm:gap-8 lg:gap-16 2xl:gap-24">
            <div className="sm:hidden relative mb-6">
              <button
                className="w-full px-5 py-3 text-[18px] text-primary font-dela flex justify-between border-primary border-[3px] rounded-md mb-2"
                onClick={() => {
                  setIsDropdownOpen(!isDropdownOpen);
                }}
              >
                <span>{activeSquad}</span>
                <span>
                  {isDropdownOpen ? (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 platforms"
                    >
                      <path
                        d="M8.625 6.25L12.375 2.5L16.125 6.25"
                        stroke="#ff8200"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                        stroke="#ff8200"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#ff8200"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#ff8200"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  )}
                </span>
              </button>
              {isDropdownOpen && (
                <div
                  className="w-full flex flex-col gap-4 text-[18px] absolute bg-[#1a1a1a] border-[3px] rounded-md px-5 py-3 z-[5]"
                  style={{ color: color.primary, borderColor: color.primary }}
                >
                  {squads?.map((squad, i) => (
                    <h4
                      key={i}
                      onClick={(e) => {
                        setActiveSquad(e.target.textContent);
                        setActiveSquadDept(squad.squadNameTextEng);

                        setCurrentIndex(0);
                        setIsDropdownOpen(false);
                      }}
                    >
                      {squad?.squadName}
                    </h4>
                  ))}
                </div>
              )}
            </div>
            <div className="hidden sm:flex flex-col sm:w-[40%] lg:w-[38%] xl:w-[34%] gap-y-4">
              {squads?.map((squad, i) => (
                <ButtonSlant
                  key={i}
                  buttonClass={` border-[3px] rounded-md text-[18px] 2xl:text-xl break-words 3xl:text-2xl px-4 py-2 2xl:py-3 lg:pl-4 lg:text-left w-full font-dela text-wrap ${
                    activeSquad === squad?.squadName ? "bottom-right-slant" : ""
                  } sm:min-h-12   2xl:min-h-[3.5rem] 3xl:min-h-[4.1rem]`}
                  slantClass="bottom-right-slant"
                  onClick={() => {
                    setActiveSquad(squad?.squadName);
                    setActiveSquadDept(squad.squadNameTextEng);

                    setCurrentIndex(0);
                  }}
                  textColor={
                    activeSquad === squad?.squadName
                      ? color.tertiary
                      : color?.primary
                  }
                  textColorHover="#ffffff"
                  bgColor={
                    activeSquad === squad?.squadName
                      ? color.primary
                      : "transparent"
                  }
                  bgColorHover={color?.primary}
                  borderColor={color.primary}
                  buttonText={squad?.squadName}
                />
              ))}
            </div>
            <div className="bg-[#464646] text-[#fff] px-4 sm:px-4 2xl:px-11 3xl:px-14 py-4 sm:py-6 2xl:py-11 sm:w-[60%] lg:w-[62%] xl:w-[66%] h-fit rounded-[4px] min-h-[30rem] sm:min-h-[49rem] 2xl:min-h-[52rem] 3xl:min-h-[62rem] 3.5xl:xl:min-h-[69rem]">
              {activeSquadData?.squadProperties?.squadImageURLs.length > 0 && (
                <div className="relative w-full">
                  <img
                    src={
                      activeSquadData?.squadProperties?.squadImageURLs[
                        currentIndex
                      ]
                    }
                    className="h-auto w-full object-fill rounded-[4px]"
                    alt="side-ptw"
                  />
                  <div className="absolute right-0 bottom-0 py-[0.5rem] px-[0.5rem] w-full lg:w-fit text-white sm:flex rounded-[10px] z-[2] justify-end">
                    {activeSquadData?.squadProperties?.squadImageURLs?.length >
                      1 && (
                      <div className="flex gap-[0.5rem] flex-end float-right">
                        <button
                          className={`w-[40px] h-[40px] bg-primary rounded-md pl-2  ${
                            currentIndex === 0
                              ? "opacity-30 pointer-events-none"
                              : "opacity-100"
                          }`}
                          onClick={() => {
                            handleImagePrev();
                          }}
                        >
                          <svg
                            width="16"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 object-contain"
                          >
                            <path
                              d="M6.25 11.875L2.5 8.125L6.25 4.375"
                              stroke="#fff"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                              stroke="#fff"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </button>
                        <button
                          className={`w-[40px] h-[40px] bg-primary rounded-md pl-2  ${
                            currentIndex ===
                            activeSquadData?.squadProperties?.squadImageURLs
                              ?.length -
                              1
                              ? "opacity-30 pointer-events-none"
                              : "opacity-100 "
                          }`}
                          onClick={() => {
                            handleImageNext();
                          }}
                        >
                          <svg
                            width="16"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 object-contain"
                          >
                            <path
                              d="M13.75 11.875L17.5 8.125L13.75 4.375"
                              stroke="#fff"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                              stroke="#fff"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <p className="mt-4 font-18-18-18">
                <FormatText
                  text={activeSquadData?.squadProperties?.squadParagraph}
                />
                {}
              </p>
            </div>
          </div>
          <div className="container w-full flex justify-center items-center">
            <ButtonSlant
              buttonText={careersPageData?.applyNowText}
              buttonClass="border-primary border-[3px] rounded-md text-[18px] 2xl:text-xl 3xl:text-2xl px-[5rem] sm:px-32 py-3 2xl:py-3 lg:pl-4l text-center w-full lg:w-fit font-dela text-primary uppercase "
              onClick={() => {
                scrollToPositions();

                filterWithDepartments(activeSquadDept, true);
              }}
              textColorHover="#ffffff"
              divClass="w-full lg:w-fit my-16"
              bgColorHover="#ff8200"
              slantClass=" bottom-right-slant"
            />
          </div>
        </section>
        {/* find your squad ends here  */}
        {/* their words starts here  */}
        <section className="!mt-20 !sm:mt-28 !lg:mt-40 !mb-40">
          <Testimonials
            testimonialLeftQuotations={
              careersPageData?.testimonialLeftQuotations
            }
            testimonialRightQuotations={
              careersPageData?.testimonialRightQuotations
            }
            testimonials={testimonials}
            bgcolor="#ff8200"
            textColor="#1a1a1a"
          />
        </section>
        {/* their ends here  */}
        {/* open positions starts here  */}
        <section
          className="container mt-20 sm:mt-28 lg:mt-40"
          id="positions-section"
        >
          <div className=" sm:flex gap-3 items-end">
            <h2 className=" font-normal text-patner-header leading-[1] uppercase text-white z-[2]">
              <span className="block">{careersPageData?.findJobsText} </span>
            </h2>
            <p className="text-[24px] text-white heading-noto-bold">
              {jobsData.length} {careersPageData?.openPositionsText}
            </p>
          </div>
          {/* desktop and tablet filter starts here */}
          <div className="my-10 hidden sm:block">
            <ul className="flex flex-wrap lg:flex-nowrap uppercase gap-[0.8rem] lg:gap-8 justify-between">
              <li className="list-none sm:w-[24%] lg:w-[28%]">
                <ButtonSlant
                  ref={departmentButtonRef}
                  buttonClass="hidden sm:flex items-center justify-center uppercase   text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer w-full  text-[#ff8200] border-[2px] border-[#ff8200] sm:px-2 lg:px-4 py-3 rounded"
                  buttonText={careersPageData?.departmentText}
                  onClick={(e) => setDisplayDepartments(!displayDepartment)}
                  textColorHover="#1a1a1a"
                  bgColorHover="#ff8200"
                  slantClass="bottom-right-slant"
                  onHoverChange={(isHover) =>
                    handleHoverChange("team", isHover)
                  }
                >
                  {displayDepartment ? (
                    hoverStates?.team ? (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2 platforms"
                      >
                        <path
                          d="M8.625 6.25L12.375 2.5L16.125 6.25"
                          stroke="#1a1a1a"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                          stroke="#1a1a1a"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2 platforms"
                      >
                        <path
                          d="M8.625 6.25L12.375 2.5L16.125 6.25"
                          stroke="#ff8200"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                          stroke="#ff8200"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    )
                  ) : hoverStates?.team ? (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#ff8200"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#ff8200"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  )}
                </ButtonSlant>
                {displayDepartment && (
                  <div className="relative" ref={departmentRef}>
                    <div className="absolute z-10 w-full rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 backdrop-blur-lg left-0 top-[1rem] h-[15rem] overflow-auto scrollable custom-scroll-careers-desk">
                      {departments?.sort().map((d) => (
                        <div
                          key={d}
                          onClick={() => filterWithDepartments(d)}
                          className={`flex group items-center justify-between cursor-pointer hover:text-[#ff8200] ${
                            d === selectedDepartment
                              ? "text-[#ff8200]"
                              : "text-[#e5e7eb56]"
                          }`}
                        >
                          <div
                            className={`flex justify-between gap-x-2.5 p-2 text-[15px] 2xl:text-[18px] 3xl:text-[22px] 4xl:text-[24px] w-fit font-semibold hover:opacity-100 rounded-md ${
                              d === selectedDepartment
                                ? "text-[#ff8200]"
                                : "group-hover:text-[#ff8200]"
                            }`}
                          >
                            {d}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </li>
              <li className="list-none sm:w-[24%] lg:w-[28%]">
                <ButtonSlant
                  ref={locationButtonRef}
                  buttonClass="hidden sm:flex items-center justify-center uppercase   text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer w-full  text-[#ff8200] border-[2px] border-[#ff8200] sm:px-2 lg:px-4 py-3 rounded"
                  buttonText={careersPageData?.locationText}
                  onClick={(e) => setDisplayLocations(!displayLocations)}
                  textColorHover="#1a1a1a"
                  bgColorHover="#ff8200"
                  slantClass="bottom-right-slant"
                  onHoverChange={(isHover) =>
                    handleHoverChange("location", isHover)
                  }
                >
                  {displayLocations ? (
                    hoverStates?.location ? (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2 platforms"
                      >
                        <path
                          d="M8.625 6.25L12.375 2.5L16.125 6.25"
                          stroke="#1a1a1a"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                          stroke="#1a1a1a"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2 platforms"
                      >
                        <path
                          d="M8.625 6.25L12.375 2.5L16.125 6.25"
                          stroke="#ff8200"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                          stroke="#ff8200"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    )
                  ) : hoverStates?.location ? (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke="#ff8200"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke="#ff8200"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  )}
                </ButtonSlant>
                {displayLocations && (
                  <div className="relative" ref={locationRef}>
                    <div className="absolute z-10 w-full rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 backdrop-blur-lg left-0 top-[1rem] h-[15rem] overflow-auto scrollable custom-scroll-careers-desk">
                      {locations?.sort().map((d) => (
                        <div
                          key={d}
                          onClick={() => filterWithLocations(d)}
                          className={`flex group items-center justify-between cursor-pointer ${
                            d === selectedLocation
                              ? "text-[#ff8200]"
                              : "text-[#e5e7eb56]"
                          } hover:text-[#ff8200]`}
                        >
                          <div
                            className={`flex justify-between gap-x-2.5 p-2 text-[15px] 2xl:text-[18px] 3xl:text-[22px] 4xl:text-[24px] w-fit font-semibold hover:opacity-100 rounded-md ${
                              d === selectedLocation
                                ? "text-[#ff8200]"
                                : "group-hover:text-[#ff8200]"
                            }`}
                          >
                            {d}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </li>
              <li className="list-none sm:w-[24%] lg:w-[28%] relative">
                {/* <input
                  type="search"
                  placeholder={`${careersPageData?.searchText}`}
                  className="hidden sm:flex items-center justify-center uppercase   placeholder-primary text-center text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer w-full  text-[#ff8200] border border-[#ff8200] bottom-right-slant-hoverk sm:px-2 lg:px-4 py-3 rounded bg-transparent"
                  // value={searchTerm}
                  onChange={handleSearch}
                /> */}
                <div className="hidden sm:flex items-center justify-center uppercase font-18-18-18 cursor-pointer w-full  text-[#ff8200] border-[2px] border-[#ff8200] bottom-right-slant-hoverk px-4 py-3 rounded bg-transparent">
                  <input
                    type="search"
                    placeholder={`${careersPageData?.searchText}`}
                    className="bg-transparent focus:outline-none placeholder-primary w-[inherit]"
                    onChange={handleSearch}
                    value={searchValue}
                  />
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2 "
                  >
                    <path
                      stroke="#ff8200"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>

                {/* <button className="hidden sm:flex items-center justify-center uppercase   text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer w-full  text-[#ff8200] border border-[#ff8200] bottom-right-slant-hover hover:bg-[#ff8200] hover:text-black sm:px-2 lg:px-4 py-3 rounded">
                  Search
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2"
                  >
                    <path
                      stroke="#ff8200"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </button> */}
                <div className="relative"></div>
              </li>

              <li className="list-none sm:w-[18%] lg:w-[12%] relative">
                <button
                  className="relative hidden sm:flex items-center justify-center uppercase hover-reset-svg-careers text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#1a1a1a] bg-[#ff8200] hover:bg-transparent   hover:text-[#ff8200] px-4 py-3 rounded w-full font-dela"
                  onClick={handleResetClick}
                >
                  {careersPageData?.resetText}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 400 120"
                    className="absolute inset-0 w-full h-full 2xl:hidden"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M10,2 H390 A5,5 0 0 1 395,7 v78 L359,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                      fill="none"
                      stroke="#ff8200"
                      stroke-width="4"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 400 120"
                    className="absolute inset-0 w-full h-full hidden 2xl:block 3xl:hidden"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M10,2 H390 A5,5 0 0 1 395,7 v80 L362,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                      fill="none"
                      stroke="#ff8200"
                      stroke-width="4"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 400 120"
                    className="absolute inset-0 w-full h-full hidden 3xl:block"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M10,2 H390 A5,5 0 0 1 395,7 v80 L370,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                      fill="none"
                      stroke="#ff8200"
                      stroke-width="4"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </div>
          {/* desktop and tablet filter ends here */}
          {/* mobile filter starts here */}
          <div className="sm:hidden my-8">
            <ul className="flex flex-wrap lg:flex-nowrap uppercase gap-4 sm:gap-4 lg:gap-8 justify-between">
              <li className="list-none ">
                <div className="hidden sm:flex items-center justify-center uppercase font-18-18 cursor-pointer w-full  text-[#ff8200] border-[2px] border-[#ff8200] bottom-right-slant-hoverk px-4 py-3 rounded bg-transparent">
                  <input
                    type="search"
                    placeholder={`${careersPageData?.searchText}`}
                    className="bg-transparent focus:outline-none placeholder-primary"
                    onChange={handleSearch}
                    value={searchValue}
                  />
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2 "
                  >
                    <path
                      stroke="#ff8200"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <div className="relative"></div>
              </li>
              <li className="list-none relative hidden sm:block">
                <button
                  className="relative flex items-center justify-center uppercase hover-reset-svg text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#1a1a1a] bg-[#ff8200] hover:bg-transparent   hover:text-[#ff8200]  px-6 xxs:px-9 xs:px-[3rem] py-3 rounded w-full font-dela"
                  onClick={handleResetClick}
                >
                  {careersPageData?.resetText}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 400 120"
                    className="absolute inset-0 w-full h-full 2xl:hidden"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M10,2 H390 A5,5 0 0 1 395,7 v78 L359,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                      fill="none"
                      stroke="#ff8200"
                      stroke-width="4"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 400 120"
                    className="absolute inset-0 w-full h-full hidden 2xl:block 3xl:hidden"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M10,2 H390 A5,5 0 0 1 395,7 v80 L362,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                      fill="none"
                      stroke="#ff8200"
                      stroke-width="4"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 400 120"
                    className="absolute inset-0 w-full h-full hidden 3xl:block"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M10,2 H390 A5,5 0 0 1 395,7 v80 L370,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                      fill="none"
                      stroke="#ff8200"
                      stroke-width="4"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </li>
            </ul>
            {/* mobile search and filter  */}
            <div className="block sm:hidden">
              <ul className="grid grid-cols-2 uppercase gap-4 justify-between">
                <li className="list-none ">
                  <div className="flex items-center justify-center uppercase text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer w-full  text-[#ff8200] border border-[#ff8200] px-4  py-3 rounded bg-transparent">
                    <input
                      type="search"
                      placeholder={`${careersPageData?.searchText}`}
                      className="bg-transparent focus:outline-none placeholder-primary w-[inherit]"
                      onChange={handleSearch}
                      value={searchValue}
                    />
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 "
                    >
                      <path
                        stroke="#ff8200"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <div className="relative"></div>
                </li>
                <li className="list-none relative">
                  <button
                    className="relative flex items-center justify-center uppercase hover-reset-svg-careers text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#1a1a1a] bg-[#ff8200] hover:bg-transparent   hover:text-[#ff8200]  px-6 xxs:px-9 xs:px-[3rem] py-3 rounded w-full font-dela"
                    onClick={handleResetClick}
                  >
                    {careersPageData?.resetText}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 400 120"
                      className="absolute inset-0 w-full h-full 2xl:hidden"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M10,2 H390 A5,5 0 0 1 395,7 v78 L359,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                        fill="none"
                        stroke="#ff8200"
                        stroke-width="4"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 400 120"
                      className="absolute inset-0 w-full h-full hidden 2xl:block 3xl:hidden"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M10,2 H390 A5,5 0 0 1 395,7 v80 L362,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                        fill="none"
                        stroke="#ff8200"
                        stroke-width="4"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 400 120"
                      className="absolute inset-0 w-full h-full hidden 3xl:block"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M10,2 H390 A5,5 0 0 1 395,7 v80 L370,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                        fill="none"
                        stroke="#ff8200"
                        stroke-width="4"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
            </div>
            <div className="my-4">
              <ButtonSlant
                ref={departmentButtonMobileRef}
                buttonClass="flex items-center justify-between sm:justify-center uppercase font-18-18-18 cursor-pointer  text-[#ff8200] border border-[#ff8200] px-4 py-3 rounded w-full"
                buttonText={careersPageData?.departmentText}
                onClick={(e) => {
                  setDisplayDepartmentMobile((prev) => !prev);
                  setDisplayLocationsMobile(false);
                }}
                textColorHover="#1a1a1a"
                bgColorHover="#ff8200"
                slantClass="bottom-right-slant-sm"
                onHoverChange={(isHover) =>
                  handleHoverChange("teamMobile", isHover)
                }
              >
                {displayDepartmentMobile ? (
                  hoverStates?.teamMobile ? (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 platforms"
                    >
                      <path
                        d="M8.625 6.25L12.375 2.5L16.125 6.25"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 platforms"
                    >
                      <path
                        d="M8.625 6.25L12.375 2.5L16.125 6.25"
                        stroke="#ff8200"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                        stroke="#ff8200"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  )
                ) : hoverStates?.teamMobile ? (
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2"
                  >
                    <path
                      d="M8.125 13.75L11.875 17.5L15.625 13.75"
                      stroke="#1a1a1a"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                      stroke="#1a1a1a"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2"
                  >
                    <path
                      d="M8.125 13.75L11.875 17.5L15.625 13.75"
                      stroke="#ff8200"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                      stroke="#ff8200"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                )}
              </ButtonSlant>
              {displayDepartmentMobile && (
                <div className="relative" ref={departmentRefMobile}>
                  <div className="absolute z-10 w-full rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 backdrop-blur-lg left-0 top-[1rem] h-[15rem] overflow-auto scrollable custom-scroll-careers-desk">
                    {departments?.sort().map((d) => (
                      <div
                        key={d}
                        className={`flex group items-center justify-between cursor-pointer ${
                          d === selectedDepartment
                            ? "text-[#ff8200]"
                            : "text-[#e5e7eb56]"
                        } hover:text-[#ff8200]`}
                        onClick={() => filterWithDepartments(d)}
                      >
                        <div
                          className={`flex justify-between gap-x-2.5 p-2 text-[15px] 2xl:text-[18px] 3xl:text-[22px] 4xl:text-[24px] w-fit font-semibold hover:opacity-100 rounded-md ${
                            d === selectedDepartment
                              ? "text-[#ff8200]"
                              : "group-hover:text-[#ff8200]"
                          }`}
                        >
                          {d}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="my-4">
              <ButtonSlant
                ref={locationButtonMobileRef}
                buttonClass="flex items-center justify-between sm:justify-center uppercase sm-only:text-[16px] font-18-18-18 cursor-pointer  text-[#ff8200] border border-[#ff8200] px-4 py-3 rounded w-full"
                buttonText={careersPageData?.locationText}
                onClick={(e) => {
                  setDisplayLocationsMobile((prev) => !prev);
                  setDisplayDepartmentMobile(false);
                }}
                textColorHover="#1a1a1a"
                bgColorHover="#ff8200"
                slantClass="bottom-right-slant-sm"
                onHoverChange={(isHover) =>
                  handleHoverChange("locationMobile", isHover)
                }
              >
                {displayLocationsMobile ? (
                  hoverStates?.locationMobile ? (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 platforms"
                    >
                      <path
                        d="M8.625 6.25L12.375 2.5L16.125 6.25"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                        stroke="#1a1a1a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 platforms"
                    >
                      <path
                        d="M8.625 6.25L12.375 2.5L16.125 6.25"
                        stroke="#ff8200"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                        stroke="#ff8200"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  )
                ) : hoverStates?.locationMobile ? (
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2"
                  >
                    <path
                      d="M8.125 13.75L11.875 17.5L15.625 13.75"
                      stroke="#1a1a1a"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                      stroke="#1a1a1a"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2"
                  >
                    <path
                      d="M8.125 13.75L11.875 17.5L15.625 13.75"
                      stroke="#ff8200"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                      stroke="#ff8200"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                )}
              </ButtonSlant>
              {displayLocationsMobile && (
                <div className="relative" ref={locationRefMobile}>
                  <div className="absolute z-10 w-full rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 backdrop-blur-lg left-0 top-[1rem] h-[15rem] overflow-auto scrollable custom-scroll-careers-desk">
                    {locations?.sort().map((l) => (
                      <div
                        key={l}
                        className={`flex group items-center justify-between cursor-pointer ${
                          l === selectedLocation
                            ? "text-[#ff8200]"
                            : "text-[#e5e7eb56]"
                        } hover:text-[#ff8200]`}
                        onClick={() => filterWithLocations(l)}
                      >
                        <div
                          className={`flex justify-between gap-x-2.5 p-2 text-[15px] 2xl:text-[18px] 3xl:text-[22px] 4xl:text-[24px] w-fit font-semibold hover:opacity-100 rounded-md ${
                            l === selectedLocation
                              ? "text-[#ff8200]"
                              : "group-hover:text-[#ff8200]"
                          }`}
                        >
                          {l}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="relative"></div>
            </div>
          </div>
          {/* mobile filter ends here */}

          <section className="px-5 py-8 sm:py-10 lg:py-5 border-2 border-primary rounded-md text-white">
            <div className="titles grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="sm:border-r border-[#bec0c5]">
                <p className="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] font-bold">
                  {careersPageData?.positionText}
                </p>
              </div>
              <div className="border-r border-[#bec0c5] hidden lg:block ">
                <p className="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] font-bold">
                  {careersPageData?.teamText}
                </p>
              </div>
              <div className="hidden sm:block">
                <p className="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] font-bold">
                  {careersPageData?.locationText}
                </p>
              </div>
            </div>
            <hr className="border-[#bec0c5] lg:border-primary mt-4 mb-10 lg:my-4 "></hr>
            {loading ? (
              <p className="text-center">Jobs details loading</p>
            ) : (
              <>
                {jobsData.length > 0 ? (
                  jobsData?.slice(0, 6)?.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="openings grid items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 text-[18px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px] mb-4 "
                      >
                        <div className="flex justify-between sm:border-r border-[#bec0c5] gap-2 leading-[1]">
                          <span className="pr-2">{data.title}</span>
                          <button
                            onClick={(e) => window.open(data.url, "_blank")}
                            className="border-[2px] group border-[#ff8200] hover:bg-[#ff8200] rounded-[4px] p-2 h-fit sm:hidden slide-btn-bottom-right-slant"
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="object-contain group-hover:hidden"
                            >
                              <path
                                d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                stroke="#ff8200"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                stroke="#ff8200"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="object-contain hidden group-hover:block"
                            >
                              <path
                                d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                stroke="#1a1a1a"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                stroke="#1a1a1a"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </button>
                        </div>

                        <div className="hidden lg:block border-r border-[#bec0c5] min-h-[2rem]">
                          <span>{data.department}</span>
                        </div>

                        <div className="hidden sm:flex justify-between items-center gap-2 leading-[1]">
                          <span className="">
                            {data.city}
                            {data.city === "" ? "" : ", "} {data.state}
                            {data.state === "" ? "" : ", "}
                            {data.country}.
                          </span>
                          <button
                            onClick={(e) => window.open(data.url, "_blank")}
                            className="border-[2px] border-[#ff8200] hover:bg-[#ff8200] slide-btn-bottom-right-slant rounded-[4px] p-2 h-fit group"
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="object-contain group-hover:hidden"
                            >
                              <path
                                d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                stroke="#ff8200"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                stroke="#ff8200"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="object-contain hidden group-hover:block"
                            >
                              <path
                                d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                stroke="#1a1a1a"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                stroke="#1a1a1a"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-center">No jobs found</p>
                )}
              </>
            )}
          </section>

          <div className="container w-full flex justify-center items-center">
            <ButtonSlant
              buttonText={careersPageData?.viewAllText}
              buttonClass="border-primary border-[3px] rounded-md text-[18px] 2xl:text-xl 3xl:text-2xl px-[5rem] sm:px-32 py-3 2xl:py-3 lg:pl-4l text-center w-full lg:w-fit font-dela text-primary uppercase"
              onClick={() => {
                setShowOverlay(true);
                document.body.classList.add("no-scroll");
              }}
              textColorHover="#ffffff"
              divClass="w-full lg:w-fit my-16"
              bgColorHover="#ff8200"
              slantClass=" bottom-right-slant"
            />
          </div>
          {/* overlay careers starts here  */}
          {showOverlay && (
            <section className="">
              <div
                id="modal-careers"
                className="careers-popop-page fixed inset-0 items-center justify-center bg-[#1a1a1a] z-[999] transition-all duration-75"
              >
                <div className="container my-8">
                  <ButtonSlant
                    buttonClass="border-[2px] border-[#ff5dd2] rounded-[4px] p-2 h-fit group"
                    slantClass="arrow-btn-top-left"
                    divClass="w-fit"
                    bgColorHover="#ff5dd2"
                    onClick={() => {
                      setShowOverlay(false);
                      document.body.classList.remove("no-scroll");
                      handleHoverChange("close", false);
                    }}
                    onHoverChange={(isHover) =>
                      handleHoverChange("close", isHover)
                    }
                  >
                    {hoverStates?.close ? (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="object-contain "
                      >
                        <path
                          d="M6.25 11.875L2.5 8.125L6.25 4.375"
                          stroke="#1a1a1a"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                          stroke="#1a1a1a"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="object-contain  "
                      >
                        <path
                          d="M6.25 11.875L2.5 8.125L6.25 4.375"
                          stroke="#ff5dd2"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                          stroke="#ff5dd2"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    )}
                  </ButtonSlant>
                </div>

                <div className="container sm:flex gap-3 items-end">
                  <h2 className=" font-normal text-patner-header leading-[1] uppercase text-white z-[2]">
                    <span className="block">
                      {careersPageData?.careersText}{" "}
                    </span>
                  </h2>
                  <p className="text-[24px] text-[#ff5dd2] heading-noto-bold">
                    {jobsData.length} {careersPageData?.openPositionsText}
                  </p>
                </div>
                {/* mobile filter starts here */}
                <div className="sm:hidden my-8 container">
                  <ul className="flex flex-wrap lg:flex-nowrap uppercase gap-4 sm:gap-4 lg:gap-8 justify-between">
                    <li className="list-none ">
                      <div className="hidden sm:flex items-center justify-center uppercase font-18-18-18 cursor-pointer w-full  text-[#ff8200] border-[2px] border-[#ff8200] bottom-right-slant-hoverk px-4 py-3 rounded bg-transparent">
                        <input
                          type="search"
                          placeholder={`${careersPageData?.searchText}`}
                          className="bg-transparent focus:outline-none placeholder-[#ff5dd2] w-[inherit]"
                          onChange={handleSearch}
                          value={searchValue}
                        />
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2 "
                        >
                          <path
                            stroke="#ff5dd2"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <div className="relative"></div>
                    </li>
                    <li className="list-none relative hidden sm:block">
                      <button
                        className="relative flex items-center justify-center uppercase hover-reset-svg text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#1a1a1a] bg-[#ff5dd2] hover:bg-transparent   hover:text-[#ff5dd2]  px-6 xxs:px-9 xs:px-[3rem] py-3 rounded w-full font-dela"
                        onClick={handleResetClick}
                      >
                        {careersPageData?.resetText}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 400 120"
                          className="absolute inset-0 w-full h-full 2xl:hidden"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M10,2 H390 A5,5 0 0 1 395,7 v78 L359,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                            fill="none"
                            stroke="#ff5dd2"
                            stroke-width="4"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 400 120"
                          className="absolute inset-0 w-full h-full hidden 2xl:block 3xl:hidden"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M10,2 H390 A5,5 0 0 1 395,7 v80 L362,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                            fill="none"
                            stroke="#ff5dd2"
                            stroke-width="4"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </li>
                  </ul>
                  {/* mobile search and filter  */}
                  <div className="block sm:hidden">
                    <ul className="grid grid-cols-2 uppercase gap-4 justify-between">
                      <li className="list-none ">
                        <div className="flex items-center justify-center uppercase text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer w-full  text-[#ff5dd2] border border-[#ff5dd2] px-4  py-3 rounded bg-transparent">
                          <input
                            type="search"
                            placeholder={`${careersPageData?.searchText}`}
                            className="bg-transparent focus:outline-none placeholder-[#ff5dd2] w-[inherit]"
                            onChange={handleSearch}
                            value={searchValue}
                          />
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-2 "
                          >
                            <path
                              stroke="#ff5dd2"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                            />
                          </svg>
                        </div>
                        <div className="relative"></div>
                      </li>
                      <li className="list-none relative">
                        <button
                          className="relative flex items-center justify-center uppercase hover-reset-svg-careers-purple text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#1a1a1a] bg-[#ff5dd2] hover:bg-transparent   hover:text-[#ff5dd2]  px-6 xxs:px-9 xs:px-[3rem] py-3 rounded w-full font-dela"
                          onClick={handleResetClick}
                        >
                          {careersPageData?.resetText}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 400 120"
                            className="absolute inset-0 w-full h-full 2xl:hidden"
                            preserveAspectRatio="none"
                          >
                            <path
                              d="M10,2 H390 A5,5 0 0 1 395,7 v78 L359,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                              fill="none"
                              stroke="#ff5dd2"
                              stroke-width="4"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 400 120"
                            className="absolute inset-0 w-full h-full hidden 2xl:block 3xl:hidden"
                            preserveAspectRatio="none"
                          >
                            <path
                              d="M10,2 H390 A5,5 0 0 1 395,7 v80 L362,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                              fill="none"
                              stroke="#ff5dd2"
                              stroke-width="4"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 400 120"
                            className="absolute inset-0 w-full h-full hidden 3xl:block"
                            preserveAspectRatio="none"
                          >
                            <path
                              d="M10,2 H390 A5,5 0 0 1 395,7 v80 L370,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                              fill="none"
                              stroke="#ff8200"
                              stroke-width="4"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="my-4">
                    <ButtonSlant
                      ref={departmentButtonMobileRef}
                      buttonClass="flex items-center justify-between sm:justify-center uppercase font-18-18-18 cursor-pointer  text-[#ff5dd2] border border-[#ff5dd2] px-4 py-3 rounded w-full "
                      buttonText={careersPageData?.departmentText}
                      onClick={(e) =>
                        setDisplayDepartmentMobile(!displayDepartmentMobile)
                      }
                      textColorHover="#1a1a1a"
                      bgColorHover="#ff5dd2"
                      slantClass="bottom-right-slant"
                      onHoverChange={(isHover) =>
                        handleHoverChange("teamMobile", isHover)
                      }
                    >
                      {displayDepartmentMobile ? (
                        hoverStates?.teamMobile ? (
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-2 platforms"
                          >
                            <path
                              d="M8.625 6.25L12.375 2.5L16.125 6.25"
                              stroke="#1a1a1a"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                              stroke="#1a1a1a"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        ) : (
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-2 platforms"
                          >
                            <path
                              d="M8.625 6.25L12.375 2.5L16.125 6.25"
                              stroke="#ff5dd2"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                              stroke="#ff5dd2"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        )
                      ) : hoverStates?.teamMobile ? (
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2"
                        >
                          <path
                            d="M8.125 13.75L11.875 17.5L15.625 13.75"
                            stroke="#1a1a1a"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                            stroke="#1a1a1a"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2"
                        >
                          <path
                            d="M8.125 13.75L11.875 17.5L15.625 13.75"
                            stroke="#ff5dd2"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                            stroke="#ff5dd2"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      )}
                    </ButtonSlant>
                    {displayDepartmentMobile && (
                      <div className="relative" ref={departmentRefMobile}>
                        <div className="absolute z-10 w-full rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 backdrop-blur-lg left-0 top-[1rem] h-[15rem] overflow-auto scrollable custom-scroll-careers">
                          {departments?.sort().map((d) => (
                            <div
                              key={d}
                              className={`flex group items-center justify-between cursor-pointer ${
                                d === selectedDepartment
                                  ? "text-[#ff5dd2]"
                                  : "text-[#e5e7eb56]"
                              } hover:text-[#ff5dd2]`}
                              onClick={() => filterWithDepartments(d)}
                            >
                              <div
                                className={`flex justify-between gap-x-2.5 p-2 text-[15px] 2xl:text-[18px] 3xl:text-[22px] 4xl:text-[24px] w-fit font-semibold hover:opacity-100 rounded-md ${
                                  d === selectedDepartment
                                    ? "text-[#ff5dd2]"
                                    : "group-hover:text-[#ff5dd2]"
                                }`}
                              >
                                {d}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="my-4">
                    <ButtonSlant
                      ref={locationButtonMobileRef}
                      buttonClass="flex items-center justify-between sm:justify-center uppercase font-18-18-18 cursor-pointer  text-[#ff5dd2] border border-[#ff5dd2] px-4 py-3 rounded w-full"
                      buttonText={careersPageData?.locationText}
                      onClick={(e) =>
                        setDisplayLocationsMobile(!displayLocationsMobile)
                      }
                      textColorHover="#1a1a1a"
                      bgColorHover="#ff5dd2"
                      slantClass="bottom-right-slant-sm"
                      onHoverChange={(isHover) =>
                        handleHoverChange("locationMobile", isHover)
                      }
                    >
                      {displayLocationsMobile ? (
                        hoverStates?.locationMobile ? (
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-2 platforms"
                          >
                            <path
                              d="M8.625 6.25L12.375 2.5L16.125 6.25"
                              stroke="#1a1a1a"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                              stroke="#1a1a1a"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        ) : (
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-2 platforms"
                          >
                            <path
                              d="M8.625 6.25L12.375 2.5L16.125 6.25"
                              stroke="#ff5dd2"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                              stroke="#ff5dd2"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        )
                      ) : hoverStates?.locationMobile ? (
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2"
                        >
                          <path
                            d="M8.125 13.75L11.875 17.5L15.625 13.75"
                            stroke="#1a1a1a"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                            stroke="#1a1a1a"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2"
                        >
                          <path
                            d="M8.125 13.75L11.875 17.5L15.625 13.75"
                            stroke="#ff5dd2"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                            stroke="#ff5dd2"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      )}
                    </ButtonSlant>
                    {displayLocationsMobile && (
                      <div className="relative" ref={locationRefMobile}>
                        <div className="absolute z-10 w-full rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 backdrop-blur-lg left-0 top-[1rem] h-[15rem] overflow-auto scrollable custom-scroll-careers">
                          {locations?.sort().map((l) => (
                            <div
                              key={l}
                              className={`flex group items-center justify-between cursor-pointer ${
                                l === selectedLocation
                                  ? "text-[#ff5dd2]"
                                  : "text-[#e5e7eb56]"
                              } hover:text-[#ff5dd2]`}
                              onClick={() => filterWithLocations(l)}
                            >
                              <div
                                className={`flex justify-between gap-x-2.5 p-2 text-[15px] 2xl:text-[18px] 3xl:text-[22px] 4xl:text-[24px] w-fit font-semibold hover:opacity-100 rounded-md ${
                                  l === selectedLocation
                                    ? "text-[#ff5dd2]"
                                    : "group-hover:text-[#ff5dd2]"
                                }`}
                              >
                                {l}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className="relative"></div>
                  </div>
                </div>
                {/* mobile filter ends here */}
                <div className="my-6 hidden sm:block container">
                  <ul className="flex flex-wrap lg:flex-nowrap uppercase gap-[0.8rem] lg:gap-8 justify-between">
                    <li className="list-none sm:w-[24%] lg:w-[28%]">
                      <ButtonSlant
                        ref={departmentButtonRef}
                        buttonClass="hidden sm:flex items-center justify-center uppercase   text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer w-full  text-[#ff5dd2] border border-[#ff5dd2] sm:px-2 lg:px-4 py-3 rounded"
                        buttonText={careersPageData?.departmentText}
                        onClick={(e) =>
                          setDisplayDepartments(!displayDepartment)
                        }
                        textColorHover="#1a1a1a"
                        bgColorHover="#ff5dd2"
                        slantClass="bottom-right-slant"
                        onHoverChange={(isHover) =>
                          handleHoverChange("team", isHover)
                        }
                      >
                        {displayDepartment ? (
                          hoverStates?.team ? (
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="ml-2 platforms"
                            >
                              <path
                                d="M8.625 6.25L12.375 2.5L16.125 6.25"
                                stroke="#1a1a1a"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                                stroke="#1a1a1a"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          ) : (
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="ml-2 platforms"
                            >
                              <path
                                d="M8.625 6.25L12.375 2.5L16.125 6.25"
                                stroke="#ff5dd2"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                                stroke="#ff5dd2"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          )
                        ) : hoverStates?.team ? (
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-2"
                          >
                            <path
                              d="M8.125 13.75L11.875 17.5L15.625 13.75"
                              stroke="#1a1a1a"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                              stroke="#1a1a1a"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        ) : (
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-2"
                          >
                            <path
                              d="M8.125 13.75L11.875 17.5L15.625 13.75"
                              stroke="#ff5dd2"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                              stroke="#ff5dd2"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        )}
                      </ButtonSlant>
                      {displayDepartment && (
                        <div className="relative" ref={departmentRef}>
                          <div className="absolute z-10 w-full rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 backdrop-blur-lg left-0 top-[1rem] h-[15rem] overflow-auto scrollable custom-scroll-careers">
                            {departments?.sort().map((d) => (
                              <div
                                key={d}
                                onClick={() => filterWithDepartments(d)}
                                className={`flex group items-center justify-between cursor-pointer ${
                                  d === selectedDepartment
                                    ? "text-[#ff5dd2]"
                                    : "text-[#e5e7eb56]"
                                } hover:text-[#ff5dd2]`}
                              >
                                <div
                                  className={`flex justify-between gap-x-2.5 p-2 text-[15px] 2xl:text-[18px] 3xl:text-[22px] 4xl:text-[24px] w-fit font-semibold hover:opacity-100 rounded-md ${
                                    d === selectedDepartment
                                      ? "text-[#ff5dd2]"
                                      : "group-hover:text-[#ff5dd2]"
                                  }`}
                                >
                                  {d}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </li>
                    <li className="list-none sm:w-[24%] lg:w-[28%]">
                      <ButtonSlant
                        ref={locationButtonRef}
                        buttonClass="hidden sm:flex items-center justify-center uppercase   text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer w-full  text-[#ff5dd2] border border-[#ff5dd2] sm:px-2 lg:px-4 py-3 rounded"
                        buttonText={careersPageData?.locationText}
                        onClick={(e) => setDisplayLocations(!displayLocations)}
                        textColorHover="#1a1a1a"
                        bgColorHover="#ff5dd2"
                        slantClass="bottom-right-slant"
                        onHoverChange={(isHover) =>
                          handleHoverChange("location", isHover)
                        }
                      >
                        {displayLocations ? (
                          hoverStates?.location ? (
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="ml-2 platforms"
                            >
                              <path
                                d="M8.625 6.25L12.375 2.5L16.125 6.25"
                                stroke="#1a1a1a"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                                stroke="#1a1a1a"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          ) : (
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="ml-2 platforms"
                            >
                              <path
                                d="M8.625 6.25L12.375 2.5L16.125 6.25"
                                stroke="#ff5dd2"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                                stroke="#ff5dd2"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          )
                        ) : hoverStates?.location ? (
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-2"
                          >
                            <path
                              d="M8.125 13.75L11.875 17.5L15.625 13.75"
                              stroke="#1a1a1a"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                              stroke="#1a1a1a"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        ) : (
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-2"
                          >
                            <path
                              d="M8.125 13.75L11.875 17.5L15.625 13.75"
                              stroke="#ff5dd2"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                              stroke="#ff5dd2"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        )}
                      </ButtonSlant>
                      {displayLocations && (
                        <div className="relative" ref={locationRef}>
                          <div className="absolute z-10 w-full rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 backdrop-blur-lg left-0 top-[1rem] h-[15rem] overflow-auto scrollable custom-scroll-careers">
                            {locations?.sort().map((l) => (
                              <div
                                key={l} // Fixes incorrect key usage (previously used index `i`)
                                onClick={() => filterWithLocations(l)}
                                className={`flex group items-center justify-between cursor-pointer ${
                                  l === selectedLocation
                                    ? "text-[#ff5dd2]"
                                    : "text-[#e5e7eb56]"
                                } hover:text-[#ff5dd2]`}
                              >
                                <div
                                  className={`flex justify-between gap-x-2.5 p-2 text-[15px] 2xl:text-[18px] 3xl:text-[22px] 4xl:text-[24px] w-fit font-semibold hover:opacity-100 rounded-md ${
                                    l === selectedLocation
                                      ? "text-[#ff5dd2]"
                                      : "group-hover:text-[#ff5dd2]"
                                  }`}
                                >
                                  {l}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </li>
                    <li className="list-none sm:w-[24%] lg:w-[28%]">
                      <div className="hidden sm:flex items-center justify-center uppercase text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer w-full  text-[#ff5dd2] border border-[#ff5dd2] bottom-right-slant-hoverk sm:px-2 lg:px-4 py-3 rounded bg-transparent">
                        <input
                          type="search"
                          placeholder={`${careersPageData?.searchText}`}
                          className="bg-transparent focus:outline-none placeholder-[#ff5dd2] w-[inherit]"
                          onChange={handleSearch}
                          value={searchValue}
                        />
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2 "
                        >
                          <path
                            stroke="#ff5dd2"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <div className="relative"></div>
                    </li>
                    <li className="list-none sm:w-[18%] lg:w-[12%] relative">
                      <button
                        className="relative hidden sm:flex items-center justify-center uppercase hover-reset-svg-careers-purple text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#1a1a1a] bg-[#ff5dd2] hover:bg-transparent   hover:text-[#ff5dd2] px-4 py-3 rounded w-full font-dela"
                        onClick={handleResetClick}
                      >
                        {careersPageData?.resetText}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 400 120"
                          className="absolute inset-0 w-full h-full 2xl:hidden"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M10,2 H390 A5,5 0 0 1 395,7 v78 L359,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                            fill="none"
                            stroke="#ff5dd2"
                            stroke-width="4"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 400 120"
                          className="absolute inset-0 w-full h-full hidden 2xl:block 3xl:hidden"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M10,2 H390 A5,5 0 0 1 395,7 v80 L362,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                            fill="none"
                            stroke="#ff5dd2"
                            stroke-width="4"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </li>
                  </ul>
                </div>
                <section className="container px-5 py-8 sm:py-10 lg:py-5 w-[90%] pop-bg border-2 border-[#ff5dd2] rounded-md text-white  h-[15rem] lg:h-[18rem] 2xl:h-[24rem] 3xl:h-[32rem] 4xl:h-[40rem] overflow-auto scrollable custom-scroll-careers ">
                  <div className="titles grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div className="sm:border-r border-[#bec0c5]">
                      <p className="font-18-18-18 font-bold">
                        {careersPageData?.positionText}
                      </p>
                    </div>
                    <div className="border-r border-[#bec0c5] hidden lg:block ">
                      <p className="font-18-18-18 font-bold">
                        {careersPageData?.teamText}
                      </p>
                    </div>
                    <div className="hidden sm:block">
                      <p className="font-18-18-18 font-bold">
                        {careersPageData?.locationText}
                      </p>
                    </div>
                  </div>
                  <hr className="border-[#bec0c5] lg:border-[#ff5dd2] mt-4 mb-10 lg:my-4 "></hr>

                  {loading ? (
                    <p className="text-center">Job details are loading </p>
                  ) : (
                    <>
                      {jobsData.length > 0 ? (
                        jobsData?.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="openings grid items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 text-[18px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px] mb-4"
                            >
                              <div className="flex justify-between sm:border-r border-[#bec0c5]">
                                <span className="pr-2">{data.title}</span>
                                <button
                                  onClick={(e) =>
                                    window.open(data.url, "_blank")
                                  }
                                  className="border-[2px] border-[#ff5dd2] hover:bg-[#ff5dd2] rounded-[4px] p-2 h-fit sm:hidden group slide-btn-bottom-right-slant"
                                >
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="object-contain group-hover:hidden"
                                  >
                                    <path
                                      d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                      stroke="#ff5dd2"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                    <path
                                      d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                      stroke="#ff5dd2"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="object-contain hidden group-hover:block"
                                  >
                                    <path
                                      d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                      stroke="#1a1a1a"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                    <path
                                      d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                      stroke="#1a1a1a"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                </button>
                              </div>

                              <div className="hidden lg:block border-r border-[#bec0c5] min-h-[2rem]">
                                {data.department}{" "}
                              </div>
                              <div className="hidden sm:flex justify-between items-center">
                                <span className="">
                                  {data.city}
                                  {data.city === "" ? "" : ", "} {data.state}
                                  {data.state === "" ? "" : ", "}
                                  {data.country}.
                                </span>
                                <button
                                  onClick={(e) =>
                                    window.open(data.url, "_blank")
                                  }
                                  className="border-[2px] border-[#ff5dd2] hover:bg-[#ff5dd2] rounded-[4px] p-2 h-fit slide-btn-bottom-right-slant group"
                                >
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="object-contain group-hover:hidden"
                                  >
                                    <path
                                      d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                      stroke="#ff5dd2"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                    <path
                                      d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                      stroke="#ff5dd2"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="object-contain hidden group-hover:block"
                                  >
                                    <path
                                      d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                      stroke="#1a1a1a"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                    <path
                                      d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                      stroke="#1a1a1a"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p className="text-center">No jobs found.</p>
                      )}
                    </>
                  )}
                </section>
              </div>
            </section>
          )}
          {/* overlay careers ends here  */}
        </section>
        {/* open positions ends here  */}

        <Footer />
      </section>
    </>
  );
};

export default Careers;
