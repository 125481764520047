import React, { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import "../../css/pagescss/Home.css";
import {
  FormatTextFromGeneralConstants,
  navigateTo,
} from "../../constants/GeneralConstants";
import { clientUrls } from "../../constants/ClientUrls";
import { useNavigate, useParams } from "react-router-dom";
import PIL from "../PIL";
import ButtonSlant from "../ButtonSlant";

const ProjectsShowSection = ({
  homeData,
  projectsData,
  colors,
  isBtnMiddle,
  isProjectPage,
  redirectionFrom,
  lang,
}) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { param } = useParams();
  const ref = useRef(null);
  const buttonRef = useRef(null);
  const intervalRef = useRef(null);
  const isInView = useInView(ref, { once: true, threshold: 0.5 });
  const buttonInView = useInView(buttonRef, { once: true, threshold: 0.5 });
  const {
    projectSection_gamesBroughtToLifeText,
    projectSection_projectContentText,
    projectSection_viewProjectsText,
  } = homeData;
  let intervalId;
  useEffect(() => {
    const startInterval = () => {
      intervalRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          if (prevIndex === projectsData.length - 1) {
            return 0;
          } else {
            return prevIndex + 1;
          }
        });
      }, 5000);
    };

    startInterval();

    return () => clearInterval(intervalRef.current);
  }, [projectsData]);

  const handleProgressBarClick = (index) => {
    clearInterval(intervalRef.current);
    setCurrentIndex(index);
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex === projectsData.length - 1) {
          return 0;
        } else {
          return prevIndex + 1;
        }
      });
    }, 5000);
  };

  const pullupVariant = {
    initial: { y: 20, opacity: 0 },
    animate: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.3,
      },
    }),
  };

  const pullupVariantForDesc = {
    initial: { y: 20, opacity: 0 },
    animate: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.5,
      },
    }),
  };

  const formatJapaneseText = (text, lang) => {
    if (lang === "ja" && text.includes("・規模")) {
      return text.replace("・規模", "・\n規模");
    }
    return text;
  };

  return (
    <>
      {projectsData?.length > 0 ? (
        <section
          className={`container mx-auto flex flex-col gap-y-4 sm:gap-y-0 sm:flex-row sm:gap-16 ${
            param === "player-support" ? "sm:gap-12" : "sm:gap-16"
          } ${isProjectPage ? "sm:items-center" : "sm:items-end"} ${
            isProjectPage ? "" : "lg:items-start"
          }`}
        >
          <div
            className={`flex flex-col gap-3 lg:gap-6 w-auto sm:w-1/2  ${
              param === "player-support" ? "w-[43%]" : "lg:w-2/5 xl:w-[38%]"
            } mb-2 sm:mb-0 justify-center sm:justify-end lg:justify-center`}
          >
            <motion.h2
              className={`font-normal ${
                projectSection_gamesBroughtToLifeText
                  ?.toLowerCase()
                  ?.includes("games brought")
                  ? "w-[70%] sm:w-[90%] md:w-4/5 lg:w-[90%] xl:w-4/5 4xl:w-3/4"
                  : "lg:w-auto"
              } font-36-36-48 !leading-[100%] mb-3 uppercase overflow-hidden whitespace-pre-line`}
              style={{ color: isProjectPage ? colors.color : "#ffffff" }}
              ref={ref}
              variants={pullupVariant}
              initial="initial"
              animate={isInView ? "animate" : ""}
              transition={{ duration: 0.9 }}
            >
              {formatJapaneseText(
                FormatTextFromGeneralConstants(
                  projectSection_gamesBroughtToLifeText
                ),
                lang
              )
                .split(" ")
                .map((word, i) => (
                  <motion.span
                    key={i}
                    variants={pullupVariant}
                    custom={i}
                    className="inline-block"
                  >
                    {word}
                    {i <
                      formatJapaneseText(
                        FormatTextFromGeneralConstants(
                          projectSection_gamesBroughtToLifeText
                        ),
                        lang
                      ).split(" ").length -
                        1 && "\u00A0"}
                  </motion.span>
                ))}
            </motion.h2>

            <motion.p
              className="content text-white font-18-18-18 mb-3 !leading-[120%]"
              ref={ref}
              variants={pullupVariantForDesc}
              initial="initial"
              animate={isInView ? "animate" : ""}
              transition={{ delay: 0.9, duration: 0.5 }}
            >
              <motion.span
                variants={pullupVariantForDesc}
                className="inline-block"
              >
                {projectSection_projectContentText}
              </motion.span>
            </motion.p>

            <div
              className={`text-center sm:text-left ${
                isBtnMiddle ? "block" : "hidden"
              } sm:block mb-5 sm:mb-0`}
            >
              {projectSection_viewProjectsText && (
                <motion.div
                  ref={buttonRef}
                  initial={{ y: 100, opacity: 0 }}
                  animate={{
                    y: buttonInView ? 0 : 100,
                    opacity: buttonInView ? 1 : 0,
                  }}
                  transition={{ delay: 0.6, duration: 0.5 }}
                >
                  <ButtonSlant
                    buttonClass={`border-[2px] w-full sm:w-auto py-3 px-4 sm:py-2 3xl:py-3 3xl:px-5 4xl:py-4 rounded-[4px] uppercase font-18-18-18 font-normal font-dela ${
                      isProjectPage ? "!hidden" : ""
                    }`}
                    slantClass="bottom-right-slant"
                    divClass="w-full sm:w-fit"
                    borderColor={colors.color}
                    textColor={colors.color}
                    textColorHover={colors.tertiary}
                    bgColorHover={colors.color}
                    buttonText={projectSection_viewProjectsText}
                    onClick={(e) => {
                      sessionStorage.setItem("service", redirectionFrom);
                      navigate(navigateTo(clientUrls.projects));
                    }}
                  />
                </motion.div>
              )}
            </div>
          </div>

          <motion.div
            className={`w-auto sm:w-1/2 ${
              param === "player-support" ? "lg:w-[57%]" : " lg:w-3/5 xl:w-[62%]"
            } flex flex-col items-center sm:items-end ${
              isProjectPage ? "justify-end" : "justify-start"
            } sm:justify-end1 lg:justify-end1 gap-8  relative`}
          >
            <div
              className="relative rounded-xl h-48 xxs:h-52 xs:h-64 1.5xs:h-auto sm:h-[10.4rem] md:h-52 lg:h-72 xl:h-[21.4rem] 1.5xl:h-[25.6rem] 2.5xl:h-[28.9rem] 3xl:h-[32.1rem] 3.5xl:h-[35.31rem] 4xl:h-[38.5rem] cursor-pointer w-full  sm:flex sm:flex-col sm:justify-end lg:block"
              onClick={(e) =>
                navigate(
                  navigateTo(
                    `${clientUrls.projects}/${projectsData[currentIndex]?.projectLink}`
                  )
                )
              }
            >
              <motion.div
                className="w-full h-full rounded-[10px] relative group"
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "-100%" }}
                transition={{ duration: 0.5 }}
                key={currentIndex}
              >
                <PIL
                  className="h-full w-full object-fill rounded-[10px] "
                  alt={`project image ${currentIndex + 1}`}
                  lowQaulitySrc={
                    projectsData[currentIndex]?.projectImageLowQuality
                  }
                  highQualitySrc={projectsData[currentIndex]?.projectImage}
                />
                <div className="hidden cursor-pointer absolute top-0 right-0 bg-[#1a1a1a] h-16 w-16 4xl:h-20 4xl:w-20 group-hover:flex items-center justify-center z-[3] rounded-bl-lg rounded-tr-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill={colors.color}
                    viewBox="0 0 24 24"
                    strokeWidth="3"
                    stroke={colors.color}
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                    />
                  </svg>
                </div>
              </motion.div>

              <div className="absolute left-0 bottom-0 p-4 sm:p-4 lg:py-[1.2rem] lg:px-[2rem] max-w-full text-white bg-[#1A1A1ACC] rounded-tr-[10px] rounded-bl-[8px] z-[2] backdrop-blur-sm text-wrap break-words">
                <motion.h3
                  className="text-white font-18-18-18 font-normal uppercase max-w-full leading-[1]"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  {projectsData[currentIndex]?.projectTitle}
                </motion.h3>
              </div>
            </div>
            <motion.div
              className="flex gap-2 justify-between items-center w-full"
              // initial={{ opacity: 0, x: "-40%" }}
              // animate={{ opacity: 1, x: 0 }}
              // exit={{ opacity: 0, x: "40%" }}
              // transition={{ duration: 0.5 }}
              // key={currentIndex}
            >
              {projectsData?.map((_, index) => (
                <motion.span
                  key={index}
                  className="block h-[.35rem] 4xl:h-2 cursor-pointer !flex-1 z-20"
                  initial={{ backgroundColor: "#FFFFFF80" }}
                  onClick={() => {
                    handleProgressBarClick(index);
                  }}
                  animate={{
                    backgroundColor:
                      currentIndex === index
                        ? colors?.color
                        : isProjectPage
                        ? "#1a1a1a"
                        : "#FFFFFF80",
                  }}
                  transition={{ duration: 0.3 }}
                ></motion.span>
              ))}
            </motion.div>
          </motion.div>
          <div
            className={`text-center sm:text-left ${
              isBtnMiddle ? "hidden" : "block"
            } sm:hidden mt-6`}
          >
            <ButtonSlant
              buttonClass={`border-[2px] w-full sm:w-auto py-3 px-4 3xl:py-3 3xl:px-5 4xl:py-4 rounded-[4px] uppercase font-18-18-18 font-normal font-dela ${
                isProjectPage ? "!hidden" : ""
              }`}
              slantClass="bottom-right-slant"
              divClass="w-full sm:w-fit"
              borderColor={colors.color}
              textColor={colors.color}
              textColorHover={colors.tertiary}
              bgColorHover={colors.color}
              buttonText={projectSection_viewProjectsText}
              onClick={(e) => {
                sessionStorage.setItem("service", redirectionFrom);

                navigate(navigateTo(clientUrls.projects));
              }}
            />
          </div>
        </section>
      ) : null}
    </>
  );
};

export default ProjectsShowSection;
