import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getLanguage, navigateTo } from "../../constants/GeneralConstants";
import { getProjectDetailsData } from "../../services/ApiService";
import Loader from "../../components/Loader";
import Testimonials from "../../components/Testimonials";
import Footer from "../../components/Footer";
import PIL from "../../components/PIL";
import FormatText from "../../components/FormatText";
import PageNotFound from "../../components/PageNotFound";
import { setColor } from "../../store/reducers/ColorReducer";
import { useDispatch } from "react-redux";
import { clientUrls } from "../../constants/ClientUrls";
import YoutubeVideo from "../../components/YoutubeVideo";

const ProjectDescription = () => {
  const [projectDetails, setProjectDetails] = useState({});
  const [loader, setLoader] = useState(true);
  const { param, lang } = useParams();
  const [notFound, setNotFound] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    callProjectDetailsAPI(getLanguage(), param);
  }, [param, lang]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, [param]);

  const callProjectDetailsAPI = async (language, param) => {
    setLoader(true);
    try {
      const data = await getProjectDetailsData(language, param);
      if (data.status === 404) {
        setLoader(false);
        setNotFound(true);
      } else {
        document.title = data?.projectData?.title;
        setLoader(false);
        setProjectDetails(data);
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const handlePrevious = () => {
    if (projectDetails?.previousProjectData) {
      const previousProjectURL = projectDetails.previousProjectData.projectURL;
      // console.log("Navigating to previous project:", previousProjectURL);

      navigate(navigateTo(`${clientUrls.projects}/${previousProjectURL}`));
    }
  };

  const handleNext = () => {
    if (projectDetails?.nextProjectData) {
      const nextProjectURL = projectDetails.nextProjectData.projectURL;
      // console.log("Navigating to next project:", nextProjectURL);

      navigate(navigateTo(`${clientUrls.projects}/${nextProjectURL}`));
    }
  };
  useEffect(() => {
    dispatch(
      setColor({
        primary: "#004780",
        secondary: "#68FFD2",
        tertiary: "#ffffff",
        footerSecondary: "#2AD9FF",
      })
    );
  }, [dispatch]);
  if (loader) {
    return <Loader />;
  }
  const { projectData, projectPageStaticData } = projectDetails;

  return (
    <>
      {notFound ? (
        <PageNotFound />
      ) : (
        <>
          {/* <div className="flex items-center justify-center h-screen">
        <div className="p-4 text-white">
          Project Description Page!, Project Name : {param}
        </div>
      </div> */}
          <section className="project-inner">
            <div className="relative pt-[9rem]">
              <div className="absolute right-0 top-0 z-[-1] w-[90%] xs:w-[85%]  sm:w-[70%] md:w-[60%] lg:w-[55%] xl:w-auto">
                <img
                  // src="/images/project-inner-d.png"
                  src={projectPageStaticData?.projectDetailsBgImage}
                  alt="side-ptw"
                />
              </div>
              <div className="container">
                <h2 className="sm:w-[80%] font-normal text-[24px] sm:text-[30px] lg:text-[38.5px] xl:text-[48px] 2xl:text-[57.6px] 3xl:text-[72px] leading-[1] mb-3 uppercase overflow-hidden text-white">
                  {projectData?.title}
                </h2>
                <div className="max-w-full my-10 relative">
                  <PIL
                    lowQaulitySrc={projectData?.bannerImageLowQuality}
                    highQualitySrc={projectData?.bannerImage}
                    className="w-full block h-auto max-w-full zoom-animate-projectDescription lg::min-h-[20rem] xl:min-h-[25rem] 2xl:min-h-[30rem]"
                    alt={projectData?.title}
                  />
                </div>
              </div>
            </div>
            <div className="container">
              {/* project content starts here  */}

              <div className="project-content my-16">
                <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-16 lg:gap-[15rem]">
                  <div className="flex flex-col text-[#fff] col-span-1 gap-12">
                    <p className=" text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px] mb-4 lg:mb-0 uppercase mt-1 font-bold text-[#2AD9FF]">
                      {projectPageStaticData?.releasedText}{" "}
                      {projectData?.releaseYears}
                    </p>
                    <div className="hidden sm:block ">
                      <p className="text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px] font-bold uppercase pb-2">
                        {projectPageStaticData?.servicesText}
                      </p>
                      <ul className="text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px]">
                        {projectData?.services?.map((service, i) => (
                          <li key={i}>{service}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col text-[#fff] col-span-2">
                    <div className="detail-page-description">
                      <p className="mb-4 sm:mb-6 text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px] leading-normal whitespace-pre-wrap">
                        <FormatText text={projectData?.projectDescription} />
                      </p>
                    </div>
                    <div className="hidden lg:flex sm:gap-16 lg:gap-32 flex-col sm:flex-row justify-between text-[#fff] mt-8">
                      <div className="sm:w-2/3">
                        <p className="text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px] font-bold">
                          {projectPageStaticData?.developerText}
                        </p>
                        <p className="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px]">
                          {projectData?.clientName}
                        </p>
                      </div>
                      <div className="sm:w-2/3">
                        <p className="text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px] font-bold">
                          {projectPageStaticData?.publisherText}
                        </p>
                        <p className="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px]">
                          {projectData?.publisher}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Tablet and mobile  */}
                <div className="flex lg:hidden gap-16 sm:gap-8 lg:gap-32 flex-row text-[#fff] mb-8 lg:mb-12 justify-between sm:justify-normal">
                  <div className="sm:w-1/3">
                    <p className="text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px] font-bold">
                      {projectPageStaticData?.developerText}
                    </p>
                    <p className="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px]">
                      {projectData?.clientName}
                    </p>
                  </div>
                  <div className="sm:w-2/3">
                    <p className="text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px] font-bold">
                      {projectPageStaticData?.publisherText}
                    </p>
                    <p className="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px]">
                      {projectData?.publisher}
                    </p>
                  </div>
                </div>
                {/* mobile services  */}
                <div className="sm:hidden sm:w-1/3 text-[#fff]">
                  <p className="text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px] font-bold uppercase pb-2">
                    {projectPageStaticData?.servicesText}
                  </p>
                  <ul className="text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px]">
                    {projectData?.services?.map((service, i) => (
                      <li key={i}>{service}</li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* project content ends here  */}
              {/* youtube video starts here  */}
              {projectData?.videoURL &&
                projectData?.videoURL?.trim() !== "" && (
                  <div className="mt-5 lg:mt-20 video-section relative">
                    <YoutubeVideo
                      url={projectData?.videoURL}
                      className="w-full sm:h-[30rem] lg:h-[34rem] xl:h-[40rem]"
                    />
                    {/* <iframe
      className="w-full sm:h-[30rem] lg:h-[34rem] xl:h-[40rem]"
      title="Project Video"
      width="640"
      height="360"
      src={projectData?.videoURL}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowfullscreen=""
    /> */}
                  </div>
                )}
              {/* youtube video ends here  */}
              {/* pagination starts here  */}
              <div
                className={`flex justify-end lg:justify-between gap-8 sm:gap-16 lg:gap-0  my-10 `}
              >
                <div
                  className={`flex gap-8 text-[#fff] items-center ${
                    projectDetails?.previousProjectData?.projectURL
                      ? "opacity-100"
                      : "pointer-events-none opacity-30"
                  } ${
                    projectDetails?.nextProjectData?.projectURL
                      ? ""
                      : "mr-8 sm:mr-32 lg:mr-0"
                  }`}
                >
                  <button
                    onClick={handlePrevious}
                    className="w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] rounded-md pl-2 group slide-btn-top-left-slant opacity-100 border-[#68FFD2] hover:bg-[#68ffd2]"
                    // style="border-color: rgb(104, 255, 210);"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain group-hover:hidden"
                    >
                      <path
                        d="M6.25 11.875L2.5 8.125L6.25 4.375"
                        stroke="#68FFD2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                        stroke="#68FFD2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain hidden group-hover:block"
                    >
                      <path
                        d="M6.25 11.875L2.5 8.125L6.25 4.375"
                        stroke="#1A1A1A"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                        stroke="#1A1A1A"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <p className="text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px] hidden lg:block">
                    {projectPageStaticData?.previousText}
                  </p>
                </div>

                <div className="flex gap-8 text-[#fff] items-center">
                  <p className="text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px] hidden lg:block">
                    {projectPageStaticData?.nextText}
                  </p>
                  <button
                    onClick={handleNext}
                    className={`w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] slide-btn-bottom-right-slant rounded-md pl-2 group opacity-100 border-[#68FFD2] hover:bg-[#68ffd2] ${
                      projectDetails?.nextProjectData?.projectURL
                        ? "opacity-100"
                        : "pointer-events-none opacity-30"
                    }`}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain group-hover:hidden"
                    >
                      <path
                        d="M13.75 11.875L17.5 8.125L13.75 4.375"
                        stroke="#68FFD2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                        stroke="#68FFD2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain hidden group-hover:block"
                    >
                      <path
                        d="M13.75 11.875L17.5 8.125L13.75 4.375"
                        stroke="#1a1a1a"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                        stroke="#1a1a1a"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section className="mt-24 sm:mt-20 lg:mt-[12rem]">
            <Testimonials
              testimonialLeftQuotations={
                projectDetails?.projectPageStaticData?.testimonialLeftQuotations
              }
              testimonialRightQuotations={
                projectDetails?.projectPageStaticData
                  ?.testimonialRightQuotations
              }
              testimonials={projectDetails?.testimonials}
              bgcolor="#2ad9ff"
              textColor="#004780"
            />
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default ProjectDescription;
