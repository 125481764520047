import parse from "html-react-parser";

export const FormatTextFromGeneralConstants = (text) => {
  const formattedText =
    typeof text === "string" ? text.replace(/\r\n/g, "<br />") : " ";
  return parse(formattedText);
};
// Array of languages we are using in project
export const languageCodes = ["zh-chs", "zh-cht", "ja", "ko", "fr-ca"];

// use this for getting URL language
export const getLanguage = () => {
  const param = window.location.pathname.split("/");
  const language = param.find((p) => languageCodes.includes(p));
  return language || "en";
};

export const getDateFormatFromEpoch = (epoch) => {
  let year = epoch.substring(0, 4);
  let month = epoch.substring(4, 6);
  let day = epoch.substring(6, 8);
  let formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};
// use this function for general navigation, pass url as params
export const navigateTo = (url) => {
  let languageValue = getLanguage();
  return languageValue === "en" ? url : `/${languageValue}${url}`;
};

// truncate words based on number
export const truncate = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

export const parseFormattedNumber = (value) => {
  const sanitizedValue = value
    .replace(/,/g, "")
    .replace(/[^\d.]/g, "")
    .trim();

  return parseFloat(sanitizedValue);
};

// language Array
export const languagesObj = [
  {
    languageValue: "English",
    language: "English",
    languageCode: "en",
    browserLanguageCode: "en-US",
  },
  {
    languageValue: "Français",
    language: "Canadian French (Français)",
    languageCode: "fr-ca",
    browserLanguageCode: "fr-CA",
  },
  {
    languageValue: "日本語",
    language: "Japanese 日本語",
    languageCode: "ja",
    browserLanguageCode: "ja",
  },
  {
    languageValue: "한국어",
    language: "Korean 한국어",
    languageCode: "ko",
    browserLanguageCode: "ko",
  },
  {
    languageValue: "简体中文",
    language: "Simplified Chinese 简体中文",
    languageCode: "zh-chs",
    browserLanguageCode: "zh-CN",
  },
  {
    languageValue: "繁體中文",
    language: "Traditional Chinese 繁體中文",
    languageCode: "zh-cht",
    browserLanguageCode: "zh-TW",
  },
];

// countries data
export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "The Democratic Republic of the Congo", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Islamic Republic Of Iran", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Democratic People's Republic of Korea", code: "KP" },
  { name: "Republic of Korea", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People's Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "The Former Yugoslav Republic of Macedonia", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Federated States of Micronesia", code: "FM" },
  { name: "Republic of Moldova", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "United Republic of Tanzania", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Vietnam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

// export const countries = [
//   {
//     name: "Afghanistan",
//     continent: "Asia",
//   },
//   {
//     name: "Albania",
//     continent: "Europe",
//   },
//   {
//     name: "Algeria",
//     continent: "Africa",
//   },
//   {
//     name: "American Samoa",
//     continent: "Oceania",
//   },
//   {
//     name: "Andorra",
//     continent: "Europe",
//   },
//   {
//     name: "Angola",
//     continent: "Africa",
//   },
//   {
//     name: "Anguilla",
//     continent: "North America",
//   },
//   {
//     name: "Antarctica",
//     continent: "Antarctica",
//   },
//   {
//     name: "Antigua and Barbuda",
//     continent: "North America",
//   },
//   {
//     name: "Argentina",
//     continent: "South America",
//   },
//   {
//     name: "Armenia",
//     continent: "Asia",
//   },
//   {
//     name: "Aruba",
//     continent: "North America",
//   },
//   {
//     name: "Australia",
//     continent: "Oceania",
//   },
//   {
//     name: "Austria",
//     continent: "Europe",
//   },
//   {
//     name: "Azerbaijan",
//     continent: "Asia",
//   },
//   {
//     name: "Bahamas",
//     continent: "North America",
//   },
//   {
//     name: "Bahrain",
//     continent: "Asia",
//   },
//   {
//     name: "Bangladesh",
//     continent: "Asia",
//   },
//   {
//     name: "Barbados",
//     continent: "North America",
//   },
//   {
//     name: "Belarus",
//     continent: "Europe",
//   },
//   {
//     name: "Belgium",
//     continent: "Europe",
//   },
//   {
//     name: "Belize",
//     continent: "North America",
//   },
//   {
//     name: "Benin",
//     continent: "Africa",
//   },
//   {
//     name: "Bermuda",
//     continent: "North America",
//   },
//   {
//     name: "Bhutan",
//     continent: "Asia",
//   },
//   {
//     name: "Bolivia",
//     continent: "South America",
//   },
//   {
//     name: "Bosnia and Herzegovina",
//     continent: "Europe",
//   },
//   {
//     name: "Botswana",
//     continent: "Africa",
//   },
//   {
//     name: "Bouvet Island",
//     continent: "Antarctica",
//   },
//   {
//     name: "Brazil",
//     continent: "South America",
//   },
//   {
//     name: "British Indian Ocean Territory",
//     continent: "Africa",
//   },
//   {
//     name: "Brunei",
//     continent: "Asia",
//   },
//   {
//     name: "Bulgaria",
//     continent: "Europe",
//   },
//   {
//     name: "Burkina Faso",
//     continent: "Africa",
//   },
//   {
//     name: "Burundi",
//     continent: "Africa",
//   },
//   {
//     name: "Cambodia",
//     continent: "Asia",
//   },
//   {
//     name: "Cameroon",
//     continent: "Africa",
//   },
//   {
//     name: "Canada",
//     continent: "North America",
//   },
//   {
//     name: "Cape Verde",
//     continent: "Africa",
//   },
//   {
//     name: "Cayman Islands",
//     continent: "North America",
//   },
//   {
//     name: "Central African Republic",
//     continent: "Africa",
//   },
//   {
//     name: "Chad",
//     continent: "Africa",
//   },
//   {
//     name: "Chile",
//     continent: "South America",
//   },
//   {
//     name: "China",
//     continent: "Asia",
//   },
//   {
//     name: "Christmas Island",
//     continent: "Oceania",
//   },
//   {
//     name: "Cocos (Keeling) Islands",
//     continent: "Oceania",
//   },
//   {
//     name: "Colombia",
//     continent: "South America",
//   },
//   {
//     name: "Comoros",
//     continent: "Africa",
//   },
//   {
//     name: "Congo",
//     continent: "Africa",
//   },
//   {
//     name: "Cook Islands",
//     continent: "Oceania",
//   },
//   {
//     name: "Costa Rica",
//     continent: "North America",
//   },
//   {
//     name: "Croatia",
//     continent: "Europe",
//   },
//   {
//     name: "Cuba",
//     continent: "North America",
//   },
//   {
//     name: "Cyprus",
//     continent: "Asia",
//   },
//   {
//     name: "Czech Republic",
//     continent: "Europe",
//   },
//   {
//     name: "Denmark",
//     continent: "Europe",
//   },
//   {
//     name: "Djibouti",
//     continent: "Africa",
//   },
//   {
//     name: "Dominica",
//     continent: "North America",
//   },
//   {
//     name: "Dominican Republic",
//     continent: "North America",
//   },
//   {
//     name: "East Timor",
//     continent: "Asia",
//   },
//   {
//     name: "Ecuador",
//     continent: "South America",
//   },
//   {
//     name: "Egypt",
//     continent: "Africa",
//   },
//   {
//     name: "El Salvador",
//     continent: "North America",
//   },
//   {
//     name: "England",
//     continent: "Europe",
//   },
//   {
//     name: "Equatorial Guinea",
//     continent: "Africa",
//   },
//   {
//     name: "Eritrea",
//     continent: "Africa",
//   },
//   {
//     name: "Estonia",
//     continent: "Europe",
//   },
//   {
//     name: "Eswatini",
//     continent: "Africa",
//   },
//   {
//     name: "Ethiopia",
//     continent: "Africa",
//   },
//   {
//     name: "Falkland Islands",
//     continent: "South America",
//   },
//   {
//     name: "Faroe Islands",
//     continent: "Europe",
//   },
//   {
//     name: "Fiji Islands",
//     continent: "Oceania",
//   },
//   {
//     name: "Finland",
//     continent: "Europe",
//   },
//   {
//     name: "France",
//     continent: "Europe",
//   },
//   {
//     name: "French Guiana",
//     continent: "South America",
//   },
//   {
//     name: "French Polynesia",
//     continent: "Oceania",
//   },
//   {
//     name: "French Southern territories",
//     continent: "Antarctica",
//   },
//   {
//     name: "Gabon",
//     continent: "Africa",
//   },
//   {
//     name: "Gambia",
//     continent: "Africa",
//   },
//   {
//     name: "Georgia",
//     continent: "Asia",
//   },
//   {
//     name: "Germany",
//     continent: "Europe",
//   },
//   {
//     name: "Ghana",
//     continent: "Africa",
//   },
//   {
//     name: "Gibraltar",
//     continent: "Europe",
//   },
//   {
//     name: "Greece",
//     continent: "Europe",
//   },
//   {
//     name: "Greenland",
//     continent: "North America",
//   },
//   {
//     name: "Grenada",
//     continent: "North America",
//   },
//   {
//     name: "Guadeloupe",
//     continent: "North America",
//   },
//   {
//     name: "Guam",
//     continent: "Oceania",
//   },
//   {
//     name: "Guatemala",
//     continent: "North America",
//   },
//   {
//     name: "Guinea",
//     continent: "Africa",
//   },
//   {
//     name: "Guinea-Bissau",
//     continent: "Africa",
//   },
//   {
//     name: "Guyana",
//     continent: "South America",
//   },
//   {
//     name: "Haiti",
//     continent: "North America",
//   },
//   {
//     name: "Heard Island and McDonald Islands",
//     continent: "Antarctica",
//   },
//   {
//     name: "Holy See (Vatican City State)",
//     continent: "Europe",
//   },
//   {
//     name: "Honduras",
//     continent: "North America",
//   },
//   {
//     name: "Hong Kong",
//     continent: "Asia",
//   },
//   {
//     name: "Hungary",
//     continent: "Europe",
//   },
//   {
//     name: "Iceland",
//     continent: "Europe",
//   },
//   {
//     name: "India",
//     continent: "Asia",
//   },
//   {
//     name: "Indonesia",
//     continent: "Asia",
//   },
//   {
//     name: "Iran",
//     continent: "Asia",
//   },
//   {
//     name: "Iraq",
//     continent: "Asia",
//   },
//   {
//     name: "Ireland",
//     continent: "Europe",
//   },
//   {
//     name: "Israel",
//     continent: "Asia",
//   },
//   {
//     name: "Italy",
//     continent: "Europe",
//   },
//   {
//     name: "Ivory Coast",
//     continent: "Africa",
//   },
//   {
//     name: "Jamaica",
//     continent: "North America",
//   },
//   {
//     name: "Japan",
//     continent: "Asia",
//   },
//   {
//     name: "Jordan",
//     continent: "Asia",
//   },
//   {
//     name: "Kazakhstan",
//     continent: "Asia",
//   },
//   {
//     name: "Kenya",
//     continent: "Africa",
//   },
//   {
//     name: "Kiribati",
//     continent: "Oceania",
//   },
//   {
//     name: "Kuwait",
//     continent: "Asia",
//   },
//   {
//     name: "Kyrgyzstan",
//     continent: "Asia",
//   },
//   {
//     name: "Laos",
//     continent: "Asia",
//   },
//   {
//     name: "Latvia",
//     continent: "Europe",
//   },
//   {
//     name: "Lebanon",
//     continent: "Asia",
//   },
//   {
//     name: "Lesotho",
//     continent: "Africa",
//   },
//   {
//     name: "Liberia",
//     continent: "Africa",
//   },
//   {
//     name: "Libya",
//     continent: "Africa",
//   },
//   {
//     name: "Liechtenstein",
//     continent: "Europe",
//   },
//   {
//     name: "Lithuania",
//     continent: "Europe",
//   },
//   {
//     name: "Luxembourg",
//     continent: "Europe",
//   },
//   {
//     name: "Macao",
//     continent: "Asia",
//   },
//   {
//     name: "North Macedonia",
//     continent: "Europe",
//   },
//   {
//     name: "Madagascar",
//     continent: "Africa",
//   },
//   {
//     name: "Malawi",
//     continent: "Africa",
//   },
//   {
//     name: "Malaysia",
//     continent: "Asia",
//   },
//   {
//     name: "Maldives",
//     continent: "Asia",
//   },
//   {
//     name: "Mali",
//     continent: "Africa",
//   },
//   {
//     name: "Malta",
//     continent: "Europe",
//   },
//   {
//     name: "Marshall Islands",
//     continent: "Oceania",
//   },
//   {
//     name: "Martinique",
//     continent: "North America",
//   },
//   {
//     name: "Mauritania",
//     continent: "Africa",
//   },
//   {
//     name: "Mauritius",
//     continent: "Africa",
//   },
//   {
//     name: "Mayotte",
//     continent: "Africa",
//   },
//   {
//     name: "Mexico",
//     continent: "North America",
//   },
//   {
//     name: "Micronesia, Federated States of",
//     continent: "Oceania",
//   },
//   {
//     name: "Moldova",
//     continent: "Europe",
//   },
//   {
//     name: "Monaco",
//     continent: "Europe",
//   },
//   {
//     name: "Mongolia",
//     continent: "Asia",
//   },
//   {
//     name: "Montenegro",
//     continent: "Europe",
//   },
//   {
//     name: "Montserrat",
//     continent: "North America",
//   },
//   {
//     name: "Morocco",
//     continent: "Africa",
//   },
//   {
//     name: "Mozambique",
//     continent: "Africa",
//   },
//   {
//     name: "Myanmar",
//     continent: "Asia",
//   },
//   {
//     name: "Namibia",
//     continent: "Africa",
//   },
//   {
//     name: "Nauru",
//     continent: "Oceania",
//   },
//   {
//     name: "Nepal",
//     continent: "Asia",
//   },
//   {
//     name: "Netherlands",
//     continent: "Europe",
//   },
//   {
//     name: "Netherlands Antilles",
//     continent: "North America",
//   },
//   {
//     name: "New Caledonia",
//     continent: "Oceania",
//   },
//   {
//     name: "New Zealand",
//     continent: "Oceania",
//   },
//   {
//     name: "Nicaragua",
//     continent: "North America",
//   },
//   {
//     name: "Niger",
//     continent: "Africa",
//   },
//   {
//     name: "Nigeria",
//     continent: "Africa",
//   },
//   {
//     name: "Niue",
//     continent: "Oceania",
//   },
//   {
//     name: "Norfolk Island",
//     continent: "Oceania",
//   },
//   {
//     name: "North Korea",
//     continent: "Asia",
//   },
//   {
//     name: "Northern Ireland",
//     continent: "Europe",
//   },
//   {
//     name: "Northern Mariana Islands",
//     continent: "Oceania",
//   },
//   {
//     name: "Norway",
//     continent: "Europe",
//   },
//   {
//     name: "Oman",
//     continent: "Asia",
//   },
//   {
//     name: "Pakistan",
//     continent: "Asia",
//   },
//   {
//     name: "Palau",
//     continent: "Oceania",
//   },
//   {
//     name: "Palestine",
//     continent: "Asia",
//   },
//   {
//     name: "Panama",
//     continent: "North America",
//   },
//   {
//     name: "Papua New Guinea",
//     continent: "Oceania",
//   },
//   {
//     name: "Paraguay",
//     continent: "South America",
//   },
//   {
//     name: "Peru",
//     continent: "South America",
//   },
//   {
//     name: "Philippines",
//     continent: "Asia",
//   },
//   {
//     name: "Pitcairn",
//     continent: "Oceania",
//   },
//   {
//     name: "Poland",
//     continent: "Europe",
//   },
//   {
//     name: "Portugal",
//     continent: "Europe",
//   },
//   {
//     name: "Puerto Rico",
//     continent: "North America",
//   },
//   {
//     name: "Qatar",
//     continent: "Asia",
//   },
//   {
//     name: "Reunion",
//     continent: "Africa",
//   },
//   {
//     name: "Romania",
//     continent: "Europe",
//   },
//   {
//     name: "Russia",
//     continent: "Europe",
//   },
//   {
//     name: "Rwanda",
//     continent: "Africa",
//   },
//   {
//     name: "Saint Helena",
//     continent: "Africa",
//   },
//   {
//     name: "Saint Kitts and Nevis",
//     continent: "North America",
//   },
//   {
//     name: "Saint Lucia",
//     continent: "North America",
//   },
//   {
//     name: "Saint Pierre and Miquelon",
//     continent: "North America",
//   },
//   {
//     name: "Saint Vincent and the Grenadines",
//     continent: "North America",
//   },
//   {
//     name: "Samoa",
//     continent: "Oceania",
//   },
//   {
//     name: "San Marino",
//     continent: "Europe",
//   },
//   {
//     name: "Sao Tome and Principe",
//     continent: "Africa",
//   },
//   {
//     name: "Saudi Arabia",
//     continent: "Asia",
//   },
//   {
//     name: "Scotland",
//     continent: "Europe",
//   },
//   {
//     name: "Senegal",
//     continent: "Africa",
//   },
//   {
//     name: "Serbia",
//     continent: "Europe",
//   },
//   {
//     name: "Seychelles",
//     continent: "Africa",
//   },
//   {
//     name: "Sierra Leone",
//     continent: "Africa",
//   },
//   {
//     name: "Singapore",
//     continent: "Asia",
//   },
//   {
//     name: "Slovakia",
//     continent: "Europe",
//   },
//   {
//     name: "Slovenia",
//     continent: "Europe",
//   },
//   {
//     name: "Solomon Islands",
//     continent: "Oceania",
//   },
//   {
//     name: "Somalia",
//     continent: "Africa",
//   },
//   {
//     name: "South Africa",
//     continent: "Africa",
//   },
//   {
//     name: "South Georgia and the South Sandwich Islands",
//     continent: "Antarctica",
//   },
//   {
//     name: "South Korea",
//     continent: "Asia",
//   },
//   {
//     name: "South Sudan",
//     continent: "Africa",
//   },
//   {
//     name: "Spain",
//     continent: "Europe",
//   },
//   {
//     name: "Sri Lanka",
//     continent: "Asia",
//   },
//   {
//     name: "Sudan",
//     continent: "Africa",
//   },
//   {
//     name: "Suriname",
//     continent: "South America",
//   },
//   {
//     name: "Svalbard and Jan Mayen",
//     continent: "Europe",
//   },
//   {
//     name: "Sweden",
//     continent: "Europe",
//   },
//   {
//     name: "Switzerland",
//     continent: "Europe",
//   },
//   {
//     name: "Syria",
//     continent: "Asia",
//   },
//   {
//     name: "Tajikistan",
//     continent: "Asia",
//   },
//   {
//     name: "Tanzania",
//     continent: "Africa",
//   },
//   {
//     name: "Thailand",
//     continent: "Asia",
//   },
//   {
//     name: "The Democratic Republic of Congo",
//     continent: "Africa",
//   },
//   {
//     name: "Togo",
//     continent: "Africa",
//   },
//   {
//     name: "Tokelau",
//     continent: "Oceania",
//   },
//   {
//     name: "Tonga",
//     continent: "Oceania",
//   },
//   {
//     name: "Trinidad and Tobago",
//     continent: "North America",
//   },
//   {
//     name: "Tunisia",
//     continent: "Africa",
//   },
//   {
//     name: "Turkey",
//     continent: "Asia",
//   },
//   {
//     name: "Turkmenistan",
//     continent: "Asia",
//   },
//   {
//     name: "Turks and Caicos Islands",
//     continent: "North America",
//   },
//   {
//     name: "Tuvalu",
//     continent: "Oceania",
//   },
//   {
//     name: "Uganda",
//     continent: "Africa",
//   },
//   {
//     name: "Ukraine",
//     continent: "Europe",
//   },
//   {
//     name: "United Arab Emirates",
//     continent: "Asia",
//   },
//   {
//     name: "United Kingdom",
//     continent: "Europe",
//   },
//   {
//     name: "United States",
//     continent: "North America",
//   },
//   {
//     name: "United States Minor Outlying Islands",
//     continent: "Oceania",
//   },
//   {
//     name: "Uruguay",
//     continent: "South America",
//   },
//   {
//     name: "Uzbekistan",
//     continent: "Asia",
//   },
//   {
//     name: "Vanuatu",
//     continent: "Oceania",
//   },
//   {
//     name: "Venezuela",
//     continent: "South America",
//   },
//   {
//     name: "Vietnam",
//     continent: "Asia",
//   },
//   {
//     name: "Virgin Islands, British",
//     continent: "North America",
//   },
//   {
//     name: "Virgin Islands, U.S.",
//     continent: "North America",
//   },
//   {
//     name: "Wales",
//     continent: "Europe",
//   },
//   {
//     name: "Wallis and Futuna",
//     continent: "Oceania",
//   },
//   {
//     name: "Western Sahara",
//     continent: "Africa",
//   },
//   {
//     name: "Yemen",
//     continent: "Asia",
//   },
//   {
//     name: "Zambia",
//     continent: "Africa",
//   },
//   {
//     name: "Zimbabwe",
//     continent: "Africa",
//   },
// ];

//Transform content
export const transformContent = (htmlString) => {
  if (!htmlString) return "";

  const urlRegex =
    /(https?:\/\/(?:www\.)?(youtube\.com\/(?:watch\?v=|live\/|embed\/|embed\/live_stream\?channel=)|youtu\.be\/|vimeo\.com\/|dailymotion\.com\/video\/)([\w-]+))/g;

  const updatedHtmlString = htmlString.replace(urlRegex, (match) => {
    let embedUrl = null;

    if (match.includes("youtube.com") || match.includes("youtu.be")) {
      let videoId = null;
      if (match.includes("/live/")) {
        videoId = match.split("/live/")[1]?.split("?")[0];
        embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
      } else if (match.includes("watch?v=")) {
        videoId = match.split("v=")[1]?.split("&")[0];
        embedUrl = `https://www.youtube.com/embed/${videoId}`;
      } else {
        videoId = match.split("/").pop();
        embedUrl = `https://www.youtube.com/embed/${videoId}`;
      }
    } else if (match.includes("vimeo.com")) {
      const videoId = match.split("/").pop();
      embedUrl = `https://player.vimeo.com/video/${videoId}`;
    } else if (match.includes("dailymotion.com")) {
      const videoId = match.split("/").pop();
      embedUrl = `https://www.dailymotion.com/embed/video/${videoId}`;
    }

    if (embedUrl) {
      return `
        <div style="display: flex; justify-content: center; margin: 20px 0;">
          <iframe 
            width="560" 
            height="315" 
            src="${embedUrl}" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen
            style="border-radius: 8px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);"
          ></iframe>
        </div>
      `;
    }

    return match;
  });

  return updatedHtmlString;
};

export const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function (...args) {
    const context = this;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};
