// generic components for youtube videos using Iframe
import { useRef, useEffect } from "react";
import YouTube from "react-youtube";

const getEmbedUrl = (url) => {
  if (url?.includes("youtube.com") || url?.includes("youtu.be")) {
    const videoId = url?.split("v=")[1]?.split("&")[0] || url?.split("/").pop();
    return `https://www.youtube-nocookie.com/embed/${videoId}?rel=0`;
  } else if (url?.includes("vimeo.com")) {
    const videoId = url?.split("/").pop();
    return `https://player.vimeo.com/video/${videoId}`;
  } else if (url?.includes("dailymotion.com")) {
    const videoId = url?.split("/video/")[1]?.split("_")[0];
    return `https://www.dailymotion.com/embed/video/${videoId}`;
  }
  return "";
};

const getYouTubeID = (url) => {
  if (url?.includes("youtube.com") || url?.includes("youtu.be")) {
    return url?.split("v=")[1]?.split("&")[0] || url?.split("/").pop();
  }
  return null;
};

const YoutubeVideo = ({
  url,
  className,
  onPlay,
  videoId,
  isPlaying,
  onVideoReady,
}) => {
  const playerRef = useRef(null);

  const onPlayerReady = (event) => {
    playerRef.current = event.target;
    if (typeof onVideoReady === "function") {
      onVideoReady();
    }
  };

  useEffect(() => {
    if (isPlaying !== videoId && playerRef.current) {
      playerRef.current?.pauseVideo();
    }
  }, [isPlaying, videoId]);

  let videoURL = getEmbedUrl(url);
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: isPlaying ? 1 : 0,
      rel: 0,
    },
  };
  return (
    <>
      {url?.includes("youtube.com") || url?.includes("youtu.be") ? (
        <YouTube
          videoId={getYouTubeID(url)}
          opts={opts}
          onPlay={onPlay}
          onReady={onPlayerReady}
          className={className}
        />
      ) : (
        <iframe
          frameBorder="0"
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          title="1518 Studios - 3D Character - Alien"
          src={videoURL}
          id={videoURL}
          data-gtm-yt-inspected-10="true"
          className={className}
        ></iframe>
      )}
    </>
  );
};

export default YoutubeVideo;
