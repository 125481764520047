import React, { useEffect, useState, useMemo, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";

const TopBanner = ({ homeData }) => {
  const videoRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLooping, setIsLooping] = useState(false);

  const {
    bringingText,
    yourText,
    storyText,
    visionText,
    voiceText,
    ideaText,
    gameText,
    toTheText,
    worldText,
    weBringGamesToLifeText,
    bannerBgVideo,
    bannerBgVideoMob,
    bannerBgVideoTab,
  } = homeData;
  const loopWords = useMemo(
    () => [storyText, visionText, voiceText, ideaText, gameText],
    [storyText, visionText, voiceText, ideaText, gameText]
  );
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentIndex((prevIndex) => {
  //       if (prevIndex === 4) {
  //         return 0;
  //       } else {
  //         return prevIndex + 1;
  //       }
  //     });
  //   }, 1500);

  //   return () => clearInterval(intervalId);
  // }, [words]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry?.isIntersecting && videoRef.current?.paused) {
          videoRef.current.play().catch((error) => {
            console.error("Error resuming video playback:", error);
          });
        }
      },
      { threshold: 0.1 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  // useEffect(() => {
  //   const forceReflow = () => {
  //     window.scrollBy(0, 1); // Scroll down slightly
  //     window.scrollBy(0, -1); // Scroll back up
  //   };

  //   const tryPlayVideo = () => {
  //     if (videoRef.current && videoRef.current.paused) {
  //       videoRef.current.play().catch((error) => {
  //         console.error("Error resuming video playback:", error);
  //       });
  //     }
  //   };

  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === "visible") {
  //       tryPlayVideo();
  //       forceReflow();
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);
  const headerWords = [bringingText, yourText, storyText, toTheText, worldText];
  const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const wordVariants = {
    hidden: { opacity: 0, y: 40 },
    visible: { opacity: 1, y: 0 },
  };
  const finalPhraseVariants = {
    hidden: { opacity: 0, y: -30 },
    visible: { opacity: 1, y: 0 },
  };
  const loopWordVariants = {
    initial: { y: 200 },
    animate: { y: 0, transition: { duration: 0.5 } },
    exit: { y: -200, transition: { duration: 0.5 } },
  };

  useEffect(() => {
    if (isLooping) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % loopWords.length);
      }, 2000); // Change word every 2 seconds

      return () => clearInterval(interval);
    }
  }, [isLooping]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLooping(true);
    }, headerWords.length * 200); // Start looping after initial animation

    return () => clearTimeout(timer);
  }, []);
  return (
    <section className="relative h-[100vh] min-h-[24rem] max-h-[42rem] sm:max-h-[29rem] md:max-h-[42rem] md:h-[32rem] lg-only::!max-h-[48rem] lg:h-[100vh] lg:min-h-[33.5rem] xl:max-h-none 2xl:min-h-[39rem] 3xl:min-h-[48rem] 4xl:min-h-[54rem] 4xl:max-h-[58rem] w-[100vw] flex sm:items-center overflow-visible">
      <video
        className="absolute right-0 sm:hidden"
        preload="auto"
        ref={videoRef}
        muted
        loop
        autoPlay
        playsInline
        controls={false}
      >
        <source src={bannerBgVideoMob} type="video/mp4" />
      </video>
      <video
        className="hidden sm:block lg:hidden absolute object-contain  w-full h-full 1.5md:object-cover  -top-12 md:-top-4   object-right -z-10 overflow-visible"
        preload="auto"
        ref={videoRef}
        // src={bannerBgVideo}
        muted
        loop
        autoPlay
        playsInline
        controls={false}
      >
        <source src={bannerBgVideoTab} type="video/mp4" />
      </video>

      <video
        className="hidden aspect-[1.3]   lg:block absolute object-contain w-4/5 xl:w-[90%] h-auto right-4 top-4 xl:-top-4 1.5xl:-top-20 1.5xl:w-[85%]   4xl:!w-[70%]   object-right -z-10 overflow-visible"
        preload="auto"
        ref={videoRef}
        // src={bannerBgVideo}
        muted
        loop
        autoPlay
        playsInline
        controls={false}
      >
        <source src={bannerBgVideo} type="video/mp4" />
      </video>
      <div className=" sm:w-full text-white  absolute bottom-12 left-4 sm:top-0 sm:left-0 sm:relative container  lg:pt-20 3xl:pt-24">
        <motion.h2
          className={`uppercase mb-6 xs:mb-8 sm:mb-10 lg:mb-12 xl:mb-14 3xl:mb-16 page-main-header-sizek text-[32px] xxs:text-[36px] md:!text-[48px] lg:!text-[60px] xl:!text-[72px] 1.5xl:!text-[94px] 2.5xl:!text-[108px] 3xl:!text-[118px] 3.5xl:!text-[130px] 4xl:!text-[138px] !leading-[90%]  tracking-[-1.3px] lg:tracking-[-2.64px] z-10 overflow-hidden`}
          // className={`uppercase mb-6 xs:mb-8 sm:mb-10 lg:mb-12 xl:mb-14 3xl:mb-16 page-main-header-size md:!text-[48px] lg:!text-[69px] xl:!text-[80px] 2xl:!text-[102px] 2.5xl:!text-[116px] 3xl:!text-[130px] 3.5xl:!text-[138px] 4xl:!text-[146px] !leading-[90%]  tracking-[-1.3px] lg:tracking-[-2.64px] z-10 overflow-hidden`}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {/* <h2 className="uppercase mb-6 xs:mb-8 page-main-header-size !leading-[90%]  tracking-[-1.3px] lg:tracking-[-2.64px] z-10"> */}
          {/* <span className="block">{bringingText}</span>
          <span className="block sm:inline lg:block">{yourText}</span>

          <span
            className="block sm:inline lg:block text-primary sm:ml-2 lg:left-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {words[currentIndex]}
          </span>

          <span className="block">{toTheText}</span>
          <span className="block">{worldText}</span> */}
          {headerWords.map((word, index) => (
            <motion.span
              key={index}
              variants={wordVariants}
              className={`block ${
                word === storyText ? "text-primary sm:inline" : ""
              } overflow-hidden`}
            >
              {word === storyText && isLooping ? (
                <AnimatePresence mode="wait">
                  <motion.span
                    key={loopWords[currentIndex]}
                    variants={loopWordVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    // transition={{ duration: 0.5 }}
                  >
                    {loopWords[currentIndex]}
                  </motion.span>
                </AnimatePresence>
              ) : (
                word
              )}
            </motion.span>
          ))}
        </motion.h2>
        <motion.p
          className="text-2xl 3xl:text-[28px] 4xl:text-[32px] sm:!leading-[90%] tracking-[-0.72px] font-semibold w-[75%] sm:w-auto  sm:ml-2 lg:ml-3 xl:ml-4 3xl:ml-6"
          variants={finalPhraseVariants}
          initial="hidden"
          animate="visible"
          transition={{ delay: headerWords.length * 0.3 }}
        >
          {weBringGamesToLifeText}
        </motion.p>
      </div>
    </section>
  );
};

export default TopBanner;
