import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../../css/componentscss/SwiperSlide.css";
import { useNavigate } from "react-router-dom";
import { navigateTo } from "../../constants/GeneralConstants";
import { clientUrls } from "../../constants/ClientUrls";
import FormatText from "../FormatText";
import "swiper/css/mousewheel"; // Import Swiper's mousewheel CSS
import { Mousewheel } from "swiper/modules";
import useWindowWidth from "../services/useWindowWidth";
const AboutSwiperSlider = ({ service, color, headerTitle }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  const [isHoverPrev, setIsHoverPrev] = useState(false);
  const [isHoverNext, setIsHoverNext] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const windowWidth = useWindowWidth();

  const navigate = useNavigate();
  const handleNext = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      setActiveIndex((prevIndex) => prevIndex + 1);
      swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      setActiveIndex((prevIndex) => prevIndex - 1);
      swiper.slidePrev();
    }
  };
  const toggleMousewheel = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      if (swiper) {
        if (windowWidth >= 640) {
          swiper.mousewheel.enable();
        } else {
          swiper.mousewheel.disable();
        }
      }
    }
  };
  const isIOS = (userAgent) => {
    return /iPhone|iPad|iPod/i.test(userAgent);
  };
  const forceRepaint = () => {
    if (isIOS(navigator.userAgent)) {
      const ele = document.getElementById(`activePara-${activeIndex}`);
      if (ele) {
        ele.style.display = "none";
        ele.style.color = "";
        setTimeout(() => {
          ele.style.display = "block";
          ele.style.color = color.tertiary;
        }, 10);
      }
    }
  };
  useEffect(() => {
    toggleMousewheel();
    window.addEventListener("resize", toggleMousewheel);
    return () => {
      window.removeEventListener("resize", toggleMousewheel);
    };
  }, []);
  const updateSwiper = () => {
    if (swiperRef.current?.swiper) {
      const swiper = swiperRef.current.swiper;
      setTimeout(() => {
        swiper.update();
      }, 50);
    }
  };
  useEffect(() => {
    forceRepaint();
  }, [activeIndex]);
  return (
    <section className="container mb-16 sm:mb-24 lg:mb-36 ">
      <h2 className="text-white text-[36px] sm:text-[42px] md:text-[48px] lg:text-[57.6px] xl:text-[72px] 2xl:text-[86.4px]  3xl:text-[108px] 4xl:text-[129.6px] leading-[1] mb-5 sm:mb-16 2xl:mb-20 uppercase">
        {headerTitle}
      </h2>
      <Swiper
        className="custom-swiper mb-4 !overflow-visible hide-scroll"
        loop={false}
        breakpoints={{
          320: {
            slidesPerView: 1.1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 1.7,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2.3,
            spaceBetween: 40,
          },
          1286: {
            slidesPerView: 2.6,
            spaceBetween: 40,
          },
          1530: {
            slidesPerView: 2.7,
            spaceBetween: 40,
          },
          2560: {
            slidesPerView: 3.1,
            spaceBetween: 40,
          },
        }}
        ref={swiperRef}
        speed={800}
        effect="fade"
        allowSlideNext={activeIndex !== service?.length - 1}
        onSlideChangeTransitionEnd={updateSwiper}
        allowSlidePrev={activeIndex !== 0}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.activeIndex);
        }}
        modules={[Mousewheel]}
        mousewheel={{ forceToAxis: true, sensitivity: 3, thresholdDelta: 1 }}
        freeMode={true}
      >
        {service?.map((subService, subIndex) => (
          <SwiperSlide
            key={subIndex}
            className={`!flex flex-col justify-end pl-6 pb-6 pr-6 rounded-lg 
      ${
        subIndex === activeIndex
          ? "slider-cards-top-left-slant service-slide-active"
          : "service-slide"
      } 
      !h-72 xs:!h-72 lg:!h-64 xl:!h-72 2xl:!h-[22rem] 3xl:!h-[26rem] 4xl:!h-[30rem] backdrop-blur-sm lg:pt-12`}
            style={{
              color: subIndex === activeIndex ? color.tertiary : "",
              backgroundColor: subIndex === activeIndex ? color.primary : "",
            }}
          >
            <h3 className="text-xl xs:text-2xl sm:text-3xl md:text-4xl 3xl:text-[36px] 4xl:text-[43.2px] leading-[1] mb-4 uppercase sm:mt-9 lg:mt-0">
              {subService?.valuesTitleText}
            </h3>
            <p
              className="text-base xs:text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] leading-[111%] font-medium overflow-auto hide-scroll "
              id={"activePara-" + subIndex}
            >
              <FormatText text={subService?.valuesDescriptionText} />
            </p>

            {subService?.valuesButtonText && (
              <button
                className="hidden sm:block text-[18px] 2xl:text-[21.6px] mt-5 3xl:text-[27px] 4xl:text-[32.4px] px-1 py-1.5 border-[2px] font-dela rounded-md w-full sm:w-56 2xl:w-72 3xl:w-80 bottom-right-slant-hover hover:!text-black"
                style={{
                  color: "",
                  borderColor: color.tertiary,
                  backgroundColor: isHover ? color?.primary : "",
                }}
                onClick={(e) => navigate(navigateTo(clientUrls?.careers))}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = color.tertiary;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                }}
              >
                {subService?.subServiceButtonText}
              </button>
            )}
          </SwiperSlide>
        ))}
        {/* Add two dummy slides */}
        <SwiperSlide className="!flex !h-72 xs:!h-72 lg:!h-64 xl:!h-72 2xl:!h-[22rem] 3xl:!h-[26rem] invisible"></SwiperSlide>
        <SwiperSlide className="!flex !h-72 xs:!h-72 lg:!h-64 xl:!h-72 2xl:!h-[22rem] 3xl:!h-[26rem] invisible"></SwiperSlide>
      </Swiper>

      {/* Section for Previous/Next buttons */}

      <div className="hidden sm:flex gap-4">
        <button
          onClick={handlePrev}
          onMouseEnter={() => {
            setIsHoverPrev(true);
          }}
          onMouseLeave={() => {
            setIsHoverPrev(false);
          }}
          disabled={activeIndex === 0}
          className={`w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] rounded-md pl-2 group slide-btn-top-left-slant ${
            activeIndex === 0 ? "opacity-30 pointer-events-none" : "opacity-100"
          }`}
          style={{
            borderColor: color.primary,
            backgroundColor: isHoverPrev ? color.primary : "",
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain group-hover:hidden"
          >
            <path
              d="M6.25 11.875L2.5 8.125L6.25 4.375"
              stroke={color.primary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
              stroke={color.primary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain hidden group-hover:block"
          >
            <path
              d="M6.25 11.875L2.5 8.125L6.25 4.375"
              stroke="#1A1A1A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
              stroke="#1A1A1A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button
          onClick={handleNext}
          disabled={activeIndex === service?.length - 1}
          className={`w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px]  slide-btn-bottom-right-slant rounded-md pl-2 group ${
            activeIndex === service?.length - 1
              ? "opacity-30 pointer-events-none"
              : "opacity-100 "
          }`}
          style={{
            borderColor: color.primary,
            backgroundColor: isHoverNext ? color.primary : "",
          }}
          onMouseEnter={() => {
            setIsHoverNext(true);
          }}
          onMouseLeave={() => {
            setIsHoverNext(false);
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain group-hover:hidden"
          >
            <path
              d="M13.75 11.875L17.5 8.125L13.75 4.375"
              stroke={color.primary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
              stroke={color.primary}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain hidden group-hover:block"
          >
            <path
              d="M14.25 11.875L18 8.125L14.25 4.375"
              stroke="#1A1A1A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 15.625C3 13.6359 3.79018 11.7282 5.1967 10.3217C6.60322 8.91518 8.51088 8.125 10.5 8.125H18"
              stroke="#1A1A1A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </section>
  );
};

export default AboutSwiperSlider;
