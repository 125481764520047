import React, { useEffect, useState } from "react";

const PIL = ({ lowQaulitySrc, highQualitySrc, className, alt }) => {
  const [highQualityLoaded, setHighQualityLoaded] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    const dotIndex = highQualitySrc.lastIndexOf(".");
    const lowQualityPath =
      highQualitySrc.slice(0, dotIndex) +
      "LowQuality" +
      highQualitySrc.slice(dotIndex);
    setImgSrc(lowQualityPath);
  }, []);

  const handleError = () => {
    setImgSrc(highQualitySrc);
  };

  return (
    <>
      <img
        src={imgSrc}
        onError={handleError}
        alt={alt}
        className={`${className} transition-all duration-500 blur-md  ${
          highQualityLoaded ? "opacity-0" : "opacity-100"
        }`}
      />
      <img
        src={highQualitySrc}
        alt={alt}
        className={`${className} absolute top-0 left-0 transition-all duration-500 ease-in z-[2] ${
          highQualityLoaded ? "blur-0" : "blur-md"
        }`}
        onLoad={() => setHighQualityLoaded(true)}
      />
    </>
  );
};

export default PIL;
