import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLanguage, navigateTo } from "../constants/GeneralConstants";
import { clientUrls } from "../constants/ClientUrls";
import ButtonSlant from "./ButtonSlant";
import { useEffect, useState } from "react";
import { get404PageData } from "../services/ApiService";
import Loader from "./Loader";
import { setColor } from "../store/reducers/ColorReducer";

const PageNotFound = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    call404PageData(getLanguage());
  }, []);
  useEffect(() => {
    dispatch(
      setColor({
        primary: "#ff8200",
        secondary: "#ff8200",
        tertiary: "#1a1a1a",
      })
    );
  }, [dispatch]);
  const call404PageData = async (language) => {
    try {
      let data = await get404PageData(language);
      document.title = data?.headingText;
      setData(data);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };
  if (loader) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col justify-center items-center h-[100vh] sm:h-[80vh]  p-12 text-center">
      <h2
        className="font-36-36-36 mb-2 leading-[1]"
        style={{
          color: "#ff8200",
        }}
      >
        {" "}
        {data?.headingText}
      </h2>

      <p className="font-18-18-18 text-white mb-4">{data?.subHeadingText}</p>
      <p className="font-16-16-16 text-white">{data?.descriptionText}</p>

      <ButtonSlant
        onClick={(e) => navigate(navigateTo(clientUrls.home))}
        buttonClass="rounded-md font-18-18-18 py-2.5 px-5 font-dela border-[3px] "
        divClass="mt-4"
        slantClass="bottom-right-slant"
        borderColor="#ff8200"
        textColor="#ff8200"
        textColorHover="#1a1a1a"
        bgColorHover="#ff8200"
        buttonText={data?.sideHomeButtonText}
      />
    </div>
  );
};

export default PageNotFound;
