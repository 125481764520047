import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import { SlantImg } from "../CommonComponents";

const extractParts = (text) => {
  const match = text.match(/([^\d,.]*)([\d,.]+)(.*)/);
  const prefix = match ? match[1].trim() : "";
  const number = match ? parseFloat(match[2].replace(/,/g, "")) : 0;
  const suffix = match ? match[3].trim() : "";
  return { prefix, number, suffix };
};

const CommonTopBanner = ({ servicePageData, color }) => {
  const peopleCount = useMotionValue(0);
  const countryCount = useMotionValue(0);
  const yearsCount = useMotionValue(0);

  const [showPeopleText, setShowPeopleText] = useState(false);
  const [showCountriesText, setShowCountriesText] = useState(false);
  const [showYearsText, setShowYearsText] = useState(false);

  const formatNumber = (value) => {
    if (value % 1 !== 0) return value.toFixed(1);
    return Math.round(value).toString();
  };

  const statsPeopleValue = useTransform(peopleCount, formatNumber);
  const statsCountryValue = useTransform(countryCount, formatNumber);
  const statsYearsValue = useTransform(yearsCount, formatNumber);

  const { param } = useParams();

  const {
    bannerSection_heading1Text,
    bannerSection_heading2Text,
    bannerSection_statsPeopleValue,
    bannerSection_statsCountriesValue,
    bannerSection_statsYearOfXPValue,
    bannerSection_statsPeopleLabel,
    bannerSection_statsCountriesLabel,
    bannerSection_statsYearOfXPLabel,
    bannerSection_serviceBgImage,
  } = servicePageData || {};

  const peopleData = extractParts(bannerSection_statsPeopleValue);
  const countriesData = extractParts(bannerSection_statsCountriesValue);
  const yearsData = extractParts(bannerSection_statsYearOfXPValue);

  useEffect(() => {
    animate(peopleCount, peopleData.number, {
      duration: 2,
      ease: "easeOut",
      onComplete: () => setShowPeopleText(true),
    });
    animate(countryCount, countriesData.number, {
      duration: 2,
      ease: "easeOut",
      onComplete: () => setShowCountriesText(true),
    });
    animate(yearsCount, yearsData.number, {
      duration: 2,
      ease: "easeOut",
      onComplete: () => setShowYearsText(true),
    });
  }, [peopleData.number, countriesData.number, yearsData.number]);

  return (
    <section
      className="container h-[19rem] lg:h-[23rem] xl:h-[30rem] 1.5xl:h-[29rem] 2xl:h-[29rem] 2.5xl:h-[35rem] 3xl:h-[38rem] 4xl:h-[45rem]  mt-24 sm:mt-28 4xl:mt-36  mb-16 sm:mb-20  lg:mb-20 4xl:mb-32 rounded-lg flex items-center sm:gap-4 lg:gap-8 3xl:gap-16 relative top-banner-top-left-slantk"
      style={{ color: color.tertiary, backgroundColor: color.primary }}
    >
      <div className="absolute top-[-1px] left-[-1px] z-[10] rotate-180">
        <SlantImg className="h-[60px] sm:h-[75px] lg:h-[80px]" />
      </div>
      <div className="pt-12 pl-4 sm:pl-12 lg:pl-16 xl:pl-20 3xl:pl-24 4xl:pl-32 xs-only:pr-4 w-full sm:w-[60%] md:w-[67%] lg:w-[65%]">
        <h2 className="text-[28px] xs:text-3xl sm:text-[31px] md:text-4xl lg:text-[3rem] xl:text-[60px] 2xl:text-[64px] 3xl:text-[78px] 4xl:text-[98px] !leading-[120%] sm:!leading-[100%] mb-3 lg:mb-10 xl:mb-16 uppercase">
          <span className="block">{bannerSection_heading1Text}</span>
          <span className="block">{bannerSection_heading2Text}</span>
        </h2>
        <hr
          className="w-full h-[2px] border-none rounded-[1px] mb-7 lg:mb-10 xl:mb-16"
          style={{ backgroundColor: color.tertiary }}
        />
        <div className="grid grid-cols-3 gap-4 justify-between">
          <div>
            <div className="flex items-center mb-3 lg:mb-4 2xl:mb-5 3xl:mb-6">
              {showPeopleText && (
                <h3 className="text-sm sm:text-[11px] md:text-[10px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px] leading-[89%] tracking-[-3%]">
                  {peopleData.prefix}
                </h3>
              )}
              <motion.h3 className="text-xl sm:text-[14px] md:text-[16px] lg:text-[32px] xl:text-[36px] 2xl:text-[43.2px] 3xl:text-[54px] 4xl:text-[64px] leading-[89%] tracking-[-3%]">
                {statsPeopleValue}
              </motion.h3>
              {showPeopleText && (
                <h3 className="text-[12px] sm:text-[7.7px] md:text-[10px] lg:text-[18px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px] leading-[89%] tracking-[-3%]">
                  {peopleData.suffix}
                </h3>
              )}
            </div>
            <p className="text-sm sm:text-[14px] lg:text-[18px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px] font-medium !leading-[1]">
              {bannerSection_statsPeopleLabel}
            </p>
          </div>
          {/* Animated Countries Stats */}
          <div>
            <div className="flex items-center mb-3 lg:mb-4 2xl:mb-5 3xl:mb-6">
              {showCountriesText && (
                <h3 className="text-sm sm:text-[11px] md:text-[10px] lg:text-[18px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px] leading-[89%] tracking-[-3%]">
                  {countriesData.prefix}
                </h3>
              )}
              <motion.h3 className="text-xl sm:text-[14px] md:text-[16px] lg:text-[32px] xl:text-[36px] 2xl:text-[43.2px] 3xl:text-[54px] 4xl:text-[64px] leading-[89%] tracking-[-3%]">
                {statsCountryValue}
              </motion.h3>
              {showCountriesText && (
                <h3 className="text-[12px] sm:text-[7.7px] md:text-[10px] lg:text-[18px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px] leading-[89%] tracking-[-3%]">
                  {countriesData.suffix}
                </h3>
              )}
            </div>

            <p className="text-sm sm:text-[14px] lg:text-[18px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px] font-medium !leading-[1]">
              {bannerSection_statsCountriesLabel}
            </p>
          </div>

          {/* Animated Years of Experience Stats */}
          <div>
            <div className="flex items-center mb-3 lg:mb-4 2xl:mb-5 3xl:mb-6">
              {showYearsText && (
                <h3 className="text-sm sm:text-[11px] md:text-[10px] lg:text-[18px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px] leading-[89%] tracking-[-3%]">
                  {yearsData.prefix}
                </h3>
              )}
              <motion.h3 className="text-xl sm:text-[14px] md:text-[16px] lg:text-[32px] xl:text-[36px] 2xl:text-[43.2px] 3xl:text-[54px] 4xl:text-[64px] leading-[89%] tracking-[-3%]">
                {statsYearsValue}
              </motion.h3>
              {showYearsText && (
                <h3 className="text-[12px] sm:text-[7.7px] md:text-[10px] lg:text-[18px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px] leading-[89%] tracking-[-3%]">
                  {yearsData.suffix}
                </h3>
              )}
            </div>

            <p className="text-sm sm:text-[14px] lg:text-[18px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px] font-medium !leading-[1]">
              {bannerSection_statsYearOfXPLabel}
            </p>
          </div>
        </div>
      </div>
      <div className="hidden sm:block w-[30%] sm:w-[50%] md:w-[55%] lg:w-[35%] h-full relative">
        <img
          src={bannerSection_serviceBgImage}
          alt={`${param}-banner`}
          className="absolute w-full top-0 right-0 h-[19rem] lg:h-full"
        />
      </div>
    </section>
  );
};

export default CommonTopBanner;
