import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setColor } from "../../store/reducers/ColorReducer";
import CommonTopBanner from "./CommonTopBanner";
import ParagraphSection from "./ParagraphSection";
import SwiperSlideCommon from "../SwiperSlideCommon";
import Achievements from "./Achievements";
import ProjectsShowSection from "../home/ProjectsShowSection";
import NewsEvents from "../home/NewsEvents";
import { clientUrls } from "../../constants/ClientUrls";
import OurServices from "../home/OurServices";
import OurPartners from "../home/OurPartners";
import { useNavigate, useParams } from "react-router-dom";
import { navigateTo } from "../../constants/GeneralConstants";
import FormatText from "../FormatText";
import Footer from "../Footer";
import ButtonSlant from "../ButtonSlant";

const LocalizationQA = ({ LocalizationQAdata }) => {
  const dispatch = useDispatch();
  const [isHover, setIsHover] = useState(false);
  const navigate = useNavigate();
  const { lang } = useParams();

  useEffect(() => {
    document.title = LocalizationQAdata?.servicePageData?.localizationQASEOTitle
      ? LocalizationQAdata?.servicePageData?.localizationQASEOTitle
      : "Top Localization QA Services for Games | Loc Testing Services | Side";
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  useEffect(() => {
    dispatch(
      setColor({
        primary: LocalizationQAdata?.serviceColors[0]?.colorCode,
        secondary: LocalizationQAdata?.serviceColors[0]?.secondaroyColor,
        tertiary: LocalizationQAdata?.serviceColors[0]?.tertiaryColor,
      })
    );
  }, [LocalizationQAdata, lang]);
  const {
    collaborateThings,
    servicePageData,
    achivements,
    ourClients,
    projects,
    serviceColors,
    subServices,
    services,
    labs,
    type,
  } = LocalizationQAdata;

  // console.log(LocalizationQAdata, "LocalizationQadata");

  const {
    bannerSection_heading1Text,
    bannerSection_heading2Text,
    fromTheLabText,
    visitTheLabText,
    howWeCollaborateWithYouText,
    topSection_getInTouchText,
    howWeCollaborateWithYouParagraph1Text,
    howWeCollaborateWithYouParagraph2Text,
  } = servicePageData;

  return (
    <>
      <section>
        <CommonTopBanner
          servicePageData={servicePageData}
          color={{
            primary: LocalizationQAdata?.serviceColors[0]?.colorCode,
            tertiary: LocalizationQAdata?.serviceColors[0]?.tertiaryColor,
          }}
        />

        <ParagraphSection
          servicePageData={servicePageData}
          color={{
            primary: LocalizationQAdata?.serviceColors[0]?.colorCode,
            tertiary: "#1a1a1a",
          }}
        />

        <div className="bg-no-repeat1 bg-right-top1 relative my-24 md:my-20 lg:my-32">
          <div className="absolute bottom-[45rem] xxs:bottom-[43rem] xs:bottom-[40rem] sm:bottom-[20rem] right-0 z-[-1] w-[14rem] xxs:w-[15rem] sm:w-[35rem] lg:w-[40rem]">
            <img src={servicePageData?.serviceTypeBgImage} alt="side-ptw" />
          </div>
          {subServices?.map((service, index) => {
            return (
              <SwiperSlideCommon
                service={service}
                key={index}
                color={{
                  primary: LocalizationQAdata?.serviceColors[0]?.colorCode,
                  tertiary: LocalizationQAdata?.serviceColors[0]?.tertiaryColor,
                }}
                showHeading={true}
              />
            );
          })}

          <section className="container text-white ">
            <div className="w-full sm:w-[98%] lg:w-[75%]">
              <h2
                className="uppercase text-[32px] lg:text-[38.5px] xl:text-[48px] 2xl:text-[57.6px] 3xl:text-[72px] 4xl:text-[86.4px] leading-[1] w-auto mb-4 sm:mb-6"
                style={{
                  color: LocalizationQAdata?.serviceColors[0]?.colorCode,
                }}
              >
                {howWeCollaborateWithYouText}
              </h2>
              <p className="mb-4 sm:mb-4 text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] leading-[120%] sm:font-medium">
                <FormatText text={howWeCollaborateWithYouParagraph1Text} />
              </p>
              <ul className="list-[square] pl-5">
                {collaborateThings?.map((item, meta_id) => (
                  <li
                    key={meta_id}
                    className="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px]"
                  >
                    {item?.collaborateWithYouListName}
                  </li>
                ))}
              </ul>
              <p className="mb-4 mt-4 sm:mb-10 text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] leading-[120%] sm:font-medium">
                {howWeCollaborateWithYouParagraph2Text}
              </p>

              <ButtonSlant
                slantClass="bottom-right-slant"
                divClass="w-full sm:w-fit"
                buttonClass="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] px-5 py-2.5 4xl:py-3 4xl:px-4 border-[3px] font-dela rounded-md w-full sm:w-fit "
                textColor={serviceColors[0]?.colorCode}
                textColorHover="#1a1a1a"
                bgColorHover={serviceColors[0]?.colorCode}
                borderColor={serviceColors[0]?.colorCode}
                buttonText={topSection_getInTouchText}
                onClick={(e) => navigate(navigateTo(clientUrls.contact))}
              />
            </div>
          </section>
        </div>

        {/* </div> */}

        <OurPartners
          homeData={servicePageData}
          clientsData={ourClients}
          lang={lang}
        />

        <div className="bg-no-repeatk bg-left-topk sm:-mt-20k lg:-mt-32k bg-containk relative">
          <div className="absolute top-[-5rem] left-[-14rem] sm:left-[-16rem] z-[-1]">
            <img
              src={servicePageData?.achivementBgImage}
              alt="side-ptw"
              className="w-[80%] sm:w-[55%]"
            />
          </div>
          <div className="hidden sm:block sm:h-36 lg:h-[13rem]"></div>
          <Achievements
            servicePageData={servicePageData}
            achievements={achivements}
            color={LocalizationQAdata?.serviceColors[0]?.colorCode}
            lang={lang}
          />
          <div className="my-24 sm:my-32  lg:my-[10rem]">
            <ProjectsShowSection
              homeData={servicePageData}
              projectsData={projects}
              colors={{
                color: LocalizationQAdata?.serviceColors[0]?.secondaroyColor,
              }}
              isBtnBottom={true}
              redirectionFrom={type}
            />
          </div>
        </div>

        {labs.length > 0 && (
          <NewsEvents
            header1={
              bannerSection_heading1Text + " " + bannerSection_heading2Text
            }
            header2={fromTheLabText}
            headerButtonText={visitTheLabText}
            cardsData={labs}
            colors={{
              color: LocalizationQAdata?.serviceColors[0]?.secondaroyColor,
              tertiaryColor:
                LocalizationQAdata?.serviceColors[0]?.tertiaryColor,
            }}
            url={clientUrls.labs}
            toLabs={true}
            type={type}
          />
        )}
        <OurServices
          servicesData={services}
          header1={servicePageData?.exploreText}
          header2={servicePageData?.moreText}
          colors={{
            color: LocalizationQAdata?.serviceColors[0]?.colorCode,
          }}
          lang={lang}
        />
      </section>
      <Footer />
    </>
  );
};

export default LocalizationQA;
