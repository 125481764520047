import React from "react";
import YoutubeVideo from "./YoutubeVideo";

const CommonYoutube = ({ url }) => {
  return (
    <div
      className={`container relative rounded-xl xs-only:!w-full h-[16rem] sm:h-[20rem] md:h-[23.5rem] lg:h-[29.2rem] xl:h-[35rem] 1.5xl:h-[37rem] 2xl:h-[39rem] 2.5xl:h-[42rem] 3xl:h-[48rem] 3.5xl:[52rem] 4xl:h-[60rem] mb-16 service-youtube-video ${
        url?.includes("vimeo") ? "pb-[39%] xl:pb-[47%] 2xl:pb-[50%]" : ""
      }`}
    >
      <YoutubeVideo url={url} className="w-full h-full absolute top-0 left-0" />
    </div>
  );
};

export default CommonYoutube;
