import parse from "html-react-parser";
import { Link, useLocation } from "react-router-dom";
import { transformContent } from "../constants/GeneralConstants";

export const languageCodes = ["zh-chs", "zh-cht", "ja", "ko", "fr-ca"];

const FormatText = ({ text }) => {
  const location = useLocation();

  if (typeof text !== "string") return null;

  const formattedText = transformContent(text).replace(/\r\n/g, "<br />");

  const currentDomain = window.location.origin;

  const pathSegments = location.pathname.split("/");
  const firstSegment = pathSegments[1];

  const isLangPrefix = languageCodes.includes(firstSegment);
  const languagePrefix = isLangPrefix ? `/${firstSegment}` : "";

  const options = {
    replace: (domNode) => {
      if (domNode.name === "a" && domNode.attribs && domNode.attribs.href) {
        let href = domNode.attribs.href;

        if (href.startsWith("http") && !href.startsWith(currentDomain)) {
          return (
            <a href={href} target="_blank" rel="noopener noreferrer">
              {domNode.children[0]?.data}
            </a>
          );
        }

        if (href.startsWith(currentDomain)) {
          href = href.replace(currentDomain, "");
        }

        if (href.startsWith("./")) {
          const basePath = location.pathname.substring(
            0,
            location.pathname.lastIndexOf("/")
          );
          href = `${basePath}/${href.substring(2)}`;
        }

        if (href.startsWith("/")) {
          const hrefSegments = href.split("/");
          const hrefFirstSegment = hrefSegments[1];

          if (languageCodes.includes(hrefFirstSegment)) {
            href = `/${hrefSegments.slice(2).join("/")}`;
          }

          href = `${languagePrefix}${href}`;
        }

        return <Link to={href}>{domNode.children[0]?.data}</Link>;
      }
    },
  };

  return <>{parse(formattedText, options)}</>;
};

export default FormatText;
