import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import {
  FormatTextFromGeneralConstants,
  navigateTo,
} from "../../constants/GeneralConstants";
import { clientUrls } from "../../constants/ClientUrls";
import { useNavigate } from "react-router-dom";
import ButtonSlant from "../ButtonSlant";
import { SlantImg } from "../CommonComponents";

const WeBelieve = ({ data }) => {
  const [btnHover, setBtnHover] = useState(false);
  const navigate = useNavigate();

  const {
    aboutusSection_aboutTitleText,
    aboutusSection_aboutusText,
    aboutusSection_aboutContentText,
    aboutusSection_aboutBgImage,
    aboutusSection_aboutBgImageTab,
    aboutusSection_aboutBgImageMob,
  } = data;

  const { ref: aboutDivRef, inView: isAboutDivInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <motion.div
      ref={aboutDivRef}
      className="relative container we-believe-height md:blue-div my-20 we-believe-clipk flex flex-col sm:flex-row justify-between items-center lg:mt-52 lg:mb-36 xl:mb-36 2xl:mb-40 overflow-hidden"
    >
      <div className="absolute top-[-1px] left-[-1px] z-[10] rotate-180">
        <SlantImg className="h-[60px] sm:h-[75px] lg:h-[80px]" />
      </div>
      {/* Diagonal Reveal Animation */}
      <motion.div
        initial={{
          clipPath: "polygon(69% 0px, 70% 0px, 31% 100%, 30% 100%)",
        }}
        animate={
          isAboutDivInView
            ? { clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" }
            : {}
        }
        transition={{ duration: 1, ease: "easeInOut" }}
        className="absolute bg-[#61e063] z-0 w-full h-full"
      ></motion.div>

      {/* Content Section */}
      <motion.div className="relative z-10 w-full sm:w-[80%] md:w-[70%] lg:w-[64%] h-[65%] sm:h-auto px-8 sm:px-9 md:px-16 py-[4.2rem] sm:pt-24 md:pt-16 lg:pb-20 lg:pt-28 xl:pt-24 2xl:py-0">
        {/* Header */}
        <motion.h2
          className="text-[#286233] !leading-[1] text-lg xxs:text-2xl lg:text-3xl xl:text-4xl 2xl:text-[40px] 3xl:text-[50px] 4xl:text-[60px] font-bold uppercase"
          initial={{ opacity: 0, y: 20 }}
          animate={isAboutDivInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, delay: 1.1 }}
        >
          {FormatTextFromGeneralConstants(aboutusSection_aboutTitleText)}
        </motion.h2>

        {/* Paragraph */}
        <motion.p
          className="content text-[#286233] py-4 leading-[120%] common-para-size font-medium"
          initial={{ opacity: 0, y: 20 }}
          animate={isAboutDivInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, delay: 1.4 }}
        >
          {FormatTextFromGeneralConstants(aboutusSection_aboutContentText)}
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={isAboutDivInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, delay: 1.7 }}
          className="w-max cursor-pointer"
        >
          <ButtonSlant
            buttonText={aboutusSection_aboutusText}
            buttonClass="border-[2px] border-[#286233] px-4 py-3 text-[#286233] uppercase font-dela  hover:border-[#286233]  rounded-md text-base sm:text-lg 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px]"
            slantClass="bottom-right-slant"
            bgColorHover="#286233"
            textColorHover="#61e063"
            onClick={(e) => navigate(navigateTo(clientUrls.about))}
          />
        </motion.div>
      </motion.div>

      {/* Image Section */}
      <motion.div
        className="hidden lg:block lg:w-[36%] we-believe-height relative z-10"
        initial={{ opacity: 0 }}
        animate={isAboutDivInView ? { opacity: 1 } : {}}
        transition={{ duration: 0.5, delay: 1.5 }}
      >
        <img
          src={aboutusSection_aboutBgImage}
          alt="side-ptw"
          className="h-full w-full"
        />
      </motion.div>
      <motion.div
        className="hidden sm:block lg:hidden sm:w-[auto] we-believe-height relative z-10"
        initial={{ opacity: 0 }}
        animate={isAboutDivInView ? { opacity: 1 } : {}}
        transition={{ duration: 0.5, delay: 1.5 }}
      >
        <img
          src={aboutusSection_aboutBgImageTab}
          alt="side-ptw"
          className="h-full w-full"
        />
      </motion.div>
      <motion.div
        className="block sm:hidden h-[35%] w-full relative z-10"
        initial={{ opacity: 0 }}
        animate={isAboutDivInView ? { opacity: 1 } : {}}
        transition={{ duration: 0.5, delay: 1.5 }}
      >
        <img
          src={aboutusSection_aboutBgImageMob}
          alt="side-ptw"
          className="w-full h-full"
        />
      </motion.div>
    </motion.div>
  );
};

export default WeBelieve;
