export const ArrowRight = ({ color, hover, hoverColor }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain ${
          hover ? "hidden" : "block"
        }`}
      >
        <path
          d="M13.75 11.875L17.5 8.125L13.75 4.375"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain  ${
          hover ? "block" : "hidden"
        }`}
      >
        <path
          d="M14.25 11.875L18 8.125L14.25 4.375"
          stroke={hoverColor ? hoverColor : "#1A1A1A"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 15.625C3 13.6359 3.79018 11.7282 5.1967 10.3217C6.60322 8.91518 8.51088 8.125 10.5 8.125H18"
          stroke={hoverColor ? hoverColor : "#1A1A1A"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export const ArrowLeft = ({ color, hover, hoverColor }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain ${
          hover ? "hidden" : "block"
        }`}
      >
        <path
          d="M6.25 11.875L2.5 8.125L6.25 4.375"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain  ${
          hover ? "block" : "hidden"
        }`}
      >
        <path
          d="M6.25 11.875L2.5 8.125L6.25 4.375"
          stroke={hoverColor ? hoverColor : "#1A1A1A"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
          stroke={hoverColor ? hoverColor : "#1A1A1A"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export const SlantImg = ({ className }) => {
  return (
    <img
      src="https://d1r5h1ay0no5pj.cloudfront.net/side/wp-content/uploads/2025/02/clip-path.svg"
      alt="ptw-side"
      className={className}
    />
  );
};
