import React, { useRef, useEffect, useState } from "react";
import {
  FormatTextFromGeneralConstants,
  getLanguage,
  navigateTo,
  truncate,
} from "../../constants/GeneralConstants";
import Buttons from "../Buttons";
import YoutubeVideo from "../YoutubeVideo";
import { clientUrls } from "../../constants/ClientUrls";
import "../../css/componentscss/Home/Lab.css";
import { useNavigate } from "react-router-dom";
import PIL from "../PIL";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ButtonSlant from "../ButtonSlant";

const LabCard = ({ lab, labsInView, isMobile, i, getServiceUrl }) => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const {
    labImage,
    labLink,
    labTitle,
    service,
    post_id,
    publishDate,
    labImageLowQuality,
    colorCode,
    tertiaryColor,
  } = lab;

  const { ref: cardRef, inView: isFirstCardInView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <motion.div
      className="group rounded-lg relative  sm:my-4 lg:my-0  flex flex-col  lab-card flex-shrink-0 cursor-pointer news-events-div"
      key={post_id}
      initial={{ y: -100, opacity: 0 }}
      animate={{
        y: (isMobile ? isFirstCardInView : labsInView) ? 0 : -100,
        opacity: (isMobile ? isFirstCardInView : labsInView) ? 1 : 0,
      }}
      transition={{
        duration: 0.2,
        delay: i * 0.2,
      }}
      onClick={(e) => navigate(navigateTo(`${clientUrls.labs}/${labLink}`))}
      onMouseEnter={(e) => {
        setHover(true);
      }}
      onMouseLeave={(e) => {
        setHover(false);
      }}
      onTouchStart={(e) => {
        setHover(true);
      }}
      onTouchEnd={(e) => {
        setHover(false);
      }}
      ref={cardRef}
    >
      {hover && (
        <div className="cursor-pointer absolute -top-[1px] -right-[1px] bg-[#1a1a1a] h-14 w-14  4xl:h-16 4xl:w-16 flex items-center justify-center z-10 rounded-bl-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#61e063"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#61e063"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
            />
          </svg>
        </div>
      )}

      <div className="w-full h-full relative ">
        <div className="w-full h-full relative lab-card-child ">
          <PIL
            className={`w-full h-auto object-fill rounded-t-lg ${
              hover ? "news-events-slant" : ""
            }`}
            alt="lab image"
            lowQaulitySrc={labImageLowQuality}
            highQualitySrc={labImage}
          />

          <div
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: hover ? colorCode : "#46464680",
            }}
            className="flex flex-col justify-between w-full h-[36%] sm:h-[45%] text-left items-start bg-[#46464680]  rounded-b-lg text-white p-3 backdrop-blur-[3px] mt-[2px] 2xl:mt-[3px]"
          >
            <div>
              <p
                className="font-normal text-lab-ldesc  mb-1 "
                style={{ color: hover ? "#1a1a1a" : "#dcdcdc" }}
              >
                {publishDate}
              </p>
              <h2
                className="font-18-18-18 !leading-[100%] sm:!leading-[120%] xl:!leading-[100%] line-clamp-3 sm:line-clamp-2 lg:line-clamp-2 xl:line-clamp-3 word-break break-words text-wrap"
                style={{ color: hover ? tertiaryColor : "#ffffff" }}
              >
                {truncate(labTitle, 75)}
              </h2>
            </div>

            <Buttons
              className="font-normal text-lab-lbutton border-[2px] border-[#bec0c5] text-[#bec0c5] rounded-md px-2 py-1 group-hover:text-black group-hover:border-black group-hover:font-normal"
              buttonText={service}
              onClick={(e) =>
                navigate(
                  navigateTo(`${clientUrls.services}/${getServiceUrl(service)}`)
                )
              }
              style={{
                color: hover ? "#1a1a1a" : "#bec0c5",
                borderColor: hover ? "#1a1a1a" : "#bec0c5",
              }}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};
const Lab = ({
  homeData,
  labs,
  labsVideo,
  services,
  onPlay,
  playingVideo,
  colors,
}) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { ref: ref, inView: isInView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const { ref: articleRef, inView: isArticleInView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const { ref: videoTextRef, inView: isVideoInView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const { ref: labsRef, inView: labsInView } = useInView({
    triggerOnce: true,
    threshold: 0.7,
  });

  const { ref: videoRef, inView: videoInView } = useInView({
    triggerOnce: true,
    threshold: 0.8,
  });

  const pullupVariant = {
    initial: { y: 20, opacity: 0 },
    animate: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.3,
      },
    }),
  };

  const pullupVariantForDesc = {
    initial: { y: 20, opacity: 0 },
    animate: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.5,
      },
    }),
  };

  const getServiceUrl = (param) => {
    const service = services.find((service) => service.serviceName === param);
    return service ? service.serviceLink : null;
  };

  const {
    labSection_fromText,
    labSection_theLabText,
    labSection_labSectionContent,
    labSection_articlesText,
    viewAllText,
    labSection_videosText,
    labSection_visitOurLabText,
    labSection_labBgImage,
  } = homeData;

  return (
    <div>
      <div className="container mx-auto">
        <motion.h2
          className="ont-extrabold page-main-header-size leading-[1] uppercase  text-[#61e063]"
          ref={ref}
          variants={pullupVariant}
          initial="initial"
          animate={isInView ? "animate" : ""}
          transition={{ duration: 0.5 }}
        >
          <motion.span
            key={1}
            variants={pullupVariant}
            custom={1}
            className="block"
          >
            {labSection_fromText}
          </motion.span>
          <motion.span
            key={2}
            variants={pullupVariant}
            custom={2}
            className="block"
          >
            {labSection_theLabText}
          </motion.span>
        </motion.h2>
        <motion.p
          ref={ref}
          className="content text-white my-4 text-lab-ldesc font-medium lab-c-width leading-[120%]"
          variants={pullupVariantForDesc}
          initial="initial"
          animate={isInView ? "animate" : ""}
          transition={{ delay: 0.9, duration: 0.5 }}
        >
          <motion.span variants={pullupVariantForDesc} className="inline-block">
            {FormatTextFromGeneralConstants(labSection_labSectionContent)}
          </motion.span>
        </motion.p>
      </div>
      <div className="bg-d-green relative">
        <div className="hidden sm:block absolute left-0 bottom-0 -z-[1] sm:w-[33rem] md:w-[35rem] lg:w-[40rem] 1.5xl:w-[53rem] xl:w-[46rem] 2xl:w-[55rem] 3xl:w-[55vw] 3.3xl:w-[52vw] 3.5xl:w-[52vw] 3.7xl:w-[50vw] 4xl:w-[49vw]">
          <img
            src={labSection_labBgImage}
            alt="side-ptw"
            className="w-full h-full"
          />
        </div>
        <div className="container mx-auto">
          <div className="lab-c-width">
            <div className="sm:flex justify-between items-center mb-4 lg:mb-12 mt-12">
              <motion.h2
                className="text-lab-lheader font-normal text-white uppercase"
                ref={articleRef}
                variants={pullupVariantForDesc}
                initial="initial"
                animate={isArticleInView ? "animate" : ""}
                transition={{ duration: 0.5 }}
              >
                <motion.span
                  key={1}
                  variants={pullupVariantForDesc}
                  className="block"
                >
                  {labSection_articlesText}
                </motion.span>
              </motion.h2>

              <ButtonSlant
                buttonClass="font-bold border-[2px] px-6 py-2 2xl:py-[0.6rem] 3xl:py-3 3xl:px-7 rounded-md hidden sm:block font-dela font-18-18-18"
                slantClass="bottom-right-slant-sm"
                divClass="z-[2]"
                borderColor={colors.color}
                textColor={colors.color}
                textColorHover={colors.tertiary}
                bgColorHover={colors.color}
                buttonText={viewAllText}
                onClick={(e) => navigate(navigateTo(clientUrls.labs))}
              />
            </div>

            <div
              className="flex flex-col sm:flex-row sm:overflow-visible flex-nowrap  gap-4 md:gap-8  xl:gap-12 2xl:gap-16 justify-between items-center sm:mb-10 lg:mb-12 hide-scroll"
              ref={labsRef}
            >
              {labs.map((lab, i) => {
                return (
                  <LabCard
                    isMobile={isMobile}
                    lab={lab}
                    labsInView={labsInView}
                    getServiceUrl={getServiceUrl}
                    i={i}
                  />
                );
              })}
            </div>
          </div>
          {/* <div ref={labsRef}></div> */}
        </div>
        {/* form the lab ends here  */}
        {/* videos starts here  */}
        {labsVideo?.map((labmb, i) => {
          const {
            post_id,
            labTitle,
            service,
            publishDate,
            videoURL,
            colorCode,
            labLink,
          } = labmb;
          return (
            <div
              className="container mx-auto  lg:mb-16 xl:mb-28 2xl:mb-32 mt-8 lg:mt-20 xl:mt-20 lab-card-video"
              key={post_id}
            >
              {getLanguage() != "zh-chs" &&
                videoURL?.includes("youtube.com") && (
                  <div className="w-full lg:w-[68%] ml-auto">
                    <div className="sm:flex justify-between items-center mb-6 ">
                      <motion.h2
                        className="common-title-size font-normal text-white uppercase"
                        ref={videoTextRef}
                        variants={pullupVariantForDesc}
                        initial="initial"
                        animate={isVideoInView ? "animate" : ""}
                        transition={{ duration: 0.7 }}
                      >
                        <motion.span
                          key={1}
                          variants={pullupVariantForDesc}
                          className="block"
                        >
                          {labSection_videosText}
                        </motion.span>
                      </motion.h2>

                      <ButtonSlant
                        buttonClass="font-bold border-[2px] px-6 py-2 2xl:py-[0.6rem] 3xl:py-3 3xl:px-7 rounded-md hidden sm:block font-dela font-18-18-18"
                        divClass=" z-[2]"
                        slantClass="bottom-right-slant-sm"
                        borderColor={colors.color}
                        textColor={colors.color}
                        textColorHover={colors.tertiary}
                        bgColorHover={colors.color}
                        buttonText={viewAllText}
                        onClick={(e) =>
                          navigate(navigateTo(clientUrls.labs), {
                            state: { videos: true },
                          })
                        }
                      />
                    </div>
                    <motion.div
                      initial={{ y: -100, opacity: 0 }}
                      animate={{
                        y: videoInView ? 0 : -100,
                        opacity: videoInView ? 1 : 0,
                      }}
                      transition={{
                        duration: 0.2,
                      }}
                    >
                      <div
                        className="w-full video-responsive relative  flex flex-col"
                        ref={videoRef}
                      >
                        <div className="relative w-full lab-video-height">
                          <YoutubeVideo
                            videoId="LabYoutubeVideoRenderingInsideTheHomePage"
                            onPlay={() =>
                              onPlay(
                                "LabYoutubeVideoRenderingInsideTheHomePage"
                              )
                            }
                            isPlaying={playingVideo}
                            url={videoURL}
                            className="xs-only:!w-full mb-24 sm:mb-28 lg:mb-40 !h-full rounded-3xl"
                          />
                        </div>
                        <div
                          className="w-full z-50 cursor-pointer bg-[#46464680] rounded-br-[10px] rounded-bl-[10px]  p-2 sm:p-3 backdrop-blur-sm"
                          id={`lab-card-video-${i}`}
                        >
                          <h2 className="common-para-size leading-[1] text-white tracking-[0.15px] sm:tracking-normal">
                            {labTitle}
                          </h2>
                          <button className="font-normal common-button-secondary-size border-[2px] border-gray-400 rounded-md px-2 py-1 mt-3 text-[#DCDCDC]">
                            {service}
                          </button>
                        </div>
                      </div>
                      <div className=" text-center lg:text-left lg:hidden my-5">
                        <ButtonSlant
                          buttonClass="border-[#61e063]  border-[2px] py-[0.75rem] px-[3rem] rounded-[4px] uppercase w-full sm:hidden lg:block bg-[#1a1a1a] font-dela"
                          slantClass="bottom-right-slant"
                          textColor="#61e063"
                          textColorHover="#1a1a1a"
                          bgColorHover="#61e063"
                          buttonText={labSection_visitOurLabText}
                          onClick={(e) => navigate(navigateTo(clientUrls.labs))}
                        />
                      </div>
                    </motion.div>
                    {/* <div ref={videoRef}></div> */}
                  </div>
                )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Lab;
