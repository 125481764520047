import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../css/componentscss/SwiperSlide.css";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css/mousewheel"; // Import Swiper's mousewheel CSS
const ContactAccordion = ({ contactData, locations }) => {
  const [openIndex, setOpenIndex] = useState(0);
  const [imageIndices, setImageIndices] = useState({});
  const [buttonVisibility, setButtonVisibility] = useState({});
  const [scrollLocation, setScrollLocation] = useState(null);
  const accordianRef = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);
  const swipeTimerRef = useRef(null);

  useEffect(() => {
    locations?.forEach((location, regionIndex) => {
      sortLoctions(location?.subLocations)?.forEach(
        (subLocation, subLocationIndex) => {
          updateButtonVisibility(
            regionIndex,
            subLocationIndex,
            subLocation.locationImage
          );
        }
      );
    });
  }, [locations]);
  const findParentIndex = (locationName) => {
    return locations?.findIndex((region) =>
      region.subLocations.some(
        (subLocation) =>
          subLocation.locationName?.toLowerCase() === locationName
      )
    );
  };

  function findLocationIndex(regionData, locationName) {
    const lowerCaseLocationName = locationName.toLowerCase();
    for (let region of regionData) {
      for (let i = 0; i < region.subLocations.length; i++) {
        if (
          region.subLocations[i].locationName.toLowerCase() ===
          lowerCaseLocationName
        ) {
          return i;
        }
      }
    }
    return -1;
  }
  const findRegionName = (regionData, locationName) => {
    const lowerCaseLocationName = locationName.toLowerCase();
    for (let region of regionData) {
      for (let subLocation of region.subLocations) {
        if (subLocation.locationName.toLowerCase() === lowerCaseLocationName) {
          return region.regionName;
        }
      }
    }
    return null;
  };
  useEffect(() => {
    const scrollTo = sessionStorage.getItem("scrollTo");
    if (scrollTo) {
      setScrollLocation(scrollTo);
    }
    if (scrollTo) {
      const parentIndex = findParentIndex(scrollTo);
      const locationIndex = findLocationIndex(locations, scrollTo);
      const regionName = findRegionName(locations, scrollTo);

      setOpenIndex(parentIndex);
      const initializeSwiper = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
          // Ensure Swiper is initialized before sliding
          swiperRef.current.swiper.update(); // Update Swiper layout
          swiperRef.current.swiper.slideTo(locationIndex, 800); // 800 matches your speed prop
          setActiveIndex(locationIndex);
        } else {
          // Retry if Swiper isn't ready
          setTimeout(initializeSwiper, 300);
        }
      };
      setTimeout(() => {
        const headerOffset = 100;

        const element = document.getElementById(scrollTo);
        const elementMobile = document.getElementById(regionName);

        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - headerOffset;
        if (element || elementMobile) {
          if (window.innerWidth < 640) {
            elementMobile?.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          } else {
            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            });
          }
        }
        initializeSwiper();
      }, 300);
    }
    return () => {
      sessionStorage.removeItem("scrollTo");
    };
  }, []);

  useEffect(() => {
    Object?.keys(imageIndices)?.forEach((regionIndex) => {
      Object?.keys(imageIndices[regionIndex])?.forEach((subLocationIndex) => {
        const locationImages =
          locations[regionIndex]?.subLocations[subLocationIndex]
            ?.locationImage || [];
        updateButtonVisibility(regionIndex, subLocationIndex, locationImages);
      });
    });
  }, [imageIndices, locations]);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const updateButtonVisibility = (
    regionIndex,
    subLocationIndex,
    locationImages
  ) => {
    const currentIndex = imageIndices[regionIndex]?.[subLocationIndex] || 0;
    setButtonVisibility((prevVisibility) => ({
      ...prevVisibility,
      [regionIndex]: {
        ...prevVisibility[regionIndex],
        [subLocationIndex]: {
          prev: currentIndex > 0,
          next: currentIndex < locationImages.length - 1,
        },
      },
    }));
  };

  const handlePrevClick = (regionIndex, subLocationIndex, locationImages) => {
    setImageIndices((prevIndices) => {
      const currentIndex = prevIndices[regionIndex]?.[subLocationIndex] || 0;
      const newIndex = Math.max(currentIndex - 1, 0);
      return {
        ...prevIndices,
        [regionIndex]: {
          ...prevIndices[regionIndex],
          [subLocationIndex]: newIndex,
        },
      };
    });
  };
  /*----------------swiper prev next for mobile start--------------    */
  const handlePrev = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      setActiveIndex((prevIndex) => prevIndex - 1);

      swiper.slidePrev();
    }
  };
  const handleNext = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      setActiveIndex((prevIndex) => prevIndex + 1);
      swiper.slideNext();
    }
  };
  const forceRepaint = () => {
    if (swipeTimerRef.current) {
      clearTimeout(swipeTimerRef.current);
    }
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      swipeTimerRef.current = setTimeout(() => {
        swiper?.update();
      }, 900);
    }
  };
  const scrollToRef = (ref) => {
    let ele = ref.current;
    if (ele != null) {
      const headerOffset = -200;

      const elementPosition = ele.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      const outerTimeOut = setTimeout(() => {
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
        const innerTimeout = setTimeout(() => {
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
          clearTimeout(innerTimeout);
        }, 400);
        clearTimeout(outerTimeOut);
      }, 0);
    }
  };
  useEffect(() => {
    forceRepaint();
  }, [activeIndex]);
  /*----------------swiper prev next for mobile end--------------    */

  const handleNextClick = (regionIndex, subLocationIndex, locationImages) => {
    setImageIndices((prevIndices) => {
      const currentIndex = prevIndices[regionIndex]?.[subLocationIndex] || 0;
      const newIndex = Math.min(currentIndex + 1, locationImages.length - 1);
      return {
        ...prevIndices,
        [regionIndex]: {
          ...prevIndices[regionIndex],
          [subLocationIndex]: newIndex,
        },
      };
    });
  };

  const shouldShowArrows = (locationImages) => locationImages?.length > 1;
  const scrollAccordions = () => {
    scrollToRef(accordianRef);
  };
  const sortLoctions = (arr) => {
    return arr[0]?.locationOrder
      ? arr.sort((a, b) => a.locationOrder - b.locationOrder)
      : arr;
  };
  return (
    <section className="accordions" id="accordions" ref={accordianRef}>
      <div className="container mb-20 sm:mb-28 lg:mb-40">
        <h2 className="font-36-48-72 leading-[1] text-white mb-4 sm:mb-8 lg:mb-16">
          {contactData?.ourStudiosText}
        </h2>
        {locations?.map((location, regionIndex) => (
          <div key={regionIndex} id={location?.regionName}>
            <div
              className="flex text-white items-center"
              id={location?.regionName}
            >
              <h3 className="text-[24px] 2xl:text-[28.8px] 3xl:text-[36px] leading-[1] sm:leading-[100%]">
                {location?.regionName}
              </h3>

              <button
                className={`w-[40px] h-[40px] rounded-md pl-2 mx-4 border-2 ${
                  openIndex === regionIndex
                    ? "border-[#FFF647] bg-transparent"
                    : "bg-[#FFF647] border-transparent"
                }`}
                onClick={() => {
                  scrollAccordions();
                  toggleAccordion(regionIndex);
                  setActiveIndex(0);
                }}
              >
                {openIndex === regionIndex ? (
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.625 6.25L12.375 2.5L16.125 6.25"
                      stroke="#FFF647"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />

                    <path
                      d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                      stroke="#FFF647"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.625 13.75L12.375 17.5L16.125 13.75"
                      stroke="#1A1A1A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />

                    <path
                      d="M4.875 2.5C6.86412 2.5 8.77178 3.29018 10.1783 4.6967C11.5848 6.10322 12.375 8.01088 12.375 10L12.375 17.5"
                      stroke="#1A1A1A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </button>
            </div>
            <hr className="my-6 border-[#464646]" />
            {openIndex === regionIndex && (
              <div>
                {/* Desktop cards start here */}
                <div className="hidden lg:grid grid-cols-1 sm:grid-cols-2 gap-x-12 gap-y-4">
                  {sortLoctions(location?.subLocations)
                    ?.filter(
                      (loc, index, self) =>
                        index ===
                        self.findIndex(
                          (subloc) =>
                            subloc.locationName === loc.locationName &&
                            subloc.locationAddress === loc.locationAddress // Ensures unique locations
                        )
                    )
                    .map((loc, index) => {
                      // Use index here for correct key
                      const reversedImages = [...loc.locationImage].reverse();
                      const currentIndex =
                        imageIndices[regionIndex]?.[index] || 0;
                      const showArrows = shouldShowArrows(reversedImages);
                      const prevDisabled =
                        !buttonVisibility[regionIndex]?.[index]?.prev;

                      const nextDisabled =
                        !buttonVisibility[regionIndex]?.[index]?.next;
                      console.log(
                        "console-------",
                        loc.locationName,
                        showArrows,
                        prevDisabled,
                        nextDisabled,
                        buttonVisibility
                      );
                      return (
                        <div
                          className="contact-accordion"
                          key={`${loc.locationName}-${index}`} // Unique key to prevent React errors
                          id={loc?.locationName?.toLowerCase()}
                        >
                          <div className="rounded-[10px] w-full">
                            <div className="relative">
                              <img
                                src={reversedImages[currentIndex]}
                                className="h-[13rem]k sm:h-full w-full object-fill rounded-[10px]"
                                alt="side-ptw"
                              />
                              {showArrows && (
                                <div className="hidden absolute right-0 bottom-0 py-[0.5rem] px-[0.5rem] w-full lg:w-fit text-white sm:flex rounded-[10px] z-[2] justify-end">
                                  <div className="flex gap-[0.5rem]">
                                    <button
                                      onClick={() =>
                                        handlePrevClick(
                                          regionIndex,
                                          index,
                                          reversedImages
                                        )
                                      }
                                      className={`w-[40px] h-[40px] rounded-md pl-2 ${
                                        prevDisabled
                                          ? "bg-[#FFF647] opacity-50 cursor-not-allowed"
                                          : "bg-[#FFF647] opacity-100"
                                      }`}
                                      disabled={prevDisabled}
                                    >
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 2xl:w-6 2xl:h-6 object-contain"
                                      >
                                        <path
                                          d="M6.25 11.875L2.5 8.125L6.25 4.375"
                                          stroke="#1a1a1a"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                        <path
                                          d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                                          stroke="#1a1a1a"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                      </svg>
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleNextClick(
                                          regionIndex,
                                          index,
                                          reversedImages
                                        )
                                      }
                                      className={`w-[40px] h-[40px] rounded-md pl-2 ${
                                        nextDisabled
                                          ? "bg-[#FFF647] opacity-50 cursor-not-allowed"
                                          : "bg-[#FFF647] opacity-100"
                                      }`}
                                      disabled={nextDisabled}
                                    >
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 2xl:w-6 2xl:h-6 object-contain"
                                      >
                                        <path
                                          d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                          stroke="#1a1a1a"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                        <path
                                          d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                          stroke="#1a1a1a"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="my-6">
                              <h3 className="text-[24px] 1.5xl:text-[26px] 2xl:text-[28px] 2.5xl:text-[32px] 3xl:text-[36px]  3.5xl:text-[38px] 4xl:text-[43px] text-white">
                                {loc?.locationName}
                              </h3>
                              <p className="text-[#7E818A] text-[20px] 1.5xl:text-[22px] 2xl:text-[24px] 2.5xl:text-[26px] 3xl:text-[29px]  3.5xl:text-[30px] 4xl:text-[34px] my-4">
                                {loc?.locationAddress}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>

                {/* Desktop cards ends here */}

                {/* mobile cards start here */}
                <div>
                  <div
                    id="scrollContainer"
                    className="flex mb-2 overflow-x-auto gap-x-6 lg:hidden hide-scroll overflow-y-hidden"
                  >
                    {location?.subLocations?.length > 1 ? (
                      <Swiper
                        loop={false}
                        breakpoints={{
                          320: {
                            slidesPerView: 1.1,
                            spaceBetween: 10,
                          },
                          640: {
                            slidesPerView: 1.7,
                            spaceBetween: 20,
                          },
                        }}
                        ref={swiperRef}
                        speed={800}
                        effect="fade"
                        allowSlideNext={
                          activeIndex !== location?.subLocations?.length - 1
                        }
                        allowSlidePrev={activeIndex !== 0}
                        onSlideChange={(swiper) => {
                          setActiveIndex(swiper.activeIndex);
                        }}
                      >
                        {sortLoctions(location?.subLocations).map(
                          (loc, subLocationIndex) => {
                            const reversedImages = [
                              ...loc.locationImage,
                            ].reverse();
                            const currentIndex =
                              imageIndices[regionIndex]?.[subLocationIndex] ||
                              0;
                            const showArrows = shouldShowArrows(reversedImages);
                            const prevDisabled =
                              !buttonVisibility[regionIndex]?.[subLocationIndex]
                                ?.prev;
                            const nextDisabled =
                              !buttonVisibility[regionIndex]?.[subLocationIndex]
                                ?.next;
                            return (
                              <SwiperSlide
                                key={subLocationIndex}
                                className="group rounded-lg relative my-4 events-card flex flex-col flex-shrink-0"
                                id={loc?.locationName?.toLowerCase() + "mobile"}
                              >
                                <div className="rounded-[10px]">
                                  <div className="relative">
                                    <img
                                      src={reversedImages[currentIndex]}
                                      className="h-[13rem]k sm:h-full w-full object-fill rounded-[10px]"
                                      alt="side-ptw"
                                    />
                                    {showArrows && (
                                      <div className=" absolute right-0 bottom-0 py-[0.5rem] px-[0.5rem] w-full lg:w-fit text-white flex rounded-[10px] z-[2] justify-end">
                                        <div className="flex gap-[0.5rem]">
                                          <button
                                            onClick={() =>
                                              handlePrevClick(
                                                regionIndex,
                                                subLocationIndex,
                                                reversedImages
                                              )
                                            }
                                            className={`w-10 h-10 sm:w-[40px] sm:h-[40px] rounded-md  flex justify-center items-center ${
                                              prevDisabled
                                                ? "bg-[#FFF647] opacity-50 cursor-not-allowed"
                                                : "bg-[#FFF647] opacity-100"
                                            }`}
                                            disabled={prevDisabled}
                                          >
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="h-5 w-5 sm:h-6 sm:w-6 2xl:w-6 2xl:h-6 object-contain"
                                            >
                                              <path
                                                d="M6.25 11.875L2.5 8.125L6.25 4.375"
                                                stroke="#1a1a1a"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                              <path
                                                d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                                                stroke="#1a1a1a"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                            </svg>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleNextClick(
                                                regionIndex,
                                                subLocationIndex,
                                                reversedImages
                                              )
                                            }
                                            className={`w-10 h-10 sm:w-[40px] sm:h-[40px] rounded-md flex justify-center items-center ${
                                              nextDisabled
                                                ? "bg-[#FFF647] opacity-50 cursor-not-allowed"
                                                : "bg-[#FFF647] opacity-100"
                                            }`}
                                            disabled={nextDisabled}
                                          >
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="h-5 w-5 sm:h-6 sm:w-6 2xl:w-6 2xl:h-6 object-contain"
                                            >
                                              <path
                                                d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                                stroke="#1a1a1a"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                              <path
                                                d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                                stroke="#1a1a1a"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="my-6">
                                    <h3 className="text-base xs:text-xl 2xl:text-2xl text-white">
                                      {loc?.locationName}
                                    </h3>
                                    <p className="text-[#7E818A] text-[18px] my-4">
                                      {loc?.locationAddress}
                                    </p>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          }
                        )}
                      </Swiper>
                    ) : (
                      location?.subLocations.map((loc, subLocationIndex) => {
                        const reversedImages = [...loc.locationImage].reverse();
                        const currentIndex =
                          imageIndices[regionIndex]?.[subLocationIndex] || 0;
                        const showArrows = shouldShowArrows(reversedImages);
                        const prevDisabled =
                          !buttonVisibility[regionIndex]?.[subLocationIndex]
                            ?.prev;
                        const nextDisabled =
                          !buttonVisibility[regionIndex]?.[subLocationIndex]
                            ?.next;
                        return (
                          <div
                            key={subLocationIndex}
                            className="group rounded-lg relative my-4 events-card flex flex-col flex-shrink-0"
                            id={loc?.locationName?.toLowerCase() + "mobile"}
                          >
                            <div className="rounded-[10px]">
                              <div className="relative">
                                <img
                                  src={reversedImages[currentIndex]}
                                  className="h-[13rem]k sm:h-full w-full object-fill rounded-[10px]"
                                  alt="side-ptw"
                                />
                                {showArrows && (
                                  <div className="hidden absolute right-0 bottom-0 py-[0.5rem] px-[0.5rem] w-full lg:w-fit text-white sm:flex rounded-[10px] z-[2] justify-end">
                                    <div className="flex gap-[0.5rem]">
                                      <button
                                        onClick={() =>
                                          handlePrevClick(
                                            regionIndex,
                                            subLocationIndex,
                                            reversedImages
                                          )
                                        }
                                        className={`w-[40px] h-[40px] rounded-md pl-2 ${
                                          prevDisabled
                                            ? "bg-[#FFF647] opacity-50 cursor-not-allowed"
                                            : "bg-[#FFF647] opacity-100"
                                        }`}
                                        disabled={prevDisabled}
                                      >
                                        <svg
                                          width="18"
                                          height="18"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-6 w-6 2xl:w-6 2xl:h-6 object-contain"
                                        >
                                          <path
                                            d="M6.25 11.875L2.5 8.125L6.25 4.375"
                                            stroke="#1a1a1a"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></path>
                                          <path
                                            d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                                            stroke="#1a1a1a"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></path>
                                        </svg>
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleNextClick(
                                            regionIndex,
                                            subLocationIndex,
                                            reversedImages
                                          )
                                        }
                                        className={`w-[40px] h-[40px] rounded-md pl-2 ${
                                          nextDisabled
                                            ? "bg-[#FFF647] opacity-50 cursor-not-allowed"
                                            : "bg-[#FFF647] opacity-100"
                                        }`}
                                        disabled={nextDisabled}
                                      >
                                        <svg
                                          width="18"
                                          height="18"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-6 w-6 2xl:w-6 2xl:h-6 object-contain"
                                        >
                                          <path
                                            d="M13.75 11.875L17.5 8.125L13.75 4.375"
                                            stroke="#1a1a1a"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></path>
                                          <path
                                            d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                                            stroke="#1a1a1a"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></path>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="my-6">
                                <h3 className="text-base xs:text-xl 2xl:text-2xl text-white">
                                  {loc?.locationName}
                                </h3>
                                <p className="text-[#7E818A] text-[18px] my-4">
                                  {loc?.locationAddress}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                  {location?.subLocations.length > 1 && (
                    <div className="flex sm:hidden mt-8 mb-8 gap-[0.5rem] justify-end">
                      <button
                        onClick={handlePrev}
                        disabled={activeIndex === 0}
                        id="prevButton"
                        className={`w-[40px] h-[40px] rounded-md pl-2 border-[#FFF647] border-[2px] ${
                          activeIndex === 0
                            ? "cursor-not-allowed opacity-50"
                            : ""
                        }`}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain"
                        >
                          <path
                            d="M6.25 11.875L2.5 8.125L6.25 4.375"
                            stroke="#FFF647"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                            stroke="#FFF647"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </button>
                      <button
                        onClick={handleNext}
                        disabled={
                          activeIndex === location?.subLocations?.length - 1
                        }
                        id="nextButton"
                        className={`w-[40px] h-[40px] rounded-md pl-2 border-[#FFF647] border-[2px] ${
                          activeIndex === location?.subLocations?.length - 1
                            ? "cursor-not-allowed opacity-50"
                            : ""
                        }`}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain"
                        >
                          <path
                            d="M13.75 11.875L17.5 8.125L13.75 4.375"
                            stroke="#FFF647"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                            stroke="#FFF647"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  )}
                </div>

                {/* mobile cards end here */}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default ContactAccordion;
