import { clientUrls } from "../../constants/ClientUrls";
import { navigateTo, truncate } from "../../constants/GeneralConstants";
import PIL from "../PIL";
import { useNavigate } from "react-router-dom";

const VideoCard = ({ news }) => {
  const navigate = useNavigate();
  return (
    <section>
      <div
        className="my-4 cursor-pointer group news-hover-slant-parent"
        onClick={(e) =>
          navigate(navigateTo(`${clientUrls.news}/${news.newsURL}`))
        }
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4  news-hover-slant">
          <div className="img-partk flex items-center w-full h-full relative">
            <div className="hidden cursor-pointer absolute top-0 right-0 bg-[#1a1a1a] h-12 w-12 4xl:h-20 4xl:w-20 group-hover:flex items-center justify-center z-[3] rounded-bl-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#FF8200"
                viewBox="0 0 24 24"
                stroke-width="3"
                stroke="#FF8200"
                className="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                ></path>
              </svg>
            </div>
            {/* <img
            src="/images/event-card.png"
            alt="Celebrating 30 Years of PTW: Our Story"
            className="object-fill w-full h-full absolute top-0 left-0 transition-all duration-500 ease-in z-[2] blur-0"
          ></img> */}
            {/* <div className="relative"> */}
            {/* desktop img  */}
            <PIL
              lowQaulitySrc={news.newsImageLowQuality}
              highQualitySrc={news.newsImageDesktop}
              className="object-fill w-full h-auto rounded-md hidden sm:block"
              alt={news.title}
            />
            {/* mobile img  */}
            <PIL
              lowQaulitySrc={news.newsImageLowQuality}
              highQualitySrc={news.newsImageMob}
              className="object-fill w-full h-auto rounded-md sm:hidden"
              alt={news.title}
            />
            {/* </div> */}
            {/* <div className="hidden cursor-pointer absolute top-0 right-0 bg-[#1a1a1a] h-12 w-12 4xl:h-20 4xl:w-20 group-hover:flex items-center justify-center z-[3] rounded-bl-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#FF8200"
                viewBox="0 0 24 24"
                stroke-width="3"
                stroke="#FF8200"
                className="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                ></path>
              </svg>
            </div> */}
          </div>
          <div className="justify-between flex flex-col">
            <div className="flex gap-4 text-[14px] 1.5xl:text-[16px] 2xl:text-[18.6px] 3xl:text-[24px] 4xl:text-[28px]">
              <p className="text-[#FF8200]">{news?.eventDateRange}</p>
              {/* <span className="text-[#FF8200]">-</span>
              <p className="text-[#FF8200]">{news?.eventEndDate}</p> */}
            </div>
            <p className="text-[#fff] font-18-20-20 leading-[130%] font-bold line-clamp-2 break-keep">
              {news?.title}
            </p>
            <p className="text-[#FFFFFF] text-[16px] 1.5xl:text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32px] text-wrap break-words leading-[130%] lg-only:line-clamp-3">
              {truncate(news.newsShortDescription, 150)}
            </p>
          </div>
        </div>
        {/* video ui  */}
        <div className="hidden">
          <div className="rounded-[10px] w-full  my-4 group news-hover-slant-parent">
            <div className="grid sm:grid-cols-2 lg:grid-cols-1 gap-x-4 gap-y-4 news-hover-slant-parent">
              <div className="img-partk relative  ">
                {/* <PIL
          lowQaulitySrc={news.newsImageLowQuality}
          highQualitySrc={news.newsImage}
          className="object-cover h-full"
          alt={news.title}
        /> */}
                <img
                  src="/images/event-card.png"
                  alt="ptw-side"
                  className="w-full"
                />
                <div className=" cursor-pointer absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:flex items-center justify-center z-[3]">
                  {/* <a href=""> */}
                  <svg
                    className="h-[4.5rem] w-[4.5rem] lg:h-[6rem] lg:w-[6rem] text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="0.5"
                      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="0.5"
                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  {/* </a> */}
                </div>
                <div className="hidden cursor-pointer absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:flex items-center justify-center z-[3]">
                  {/* <a href=""> */}
                  <svg
                    className="h-[4.5rem] w-[4.5rem] lg:h-[6rem] lg:w-[6rem] text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke="#ff8200"
                      fill="#1a1a1a"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="0.5"
                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                    <path
                      stroke="#ff8200"
                      fill="#ff8200"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="0.5"
                      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                    />
                  </svg>
                  {/* </a> */}
                </div>
                {/* <a href="z-[10]">
          <svg
            className="h-[4.5rem] w-[4.5rem] lg:h-[6rem] lg:w-[6rem] text-white absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.5"
              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.5"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </a> */}
              </div>
              <div className="event-content">
                <div className="flex gap-4 text-[14px] 1.5xl:text-[16px] 2xl:text-[18.6px] 3xl:text-[24px] 4xl:text-[28px]">
                  <p className="text-[#FF8200]">{news?.publishedDate}</p>
                  {/* <p className="text-[#efefef] uppercase">Press releases</p> */}
                </div>
                <p className="text-[#FFFFFF] text-[16px] 1.5xl:text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32px] text-wrap break-words leading-[130%]  lg-only:line-clamp-3">
                  {truncate(news.newsShortDescription, 150)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* video ui  */}
    </section>
  );
};

export default VideoCard;
