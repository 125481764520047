import { Helmet } from "react-helmet-async";

const SideSiteSEO = ({
  title,
  description,
  image = "%PUBLIC_URL%/fav-icon.png",
  url = "https://sideglobal.com/",
}) => {
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:url" content={url} />
    <meta property="og:type" content="website" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
    <meta name="twitter:card" content="summary_large_image" />
  </Helmet>;
};

export default SideSiteSEO;
