import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const RightScatterAnimation = ({ images }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const { ref: inViewRef, inView: deskView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: inViewRefMobile, inView: mobileView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const stackedPosition = [
    { x: 0, y: 0, rotate: 0 },
    { x: 10, y: 0, rotate: 0 },

    { x: 20, y: 0, rotate: 0 },
  ];
  const scatteredPositions = [
    // { x: 0, y: 0, rotate: -5 },
    // { x: 150, y: 0, rotate: 5 },
    // { x: 320, y: 0, rotate: 10 },
    {
      x: "var(--position-1-x)",
      y: "var(--position-1-y)",
      rotate: "var(--position-1-rotate)",
    },
    {
      x: "var(--position-2-x)",
      y: "var(--position-2-y)",
      rotate: "var(--position-2-rotate)",
    },
    {
      x: "var(--position-3-x)",
      y: "var(--position-3-y)",
      rotate: "var(--position-3-rotate)",
    },
  ];
  const stackedPositionMobile = [
    { x: 0, y: 0, rotate: 0 },
    { x: 0, y: -320, rotate: 0 },

    { x: 0, y: -640, rotate: 0 },
  ];
  const scatteredPositionsMobile = [
    { x: 0, y: 0, rotate: 5 },
    { x: 0, y: -120, rotate: -5 },
    { x: 0, y: -260, rotate: 5 },
  ];
  const handleClickOutside = (event) => {
    if (
      document.getElementById("images-container") &&
      !document.getElementById("images-container").contains(event.target)
    ) {
      setHoveredIndex(null);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);
  return (
    <div id="images-container">
      <div
        className="hidden sm:flex justify-start items-center sm:min-h-[28rem] lg:min-h-96 lg:justify-start relative"
        ref={inViewRef}
      >
        {images.map((src, index) => (
          <motion.img
            key={index}
            src={src}
            alt={`company-${index}`}
            initial={stackedPosition[index]}
            animate={
              deskView ? scatteredPositions[index] : stackedPosition[index]
            }
            transition={{
              duration: 0.5,
              delay: 0.6,
              stiffness: 100,
            }}
            style={{ zIndex: hoveredIndex === index ? 3 : index }}
            onClick={() => {
              setHoveredIndex(index);
            }}
            // onMouseLeave={() => {
            //   setTimeout(() => {
            //     setHoveredIndex(null);
            //   }, 200);
            // }}
            className="absolute object-contain rounded-sm sm:h-auto w-1/3 sm:w-1/2 cursor-pointer"
          />
        ))}
      </div>
      <div
        ref={inViewRefMobile}
        className={`relative flex sm:hidden flex-col  items-center justify-center -mb-56`}
      >
        {images.map((src, index) => (
          <motion.img
            key={index}
            src={src}
            alt={`company-${index}`}
            initial={stackedPositionMobile[index]}
            animate={
              mobileView
                ? scatteredPositionsMobile[index]
                : stackedPositionMobile[index]
            }
            transition={{
              duration: 0.5,
              delay: 0.6,
              stiffness: 100,
            }}
            style={{ zIndex: hoveredIndex === index ? 3 : index }}
            className="object-contain rounded-sm w-[90%] xxs:w-[90%] lg:w-[98%] cursor-pointer"
            onClick={() => {
              setHoveredIndex(index);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default RightScatterAnimation;
