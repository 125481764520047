import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "../../css/componentscss/Home/NewsEvents.css";
import Buttons from "../Buttons";
import { useNavigate } from "react-router-dom";
import { navigateTo } from "../../constants/GeneralConstants";
import PIL from "../PIL";
import { clientUrls } from "../../constants/ClientUrls";
import ButtonSlant from "../ButtonSlant";

const SingleCard = ({
  item,
  i,
  colors,

  sortedCardsData,
  toLabs = false,
}) => {
  // console.log("from art", item);
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const { ref: newsRef, inView: newsInView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <>
      <React.Fragment ref={newsRef}></React.Fragment>

      <motion.div
        key={item.post_id}
        ref={newsRef}
        className={`group rounded-lg relative  cursor-pointer  flex flex-col ${
          i === 0 ? "z-20" : i === 1 ? "z-10" : "z-0"
        } events-card news-events-div`}
        initial={{ y: -100, opacity: 0 }}
        animate={{
          y: newsInView ? 0 : -100,
          opacity: newsInView ? 1 : 0,
        }}
        onClick={(e) =>
          navigate(
            navigateTo(
              toLabs
                ? `${clientUrls.labs}/${item.labLink}`
                : `${clientUrls.news}/${item.newsLink}`
            )
          )
        }
        transition={{
          // type: "tween",
          duration: 0.3,
          // ease: "easeOut",
          delay: i * 0.5,
        }}
        onMouseEnter={(e) => {
          setHover(true);
        }}
        onMouseLeave={(e) => {
          setHover(false);
        }}
        onTouchStart={(e) => {
          setHover(true);
        }}
        onTouchEnd={(e) => {
          setHover(false);
        }}
      >
        {hover && (
          <div className=" cursor-pointer absolute lg:-top-[1px] lg:-right-[1px] bg-[#1a1a1a] h-14 w-14 4xl:h-16 4xl:w-16 flex items-center justify-center z-10 rounded-bl-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="55"
              viewBox="0 0 24 24"
              strokeWidth="3"
              stroke={colors.color}
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
              />
            </svg>
          </div>
        )}
        <div className="!w-full h-full  ">
          <div className=" !w-auto relative events-card-child">
            <PIL
              // lowQaulitySrc={
              //   item.newsImageLowQuality || item.labImageLowQuality
              // }
              highQualitySrc={item.newsImage || item.labImage}
              alt="side-ptw-news-image"
              className={`w-full h-auto object-fill rounded-t-lg ${
                hover ? "news-events-slant" : ""
              }`}
            />

            <div
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: hover ? colors.color : "#46464680",
              }}
              className="flex flex-col justify-between w-full h-[45%] text-left items-start bg-[#46464680] rounded-b-lg text-white p-3 backdrop-blur-[8px] mt-[3px]"
            >
              <div>
                <p
                  className="font-normal lg:text-[18px]1 text-events-ndate  mb-1 group-hover:text-black"
                  style={{ color: hover ? "#1a1a1a" : "#dcdcdc" }}
                >
                  {item.publishDate}
                </p>
                <h2
                  className="lg:text-[18px]1 text-events-ntitle !leading-[100%] line-clamp-4 1.5xl:line-clamp-5 3xl:line-clamp-6"
                  style={{ color: hover ? "#1a1a1a" : "#ffffff" }}
                >
                  {item.newsTitle || item.labTitle}
                </h2>
              </div>
              <Buttons
                className="font-normal lg:text-[16px] text-events-nbutton border-[2px] border-[#bec0c5] text-[#bec0c5] rounded-md px-2 py-1 group-hover:text-black group-hover:border-black group-hover:font-normal"
                buttonText={item.newsType || item.service}
                style={{
                  color: hover ? "#1a1a1a" : "#bec0c5",
                  borderColor: hover ? "#1a1a1a" : "#bec0c5",
                }}
              />
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};
const SingleCardMobile = ({ item, colors, index, toLabs = false, i }) => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);

  const { ref: newsRefMobile, inView: newsInViewMobile } = useInView({
    triggerOnce: true,
    threshold: 0,
  });
  return (
    <motion.div
      className="group rounded-lg relative  my-4 events-card flex flex-col flex-shrink-0 news-events-div"
      key={item.post_id}
      initial={{ y: -100, opacity: 0 }}
      animate={{
        y: newsInViewMobile ? 0 : -100,
        opacity: newsInViewMobile ? 1 : 0,
      }}
      onClick={(e) =>
        navigate(
          navigateTo(
            toLabs
              ? `${clientUrls.labs}/${item.labLink}`
              : `${clientUrls.news}/${item.newsLink}`
          )
        )
      }
      transition={{
        // type: "tween",
        duration: 0.3,
        // ease: "easeOut",
        delay: index * 0.5,
      }}
      onMouseEnter={(e) => {
        setHover(true);
      }}
      onMouseLeave={(e) => {
        setHover(false);
      }}
      onTouchStart={(e) => {
        setHover(true);
      }}
      onTouchEnd={(e) => {
        setHover(false);
      }}
      ref={newsRefMobile}
    >
      {hover && (
        <div className="cursor-pointer absolute -top-[1px] -right-[1px] bg-[#1a1a1a] h-12 w-12 flex items-center justify-center rounded-bl-lg z-[3]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={colors.color}
            viewBox="0 0 24 24"
            strokeWidth="3"
            stroke={colors.color}
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
            />
          </svg>
        </div>
      )}
      <div className="w-full h-full  ">
        <div className=" w-full h-full relative">
          <PIL
            lowQaulitySrc={item.newsImageLowQuality || item.labImageLowQuality}
            highQualitySrc={item.newsImage || item.labImage}
            alt="side-ptw-news-image"
            className={`w-full h-auto object-fill rounded-t-lg ${
              hover ? "news-events-slant" : ""
            }`}
          />

          <div
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: hover ? colors.color : "#46464680",
            }}
            className="flex flex-col justify-between w-full h-[45%] text-left items-start bg-[#46464680]  rounded-b-lg text-white p-3 backdrop-blur-[8px] mt-[2px]"
          >
            <div>
              <p
                className="font-normal text-events-ndate mb-1 "
                style={{ color: hover ? "#1a1a1a" : "#dcdcdc" }}
              >
                {item.publishDate}
              </p>
              <h2
                className="sm-only:!text-[15px] text-events-ntitle leading-[1] lg:leading-5 line-clamp-3 sm:line-clamp-2 md:line-clamp-3"
                style={{ color: hover ? "#1a1a1a" : "#ffffff" }}
              >
                {item.newsTitle || item.labTitle}
              </h2>
            </div>
            <Buttons
              className="font-normal text-events-nbutton border-[2px] border-gray-400 rounded-md px-2 py-1"
              style={{
                color: hover ? "#1a1a1a" : "#bec0c5",
                borderColor: hover ? "#1a1a1a" : "#bec0c5",
              }}
              buttonText={item.newsType || item.service}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};
const NewsEvents = ({
  header1,
  header2,
  headerButtonText,
  cardsData,
  colors,
  url,
  toLabs = false,
  type,
}) => {
  const navigate = useNavigate();
  const [ref, inView] = useInView({ triggerOnce: true });

  const { ref: headingRef, inView: isInView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const [issectionHover, setIsSectionHover] = useState(false);
  const sortedCardsData = cardsData?.sort(
    (a, b) => new Date(b.publishDate) - new Date(a.publishDate)
  );

  const pullupVariantForDesc = {
    initial: { y: 20, opacity: 0 },
    animate: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.5,
      },
    }),
  };

  return (
    <section className="container mb-24 mx-auto mt-12 lg:mt-0" ref={headingRef}>
      <motion.h6
        className={` font-extrabold  w-auto md:w-[400px] text-[18px] 4xl:text-[29px] lg:w-auto leading-[1] uppercase 2xl:mb-2`}
        style={{ color: colors.color }}
        variants={pullupVariantForDesc}
        initial="initial"
        animate={isInView ? "animate" : ""}
        transition={{ duration: 0.2 }}
      >
        <motion.span variants={pullupVariantForDesc} className="block">
          {header1}
        </motion.span>
      </motion.h6>
      <div className="flex justify-between items-center mb-8 md:mb-12">
        <motion.h2
          className={`text-events-nheader font-extrabold w-auto sm:w-[390px] md:w-[400px] lg:w-auto leading-[1]`}
          style={{ color: colors.color }}
          variants={pullupVariantForDesc}
          initial="initial"
          animate={isInView ? "animate" : ""}
          transition={{ duration: 0.5 }}
        >
          <motion.span variants={pullupVariantForDesc} className="block">
            {header2}
          </motion.span>
        </motion.h2>

        <ButtonSlant
          buttonClass="hidden common-button-size sm:block sm-only:!text-sm text-lg  font-bold  border-[2px] px-6 py-3 3xl:py-4 3xl:px-7  4xl:px-8 4xl:py-5 rounded-md font-dela z-[2]"
          slantClass="bottom-right-slant-sm"
          divClass="z-[2]"
          borderColor={colors.color}
          textColor={colors.color}
          textColorHover={colors.tertiary}
          bgColorHover={colors.color}
          buttonText={headerButtonText}
          onClick={(e) => {
            toLabs
              ? navigate(navigateTo(url), {
                  state: {
                    type: type,
                  },
                })
              : navigate(navigateTo(url));
          }}
        />
      </div>
      {/* desktop cards starts here */}
      <div
        ref={ref}
        className="hidden lg:flex  lg:gap-8 xl:gap-12 2xl:gap-16"
        onMouseEnter={() => {
          setIsSectionHover(true);
        }}
      >
        {sortedCardsData?.map((item, index) => (
          <SingleCard
            sortedCardsData={sortedCardsData}
            item={item}
            i={index}
            inView={inView}
            key={item.newsTitle || item.labTitle}
            colors={colors}
            toLabs={toLabs}
          />
        ))}
      </div>
      {/* desktop cards ends here */}
      {/* mobile cards start here */}
      <div className="">
        <div className="flex overflow-x-auto overflow-y-visible gap-x-6 lg:hidden hide-scroll mb-4">
          {sortedCardsData?.map((item, index) => (
            <SingleCardMobile
              item={item}
              key={item.newsTitle || item.labTitle}
              colors={colors}
              i={index}
              toLabs={toLabs}
            />
          ))}
        </div>

        <ButtonSlant
          buttonClass="flex sm:hidden mx-auto text-center w-full text-lg font-bold border-[2px] px-6 py-3 rounded-md $ font-dela justify-center items-center"
          slantClass="bottom-right-slant"
          borderColor={colors.color}
          textColor={colors.color}
          textColorHover={colors.tertiary}
          bgColorHover={colors.color}
          buttonText={headerButtonText}
          onClick={(e) => {
            toLabs
              ? navigate(navigateTo(url), {
                  state: {
                    type: type,
                  },
                })
              : navigate(navigateTo(url));
          }}
        />
      </div>
      {/* mobile cards ends here */}
    </section>
  );
};

export default NewsEvents;
