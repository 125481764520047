import { useCallback, useEffect, useRef, useState } from "react";
import { getLanguage } from "../../constants/GeneralConstants";
import { debounce } from "lodash";
import {
  getLabsFilterData,
  getLabsListData,
  getLabsPageData,
} from "../../services/ApiService";
import "../../css/pagescss/LabsList.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import LabsArticleCard from "./LabsArticlesCard";
import LabsVideoCard from "./LabsVideoCard";
import LabsSlider from "./LabsSlider";
import Pagination, { scrollToRef } from "../../components/Pagination";
import { useDispatch } from "react-redux";
import { setColor } from "../../store/reducers/ColorReducer";
import Footer from "../../components/Footer";
import HighlightedLabArticle from "./HighLightedLabArticle";
import YoutubeVideo from "../../components/YoutubeVideo";
import ButtonSlant from "../../components/ButtonSlant";
import { SlantImg } from "../../components/CommonComponents";

const LabsList = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const location = useLocation();
  const { videos, type } = location.state || {};
  const dispatch = useDispatch();
  const typeMenuRef = useRef(null);
  const typeButtonRef = useRef(null);
  const topicMenuRef = useRef(null);
  const topicButtonRef = useRef(null);
  const typeMobileMenuRef = useRef(null);
  const typeMobileButtonRef = useRef(null);
  const topicMobileMenuRef = useRef(null);
  const topicMobileButtonRef = useRef(null);
  const mobileInputRef = useRef(null);
  const webInputRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const [labs, setLabs] = useState([]);
  const [labsPageData, setLabsPageData] = useState(null);
  const [labsFilterData, setLabsFilterData] = useState([]);
  const [showArticle, setShowArticle] = useState(videos ? false : true);
  const [allLabs, setAllLabs] = useState([]);
  const [isAscending, setIsAscending] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [labsListData, setLabsListData] = useState([]);
  const [labType, setLabType] = useState(videos ? "Yes" : "No");
  const [labsType, setLabsType] = useState([]);
  const [labsTopic, setLabsTopic] = useState(type ? [type] : []);
  const [showTypeMenu, setShowTypeMenu] = useState(false);
  const [showTopicMenu, setShowTopicMenu] = useState(false);
  const [showMobileTypeMenu, setShowMobileTypeMenu] = useState(false);
  const [showMobileTopicMenu, setShowMobileTopicMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const [hoverStates, setHoverStates] = useState({});
  const [sortBy, setSortBy] = useState(false);
  const [sorting, setSorting] = useState("N2O");
  const [loading, setLoading] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showSortFilterOptions, setShowShortFilterOptions] = useState(false);
  const [showSortFilterOptionsMobile, setShowShortFilterOptionsMobile] =
    useState(false);
  const [activeShortFilter, setActiveShortFilter] = useState("");
  const [activeSubFilter, setActiveSubFilter] = useState();
  const [activeSubFilterMobile, setActiveSubFilterMobile] = useState();
  const subFilterRef = useRef(null);
  const subFilterRefMobile = useRef(null);
  const shortingFilterRef = useRef(null);
  const shortingFilterRefMobile = useRef(null);
  const pageRef = useRef(null);
  const mobilePageRef = useRef(null);
  const [subFilterHover, setSubFilterHover] = useState(false);
  const handleHoverChange = (key, isHover) => {
    setHoverStates((prevStates) => ({
      ...prevStates,
      [key]: isHover,
    }));
  };
  useEffect(() => {
    callLabsPageData(getLanguage());
    callLabsfilterData(getLanguage());
  }, [lang]);
  useEffect(() => {
    dispatch(
      setColor({
        primary: "#61e063",
        secondary: "#61e063",
        tertiary: "#286233",
      })
    );
  }, [dispatch]);

  const handleClickOutside = (event) => {
    if (
      shortingFilterRef.current &&
      !shortingFilterRef.current.contains(event.target)
    ) {
      setShowShortFilterOptions(false);
    }
    if (
      shortingFilterRefMobile.current &&
      !shortingFilterRefMobile.current.contains(event.target)
    ) {
      setShowShortFilterOptionsMobile(false);
    }
    if (
      subFilterRef.current &&
      !subFilterRef.current.contains(event.target) &&
      !event.target.closest(".topic") &&
      !event.target.closest(".type")
    ) {
      setActiveSubFilter(undefined);
      setShowTypeMenu(false);
      setShowTopicMenu(false);
    }
    if (
      subFilterRefMobile.current &&
      !subFilterRefMobile.current.contains(event.target) &&
      !event.target.closest(".topicMobile") &&
      !event.target.closest(".typeMobile")
    ) {
      setActiveSubFilterMobile(undefined);
      setShowMobileTypeMenu(false);
      setShowMobileTopicMenu(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        typeMenuRef.current &&
        !typeMenuRef.current.contains(event.target) &&
        typeButtonRef.current &&
        !typeButtonRef.current.contains(event.target)
      ) {
        setShowTypeMenu(false);
      }
      if (
        topicMenuRef.current &&
        !topicMenuRef.current.contains(event.target) &&
        topicButtonRef.current &&
        !topicButtonRef.current.contains(event.target)
      ) {
        setShowTopicMenu(false);
      }
      if (
        typeMobileMenuRef.current &&
        !typeMobileMenuRef.current.contains(event.target) &&
        typeMobileButtonRef.current &&
        !typeMobileButtonRef.current.contains(event.target)
      ) {
        setShowMobileTypeMenu(false);
      }
      if (
        topicMobileMenuRef.current &&
        !topicMobileMenuRef.current.contains(event.target) &&
        topicMobileButtonRef.current &&
        !topicMobileButtonRef.current.contains(event.target)
      ) {
        setShowMobileTopicMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      setScrollPosition(window.scrollY || document.documentElement.scrollTop);
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.width = "100%";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      document.body.style.overflow = "";
      window.scrollTo(0, scrollPosition);
    }

    return () => {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      document.body.style.overflow = "";
    };
  }, [isOpen, location.pathname]);

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => setIsOpen(false);

  const prevVideo = () => {
    if (!loading) {
      setLoading(true);
      setCurrentIndex((prev) =>
        prev === 0 ? labsListData.length - 1 : prev - 1
      );
    }
  };

  const nextVideo = () => {
    if (!loading) {
      setLoading(true);
      setCurrentIndex((prev) =>
        prev === labsListData.length - 1 ? 0 : prev + 1
      );
    }
  };

  const callLabsPageData = async (language) => {
    try {
      let data = await getLabsPageData(language);
      document.title = data?.labsPageData?.labsSEOTitle;
      setLabsPageData(data?.labsPageData);
      setLabs(data?.labs);
    } catch (err) {
      console.log(err);
    }
  };

  const callLabsfilterData = async (language) => {
    try {
      let data = await getLabsFilterData(language);
      setLabsFilterData(data);
      setActiveShortFilter(
        data?.labsFilters?.find((el) => el.sorting_key == "N2O").sorting_value
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    callLabsListData(getLanguage());
  }, [showArticle, lang, labsTopic, labsType, reset, labType, sorting]);

  const callLabsListData = async (language, page = 1, term = "") => {
    let obj = {
      labsType: labsType,
      labsTopic: labsTopic,
      pageno: page,
      type: labType,
      sorting: sorting,
      term: term,
    };

    try {
      setIsLoading(true);
      let data = await getLabsListData(language, obj);
      setAllLabs(data?.labs);
      setLabsListData(data?.labs);
      setTotalPages(Math.ceil(data?.totalrecords / 9));
      setLoader(false);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const debouncedFetchLabs = useCallback(
    debounce((searchTerm) => {
      callLabsListData(getLanguage(), 1, searchTerm);
    }, 500),
    [labType]
  );

  const handleSearch = (event) => {
    const term = event.target.value;
    setFilterSearchValue(term);
    debouncedFetchLabs(term);
  };

  useEffect(() => {
    return () => debouncedFetchLabs.cancel();
  }, [debouncedFetchLabs]);

  const onPaginationChange = (page) => {
    callLabsListData(getLanguage(), page, filterSearchValue);
    scrollToRef(mobilePageRef);
    scrollToRef(pageRef);
    setCurrentPage(page);
  };

  if (loader) {
    return <Loader />;
  }
  const resetData = () => {
    if (
      labsTopic.length ||
      labsType.length ||
      mobileInputRef.current ||
      webInputRef.current
    ) {
      setLabsTopic([]);
      setLabsType([]);
      navigate(location.pathname, { state: null });
      mobileInputRef.current.value = "";
      webInputRef.current.value = "";
    }
  };

  const changeFilter = (filter, type) => {
    if (type === "labsType") {
      let arr = [];
      if (labsType.includes(filter.id)) {
        setLabsType([]);
      } else {
        arr.push(filter.id);
        setLabsType(arr);
      }
      setShowTypeMenu(false);
    } else if (type === "labsTopic") {
      let arr = [];
      if (labsTopic.includes(filter.id)) {
        setLabsTopic([]);
      } else {
        arr.push(filter.id);
        setLabsTopic(arr);
      }
      setShowTopicMenu(false);
    }
  };
  const toggleElement = (array, element) => {
    return array.includes(element)
      ? array.filter((e) => e !== element)
      : [...array, element];
  };
  return (
    <>
      {/* <TopBannerForOtherPages pageName="Labs" /> */}
      <section className="lab-page">
        <section className="bg-[#61e063] flex relative pt-28 pb-24 sm:pb-20 mb-16 lg:mb-28 3xl:h-[50rem] 4xl:h-[55rem] project-slant-bottom-mobilek slant-bottom-footerk z-0">
          {/* <img
             className="z-[-1] absolute top-[-30%] left-[-50%] h-[110%] max-w-[150%] rotate-[10deg]  sm:left-[9%] sm:h-[170%] sm:top-[-50%] lg:top-[-35%] opacity-[20%]"
            src={labsPageData?.bannerSection_bannerBgImage}
          /> */}
          <div className="absolute right-0 top-0 h-full hidden sm:block z-[-1]">
            <img
              alt="side-ptw"
              src={labsPageData?.bannerSection_bannerBgImage}
              className="h-full"
            />
          </div>
          <div className="absolute right-0 top-0 h-full sm:hidden z-[-1]">
            <img
              alt="side-ptw"
              src={labsPageData?.bannerSection_bannerBgImageMob}
              className="h-full"
            />
          </div>
          <div className="absolute right-0 -bottom-[1px] z-[10]">
            <SlantImg className="h-[100px] sm:h-auto" />
          </div>
          <LabsSlider labsData={labs} labsPageData={labsPageData} />
        </section>
        {/* Filter Ui starts here  */}
        <section className="filter container mb-10 lg:mb-20">
          {/* desktop and tablet ui starts here  */}
          <div className="flex xxs:gap-6 sm:gap-10 text-center mb-4 sm:my-0">
            <ButtonSlant
              buttonClass="w-full text-left text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px]  font-bold border-[#61e063] border-[2px] px-6 py-3 4xl:py-4 4xl:px-8 rounded-md text-map-button font-dela"
              onClick={() => {
                setShowArticle(true);
                setLabType("No");
                setCurrentPage(1);
                setFilterSearchValue("");
                setLabsType([]);
                setLabsTopic([]);
                setActiveShortFilter(
                  labsFilterData?.labsFilters?.find(
                    (el) => el.sorting_key === "N2O"
                  ).sorting_value
                );
                setSorting("N2O");
              }}
              divClass="w-full"
              slantClass="bottom-right-slant"
              textColor={showArticle === true ? "#1a1a1a" : "#61e063"}
              textColorHover="#1a1a1a"
              bgColor={showArticle === true ? "#61e063" : ""}
              bgColorHover="#61e063"
              buttonText={labsPageData?.articlesText}
            />
            <ButtonSlant
              buttonClass="w-full text-left text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px]  font-bold border-[#61e063] border-[2px] px-6 py-3 4xl:py-4 4xl:px-8 rounded-md text-map-button font-dela"
              onClick={() => {
                setLabType("Yes");
                setShowArticle(false);
                setCurrentPage(1);
                setFilterSearchValue("");
                setLabsType([]);
                setLabsTopic([]);
                setActiveShortFilter(
                  labsFilterData?.labsFilters?.find(
                    (el) => el.sorting_key === "N2O"
                  ).sorting_value
                );
                setSorting("N2O");
              }}
              divClass="w-full"
              slantClass="bottom-right-slant"
              textColor={showArticle === false ? "#1a1a1a" : "#61e063"}
              textColorHover="#1a1a1a"
              bgColor={showArticle === false ? "#61e063" : ""}
              bgColorHover="#61e063"
              buttonText={labsPageData?.videosText}
            />
          </div>
          <div
            className="flex lg:gap-10 text-center sm:my-6 sm:justify-between"
            ref={pageRef}
          >
            <div className="lg:flex-1">
              <ul className="flex flex-wrap lg:flex-nowrap gap-y-4">
                <li
                  className="list-none sm:mr-4 2xl:mr-8 type"
                  onClick={() => {
                    if (activeSubFilter === "labsType") {
                      setActiveSubFilter(undefined);
                    } else {
                      setActiveSubFilter("labsType");
                    }
                  }}
                >
                  <ButtonSlant
                    ref={typeButtonRef}
                    buttonClass="hidden sm:flex items-center group hover-svg text-[18px] sm-only:text-[16px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer text-[#61e063] border border-[#61e063] sm-only:px-2 px-5 py-2 rounded "
                    buttonText={labsPageData?.typeText}
                    onClick={() => {
                      setShowTypeMenu(!showTypeMenu);
                      setShowTopicMenu(false); // Close topic menu if open
                    }}
                    textColorHover="#1a1a1a"
                    divClass="w-fit"
                    bgColorHover="#61e063"
                    slantClass="bottom-right-slant"
                    onHoverChange={(isHover) =>
                      handleHoverChange("typeDesk", isHover)
                    }
                  >
                    {showTypeMenu ? (
                      hoverStates?.typeDesk ? (
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2 platforms"
                        >
                          <path
                            d="M8.625 6.25L12.375 2.5L16.125 6.25"
                            stroke="#1a1a1a"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                            stroke="#1a1a1a"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2 platforms"
                        >
                          <path
                            d="M8.625 6.25L12.375 2.5L16.125 6.25"
                            stroke="#61e063"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                            stroke="#61e063"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      )
                    ) : hoverStates?.typeDesk ? (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2"
                      >
                        <path
                          d="M8.125 13.75L11.875 17.5L15.625 13.75"
                          stroke="#1a1a1a"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                          stroke="#1a1a1a"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2"
                      >
                        <path
                          d="M8.125 13.75L11.875 17.5L15.625 13.75"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    )}
                  </ButtonSlant>
                  {/* {showTypeMenu && (
                    <div ref={typeMenuRef} className="relative">
                      <div className="absolute z-10 w-screen max-w-[16rem] rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 backdrop-blur-lg left-0 top-[1rem]  overflow-auto scrollable custom-scroll-filter">
                        {labsFilterData?.labsType
                          .sort((a, b) => a.value.localeCompare(b.value))
                          .map((filter, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                changeFilter(filter, "labsType");
                              }}
                              style={{
                                color:
                                  labsType[0] === filter.id ? "#61e063" : "",
                              }}
                              className="cursor-pointer flex justify-between gap-x-2.5 p-2 text-[15px] 2xl:text-[18px] 3xl:text-[22px] 4xl:text-[24px] w-fit font-semibold text-white rounded-md hover:text-[#61e063] uppercase"
                            >
                              {filter.value}
                            </div>
                          ))}
                      </div>
                    </div>
                  )} */}
                </li>
                <li
                  className="list-none sm:mr-4 2xl:mr-8 relative topic"
                  onClick={() => {
                    if (activeSubFilter != "labsTopic") {
                      setActiveSubFilter("labsTopic");
                    } else {
                      setActiveSubFilter(undefined);
                    }
                  }}
                >
                  <ButtonSlant
                    ref={topicButtonRef}
                    buttonClass="hidden sm:flex items-center group hover-svg text-[18px] sm-only:text-[16px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer text-[#61e063] border border-[#61e063] sm-only:px-2 px-5 py-2 rounded "
                    buttonText={labsPageData?.topicText}
                    onClick={() => {
                      setShowTopicMenu(!showTopicMenu);
                      setShowTypeMenu(false);
                    }}
                    textColorHover="#1a1a1a"
                    divClass="w-fit"
                    bgColorHover="#61e063"
                    slantClass="bottom-right-slant"
                    onHoverChange={(isHover) =>
                      handleHoverChange("topicDesk", isHover)
                    }
                  >
                    {showTopicMenu ? (
                      hoverStates?.topicDesk ? (
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2 platforms"
                        >
                          <path
                            d="M8.625 6.25L12.375 2.5L16.125 6.25"
                            stroke="#1a1a1a"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                            stroke="#1a1a1a"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2 platforms"
                        >
                          <path
                            d="M8.625 6.25L12.375 2.5L16.125 6.25"
                            stroke="#61e063"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                            stroke="#61e063"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      )
                    ) : hoverStates?.topicDesk ? (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2"
                      >
                        <path
                          d="M8.125 13.75L11.875 17.5L15.625 13.75"
                          stroke="#1a1a1a"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                          stroke="#1a1a1a"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2"
                      >
                        <path
                          d="M8.125 13.75L11.875 17.5L15.625 13.75"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                          stroke="#61e063"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    )}
                  </ButtonSlant>
                  {/* {showTopicMenu && (
                    <div ref={topicMenuRef} className="relative">
                      <div className="absolute z-10 w-screen max-w-[17rem] 3xl:max-w-[19rem] text-left rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 backdrop-blur-lg left-0 top-[1rem]  overflow-auto scrollable custom-scroll-filter">
                        {labsFilterData?.labsTopic
                          .sort((a, b) => a.value.localeCompare(b.value))
                          .map((filter, index) => (
                            <div
                              key={index}
                              onClick={() => changeFilter(filter, "labsTopic")}
                              style={{
                                color:
                                  labsTopic[0] === filter.id ? "#61e063" : "",
                              }}
                              className="cursor-pointer flex justify-between gap-x-2.5 p-2 text-[15px] 2xl:text-[18px] 3xl:text-[22px] 4xl:text-[24px] w-fit font-medium text-white rounded-md hover:text-[#61e063]"
                            >
                              {filter.value}
                            </div>
                          ))}
                      </div>
                    </div>
                  )} */}
                </li>
              </ul>
            </div>
            <div className="relative flex lg:flex-1">
              <label className="hidden sm:flex justify-between items-center  border border-[#61e063] sm-only:px-2 px-5 py-2 rounded  sm:mr-4 2xl:mr-8 sm:w-[68%]k md:w-[66%]k lg:w-[65%]k w-[85%]">
                <input
                  type="search"
                  id="default-search"
                  className="block bg-transparent border-transparent focus-within:outline-0 focus:outline-0 focus-0 text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#61e063] rounded w-fitk placeholder:text-[#61e063]"
                  placeholder={labsPageData?.searchText}
                  required
                  appreance="none"
                  onChange={handleSearch}
                  value={filterSearchValue}
                  ref={webInputRef}
                />
                <svg
                  className="w-4 h-4 text-[#61e063]"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </label>
              <button
                ref={shortingFilterRef}
                onClick={(e) => {
                  setShowShortFilterOptions(!showSortFilterOptions);
                }}
                className="hidden sm:flex items-center group hover-svg sm:w-[32%]k md:w-[34%]k lg:w-[35%]k w-auto justify-center text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#61e063] border border-[#61e063] bottom-right-slant-hover hover:bg-[#61e063] hover:text-[#1A1A1A] sm-only:px-2 px-5 py-2 rounded"
              >
                {/* {labsPageData?.sortByText} */}
                <svg
                  width="22"
                  height="22"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="lg:ml-3k"
                >
                  <path
                    stroke="#61e063"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                  />
                </svg>
                {/* <svg
                  width="22"
                  height="22"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="lg:ml-3"
                >
                  <path
                    stroke="#61e063"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 9l4-4l4 4m-4 -4v14"
                  />
                  <path
                    stroke="#61e063"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 15l-4 4l-4-4m4 4v-14"
                  />
                </svg> */}
              </button>
              <div className="relative"></div>
            </div>
          </div>
          {/* dropdown option ui starts here  */}
          {activeSubFilter && (
            <div
              class="hidden sm:flex flex-row my-5 gap-3 flex-wrap"
              ref={subFilterRef}
            >
              {labsFilterData[activeSubFilter]?.map((el) => {
                return (
                  <button
                    class={`border-2 text-[#dcdcdc]  hover:border-[#61e063] rounded-[50px] hover:text-[#61e063] py-1 px-3 text-lg ${
                      labsType?.includes(el.id) || labsTopic?.includes(el.id)
                        ? "!text-[#61e063] !border-[#61e063]"
                        : ""
                    }`}
                    onClick={() => {
                      if (activeSubFilter === "labsTopic") {
                        setLabsTopic(toggleElement(labsTopic, el?.id));
                      } else if (activeSubFilter === "labsType") {
                        setLabsType(toggleElement(labsType, el?.id));
                      }
                    }}
                  >
                    {el?.value}
                  </button>
                );
              })}
            </div>
          )}

          {/* dropdown option ui ends here  */}
          {/* newest to oldest filter starts here  */}

          {showSortFilterOptions && (
            <div className="hidden sm:flex justify-end mt-4 relative">
              <div className=" z-10 w-screen max-w-[15.5rem] rounded-[10px] bg-[#1a1a1acc] flex flex-col backdrop-blur-lg  lg:max-w-[18rem] xl:max-w-[20rem] 3xl:max-w-[25rem] 4xl:max-w-[26rem]  overflow-auto scrollable custom-scroll-filter ">
                {labsFilterData?.labsFilters?.map((option) => {
                  return (
                    <div
                      className={`flex group items-center justify-between cursor-pointer hover:bg-[#61e063] rounded ${
                        activeShortFilter === option.sorting_value
                          ? "pointer-events-none"
                          : ""
                      }`}
                      onClick={() => {
                        setSorting(option?.sorting_key);
                        setActiveShortFilter(option?.sorting_value);
                        setShowShortFilterOptions(false);
                        if (currentPage > 1) {
                          setCurrentPage(1);
                        }
                      }}
                    >
                      <div
                        className={`cursor-pointer flex justify-between group-hover:text-black  gap-x-2.5 p-2 text-[15px] 2xl:text-[18px] 3xl:text-[22px] 4xl:text-[24px] w-fit font-semibold   hover:opacity-100 rounded-md text-white`}
                        style={{
                          color:
                            activeShortFilter === option?.sorting_value
                              ? "#61e063"
                              : "",
                        }}
                      >
                        {option?.sorting_value}
                      </div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-10 w-10 p-2 object-contain"
                      >
                        <path
                          d="M13.75 11.875L17.5 8.125L13.75 4.375"
                          stroke="#1A1A1A"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                          stroke="#1A1A1A"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* newest to oldest filter ends here  */}
          {/* desktop and tablet ui ends here  */}
          {/* mobile filter starts here  */}
          <div className="sm:hidden" ref={mobilePageRef}>
            <label className="flex justify-between items-center border border-[#61e063] px-5 py-2 rounded  sm:mr-4 2xl:mr-8">
              <input
                type="search"
                id="default-search"
                className="block bg-transparent border-transparent focus-within:outline-0 focus:outline-0 focus-0 text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#61e063] rounded w-fit placeholder:text-[#61e063]"
                placeholder={labsPageData?.searchText}
                required
                appreance="none"
                onChange={handleSearch}
                ref={mobileInputRef}
                value={filterSearchValue}
              />
              <svg
                className="w-4 h-4 text-[#61e063]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </label>
            <div className="flex my-4 gap-8 justify-between">
              <button
                ref={shortingFilterRefMobile}
                onClick={(e) => {
                  setShowShortFilterOptionsMobile(!showSortFilterOptionsMobile);
                }}
                className="flex items-center justify-between relative text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#61e063] border border-[#61e063] px-5 py-2 rounded w-full"
              >
                {activeShortFilter}

                {/* <svg
                  width="22"
                  height="22"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="lg:ml-3"
                >
                  <path
                    stroke="#61e063"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 9l4-4l4 4m-4 -4v14"
                  />
                  <path
                    stroke="#61e063"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 15l-4 4l-4-4m4 4v-14"
                  />
                </svg> */}
                <svg
                  width="22"
                  height="22"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="lg:ml-3"
                >
                  <path
                    stroke="#61e063"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                  />
                </svg>
              </button>

              {/* <button
                onClick={sortingByPublishedDate}
                 className="flex items-center justify-between text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#61e063] border border-[#61e063] px-5 py-2 rounded w-full"
              >
                {labsPageData?.filterText}
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                   className="ml-2"
                >
                  <path
                    d="M8.125 13.75L11.875 17.5L15.625 13.75"
                    stroke="#61e063"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                    stroke="#61e063"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </button> */}
            </div>
            {/* mobile newest to oldest starts here  */}
            {showSortFilterOptionsMobile && (
              <div className="relative">
                <div className=" z-10 w-screen max-w-[15rem] rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 left-0 top-[-1rem] backdrop-blur-lg  lg:max-w-[18rem]  absolute sm:relative overflow-auto scrollable custom-scroll-filter ">
                  {labsFilterData?.labsFilters?.map((option) => {
                    return (
                      <div
                        className={`flex group items-center justify-between cursor-pointer hover:bg-[#61e063] rounded left-[-11.5rem] lg:left-0 ${
                          activeShortFilter === option?.sorting_value
                            ? "pointer-events-none"
                            : ""
                        }`}
                        onClick={() => {
                          setSorting(option?.sorting_key);
                          setActiveShortFilter(option?.sorting_value);
                          setShowShortFilterOptionsMobile(false);
                          if (currentPage > 1) {
                            setCurrentPage(1);
                          }
                        }}
                      >
                        <div
                          className="cursor-pointer flex justify-between group-hover:text-black  gap-x-2.5 p-2 text-[15px]  w-fit font-semibold   hover:opacity-100 rounded-md
                   text-white
                "
                          style={{
                            color:
                              activeShortFilter === option?.sorting_value
                                ? "#61e063"
                                : "",
                          }}
                        >
                          {option?.sorting_value}
                        </div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-10 w-10 p-2 object-contain"
                        >
                          <path
                            d="M13.75 11.875L17.5 8.125L13.75 4.375"
                            stroke="#1A1A1A"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                            stroke="#1A1A1A"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* mobile newest to oldest ends here  */}
            <div className="mobile-fil lg:flex-1">
              <ul className="flex flex-wrap lg:flex-nowrap justify-between typeMobile">
                <li
                  className="list-none sm:mr-4 2xl:mr-8 w-[45%]"
                  onClick={() => {
                    if (activeSubFilterMobile != "labsType") {
                      setActiveSubFilterMobile("labsType");
                    } else {
                      setActiveSubFilterMobile(undefined);
                    }
                  }}
                >
                  <button
                    ref={typeMobileButtonRef}
                    onClick={() => {
                      setShowMobileTypeMenu(!showMobileTypeMenu);
                      setShowMobileTopicMenu(false);
                    }}
                    className={`flex items-center justify-between group  text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer text-[#61e063] border border-[#61e063] ${
                      showMobileTypeMenu ? "bottom-right-slant-sm" : ""
                    }  px-5 py-2 rounded w-full`}
                    style={{
                      color: showMobileTypeMenu ? "#1a1a1a" : "",
                      backgroundColor: showMobileTypeMenu ? "#61e063" : "",
                    }}
                  >
                    {labsPageData?.typeText}
                    {showMobileTypeMenu ? (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2 platforms"
                      >
                        <path
                          d="M8.625 6.25L12.375 2.5L16.125 6.25"
                          stroke="#1a1a1a"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                          stroke="#1a1a1a"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2"
                      >
                        <path
                          d="M8.125 13.75L11.875 17.5L15.625 13.75"
                          stroke="#61e063"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                          stroke="#61e063"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    )}
                  </button>
                </li>
                <li
                  className="list-none sm:mr-4 2xl:mr-8 relative w-[45%] topicMobile"
                  onClick={() => {
                    if (activeSubFilterMobile != "labsTopic") {
                      setActiveSubFilterMobile("labsTopic");
                    } else {
                      setActiveSubFilterMobile(undefined);
                    }
                  }}
                >
                  <button
                    ref={topicMobileButtonRef}
                    onClick={() => {
                      setShowMobileTopicMenu(!showMobileTopicMenu);
                      setShowMobileTypeMenu(false);
                    }}
                    className={`flex items-center justify-between group  text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer text-[#61e063] border border-[#61e063] ${
                      showMobileTopicMenu ? "bottom-right-slant-sm" : ""
                    }  px-5 py-2 rounded w-full`}
                    style={{
                      color: showMobileTopicMenu ? "#1a1a1a" : "",
                      backgroundColor: showMobileTopicMenu ? "#61e063" : "",
                    }}
                  >
                    {labsPageData?.topicText}
                    {showMobileTopicMenu ? (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2 platforms"
                      >
                        <path
                          d="M8.625 6.25L12.375 2.5L16.125 6.25"
                          stroke="#1a1a1a"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                          stroke="#1a1a1a"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2"
                      >
                        <path
                          d="M8.125 13.75L11.875 17.5L15.625 13.75"
                          stroke="#61e063"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                          stroke="#61e063"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    )}
                  </button>
                </li>
              </ul>
            </div>
            <div className="relative"></div>
          </div>
          {/* mobile filter ends here  */}
          {activeSubFilterMobile && (
            <div
              class="flex sm:hidden flex-row my-5 gap-3 flex-wrap"
              ref={subFilterRefMobile}
            >
              {labsFilterData[activeSubFilterMobile]?.map((el) => {
                return (
                  <button
                    class={`border-2 text-[#dcdcdc] border-[#dcdcdc] rounded-[50px] py-1 px-3 text-lg ${
                      labsType?.includes(el.id) || labsTopic?.includes(el.id)
                        ? "!text-[#61e063] !border-[#61e063]"
                        : ""
                    }`}
                    onClick={() => {
                      if (activeSubFilterMobile === "labsTopic") {
                        setLabsTopic(toggleElement(labsTopic, el?.id));
                      } else if (activeSubFilterMobile === "labsType") {
                        setLabsType(toggleElement(labsType, el?.id));
                      }
                    }}
                  >
                    {el?.value}
                  </button>
                );
              })}
            </div>
          )}
        </section>
        {/* filter ui ends here  */}
        {/* article starts here  */}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {showArticle ? (
              <section className="labs-article ">
                {labsListData.length > 0 ? (
                  <>
                    <div className="grid grid-col-1 lg:grid-cols-2 gap-x-12 gap-y-8 my-12 container">
                      {labsListData.length > 0 &&
                        labsListData.slice(0, 4).map((lab, index) => {
                          return <LabsArticleCard labs={lab} key={index} />;
                        })}
                    </div>
                    {labsListData.length >= 5 && (
                      <HighlightedLabArticle
                        labs={labsListData[4]}
                        labsPageData={labsPageData}
                      />
                    )}
                    <section className="articles container">
                      <div className="grid grid-col-1 lg:grid-cols-2 gap-x-12 gap-y-8 my-12">
                        {labsListData.length > 5 &&
                          labsListData
                            .slice(5, 9)
                            .map((lab, index) => (
                              <LabsArticleCard labs={lab} key={index} />
                            ))}
                      </div>
                    </section>
                  </>
                ) : (
                  <div className="flex justify-center items-center h-40">
                    <p className="text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] text-[#61e063] font-medium">
                      No Labs Found
                    </p>
                  </div>
                )}
              </section>
            ) : (
              <section className="labs-video container">
                {labsListData.length > 0 ? (
                  <div className="grid grid-col-1 lg:grid-cols-2 gap-x-12 gap-y-8 my-12">
                    {labsListData.map((lab, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => openModal(index)}
                          className="cursor-pointer"
                        >
                          <LabsVideoCard labs={lab} key={index} />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-40">
                    <p className="text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] text-[#61e063] font-medium">
                      No Labs Found
                    </p>
                  </div>
                )}
              </section>
            )}
          </>
        )}
      </section>
      <section className="container mb-20 sm:mb-28 lg:mb-40 flex items-center justify-center pagination text-white text-[16px] 3xl:text-[22px] gap-4 mx-6">
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPaginationValueChange={onPaginationChange}
            color="#61e063"
          />
        )}
      </section>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md z-10">
          <div className="bg-[#1a1a1a] lg:bg-[#1a1a1acc] p-6 rounded-lg shadow-lg max-w-[90vw] max-h-auto sm:max-h-[70vh] md:max-h-[75vh] lg:max-h-[73vh] xl:max-h-[81vh] 1.5xl:max-h-[80vh] 2xl:max-h-[80vh] 3xl:max-h-[80vh] 3.3xl:max-h-[82vh] w-full sm:h-full relative mt-12">
            {/* Modal Header with Title */}
            {/* <div  className="flex justify-between items-center border-b pb-2 mb-4">
              <h3  className="text-lg font-semibold text-[#61e063] leading-[1]">
                {labsListData[currentIndex].title}
              </h3> */}
            {/* Close Button */}
            <button
              onClick={closeModal}
              className="!text-[#1a1a1a] sm:hover:text-[#61e063] absolute right-[-15px] sm:right-[-20px] top-[-15px] sm:top-[-20px] bg-[#61e063] sm:hover:bg-[#fff] rounded-[50rem] p-2 w-[35px] h-[35px] sm:w-[40px] sm:h-[40px]"
            >
              ✖
            </button>

            {/* </div> */}
            {/* <div  className="flex flex-col items-center"> */}
            <div className="relative w-full h-full">
              <YoutubeVideo
                url={labsListData[currentIndex].videoUrl}
                className="rounded-lg w-full xxs:h-[36vh] sm:h-[75%] lg:h-[70%] xl:h-[81%] 1.5xl:h-[79%] 2xl:h-[77%] 3xl:h-[83%] 3.3xl:h-[86%]"
                videoId={currentIndex}
                // isPlaying={currentIndex}
                onVideoReady={() => setLoading(false)}
              />
              <h3 className="text-lg font-semibold text-[#61e063] line-clamp-1 my-2 sm:my-4">
                {labsListData[currentIndex].title}
              </h3>
              {/* <iframe
                width="100%"
                height="300"
                src={labsListData[currentIndex].videoUrl}
                title="Video Player"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                 className="rounded-lg"
              ></iframe> */}
              {/* </div> */}

              {/* Navigation Buttons */}
              <div className="flex justify-between">
                {/* <button
                onClick={prevVideo}
                 className="hidden sm:flex items-center group hover-svg text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer text-[#61e063] border border-[#61e063] bottom-right-slant-hover hover:bg-[#61e063] hover:text-[#1A1A1A] px-5 py-2 rounded w-fit"
              >
                Previous
              </button>
              <button
                onClick={nextVideo}
                 className="hidden sm:flex items-center group hover-svg text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer text-[#61e063] border border-[#61e063] bottom-right-slant-hover hover:bg-[#61e063] hover:text-[#1A1A1A] px-5 py-2 rounded w-fit"
              >
                Next
              </button> */}
                <button
                  onClick={prevVideo}
                  className="w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] group hover-svg  rounded-md pl-2 group opacity-100 text-[#61e063] border-[#61e063] bottom-right-slant-hover hover:bg-[#61e063] hover:text-[#1A1A1A] z-[999]"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain block"
                  >
                    <path
                      d="M6.25 11.875L2.5 8.125L6.25 4.375"
                      stroke="#61e063"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                      stroke="#61e063"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain  hidden"
                  >
                    <path
                      d="M6.25 11.875L2.5 8.125L6.25 4.375"
                      stroke="#1A1A1A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                      stroke="#1A1A1A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </button>
                <button
                  onClick={nextVideo}
                  className="w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] group hover-svg  rounded-md pl-2 group opacity-100 text-[#61e063] border-[#61e063] bottom-right-slant-hover hover:bg-[#61e063] hover:text-[#1A1A1A] z-[999]"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain block"
                  >
                    <path
                      d="M13.75 11.875L17.5 8.125L13.75 4.375"
                      stroke="#61e063"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                      stroke="#61e063"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain  hidden"
                  >
                    <path
                      d="M14.25 11.875L18 8.125L14.25 4.375"
                      stroke="#1A1A1A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M3 15.625C3 13.6359 3.79018 11.7282 5.1967 10.3217C6.60322 8.91518 8.51088 8.125 10.5 8.125H18"
                      stroke="#1A1A1A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading ? <> </> : <Footer />}
    </>
  );
};

export default LabsList;
