import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../css/componentscss/Header.css";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { getHeaderAndFooterData } from "../services/ApiService";
import { useDispatch } from "react-redux";
import { setLanguage } from "../store/reducers/LanguageReducer";
import {
  getLanguage,
  languageCodes,
  languagesObj,
  navigateTo,
} from "../constants/GeneralConstants";
import { clientUrls } from "../constants/ClientUrls";
import Buttons from "./Buttons";
import { useSelector } from "react-redux";
const HeaderTemp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const controls = useAnimation();
  const controls2 = useAnimation();
  const controlsLangMobile = useAnimation();
  const [headerData, setHeaderData] = useState([]);
  const [languageMenu, setLanguageMenu] = useState([]);
  const [servicesMenu, setServicesMenu] = useState([]);
  const [isServiceOpen, setIsServiceOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLanguageValue, setSelectedLanguageValue] = useState("en");
  const [isServiceMobileMenu, setIsServiceMobileMenu] = useState(false);
  const [isLanguageMobileMenu, setIsLanguageMobileMenu] = useState(false);

  const langRef = useRef(null);
  const langRefMobile = useRef(null);
  const servicesRef = useRef(null);
  const serviceRefMobile = useRef(null);
  const { primary, secondary, tertiary, menusColor } = useSelector(
    (state) => state.color.color
  );
  const serviceUrlName = location.pathname.split("/").filter(Boolean).pop();
  const navData = useSelector((state) => state.language.headerData);
  useEffect(() => {
    setSelectedLanguageValue(getLanguage() === "en" ? "en" : getLanguage());
    callHeaderAndFooterApi(getLanguage());
  }, [getLanguage()]);

  useEffect(() => {
    if (showMobileMenu) {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = "100%";
      document.body.style.overflow = "hidden";
    } else {
      const scrollY = Math.abs(parseInt(document.body.style.top || "0"));
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      document.body.style.overflow = "";
      window.scrollTo({ top: scrollY, behavior: "instant" });
    }
  }, [showMobileMenu]);

  const handleLogoClick = () => {
    setShowMobileMenu(false);
    navigate(navigateTo(clientUrls.home));
    pageScrollTop();
  };

  const callHeaderAndFooterApi = (language) => {
    setHeaderData(navData?.header);
    setLanguageMenu(navData?.languagesMenu);
    setServicesMenu(navData?.servicesMenu);
    const lan = navData?.languagesMenu;
    const urlLang = lan?.find(
      (language) => language.languageCode === getLanguage()
    )?.languageValue;
    setSelectedLanguage(urlLang);
  };

  const handleLanguageChange = (
    selectedObj,
    languageModal,
    mobileModal = false
  ) => {
    let selectedLanguageCode = selectedObj.languageCode;
    setSelectedLanguage(selectedObj.languageValue);
    setSelectedLanguageValue(selectedLanguageCode);
    let existingUrl = location.pathname;
    languageCodes.some((code) => {
      if (existingUrl.includes(`/${code}`)) {
        existingUrl = existingUrl.replace(`/${code}`, "");
        return true;
      }
      return false;
    });

    if (existingUrl === "/") {
      existingUrl = "";
    }

    selectedLanguageCode === "en"
      ? navigate(`../${existingUrl}`, { replace: true })
      : navigate(`../${selectedLanguageCode}${existingUrl}`, { replace: true });
    callHeaderAndFooterApi(selectedLanguageCode);
    dispatch(setLanguage(selectedLanguageCode));
    if (languageModal === true) {
      handleLangClick();
    }
    if (mobileModal === true) {
      setShowMobileMenu(false);
    }
  };

  const handleServiceClick = () => {
    setIsServiceOpen(!isServiceOpen);

    if (isServiceOpen) {
      controls.start({ visibility: "hidden", opacity: 0 });
    } else {
      controls.start({ visibility: "visible", opacity: 1 });
    }
  };

  const handleLangClick = () => {
    setIsLangOpen(!isLangOpen);

    if (isLangOpen) {
      controls2.start({ visibility: "hidden", opacity: 0 });
    } else {
      controls2.start({ visibility: "visible", opacity: 1 });
    }
  };

  const handleClickOutside = (event) => {
    if (servicesRef.current && !servicesRef.current.contains(event.target)) {
      controls.start({ visibility: "hidden", opacity: 0 });

      setIsServiceOpen(false);
    }
    if (langRef.current && !langRef.current.contains(event.target)) {
      controls2.start({ visibility: "hidden", opacity: 0 });
      setIsLangOpen(false);
    }
    if (
      serviceRefMobile.current &&
      !serviceRefMobile.current.contains(event.target)
    ) {
      setIsServiceMobileMenu(false);
    }
    if (
      langRefMobile.current &&
      !langRefMobile.current.contains(event.target)
    ) {
      controlsLangMobile.start({ visibility: "hidden", opacity: 0 });

      setIsLanguageMobileMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const groupedServices = servicesMenu.reduce((result, service, index) => {
    const groupIndex = Math.floor(index / 2);
    if (!result[groupIndex]) {
      result[groupIndex] = [];
    }
    result[groupIndex].push(service);
    return result;
  }, []);

  const handleMobileNavigation = (url) => {
    setShowMobileMenu(false);
    navigate(navigateTo(url));
  };

  const { pathname: activePath } = location;

  const pageScrollTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 300);
  };
  return (
    <>
      {/* Header Component goes here */}
      <header className="text-white fixed top-0 w-full z-[999]" id="header">
        <section className="relative lg:mx-[0.5rem] lg:my-[0.4rem]">
          <nav
            className="relative flex items-center justify-between bg-[#1a1a1a] lg:bg-[#1a1a1acc] backdrop-blur-lg lg:rounded-[5px] uppercase p-2 lg:px-6 3xl:px-10 3xl:py-3 3xl:h-20"
            aria-label="Global"
          >
            <div
              className="flex ml-4 lg:ml-0 lg:mr-[5rem] 2xl:mr-0"
              onClick={handleLogoClick}
            >
              {!showMobileMenu ? (
                <a className="-m-1.5 p-1.5 text-white cursor-pointer">
                  {headerData?.sideLogo ? (
                    <img
                      alt="side logo"
                      src={headerData.sideLogo}
                      className="h-[42px] 3xl:h-11 4xl:h-20"
                    />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="73"
                      height="34"
                      viewBox="0 0 73 34"
                      // className="3xl:w-[6rem]"
                      fill="none"
                    >
                      <path
                        d="M11.5257 31.4714H0V27.2476H9.80847C10.0685 27.2476 10.3074 27.1011 10.4262 26.8701C10.6123 26.5071 10.446 26.0636 10.0672 25.9105L1.41893 22.439C0.842121 22.208 0.397304 21.7342 0.200633 21.1442C0.00528233 20.5542 0.0791947 19.9087 0.40258 19.3781L3.15993 14.8665C3.54404 14.2383 4.22644 13.8555 4.96165 13.8555H15.7785V18.0793H6.66305C6.3423 18.0793 6.044 18.2469 5.87637 18.5201C5.57938 19.0059 5.79057 19.6434 6.31987 19.8559L14.4903 23.136C15.273 23.4501 15.8155 24.2104 15.8168 25.108C15.8168 25.4485 15.7231 25.7838 15.5687 26.0873L13.4053 30.3204C13.045 31.0266 12.3177 31.4714 11.5244 31.4714H11.5257Z"
                        fill="white"
                      />
                      <path
                        d="M24.8674 13.8564H20.542V31.471H24.8674V13.8564Z"
                        fill="white"
                      />
                      <path
                        d="M22.6552 5.37109L20.2793 7.74698L22.7568 10.2245L25.1327 7.84862L22.6552 5.37109Z"
                        fill="white"
                      />
                      <path
                        d="M70.3704 31.4691H54.7529V13.8545H68.9713L72.7873 20.0978C73.2545 20.862 72.9179 21.8626 72.085 22.1912L62.3083 26.0389C61.5678 26.3306 61.7763 27.4301 62.5722 27.4301H70.3704V31.4691ZM58.3366 18.7845V21.6712C58.3366 22.3707 59.0401 22.8486 59.6908 22.5938L67.0231 19.7071C67.5959 19.4827 67.8256 18.7937 67.5062 18.2684C67.3254 17.974 67.0059 17.7945 66.6601 17.7945H59.3278C58.7801 17.7945 58.3379 18.238 58.3379 18.7845H58.3366Z"
                        fill="white"
                      />
                      <path
                        d="M38.2864 12.409V12.4064C32.8772 12.4064 28.4766 16.8071 28.4766 22.2162C28.4766 27.6253 32.8772 32.026 38.2864 32.026C40.6623 32.026 42.9312 31.1535 44.6907 29.6408C45.3982 30.9964 46.0568 32.2147 46.5848 33.1783H51.5372C51.2864 32.7374 49.4742 29.5352 47.5866 25.8143C48.1701 24.3637 48.5528 22.7983 48.607 22.5726L48.6162 22.5356C50.4958 14.0022 49.948 6.01657 47.2553 2.66656C46.0304 1.14071 44.6405 0.821289 43.6902 0.821289C42.3293 0.821289 41.082 1.4707 40.1765 2.64808C38.7906 4.45376 38.388 7.21771 38.2956 9.21874C38.2481 10.2575 38.2692 11.3333 38.3576 12.4104H38.2864V12.409ZM44.7527 19.8126C44.1218 18.329 43.607 16.9549 43.31 15.8435C42.7015 13.5613 42.4626 10.8634 42.6712 8.62873C42.8098 7.15568 43.1002 6.24228 43.351 5.71694C43.4975 5.41072 43.9265 5.3896 44.1007 5.6813C44.4795 6.30696 44.9666 7.53582 45.2332 9.72296C45.583 12.6123 45.4127 16.1418 44.7541 19.8139H44.7527V19.8126ZM38.289 27.7032C35.265 27.7032 32.8046 25.2428 32.8046 22.2188C32.8046 19.1948 35.2637 16.7345 38.289 16.7345C38.5583 16.7345 38.8249 16.7569 39.0889 16.7952C39.1034 16.8506 39.1166 16.9047 39.1311 16.9602C39.7489 19.2806 41.1414 22.4802 42.6356 25.5635C41.6047 26.898 39.9957 27.7045 38.289 27.7045V27.7032Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </a>
              ) : (
                <div className="h-[42px] 3xl:h-11 4xl:h-20"></div>
              )}
            </div>

            <div className="flex lg:hidden  gap-4">
              {!showMobileMenu ? (
                <button
                  type="button"
                  className="w-[40px] h-[40px] mr-4 sm:mr-0 inline-flex items-center justify-center p-1 rounded-[3px] text-primary border-[2px]  hover:text-black"
                  onClick={() => {
                    setShowMobileMenu(true);
                  }}
                  style={{ borderColor: secondary }}
                >
                  {/* <span className="sr-only">Open main menu</span> */}
                  <svg
                    className="size-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={secondary}
                    aria-hidden="true"
                    data-slot="icon"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                </button>
              ) : (
                ""
              )}

              {showMobileMenu ? (
                <button
                  className="w-[40px] h-[40px] mr-4 sm:mr-10 inline-flex items-center justify-center p-1 rounded-[3px] text-primary border-[2px] hover:text-black"
                  onClick={() => {
                    setShowMobileMenu(false);
                  }}
                  style={{ color: secondary, borderColor: secondary }}
                >
                  X
                </button>
              ) : (
                ""
              )}
              {!showMobileMenu ? (
                <div className="hidden sm:block lg:hidden">
                  <button
                    className="px-6 py-2 3xl:py-4  slant-btn-bottom text-[#1A1A1A] text-[18px] 3xl:text-2xl rounded-[3px] uppercase font-dela "
                    onClick={() => {
                      navigate(navigateTo(clientUrls.contact));
                      pageScrollTop();
                    }}
                    style={{ backgroundColor: secondary }}
                  >
                    {headerData.contactText}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>

            <motion.div
              initial={{ y: "-150%" }}
              animate={{ y: showMobileMenu ? 0 : "-150%" }}
              transition={{ duration: 0.3 }}
              id="mobile-menu"
              className="bg-[#1A1A1A] flex justify-between flex-col fixed top-14 left-0 overflow-y-autok overflow-x-hidden mobile-menu-h w-full lg:hidden font-semibold common-para-size mobile-menu-h overflow-y-scroll"
              style={{
                backgroundImage: `url(${headerData?.backgroundImage})`,
              }}
            >
              <div className="submenu-top px-9 sm:px-12 mt-6 sm:mt-14 z-50 relative uppercase">
                <div
                  onClick={(e) => {
                    setIsServiceMobileMenu(!isServiceMobileMenu);
                  }}
                  className="flex lg:block cursor-pointer justify-between items-center"
                  style={{
                    color:
                      isServiceMobileMenu || activePath.includes("services")
                        ? secondary
                        : "#ffffff",
                  }}
                  ref={serviceRefMobile}
                >
                  {headerData.servicesText}
                  {isServiceMobileMenu ? (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2711_44512)">
                        <path
                          d="M17.7469 4.34856L12.3469 0.194712C12.2531 0.137018 12.1547 0.0901432 12.0516 0.0540867C11.9484 0.0180283 11.8313 0 11.7 0C11.5687 0 11.4516 0.0180283 11.3484 0.0540867C11.2453 0.0901432 11.1469 0.137018 11.0531 0.194712L5.65312 4.34856C5.57812 4.42067 5.51719 4.49639 5.47031 4.57572C5.42344 4.65505 5.4 4.74519 5.4 4.84615C5.4 5.03365 5.48906 5.19591 5.66719 5.33293C5.84531 5.46995 6.05625 5.53846 6.3 5.53846C6.43125 5.53846 6.54844 5.52043 6.65156 5.48438C6.75469 5.44832 6.85312 5.40144 6.94687 5.34375L10.8 2.35817V9C10.8 10.0529 10.5375 11.0409 10.0125 11.9639C9.50625 12.887 8.80313 13.6947 7.90312 14.387C7.00312 15.0793 5.95313 15.6202 4.75312 16.0096C3.55312 16.4135 2.26876 16.6154 0.9 16.6154C0.656249 16.6154 0.445313 16.6839 0.267188 16.8209C0.0890617 16.9579 0 17.1202 0 17.3077C0 17.4952 0.0890617 17.6575 0.267188 17.7945C0.445313 17.9315 0.656249 18 0.9 18C2.51251 18 4.03124 17.762 5.45625 17.2861C6.88126 16.8101 8.11874 16.1683 9.16875 15.3606C10.2188 14.5529 11.0531 13.601 11.6719 12.5048C12.2906 11.4086 12.6 10.2404 12.6 9V2.35817L16.4531 5.34375C16.5469 5.40144 16.6453 5.44832 16.7484 5.48437C16.8516 5.52043 16.9687 5.53846 17.1 5.53846C17.3438 5.53846 17.5547 5.46995 17.7328 5.33293C17.9109 5.19591 18 5.03365 18 4.84615C18 4.74519 17.9766 4.65505 17.9297 4.57572C17.8828 4.49639 17.8219 4.42067 17.7469 4.34856Z"
                          fill={secondary}
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2711_44512">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="matrix(1 0 0 -1 0 18)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2694_71124)">
                        <path
                          d="M17.7469 13.6514L12.3469 17.8053C12.2531 17.863 12.1547 17.9099 12.0516 17.9459C11.9484 17.982 11.8313 18 11.7 18C11.5687 18 11.4516 17.982 11.3484 17.9459C11.2453 17.9099 11.1469 17.863 11.0531 17.8053L5.65312 13.6514C5.57812 13.5793 5.51719 13.5036 5.47031 13.4243C5.42344 13.345 5.4 13.2548 5.4 13.1538C5.4 12.9663 5.48906 12.8041 5.66719 12.6671C5.84531 12.53 6.05625 12.4615 6.3 12.4615C6.43125 12.4615 6.54844 12.4796 6.65156 12.5156C6.75469 12.5517 6.85312 12.5986 6.94687 12.6562L10.8 15.6418V9C10.8 7.94711 10.5375 6.95914 10.0125 6.03606C9.50625 5.11298 8.80313 4.30529 7.90312 3.61298C7.00312 2.92067 5.95313 2.37981 4.75312 1.99038C3.55312 1.58654 2.26876 1.38462 0.9 1.38462C0.656249 1.38462 0.445313 1.31611 0.267188 1.17909C0.0890617 1.04207 0 0.879809 0 0.692308C0 0.504807 0.0890617 0.342549 0.267188 0.205529C0.445313 0.068509 0.656249 0 0.9 0C2.51251 0 4.03124 0.237978 5.45625 0.713942C6.88126 1.18991 8.11874 1.83173 9.16875 2.63942C10.2188 3.44712 11.0531 4.39903 11.6719 5.49519C12.2906 6.59135 12.6 7.75961 12.6 9V15.6418L16.4531 12.6562C16.5469 12.5986 16.6453 12.5517 16.7484 12.5156C16.8516 12.4796 16.9687 12.4615 17.1 12.4615C17.3438 12.4615 17.5547 12.53 17.7328 12.6671C17.9109 12.8041 18 12.9663 18 13.1538C18 13.2548 17.9766 13.345 17.9297 13.4243C17.8828 13.5036 17.8219 13.5793 17.7469 13.6514Z"
                          fill={secondary}
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2694_71124">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                </div>
                <hr className="my-4 border-[#2C2C2C]" />
                {isServiceMobileMenu && (
                  <>
                    <div className="sub-menu uppercase">
                      <motion.ul
                        className="text-[#BEC0C5]"
                        initial={{
                          visibility: "hidden",
                          opacity: 0,
                        }}
                        animate={{ visibility: "visible", opacity: 1 }}
                        transition={{ duration: 0.5, ease: "linear" }}
                      >
                        {servicesMenu.map((data, index) => {
                          return (
                            <li
                              key={index}
                              className={`${
                                data.serviceURL === serviceUrlName
                                  ? "opacity-40 pointer-events-none"
                                  : ""
                              } my-5`}
                              onClick={(e) => {
                                navigate(
                                  navigateTo(
                                    `${clientUrls.services}/${data.serviceURL}`
                                  )
                                );
                                setShowMobileMenu(false);
                                pageScrollTop();
                              }}
                            >
                              {data.serviceName}
                            </li>
                          );
                        })}
                      </motion.ul>
                    </div>
                    <hr className="my-4 border-[#2C2C2C]" />
                  </>
                )}
                <div
                  onClick={(e) => {
                    handleMobileNavigation(clientUrls.projects);
                    pageScrollTop();
                  }}
                  className=" block cursor-pointer"
                  style={{
                    color: activePath.includes("projects")
                      ? secondary
                      : "#ffffff",
                  }}
                >
                  {headerData.projectsText}
                </div>
                <hr className="my-4 border-[#2C2C2C]" />
                <div
                  onClick={(e) => {
                    handleMobileNavigation(clientUrls.about);
                    pageScrollTop();
                  }}
                  className=" block cursor-pointer"
                  style={{
                    color:
                      activePath.includes("about") ||
                      activePath.includes("companyprofile")
                        ? secondary
                        : "#ffffff",
                  }}
                >
                  {headerData.aboutText}
                </div>
                <hr className="my-4 border-[#2C2C2C]" />
                <div
                  onClick={(e) => {
                    handleMobileNavigation(clientUrls.careers);

                    pageScrollTop();
                  }}
                  className=" block cursor-pointer"
                  style={{
                    color: activePath.includes("careers")
                      ? secondary
                      : "#ffffff",
                  }}
                >
                  {headerData.careersText}
                </div>
                <hr className="my-4 border-[#2C2C2C]" />
                <div
                  onClick={(e) => {
                    handleMobileNavigation(clientUrls.news);

                    pageScrollTop();
                  }}
                  className=" block cursor-pointer"
                  style={{
                    color: activePath.includes("news") ? secondary : "#ffffff",
                  }}
                >
                  {headerData.newsText}
                </div>
                <hr className="my-4 border-[#2C2C2C]" />
                <div
                  onClick={(e) => {
                    handleMobileNavigation(clientUrls.labs);

                    pageScrollTop();
                  }}
                  className=" block cursor-pointer"
                  style={{
                    color: activePath.includes("lab") ? secondary : "#ffffff",
                  }}
                >
                  {headerData.labText}
                </div>
                <hr className="my-4 border-[#2C2C2C]" />
                <div
                  onClick={(e) =>
                    setIsLanguageMobileMenu(!isLanguageMobileMenu)
                  }
                  className="mt-12 mb-2 w-full flex items-center"
                  ref={langRefMobile}
                >
                  <button
                    className="btn-white-navl  uppercase mr-2"
                    style={{
                      color: isLanguageMobileMenu ? secondary : "#ffffff",
                    }}
                  >
                    {selectedLanguage}
                  </button>
                  {isLanguageMobileMenu ? (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2711_44512)">
                        <path
                          d="M17.7469 4.34856L12.3469 0.194712C12.2531 0.137018 12.1547 0.0901432 12.0516 0.0540867C11.9484 0.0180283 11.8313 0 11.7 0C11.5687 0 11.4516 0.0180283 11.3484 0.0540867C11.2453 0.0901432 11.1469 0.137018 11.0531 0.194712L5.65312 4.34856C5.57812 4.42067 5.51719 4.49639 5.47031 4.57572C5.42344 4.65505 5.4 4.74519 5.4 4.84615C5.4 5.03365 5.48906 5.19591 5.66719 5.33293C5.84531 5.46995 6.05625 5.53846 6.3 5.53846C6.43125 5.53846 6.54844 5.52043 6.65156 5.48438C6.75469 5.44832 6.85312 5.40144 6.94687 5.34375L10.8 2.35817V9C10.8 10.0529 10.5375 11.0409 10.0125 11.9639C9.50625 12.887 8.80313 13.6947 7.90312 14.387C7.00312 15.0793 5.95313 15.6202 4.75312 16.0096C3.55312 16.4135 2.26876 16.6154 0.9 16.6154C0.656249 16.6154 0.445313 16.6839 0.267188 16.8209C0.0890617 16.9579 0 17.1202 0 17.3077C0 17.4952 0.0890617 17.6575 0.267188 17.7945C0.445313 17.9315 0.656249 18 0.9 18C2.51251 18 4.03124 17.762 5.45625 17.2861C6.88126 16.8101 8.11874 16.1683 9.16875 15.3606C10.2188 14.5529 11.0531 13.601 11.6719 12.5048C12.2906 11.4086 12.6 10.2404 12.6 9V2.35817L16.4531 5.34375C16.5469 5.40144 16.6453 5.44832 16.7484 5.48437C16.8516 5.52043 16.9687 5.53846 17.1 5.53846C17.3438 5.53846 17.5547 5.46995 17.7328 5.33293C17.9109 5.19591 18 5.03365 18 4.84615C18 4.74519 17.9766 4.65505 17.9297 4.57572C17.8828 4.49639 17.8219 4.42067 17.7469 4.34856Z"
                          fill={secondary}
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2711_44512">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="matrix(1 0 0 -1 0 18)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2694_71124)">
                        <path
                          d="M17.7469 13.6514L12.3469 17.8053C12.2531 17.863 12.1547 17.9099 12.0516 17.9459C11.9484 17.982 11.8313 18 11.7 18C11.5687 18 11.4516 17.982 11.3484 17.9459C11.2453 17.9099 11.1469 17.863 11.0531 17.8053L5.65312 13.6514C5.57812 13.5793 5.51719 13.5036 5.47031 13.4243C5.42344 13.345 5.4 13.2548 5.4 13.1538C5.4 12.9663 5.48906 12.8041 5.66719 12.6671C5.84531 12.53 6.05625 12.4615 6.3 12.4615C6.43125 12.4615 6.54844 12.4796 6.65156 12.5156C6.75469 12.5517 6.85312 12.5986 6.94687 12.6562L10.8 15.6418V9C10.8 7.94711 10.5375 6.95914 10.0125 6.03606C9.50625 5.11298 8.80313 4.30529 7.90312 3.61298C7.00312 2.92067 5.95313 2.37981 4.75312 1.99038C3.55312 1.58654 2.26876 1.38462 0.9 1.38462C0.656249 1.38462 0.445313 1.31611 0.267188 1.17909C0.0890617 1.04207 0 0.879809 0 0.692308C0 0.504807 0.0890617 0.342549 0.267188 0.205529C0.445313 0.068509 0.656249 0 0.9 0C2.51251 0 4.03124 0.237978 5.45625 0.713942C6.88126 1.18991 8.11874 1.83173 9.16875 2.63942C10.2188 3.44712 11.0531 4.39903 11.6719 5.49519C12.2906 6.59135 12.6 7.75961 12.6 9V15.6418L16.4531 12.6562C16.5469 12.5986 16.6453 12.5517 16.7484 12.5156C16.8516 12.4796 16.9687 12.4615 17.1 12.4615C17.3438 12.4615 17.5547 12.53 17.7328 12.6671C17.9109 12.8041 18 12.9663 18 13.1538C18 13.2548 17.9766 13.345 17.9297 13.4243C17.8828 13.5036 17.8219 13.5793 17.7469 13.6514Z"
                          fill={secondary}
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2694_71124">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                </div>
                {isLanguageMobileMenu && (
                  <motion.div
                    className="sub-menu uppercase "
                    initial={{
                      visibility: "hidden",
                      opacity: 0,
                    }}
                    animate={{ visibility: "visible", opacity: 1 }}
                    transition={{ duration: 0.5, ease: "linear" }}
                  >
                    <ul className="text-[#BEC0C5]">
                      {languageMenu.map((data, index) => {
                        return (
                          <li
                            onClick={(e) => {
                              handleLanguageChange(data, true, true);
                              setIsLanguageMobileMenu(!isLanguageMobileMenu);
                            }}
                            key={index}
                            className={`my-5 ${
                              selectedLanguage === data?.languageValue
                                ? "opacity-40 pointer-events-none"
                                : ""
                            } `}
                          >
                            {data.languageValue}
                          </li>
                        );
                      })}
                      {/* <li className="py-2">English</li>
                    <li className="py-2">Canadian French (Français)</li>
                    <li className="py-2">Japanese 日本語</li>
                    <li className="py-2">Korean 한국어</li>
                    <li className="py-2">Simplified Chinese 简体中文</li>
                    <li className="py-2">Traditional Chinese 繁體中文</li> */}
                    </ul>
                  </motion.div>
                )}
                {/* <div className="btn-white-wrapper w-full my-4">
                  <button className="btn-white1 px-6 py-2 border-[2px] border-[#fff] text-[#fff] font-black uppercase w-full">
                    Contact
                  </button>
                </div> */}
                <div className="w-full my-4">
                  <Buttons
                    className="px-6 py-2 text-[18px] text-[#1a1a1a] uppercase w-full font-dela rounded-[4px] mb-4"
                    buttonText={headerData.contactText}
                    onClick={(e) => {
                      handleMobileNavigation(clientUrls.contact);
                    }}
                    style={{ backgroundColor: secondary }}
                  />
                </div>
              </div>
            </motion.div>
          </nav>
          <nav
            className="w-[90%] 2xl:w-4/5a 2xl:w-[87%]
          ] absolute flex items-center justify-between right-0 top-1/2 -translate-y-[50%] uppercase  lg:px-6 3xl:px-10 3xl:py-3"
          >
            {/* Desctop */}
            <motion.ul
              className="hidden lg:flex lg:gap-x-[1rem] xl:gap-x-[2rem] 2xl:gap-x-12 list-none w-[65%] xl:w-[67%] justify-between items-center"
              initial={{ scale: 1 }}
              // whileHover={{ scale: 1.005 }}
              transition={{ type: "spring", stiffness: 5000, duration: 500 }}
            >
              <motion.li
                className="relative"
                onClick={handleServiceClick}
                ref={servicesRef}
              >
                <motion.button
                  type="button"
                  className={`flex items-center gap-x-1 text-[12px] lg:text-[11px] xl:text-[14px]  3xl:text-[18px] 4xl:text-[22px] font-semibold uppercase hover:font-bold `}
                  style={{
                    color:
                      isServiceOpen || activePath.includes("services")
                        ? secondary
                        : "#ffffff",
                    fontWeight:
                      isServiceOpen || activePath.includes("services")
                        ? 900
                        : 600,
                  }}
                  aria-expanded="false"
                  initial={{
                    fontWeight:
                      isServiceOpen || activePath.includes("services")
                        ? 900
                        : 600,
                  }}
                  whileHover={{ fontWeight: 900, scale: 1 }}
                  transition={{ type: "spring", stiffness: 5000 }}
                >
                  <Link>{headerData?.servicesText}</Link>
                  {isServiceOpen ? (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 platforms"
                    >
                      <path
                        d="M8.625 6.25L12.375 2.5L16.125 6.25"
                        stroke={secondary}
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                        stroke={secondary}
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <path
                        d="M8.125 13.75L11.875 17.5L15.625 13.75"
                        stroke={secondary}
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                        stroke={secondary}
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  )}
                </motion.button>
                {/* services dropdown starts here */}
                <motion.div
                  initial={{
                    visibility: "hidden",
                    opacity: 0,
                  }}
                  animate={controls}
                  transition={{ duration: 0.5 }}
                  className="header-service-dropdown absolute -left-8 top-11 3xl:top-[3.75rem] z-10 w-screen max-w-xs rounded-[10px] bg-[#1a1a1acc]   flex flex-col p-2 backdrop-blur-sm"
                >
                  {/* <div className="w-full h-full backdrop-blur-lg "> */}
                  {servicesMenu?.map((service) => (
                    <Link
                      key={service?.serviceName}
                      to={navigateTo(
                        `${clientUrls.services}/${service.serviceURL}`
                      )}
                      // to={navigateTo(
                      //   `${clientUrls.services}/${service.serviceURL}`
                      // )}
                      className={`${
                        service.serviceURL === serviceUrlName
                          ? "opacity-40 pointer-events-none"
                          : ""
                      } py-2 flex justify-between gap-x-2.5 p-2 4xl:p-3 text-[12px] xl:text-[14px]  4xl:text-xl  font-semibold text-white rounded-md group back ${
                        menusColor ? "hover:text-white" : "hover:text-[#1a1a1a]"
                      } `}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = secondary)
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                      onClick={() => {
                        pageScrollTop();
                      }}
                    >
                      {service.serviceName}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="hidden group-hover:block"
                      >
                        <path
                          d="M13.75 11.875L17.5 8.125L13.75 4.375"
                          stroke="#fff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                          stroke="#fff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Link>
                  ))}
                  {/* </div> */}
                </motion.div>
                {/* services dropdown ends here  */}
              </motion.li>

              <motion.li
                initial={{
                  fontWeight: activePath.includes("projects") ? 900 : 600,
                }}
                whileHover={{ fontWeight: 900, scale: 1 }}
                transition={{ type: "spring", stiffness: 5000 }}
                className={`text-[12px] lg:text-[11px] xl:text-[14px]  3xl:text-[18px] 4xl:text-[22px]  hover:font-extrabold`}
                style={{
                  color: activePath.includes("projects")
                    ? secondary
                    : "#ffffff",
                  fontWeight: activePath.includes("projects") ? 900 : 600,
                }}
              >
                <Link
                  to={navigateTo(clientUrls.projects)}
                  onClick={() => {
                    pageScrollTop();
                  }}
                >
                  {headerData.projectsText}
                </Link>
              </motion.li>
              <motion.li
                initial={{
                  fontWeight:
                    activePath.includes("about") ||
                    activePath.includes("companyprofile")
                      ? 900
                      : 600,
                }}
                whileHover={{ fontWeight: 900, scale: 1 }}
                transition={{ type: "spring", stiffness: 5000 }}
                className={`text-[12px] lg:text-[11px] xl:text-[14px]  3xl:text-[18px] 4xl:text-[22px] `}
                style={{
                  color:
                    activePath.includes("about") ||
                    activePath.includes("companyprofile")
                      ? secondary
                      : "#ffffff",
                  fontWeight:
                    activePath.includes("about") ||
                    activePath.includes("companyprofile")
                      ? 900
                      : 600,
                }}
              >
                <Link
                  to={navigateTo(clientUrls.about)}
                  onClick={() => {
                    pageScrollTop();
                  }}
                >
                  {headerData.aboutText}
                </Link>
              </motion.li>
              <motion.li
                initial={{
                  fontWeight: activePath.includes("careers") ? 900 : 600,
                }}
                whileHover={{ fontWeight: 900, scale: 1 }}
                transition={{ type: "spring", stiffness: 5000 }}
                className={`text-[12px] lg:text-[11px] xl:text-[14px]  3xl:text-[18px] 4xl:text-[22px] font-semibold `}
                style={{
                  color: activePath.includes("careers") ? secondary : "#ffffff",
                  fontWeight: activePath.includes("careers") ? 900 : "",
                }}
              >
                <Link
                  to={navigateTo(clientUrls.careers)}
                  onClick={() => {
                    pageScrollTop();
                  }}
                >
                  {headerData.careersText}
                </Link>
              </motion.li>
              <motion.li
                initial={{
                  fontWeight: activePath.includes("news") ? 900 : 600,
                }}
                whileHover={{ fontWeight: 900, scale: 1 }}
                transition={{ type: "spring", stiffness: 5000 }}
                className={`text-[12px] lg:text-[11px] xl:text-[14px]  3xl:text-[18px] 4xl:text-[22px] font-semibold `}
                style={{
                  color: activePath.includes("news") ? secondary : "#ffffff",
                }}
              >
                <Link
                  to={navigateTo(clientUrls.news)}
                  onClick={() => {
                    pageScrollTop();
                  }}
                >
                  {headerData.newsText}
                </Link>
              </motion.li>
              <motion.li
                initial={{
                  fontWeight: activePath.includes("labs") ? 900 : 600,
                }}
                whileHover={{ fontWeight: 900, scale: 1 }}
                transition={{ type: "spring", stiffness: 5000 }}
                className={`text-[12px] lg:text-[11px] xl:text-[14px]  3xl:text-[18px] 4xl:text-[22px] font-semibold`}
                style={{
                  color: activePath.includes("lab") ? secondary : "#ffffff",
                  fontWeight: activePath.includes("lab") ? 900 : "",
                }}
              >
                <Link
                  to={navigateTo(clientUrls.labs)}
                  onClick={() => {
                    pageScrollTop();
                  }}
                >
                  {headerData.labText}
                </Link>
              </motion.li>
            </motion.ul>
            <div className="hidden lg:flex  lg:justify-end lg:gap-6 xl:gap-7 4xl:gap-12 items-center">
              <div
                className="hidden lg:flex lg:gap-x-4 2xl:gap-x-12 relative"
                ref={langRef}
              >
                <motion.li
                  whileHover={{ fontWeight: "bold", scale: 1.1 }}
                  transition={{ type: "spring", stiffness: 100, damping: 20 }}
                  className="list-none"
                >
                  <button
                    type="button"
                    className="flex items-center gap-x-1 text-[12px] lg:text-[11px] xl:text-[14px]  3xl:text-[18px] 4xl:text-[22px] font-semibold text-red uppercase "
                    aria-expanded="false"
                    onClick={handleLangClick}
                    // onMouseEnter={(e) => {
                    //   e.currentTarget.style.color = secondary;
                    // }}
                    // onMouseLeave={(e) => {
                    //   e.currentTarget.style.color = "#ffffff";
                    // }}
                    style={{ color: isLangOpen ? secondary : "#fff" }}
                  >
                    {selectedLanguage}
                    {isLangOpen ? (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2 platforms"
                      >
                        <path
                          d="M8.625 6.25L12.375 2.5L16.125 6.25"
                          stroke={secondary}
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                          stroke={secondary}
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2"
                      >
                        <path
                          d="M8.125 13.75L11.875 17.5L15.625 13.75"
                          stroke={secondary}
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                          stroke={secondary}
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    )}
                  </button>
                </motion.li>
              </div>
              {/*language dropdown starts here */}
              <motion.div
                className="absolute top-14 3xl:top-[5.5rem] z-10 right-0 w-screen max-w-xs 3xl:max-w-[25rem] rounded-[10px] mt-[0.4rem] bg-[#1a1a1acc] p-2 backdrop-blur-sm"
                initial={{
                  visibility: "hidden",
                  opacity: 0,
                }}
                animate={controls2}
                transition={{ duration: 0.5 }}
              >
                {languageMenu.map((data, index) => {
                  return (
                    <div
                      key={data?.language}
                      onClick={(e) => handleLanguageChange(data, true)}
                      className={`flex justify-between cursor-pointer  p-2 4xl:py-3 text-[12px] xl:text-[14px]  4xl:text-xl font-semibold text-white  rounded-md group ${
                        selectedLanguage === data?.languageValue
                          ? "opacity-40 pointer-events-none"
                          : ""
                      } ${
                        menusColor ? "hover:text-white" : "hover:text-[#1a1a1a]"
                      }`}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = secondary)
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      {data.languageValue}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="hidden group-hover:block"
                      >
                        <path
                          d="M13.75 11.875L17.5 8.125L13.75 4.375"
                          stroke="#fff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                          stroke="#fff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  );
                })}
              </motion.div>
              {/*language dropdown ends here  */}
              <button
                className="px-6 py-2 2xl:py-2.5 3xl:py-3 4xl:py-4  slant-btn-bottom text-[#1A1A1A] text-[18px] lg:text-sm xl:text-lg 3xl:text-2xl 4xl:text-3xl rounded-[3px] uppercase font-dela lg:!min-h-10 xl:!min-h-12 2xl:!min-h-12 3xl:!min-h-14  lg:!min-w-36 xl:!min-w-[10.5rem] 3xl:!min-w-52"
                onClick={() => {
                  navigate(navigateTo(clientUrls.contact));
                  pageScrollTop();
                }}
                style={{ backgroundColor: secondary }}
              >
                {headerData.contactText}
              </button>
            </div>
          </nav>
        </section>
      </header>
    </>
  );
};

const NetworkError = () => {
  return (
    <>
      <HeaderTemp />

      <div className="flex flex-col justify-center items-center h-[100vh] sm:h-[80vh]  p-12 text-center">
        <h2 className="font-36-36-36 mb-2 leading-[1] text-primary">
          Network Error
        </h2>

        <p className="font-16-16-16 text-white mb-4">
          {" "}
          Oops! Something went wrong. Please check your connection and try
          again.
        </p>
      </div>
    </>
  );
};

export default NetworkError;
