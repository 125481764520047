import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { motion, useInView } from "framer-motion";

const Buttons = ({
  className = "",
  buttonText,
  to,
  onClick,
  id,
  style,
  borderColor = "#2ad9ff",
  animationDelay = 0.2,
  animated = false, // Enables animation only if this is true
  children, // SVG or any extra elements passed as children
  onMouseEnter,
  onMouseLeave,
}) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-50px" });

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    } else if (to) {
      navigate(to);
    }
  };

  return (
    <button
      ref={ref}
      id={id}
      className={`relative overflow-hidden px-7 sm:px-4 md:px-7 py-2 rounded-md ${className}`}
      onClick={handleClick}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <motion.span
        initial={animated ? { opacity: 0, y: 10 } : {}}
        animate={animated && isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5, delay: animationDelay + 0.6 }}
        className="relative z-10 block text-center"
      >
        {buttonText}
      </motion.span>

      {children && (
        <div className="absolute inset-0 w-full h-full">{children}</div>
      )}

      {animated && (
        <>
          <motion.span
            initial={{ height: "0%" }}
            animate={isInView ? { height: "100%" } : {}}
            transition={{ duration: 0.4, delay: animationDelay }}
            className="absolute left-0 top-0 w-[2px]"
            style={{ backgroundColor: borderColor }}
          />
          <motion.span
            initial={{ height: "0%" }}
            animate={isInView ? { height: "100%" } : {}}
            transition={{ duration: 0.4, delay: animationDelay }}
            className="absolute right-0 top-0 w-[2px]"
            style={{ backgroundColor: borderColor }}
          />

          <motion.span
            initial={{ width: "0%" }}
            animate={isInView ? { width: "100%" } : {}}
            transition={{ duration: 0.4, delay: animationDelay + 0.4 }}
            className="absolute top-0 left-0 h-[2px]"
            style={{ backgroundColor: borderColor }}
          />
          <motion.span
            initial={{ width: "0%" }}
            animate={isInView ? { width: "100%" } : {}}
            transition={{ duration: 0.4, delay: animationDelay + 0.4 }}
            className="absolute bottom-0 left-0 h-[2px]"
            style={{ backgroundColor: borderColor }}
          />
        </>
      )}
    </button>
  );
};

export default Buttons;
