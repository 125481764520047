import React, { useEffect } from "react";
import CommonTopBanner from "./CommonTopBanner";
import ParagraphSection from "./ParagraphSection";
import SwiperSlideCommon from "../SwiperSlideCommon";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setColor } from "../../store/reducers/ColorReducer";
import ProjectsShowSection from "../home/ProjectsShowSection";
import NewsEvents from "../home/NewsEvents";
import OurServices from "../home/OurServices";
import OurPartners from "../home/OurPartners";
import { clientUrls } from "../../constants/ClientUrls";
import FormatText from "../FormatText";
import CommonYoutube from "../CommonYoutube";
import Footer from "../Footer";

const PlayerSupport = ({ PlayerData }) => {
  const {
    servicePageData,
    subServices,
    services,
    ourPartners,
    labs,
    playersCommunityTop,
    projects,
    serviceColors,
    innovatingVision,
    type,
  } = PlayerData;
  const dispatch = useDispatch();
  const { lang } = useParams();
  useEffect(() => {
    dispatch(
      setColor({
        primary: serviceColors[0]?.colorCode,
        secondary: serviceColors[0]?.secondaroyColor,
        tertiary: serviceColors[0]?.tertiaryColor,
      })
    );
  }, [PlayerData, lang]);

  useEffect(() => {
    document.title = PlayerData?.servicePageData?.playerSEOTitle
      ? PlayerData?.servicePageData?.playerSEOTitle
      : "Video Game Player Support Services | Multilingual & Technology Support | Side";
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  const {
    bannerSection_heading1Text,
    bannerSection_heading2Text,
    fromTheLabText,
    visitTheLabText,
    serviceTypeBgImageMob,
  } = servicePageData;

  return (
    <>
      <section>
        <CommonTopBanner
          servicePageData={servicePageData}
          color={{
            primary: serviceColors[0]?.colorCode,
            tertiary: serviceColors[0]?.tertiaryColor,
          }}
        />
        <div className="mb-20 sm:mb-28 lg:mb-40">
          <ParagraphSection
            servicePageData={servicePageData}
            color={{
              primary: serviceColors[0]?.colorCode,
              tertiary: serviceColors[0]?.tertiaryColor,
            }}
          />
        </div>
        <div
          className="bg-no-repeat player-support-bg-1  bg-contain relative"
          // style={{
          //   backgroundImage: `url(${servicePageData?.serviceTypeBgImage})`,
          // }}
        >
          <div className="hidden sm:block absolute right-0 top-[20rem] w-[45%] 3.5xl:w-[50%] z-[-1]">
            <img
              src={servicePageData?.serviceTypeBgImage}
              alt="ptw-side"
              className=""
            />
          </div>
          <div className="sm:hidden absolute right-0 z-[-1]">
            <img src={serviceTypeBgImageMob} alt="ptw-side" />
          </div>
          {/* youtube video starts here */}

          {/* <CommonYoutube url={servicePageData?.serviceVideo} /> */}
          {/* youtube video ends here */}

          {/* paragraph starts here */}
          <section className="container mb-32  text-white flex flex-col sm:flex-row sm:gap-x-8 lg:gap-x-12 xl:gap-x-12 ">
            <h2 className="uppercase leading-[1] text-3xl xs-only:mb-4 sm:w-[55%] sm:text-[32px] md:text-4xl  lg:text-[38px] xl:text-5xl 2xl:text-[52px] 3xl:text-[62px]">
              {/* Exceptional player <br className="sm:hidden" /> support on a truly
            global scale */}
              {servicePageData?.exceptionalPlayerSection_headingText}
            </h2>
            <div className="text-[18px] 2xl:text-xl 3xl:text-2xl font-medium leading-[133%] sm:w-[45%]">
              <p className=" ">
                <FormatText
                  text={
                    servicePageData?.exceptionalPlayerSection_exceptionalParagraph1
                  }
                />
              </p>

              <p>
                <FormatText
                  text={
                    servicePageData?.exceptionalPlayerSection_exceptionalParagraph2
                  }
                />
              </p>
            </div>
          </section>
          {subServices?.map((service, index) => {
            return (
              <SwiperSlideCommon
                service={service}
                key={index}
                color={{
                  primary: serviceColors[0]?.colorCode,
                  tertiary: serviceColors[0]?.tertiaryColor,
                }}
                showHeading={true}
              />
            );
          })}
        </div>

        {/* Our patners Section */}
        <OurPartners
          homeData={servicePageData}
          clientsData={ourPartners}
          lang={lang}
        />
        {/* paragraph ends here */}

        {/*------------innovating section--------------------*/}
        <div className="relative">
          <div className="hidden sm:block lg:hidden absolute sm:h-1/2 md:h-2/5 w-auto bottom-60 md:bottom-96 -left-[40rem] -z-[1] ">
            <img
              className="w-4/5 h-auto"
              src={servicePageData?.labsBackgroundImage}
            />
          </div>
          <section className="container flex flex-col gap-y-8 lg:flex-row-reverse lg:items-center lg:gap-x-20 lg:2xl:gap-x-32">
            <div className="lg:w-[63%]">
              <h2
                className=" uppercase text-4xl leading-[1] mb-8 lg:text-[39px] xl:text-5xl 2xl:text-[52px] 3xl:text-[62px]"
                style={{ color: serviceColors[0]?.colorCode }}
              >
                {/* Innovating to bring your vision to life */}
                {servicePageData?.innovatingSection_headingText}
              </h2>
              <p className="font-18-18-18 leading-[120%] text-white mb-6 font-medium ">
                <FormatText
                  text={servicePageData?.innovatingSection_paragraph1Text}
                />
              </p>
              <p className="font-18-18-18 leading-[120%] text-white font-medium">
                <FormatText
                  text={servicePageData?.innovatingSection_paragraph2Text}
                />
              </p>
            </div>
            <div className="grid  grid-cols-2 sm:grid-cols-3 gap-4 sm:w-4/5 lg:w-[37%] lg:grid-cols-2">
              {innovatingVision?.map((logo, meta_value) => {
                return (
                  <div className="border-[0.16px] border-[#ffffff20] flex justify-center items-center px-2 py-5 lg:py-5">
                    <img
                      src={logo.innovatingSection_serviceLogo}
                      key={logo.meta_value}
                      alt="client-logo"
                      className="object-contain h-[56px] 3xl:h-[70px]"
                    />
                  </div>
                );
              })}
            </div>
          </section>

          <section className="my-24 sm:my-[8rem] lg:mt-[14rem] lg:mb-0">
            <ProjectsShowSection
              homeData={servicePageData}
              projectsData={projects}
              colors={{
                color: serviceColors[0]?.secondaroyColor,
              }}
              isBtnBottom={true}
              redirectionFrom={type}
            />
          </section>
        </div>
        <div className="relative lg:pt-64 xl:pt-[7rem] 2xl:pt-72">
          <div className="hidden lg:block absolute h-auto w-[90%] xl-only:w-[80%] 2.5xl:w-[85%] 3xl:w-[85%] 3.3xl:w-[80%] 4xl:w-[75%] top-4 -z-[1] ">
            <img
              className="w-4/5 h-auto"
              src={servicePageData?.labsBackgroundImage}
            />
          </div>
          <NewsEvents
            header1={
              bannerSection_heading1Text + " " + bannerSection_heading2Text
            }
            header2={fromTheLabText}
            headerButtonText={visitTheLabText}
            cardsData={labs}
            colors={{
              color: serviceColors[0]?.secondaroyColor,
              tertiaryColor: serviceColors[0]?.tertiaryColor,
            }}
            url={clientUrls.labs}
            toLabs={true}
            type={type}
          />
        </div>

        <OurServices
          servicesData={services}
          header1={servicePageData?.exploreText}
          header2={servicePageData?.moreText}
          colors={{ color: serviceColors[0]?.colorCode }}
          lang={lang}
        />
      </section>
      <Footer />
    </>
  );
};

export default PlayerSupport;
