import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Lottie from "react-lottie-player";

import lottieJson from "./lottieloader.json";

const Loader = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="h-[101vh] w-full flex items-center justify-center">
      {/* <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}
        className="logo"
      >
        {" "}
        <img
          alt="side logo loader"
          src="/images/Sidelogo.png"
          className="4xl:h-20"
        />
      </motion.div> */}
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 150, height: 150 }}
      />
    </div>
  );
};

export default Loader;
