import { navigateTo, truncate } from "../../constants/GeneralConstants";
import { useNavigate } from "react-router-dom";
import { clientUrls } from "../../constants/ClientUrls";
import PIL from "../../components/PIL";

const LabsArticleCard = ({ labs }) => {
  const navigate = useNavigate();
  return (
    <div
      className="my-4 cursor-pointer  group news-hover-slant-parent"
      onClick={(e) =>
        navigate(navigateTo(`${clientUrls.labs}/${labs.labsURL}`))
      }
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 news-hover-slant">
        <div className="img-partk flex items-center w-full relative">
          <div className="hidden cursor-pointer absolute top-0 right-0 bg-[#1a1a1a] h-12 w-12 4xl:h-20 4xl:w-20 group-hover:flex items-center justify-center z-[3] rounded-bl-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#61e063"
              viewBox="0 0 24 24"
              stroke-width="3"
              stroke="#61e063"
              className="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
              ></path>
            </svg>
          </div>
          {/* desktop img  */}
          <PIL
            lowQaulitySrc={labs.labsImageLowQuality}
            highQualitySrc={labs.labsImageDesktop}
            className="object-fill w-full h-auto rounded-md hidden sm:block"
            alt={labs.title}
          />
          {/* mobile img  */}
          <PIL
            lowQaulitySrc={labs.labsImageLowQuality}
            highQualitySrc={labs.labsImageMob}
            className="object-fill w-full h-auto rounded-md sm:hidden"
            alt={labs.title}
          />
        </div>
        <div className="justify-between flex flex-col">
          <div className="flex gap-4 text-[14px] 1.5xl:text-[16px] 2xl:text-[18.6px] 3xl:text-[24px] 4xl:text-[28px]">
            <p className="text-[#61e063]">{labs?.labsPublishedDate}</p>
            {/* <p className="text-[#efefef] uppercase">Press releases</p> */}
          </div>
          <p className="text-[#fff] font-18-20-20 leading-[130%] font-bold line-clamp-2 break-keep">
            {labs.title}
          </p>
          <p className="text-[#FFFFFF] text-[16px] 1.5xl:text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32px] text-wrap break-words leading-[130%] lg-only:line-clamp-4">
            {truncate(labs.labsShortDescription, 150)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LabsArticleCard;
