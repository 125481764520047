import React, { useEffect, useRef, useState } from "react";
import "../../css/pagescss/About.css";
import Draggable from "react-draggable";
import { ArrowLeft, ArrowRight } from "../CommonComponents";
import { getLanguage } from "../../constants/GeneralConstants";

const Timeline = ({ aboutData, timelineData }) => {
  const [timelineWidth, setTimelineWidth] = useState(0);
  const [timelineOffsetWidth, setTimelineOffsetWidth] = useState(0);
  const [isHoverPrev, setIsHoverPrev] = useState(false);
  const [isHoverNext, setIsHoverNext] = useState(false);
  const [initialActiveEventIndex, setInitialActiveEventIndex] = useState();
  const [initialActivePointPosition, setInitialActivePointPosition] =
    useState();
  const [initialTranslate, setInitialTranslate] = useState(0);
  const [currentEventObj, setCurrentEventObj] = useState({});
  const [isActiveArr, setIsActiveArr] = useState([]);
  const timelineRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isDragHover, setIsDragHover] = useState(false);
  const [isDragOnDiv, setIsDragOnDiv] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: -initialTranslate, y: 0 });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isMobile, setIsMobile] = useState(true);
  let activeYear = "";
  let showActiveYear = false;

  const handleMouseMove = (e) => {
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect();
      setCursorPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    }
  };

  const handleActiveEventPosition = () => {
    const el = document.getElementById("timeline-div");
    setTimelineWidth(el?.scrollWidth);
    setTimelineOffsetWidth(el?.offsetWidth);
    setInitialTranslate(el?.scrollWidth - el?.offsetWidth + 72);
    setPosition({ x: -(el?.scrollWidth - el?.offsetWidth + 72), y: 0 });

    const initIndex = timelineData
      .map((obj) => obj.isActive === "Yes")
      .lastIndexOf(true);
    setInitialActiveEventIndex(initIndex);
    setTimeout(() => {
      setCurrentEventObj(timelineData?.[initIndex]);
    }, 1000);
    setIsActiveArr(
      timelineData
        ?.filter((el) => el.isActive === "Yes")
        .map((el) => el?.post_id)
    );
    const element = document.getElementById("initialActiveSpan");

    setTimeout(() => {
      if (element) {
        setInitialActivePointPosition(element?.getBoundingClientRect()?.left);
      }
    }, 1200);
  };
  const handleTimelineMove = (index) => {
    const index2 = timelineData?.findIndex(
      (el) => el.post_id === currentEventObj.post_id
    );
    const clickedDiv = document.querySelector(
      `.child-span-${index || index === 0 ? index : index2}`
    );
    const targetDiv = document.getElementById("initialActiveSpan");
    if (targetDiv) {
      const clickedSpanRect = clickedDiv?.getBoundingClientRect();
      const targetSpanRect = targetDiv?.getBoundingClientRect();

      const distance = targetSpanRect?.left - clickedSpanRect?.left;

      const timlineDiv = document.getElementById("timeline-div");
      if (timlineDiv) {
        timlineDiv.style.transform = `translateX(${
          -initialTranslate + distance
        }px)`;
      }

      setPosition({ x: -initialTranslate + distance, y: 0 });
    }
  };
  useEffect(() => {
    if (!initialActivePointPosition) handleActiveEventPosition();
    let prevWidth = window.innerWidth;
    let prevHeight = window.innerHeight;

    const handleResize = () => {
      const currentWidth = window.innerWidth;
      const currentHeight = window.innerHeight;
      if (currentWidth !== prevWidth) {
        setWindowWidth(currentWidth);
        setTimeout(() => {
          handleActiveEventPosition();
        }, 500);
        prevWidth = currentWidth;
      }
      prevHeight = currentHeight;
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [timelineData, windowWidth]);

  const handleArrowClick = (arrow) => {
    let tempObj;
    if (arrow === "PREV") {
      const prevTemp = isActiveArr.indexOf(currentEventObj.post_id);
      tempObj = timelineData?.find(
        (obj) => obj.post_id === isActiveArr[prevTemp - 1]
      );
      setTimeout(() => {
        setCurrentEventObj(tempObj);
      }, 1000);
    } else {
      const nextTemp = isActiveArr.indexOf(currentEventObj.post_id);
      tempObj = timelineData?.find(
        (obj) => obj.post_id === isActiveArr[nextTemp + 1]
      );
      setTimeout(() => {
        setCurrentEventObj(tempObj);
      }, 1000);
    }
    handleTimelineMove(timelineData?.indexOf(tempObj));
  };
  function findClosestIndex(array, target) {
    let closestIndex = 0;
    let closestDifference = Math.abs(target - array[0]);
    array.forEach((value, i) => {
      const currentDifference = Math.abs(target - value);

      if (currentDifference < closestDifference) {
        closestIndex = i;
        closestDifference = currentDifference;
      }
    });

    return closestIndex;
  }
  const handleDrag = (e, ui) => {
    const newX = position.x + ui.deltaX;
    setPosition({ x: newX, y: position.y });

    setIsDragHover(true);
    const elements = document.querySelectorAll("[data-event-index]");

    const elementsArray = Array.from(elements);

    const elementBounderyClients = elementsArray.map(
      (el) => el.getBoundingClientRect().left
    );

    const closestValueIndex = findClosestIndex(
      elementBounderyClients,
      initialActivePointPosition
    );

    const closestElement = elementsArray[closestValueIndex];

    const objIndex = closestElement.dataset.eventIndex;

    let curObj = timelineData?.[objIndex];

    setCurrentEventObj(curObj);
  };

  const handleDragStop = (e, ui) => {
    const newX = position.x + ui.deltaX;
    setPosition({ x: newX, y: position.y });
    const timer = setTimeout(() => {
      setIsDragging(false);
      clearTimeout(timer);
    }, 400);
    if (!isDragOnDiv) setIsDragHover(false);

    const elements = document.querySelectorAll("[data-event-index]");

    const elementsArray = Array.from(elements);
    setTimeout(() => {
      const elementBounderyClients = elementsArray.map(
        (el) => el.getBoundingClientRect().left
      );

      const closestValueIndex = findClosestIndex(
        elementBounderyClients,
        initialActivePointPosition
      );

      const closestElement = elementsArray[closestValueIndex];

      const objIndex = closestElement.dataset.eventIndex;

      let curObj = timelineData[objIndex];

      setCurrentEventObj(curObj);

      handleTimelineMove(objIndex);
    }, 300);
  };
  const handleMouseEnter = () => {
    setIsDragHover(true);
    setIsDragOnDiv(true);
    setIsMobile(false);
  };
  const handleMouseEnterMobile = () => {
    setIsDragHover(true);
    setIsDragOnDiv(true);
    setIsMobile(true);
  };
  const handleMouseLeave = () => {
    setIsDragHover(false);
    setIsDragOnDiv(false);
  };

  const isTouchDevice = () => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };

  return (
    <section>
      <div className="container mb-20">
        <h2 className="text-3xl sm:text-4xl 2xl:text-[40px] 3xl:text-[44px] 4xl:text-[48px] leading-[1] text-white  lg:w-3/5">
          {aboutData?.historyHeadingText}
        </h2>
      </div>
      <div className="relative h-80">
        <div
          className="h-80 absolute bottom-[16.6rem] lg:bottom-[16.8rem]"
          style={{ left: `${initialActivePointPosition - 15}px` }}
        >
          <div className="w-[30px] h-[30px] active-dice absolute bottom-0 ml-10">
            <div className="w-full h-full rotate-45 border-[2px] border-[#FFF647] absolute top-0 left-0"></div>
          </div>
          <div className="absolute left-16 bg-[#46464680] -top-9 rounded-[8px] w-60 xxs:w-[16rem] 1.5xs:w-[17rem] lg:w-[22rem] xl:w-[23rem] 2xl:w-[24rem] p-4 flex flex-col gap-2">
            {getLanguage() === "en" || getLanguage() === "fr-ca" ? (
              <p className="uppercase  text-[#FFF647] text-base xxs:text-[18px]">
                {currentEventObj?.historyMonth} {currentEventObj?.historyYear}
              </p>
            ) : (
              <p className="uppercase  text-[#FFF647] text-base xxs:text-[18px]">
                {currentEventObj?.historyYear} {currentEventObj?.historyMonth}
              </p>
            )}
            <h3 className="text-[#FFF647] text-[18px] xxs:text-[20px] ms:text-[22px] lg:text-[24px] uppercase leading-[1] mb-1 line-clamp-2 ">
              {currentEventObj?.historyTitle}
            </h3>
            <p className="text-white text-base xxs:text-[18px] leading-5 h-44 overflow-y-scroll hide-scroll">
              {currentEventObj?.descriptionText}
            </p>
          </div>
        </div>
        <Draggable
          onDrag={(e, ui) => handleDrag(e, ui)}
          axis="x"
          grid={[10, 10]}
          position={position}
          disabled={isTouchDevice() ? false : !isDragHover}
          onStart={() => {
            setIsDragging(true);
          }}
          onStop={handleDragStop}
        >
          <div
            className="flex  mr-16 xxs:mr-24 xs:mr-40 lg:mr-80 max-w-fit mt-[22rem] flex-nowrap  p-8 hide-scroll transition-all duration-1000"
            id="timeline-div"
            onMouseMove={handleMouseMove}
            ref={timelineRef}
            style={{
              // transform: `translateX(-${
              //   timelineWidth - timelineOffsetWidth + 72
              // }px)`,
              cursor: isDragHover ? "none" : "auto",
              userSelect: "none",
              WebkitUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
              // backgroundColor: "#ff8200",
              // width: `${timelineWidth}px`,
            }}
          >
            {isDragHover && !isMobile && (
              <div
                className="absolute flex cursor-grabbing z-10 pointer-events-none"
                style={{
                  left: cursorPosition.x - 60,
                  top: cursorPosition.y - 45,
                }}
              >
                <div className="!mr-2 mt-10">
                  <svg
                    width="9"
                    height="15"
                    viewBox="0 0 9 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.99408 1.12738L1.19092 7.50632L7.99408 13.8853"
                      stroke="#FEFBF7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="w-24 h-24 bg-white flex items-center justify-center cursor-grabbing rounded-full">
                  <p className="text-black">Drag</p>
                </div>
                <div className="righticon !ml-2 mt-10">
                  <svg
                    width="9"
                    height="15"
                    viewBox="0 0 9 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.17291 1.12006L7.97607 7.49899L1.17291 13.8779"
                      stroke="#FEFBF7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            )}

            {timelineData.map((event, i) => {
              if (
                activeYear !== event?.historyYear &&
                event?.isActive === "Yes"
              ) {
                activeYear = event?.historyYear;

                showActiveYear = true;
              } else {
                showActiveYear = false;
              }
              return (
                <>
                  {" "}
                  {event.isActive === "Yes" ? (
                    <p
                      className={`relative flex-shrink-0  cursor-pointer child-span-${i} w-20 -z-10`}
                      id={
                        initialActiveEventIndex === i ? "initialActiveSpan" : ""
                      }
                      data-event-index={i}
                      onClick={() => {
                        setCurrentEventObj(event);
                        handleTimelineMove(i);
                      }}
                    >
                      <span className="absolute w-[10.2px] h-[10.2px] bg-[#FFF647] rotate-45 top-0 left-1/2 -translate-x-1/2"></span>
                      {i === 0 || showActiveYear ? (
                        <span className="absolute top-8 left-1/2 -translate-x-1/2 text-white text-base">
                          {event?.historyYear}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  ) : (
                    <div className="relative w-20 flex-shrink-0">
                      <span className="w-[10px] h-[10px] bg-white rounded-full absolute  top-0 left-1/2 -translate-x-1/2"></span>
                      <span className="absolute top-8 left-1/2 -translate-x-1/2 text-white text-base">
                        {event?.historyYear}
                      </span>
                    </div>
                  )}
                </>
              );
            })}
            <div
              className="h-32 md:h-14 w-full  absolute -bottom-10 left-0 translate-x-0 z-[5] "
              style={{
                // transform: `translateX(${
                //   timelineWidth - timelineOffsetWidth + 72
                // }px)`,
                width: `${timelineWidth}px`,
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onTouchStart={handleMouseEnterMobile}
              onTouchEnd={handleMouseLeave}
            ></div>
          </div>
        </Draggable>
      </div>

      <div className=" container flex gap-4 -mt-48 sm:-mt-40">
        <div
          className="z-[2]  w-fit h-fit group"
          onMouseEnter={() => {
            setIsHoverPrev(
              isActiveArr[0] === currentEventObj.post_id ? false : true
            );
          }}
          onMouseLeave={() => {
            setIsHoverPrev(false);
          }}
          onTouchStart={() => {
            setIsHoverPrev(
              isActiveArr[0] === currentEventObj.post_id ? false : true
            );
          }}
          onTouchEnd={() => {
            setIsHoverPrev(false);
          }}
        >
          <button
            onClick={() => handleArrowClick("PREV")}
            disabled={isActiveArr[0] === currentEventObj.post_id}
            className={`w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] rounded-md pl-2  ${
              isHoverPrev ? "arrow-btn-top-left" : ""
            } ${
              isActiveArr[0] === currentEventObj.post_id
                ? "opacity-30 pointer-events-none"
                : "opacity-100"
            }`}
            style={{
              borderColor: "#FFF647",
              backgroundColor: isHoverPrev ? "#FFF647" : "",
            }}
          >
            <ArrowLeft hover={isHoverPrev} color="#fff647" />
          </button>
        </div>
        <div
          onMouseEnter={() => {
            setIsHoverNext(
              isActiveArr[isActiveArr.length - 1] === currentEventObj.post_id
                ? false
                : true
            );
          }}
          onMouseLeave={() => {
            setIsHoverNext(false);
          }}
          onTouchStart={() => {
            setIsHoverNext(
              isActiveArr[isActiveArr.length - 1] === currentEventObj.post_id
                ? false
                : true
            );
          }}
          onTouchEnd={() => {
            setIsHoverNext(false);
          }}
          className="z-[2] w-fit h-fit group"
        >
          <button
            onClick={() => {
              handleArrowClick("NEXT");
            }}
            disabled={
              isActiveArr[isActiveArr.length - 1] === currentEventObj.post_id
            }
            className={`w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px]  ${
              isHoverNext ? "arrow-btn-bottom-right" : ""
            } rounded-md pl-2  ${
              isActiveArr[isActiveArr.length - 1] === currentEventObj.post_id
                ? "opacity-30 pointer-events-none"
                : "opacity-100 "
            } `}
            style={{
              borderColor: "#FFF647",
              backgroundColor: isHoverNext ? "#FFF647" : "",
            }}
          >
            <ArrowRight color="#FFF647" hover={isHoverNext} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Timeline;
