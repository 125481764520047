import React, { useEffect, useState } from "react";
import "../index.css";
import Buttons from "./Buttons";
import { navigateTo } from "../constants/GeneralConstants";
import { clientUrls } from "../constants/ClientUrls";
import "../css/componentscss/Home/Map.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import ButtonSlant from "./ButtonSlant";

const MapSection = ({ homeData, locations, aboutPage, lang }) => {
  const [btnHover, setBtnHover] = useState(false);
  const navigate = useNavigate();
  const {
    locationsSection_joinDevelopersAndPublishersText,
    locationsSection_thatWorkWithUsText,
    locationsSection_locationBgMap,
    locationsSection_joinTheTeamText,
    locationsSection_getInTouchText,
    aGlobalTeamText,
    creativesAndEngineersText,
  } = homeData;
  const { ref: mapRef, inView: mapInView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const { ref: dotsRef, inView: dotsInView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const mapControls = useAnimation();
  const dotsControls = useAnimation();
  const [animationsComplete, setAnimationsComplete] = useState(false);
  const [randomTooltips, setRandomTooltips] = useState([]);
  const [hoveredTooltip, setHoveredTooltip] = useState(null); // Track hovered tooltip
  const [isTooltipHovered, setIsTooltipHovered] = useState(false); // Track tooltip hover

  // Map reveal animation
  useEffect(() => {
    if (mapInView) {
      mapControls.start({
        clipPath: "circle(100% at 50% 50%)",
        transition: { duration: 2 },
      });
    }
  }, [mapInView, mapControls]);

  // Location dots staggered animation
  useEffect(() => {
    if (dotsInView) {
      dotsControls.start("visible").then(() => {
        setAnimationsComplete(true);
      });
    }
  }, [dotsInView, dotsControls]);

  // Show random tooltips for 5 seconds with animation
  const randomLocations = ["los-angeles", "tokyo", "london"];
  useEffect(() => {
    if (animationsComplete && locations?.length > 0) {
      const indexes = randomLocations.map((city) =>
        locations.findIndex((item) => item.locationLink === city)
      );

      // Animate tooltips sequentially with a 1.5-second gap
      indexes.forEach((index, i) => {
        setTimeout(() => {
          setRandomTooltips((prev) => [...prev, index]);
        }, i * 500); // 1.5-second gap
      });

      // Hide tooltips after 5 seconds
      const timeout = setTimeout(() => {
        setRandomTooltips([]);
      }, 1000 + 3 * 500); // 5 seconds + animation time

      return () => clearTimeout(timeout);
    }
  }, [animationsComplete, locations]);

  // Variants for staggered animation
  const dotsVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.5,
      },
    },
  };

  const dotVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1 },
  };

  // Tooltip reveal animation variants
  const getTooltipVariants = (position) => {
    if (position?.toUpperCase() === "BOTTOM") {
      // Left-to-right reveal for bottom-right tooltips
      return {
        hidden: { clipPath: "inset(0 100% 0 0)" },
        visible: { clipPath: "inset(0 0% 0 0)" },
      };
    } else {
      // Right-to-left reveal for top-left tooltips
      return {
        hidden: { clipPath: "inset(0 0% 0 100%)" },
        visible: { clipPath: "inset(0 0% 0 0)" },
      };
    }
  };

  const popupPosition = (value) => {
    if (value?.toUpperCase() === "BOTTOM") {
      return "lg:top-2 lg:left-2 xl:top-[0.6rem] xl:left-[0.6rem] 3xl:left-4 3xl:top-4 slant-top-left-map";
    } else {
      return "lg:bottom-[0.5rem] lg:right-[0.5rem] xl:bottom-[0.6rem] xl:right-[0.6rem]  3xl:right-4 3xl:bottom-4 bottom-right-slant-map";
    }
  };

  let timer;
  return (
    <div className="hidden lg:block relative mb-32">
      {aboutPage ? (
        <h2 className="text-white lg:text-[#2AD9FF] leading-[1] text-center common-title-size map-header-size mx-auto lg:tracking-[2px] font-[400]">
          <span className="">
            {aGlobalTeamText}
            {lang === "zh-chs" ? "" : " "}
            <span
              className={`text-[#2ad9ff] lg:text-white ${
                lang === "zh-chs" ? "" : "ml-2"
              }`}
            >
              {creativesAndEngineersText}
            </span>
          </span>
        </h2>
      ) : (
        <h2 className="text-[#2AD9FF] leading-[1] lg:text-[40px] uppercase text-center text-map-header map-header-size mx-auto lg:tracking-[2px] font-[400]">
          <span className="block">
            {locationsSection_joinDevelopersAndPublishersText}
            <span className="text-white ml-2">
              {locationsSection_thatWorkWithUsText}
            </span>
          </span>
        </h2>
      )}
      <div
        className={`mb-8 ${
          aboutPage
            ? "lg:mt-24 xl:mt-4 2xl:mt-0"
            : "lg:mt-32 xl:mt-20 2xl:mt-12"
        } mx-auto map-size relative`}
        ref={mapRef}
      >
        <motion.img
          src={locationsSection_locationBgMap}
          alt="Map"
          className="w-full h-full"
          initial={{ clipPath: "circle(0% at 50% 50%)" }}
          animate={mapControls}
        />

        {/* Location dots container */}
        <motion.div
          ref={dotsRef}
          variants={dotsVariants}
          initial="hidden"
          animate={dotsControls}
          className="absolute inset-0"
        >
          {locations?.map((location, index) => (
            <motion.div
              key={index}
              className={`absolute w-[9px] h-[9px] xl:w-[11px] xl:h-[11px] 2xl:h-[12px] 2xl:w-[12px] 3xl:w-4 3xl:h-4 4xl:w-5 4xl:h-5 cursor-pointer group`}
              style={{
                bottom: `${location?.mapBottom}`,
                left: `${location?.mapLeft}`,
              }}
              variants={dotVariants}
              onMouseEnter={() => {
                clearTimeout(timer);
                setHoveredTooltip(index);
              }} // Track hover
              onMouseLeave={() => {
                // Add a delay before hiding the tooltip
                timer = setTimeout(() => {
                  if (!isTooltipHovered) {
                    setHoveredTooltip(null);
                  }
                }, 400); // Adjust delay as needed
              }}
            >
              <div className="w-full h-full bg-[#2AD9FF] left-0 top-0 rotate-[45deg]"></div>
              {/* Tooltip wrapper with slant shape */}
              {(randomTooltips.includes(index) || hoveredTooltip === index) && (
                <div
                  className={`absolute ${popupPosition(
                    location?.mapLocationPopupPosition
                  )} transform -translate-x-1/6 z-10 overflow-hidden`}
                  onMouseEnter={() => setIsTooltipHovered(true)} // Track tooltip hover
                  onMouseLeave={() => {
                    setIsTooltipHovered(false);
                    setHoveredTooltip(null); // Hide tooltip when leaving
                  }}
                >
                  {/* Tooltip content with directional reveal animation */}
                  <motion.div
                    className="bg-[#2AD9FF] text-black px-2 py-1 whitespace-nowrap"
                    variants={getTooltipVariants(
                      location?.mapLocationPopupPosition
                    )}
                    initial="hidden"
                    animate="visible"
                    transition={{
                      duration: 0.5, // 2 seconds for random, 0.5 seconds for hover
                      ease: "easeInOut",
                    }}
                  >
                    <h3
                      className="lg-only:!text-sm text-map-button font-bold"
                      onClick={(e) => {
                        sessionStorage.setItem(
                          "scrollTo",
                          location?.locationName?.toLowerCase()
                        );
                        navigate(navigateTo(clientUrls.contact));
                      }}
                    >
                      {location?.locationName}
                    </h3>
                  </motion.div>
                </div>
              )}
            </motion.div>
          ))}
        </motion.div>
      </div>

      {/* Buttons and other content */}
      <div className="lg:flex text-center justify-center ">
        <ButtonSlant
          buttonText={locationsSection_joinTheTeamText}
          buttonClass="uppercase text-lg text-[#2AD9FF] border-[2px] font-bold border-[#2AD9FF]  px-6 py-3 4xl:py-4 4xl:px-8 rounded-md text-map-button font-dela"
          slantClass="bottom-right-slant"
          divClass="w-fit mr-4"
          bgColorHover="#2ad9ff"
          textColorHover="#1a1a1a"
          onClick={(e) => navigate(navigateTo(clientUrls.careers))}
        />
        <button
          onClick={(e) => navigate(navigateTo(clientUrls.contact))}
          className="relative bg-[#2ad9ff] hover:bg-transparent text-[#1a1a1a] hover:text-[#2ad9ff] text-map-button font-dela cursor-pointer border-none outline-none px-7 py-2 rounded-md"
        >
          {locationsSection_getInTouchText}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 120"
            className="absolute inset-0 w-full h-full 2xl:hidden"
            preserveAspectRatio="none"
          >
            <path
              d="M10,2 H390 A5,5 0 0 1 395,7 v78 L359,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
              fill="none"
              stroke="#2ad9ff"
              stroke-width="4"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 120"
            className="absolute inset-0 w-full h-full hidden 2xl:block 3xl:hidden"
            preserveAspectRatio="none"
          >
            <path
              d="M10,2 H390 A5,5 0 0 1 395,7 v80 L362,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
              fill="none"
              stroke="#2ad9ff"
              stroke-width="4"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 120"
            className="absolute inset-0 w-full h-full hidden 3xl:block"
            preserveAspectRatio="none"
          >
            <path
              d="M10,2 H390 A5,5 0 0 1 395,7 v80 L370,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
              fill="none"
              stroke="#2ad9ff"
              stroke-width="4"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default MapSection;
