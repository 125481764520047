import React, { useEffect } from "react";
import CommonTopBanner from "./CommonTopBanner";
import ParagraphSection from "./ParagraphSection";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setColor } from "../../store/reducers/ColorReducer";
import OurPartners from "../home/OurPartners";
import AAACoDev from "./AAACoDev";
import ProjectsShowSection from "../home/ProjectsShowSection";
import NewsEvents from "../home/NewsEvents";
import OurServices from "../home/OurServices";
import { clientUrls } from "../../constants/ClientUrls";
import CommonYoutube from "../CommonYoutube";
import Footer from "../Footer";

const GameDevelopment = ({ GameDevelopmentdata }) => {
  const {
    servicePageData,
    services,
    labs,
    gameServices,
    ourPartners,
    projects,
    serviceColors,
    type,
  } = GameDevelopmentdata;

  useEffect(() => {
    document.title = GameDevelopmentdata?.servicePageData?.gameDevSEOTitle
      ? GameDevelopmentdata?.servicePageData?.gameDevSEOTitle
      : "Expert Game Development & Design Services | Game Co-Dev Services | Side";
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  const dispatch = useDispatch();
  const { lang } = useParams();
  useEffect(() => {
    dispatch(
      setColor({
        primary: serviceColors[0]?.colorCode,
        secondary: serviceColors[0]?.secondaroyColor,
        tertiary: serviceColors[0]?.tertiaryColor,
      })
    );
  }, [GameDevelopmentdata, lang]);

  const {
    fromTheLabText,
    visitTheLabText,
    bannerSection_heading1Text,
    bannerSection_heading2Text,
  } = servicePageData;

  return (
    <>
      <section>
        <CommonTopBanner
          servicePageData={servicePageData}
          color={{
            primary: serviceColors[0]?.colorCode,
            tertiary: serviceColors[0]?.tertiaryColor,
          }}
        />
        <div className="mb-4 sm:mb-0 lg:mb-40">
          <ParagraphSection
            servicePageData={servicePageData}
            color={{
              primary: serviceColors[0]?.colorCode,
              tertiary: serviceColors[0]?.tertiaryColor,
            }}
          />
        </div>
        <div
          className="bg-no-repeatk  bg-containk game-development-bg-1k lg:pb-60 pt-28 sm:pt-32 lg:pt-0 relative"
          // style={{
          //   backgroundImage: `url(${servicePageData?.serviceTypeBgImage})`,
          // }}
        >
          <div className="absolute right-0 z-[-1]">
            <img
              src={servicePageData?.serviceTypeBgImage}
              alt="ptw-side"
              className="w-[43%] float-right "
            />
          </div>
          {/* youtube video starts here */}
          {/* <img src="images/yt.png" alt="side-ptw" /> */}
          {/* <CommonYoutube url={servicePageData?.serviceVideo} /> */}
          {/* youtube video ends here */}
          <OurPartners
            homeData={servicePageData}
            clientsData={ourPartners}
            lang={lang}
          />
          {/* AAA CO DEV Services Starts here */}
          <AAACoDev
            gameServices={gameServices}
            color={{
              primary: serviceColors[0]?.colorCode,
              tertiary: serviceColors[0]?.tertiaryColor,
            }}
          />
          {/* AAA CO DEV Services Ends here */}
        </div>
        <div className="relative">
          <div className="hidden sm:block absolute -left-[50%] sm:left-[-35%] 1.5md:-left-[45%] lg:-left-[18%] 2xl:-left-[24%] 3xl:-left-[25%] md-only:top-[-10rem] z-[-1]">
            <img
              className="w-[70vw] sm:w-[78vw] lg:w-[70vw] 3xl:w-[70vw] 3.3xl:w-[67vw] 4xl:w-[75vw] h-auto"
              src={servicePageData?.projectBgImage}
              alt="side-ptw"
            />
          </div>
          <div className="my-24 sm:my-32 lg:mb-[14rem] lg:mt-4">
            <ProjectsShowSection
              homeData={servicePageData}
              projectsData={projects}
              colors={{ color: serviceColors[0]?.secondaroyColor }}
              redirectionFrom={type}
            />
          </div>
          <NewsEvents
            header1={`${bannerSection_heading1Text} ${" "} ${bannerSection_heading2Text}`}
            header2={fromTheLabText}
            headerButtonText={visitTheLabText}
            cardsData={labs}
            colors={{
              color: serviceColors[0]?.secondaroyColor,

              tertiaryColor: serviceColors[0]?.tertiaryColor,
            }}
            url={clientUrls.labs}
            toLabs={true}
            type={type}
          />
        </div>
        <OurServices
          servicesData={services}
          header1={servicePageData?.exploreText}
          header2={servicePageData?.moreText}
          colors={{ color: serviceColors[0]?.colorCode }}
          lang={lang}
        />
      </section>
      <Footer />
    </>
  );
};

export default GameDevelopment;
