import { useEffect, useRef, useState, useCallback } from "react";
import { debounce } from "lodash";
import {
  getNewsFilterData,
  getNewsListData,
  getNewsPageData,
} from "../../services/ApiService";
import { getLanguage } from "../../constants/GeneralConstants";
import "../../css/pagescss/NewsList.css";
import Video from "../../components/news/Video";
import NewsSlider from "../../components/news/NewsSlider";
import Footer from "../../components/Footer";
import { useParams } from "react-router-dom";
import ArticleCard from "../../components/news/ArticleCard";
import HighlightedArticle from "../../components/news/HighlightedArticle";
import Pagination, { scrollToRef } from "../../components/Pagination";
import Loader from "../../components/Loader";
import { useDispatch } from "react-redux";
import { setColor } from "../../store/reducers/ColorReducer";
import VideoCard from "../../components/news/VideoCard";
import HighlightedVideos from "../../components/news/HighlightedVideos";
import ButtonSlant from "../../components/ButtonSlant";
import { SlantImg } from "../../components/CommonComponents";

let type = "News";

const NewsList = () => {
  const dispatch = useDispatch();
  const { lang } = useParams();
  const [loader, setLoader] = useState(true);
  const [newsPageData, setNewsPageData] = useState(null);
  const [newsStaticPageData, setNewsStaticPageData] = useState({});
  const [showArticle, setShowArticle] = useState(
    type === "News" ? true : false
  );
  const [allNews, setAllNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortFilterData, setSortFilterData] = useState([]);
  const [newsListData, setNewsListData] = useState([]);
  const [sorting, setSorting] = useState("N2O");
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const [showSortFilterOptions, setShowShortFilterOptions] = useState(false);
  const [showSortFilterOptionsMobile, setShowShortFilterOptionsMobile] =
    useState(false);
  const [activeShortFilter, setActiveShortFilter] = useState("");
  const shortingFilterRef = useRef(null);
  const shortingFilterRefMobile = useRef(null);

  const pageRef = useRef(null);

  useEffect(() => {
    setLoader(true);
    callNewsPageData(getLanguage());
    callNewsFilterData(getLanguage());
  }, [lang]);
  const handleClickOutside = (event) => {
    if (
      shortingFilterRef.current &&
      !shortingFilterRef.current.contains(event.target)
    ) {
      setShowShortFilterOptions(false);
    }
    if (
      shortingFilterRefMobile.current &&
      !shortingFilterRefMobile.current.contains(event.target)
    ) {
      setShowShortFilterOptionsMobile(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const callNewsPageData = async (language) => {
    try {
      let data = await getNewsPageData(language);
      document.title = data?.newsPageData?.newsSEOTitle;
      setNewsPageData(data?.news);
      setNewsStaticPageData(data?.newsPageData);
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const callNewsFilterData = async (language) => {
    try {
      let data = await getNewsFilterData(language);
      setSortFilterData(data);
      setActiveShortFilter(data?.filters?.find((el) => el.id == "N2O").value);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    callNewsListData(getLanguage());
  }, [showArticle, lang, showArticle, sorting]);
  useEffect(() => {
    dispatch(
      setColor({
        primary: "#ff8200",
        secondary: "#ff8200",
        tertiary: "#333333",
      })
    );
  }, [dispatch]);
  const callNewsListData = async (language, page = 1, term = "") => {
    // console.log(showArticle);
    let obj = {
      sorting: sorting,
      // newsType: newsType,
      // newsTopic: newsTopic,
      type: type === "News" ? "News" : "Event",
      pageno: page,

      term: term,
    };

    try {
      setIsLoading(true);
      let data = await getNewsListData(language, obj);
      setAllNews(data?.news);
      setNewsListData(data?.news);
      setTotalPages(Math.ceil(data?.totalrecords / 9));
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const onPaginationChange = (page) => {
    scrollToRef(pageRef);
    setCurrentPage(page);
    callNewsListData(getLanguage(), page, filterSearchValue);
  };

  const debouncedFetchNews = useCallback(
    debounce((searchTerm) => {
      setCurrentPage(1);
      callNewsListData(getLanguage(), 1, searchTerm);
    }, 500),
    []
  );

  const handleSearch = (event) => {
    const term = event.target.value;
    setFilterSearchValue(term);
    debouncedFetchNews(term);
  };

  useEffect(() => {
    return () => debouncedFetchNews.cancel();
  }, [debouncedFetchNews]);

  if (loader) {
    return <Loader />;
  }

  return (
    <>
      <section className="news-page">
        <section className="bg-[#FF8200] flex relative pt-28 pb-24 md:pb-20 mb-16 lg:mb-28 3xl:h-[50rem] 4xl:h-[55rem] project-slant-bottom-mobilek slant-bottom-footerk">
          <div className="absolute right-0 top-0 h-full hidden sm:block">
            <img
              alt="side-ptw"
              src={newsStaticPageData?.bannerSection_bannerBgImage}
              className="h-full"
            />
          </div>
          <div className="absolute right-0 top-0 h-full sm:hidden">
            <img
              alt="side-ptw"
              src={newsStaticPageData?.bannerSection_bannerBgImageMob}
              className="h-full"
            />
          </div>
          <div className="absolute right-0 -bottom-[1px] z-[10]">
            <SlantImg className="h-[100px] sm:h-auto" />
          </div>
          {/* <img
             className=" z-[-1] absolute top-[-30%] left-[-50%] h-[110%] max-w-[150%] rotate-[10deg]  sm:left-[9%] sm:h-[170%] sm:top-[-50%] lg:top-[-35%] opacity-[20%]"
            src={newsStaticPageData?.bannerSection_bannerBgImage}
            alt=""
          /> */}
          <NewsSlider
            newsData={newsPageData}
            newsPageData={newsStaticPageData}
            lang={lang}
          />
        </section>
        {/* filter starts here  */}
        <section className="filter container mb-10 lg:mb-20" ref={pageRef}>
          {/* new filter design starts here  */}
          <div className="sm:flex w-full sm:gap-4 lg:gap-16 justify-between">
            <div className="flex lg:flex-1 gap-4 lg:gap-6 mb-4 sm:mb-0">
              <ButtonSlant
                buttonClass="w-full text-left text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px]  font-bold border-[#FF8200] border-[2px] px-6 py-3 4xl:py-4 4xl:px-8 rounded-md text-map-button font-dela"
                onClick={() => {
                  setShowArticle(true);
                  type = "News";
                  setCurrentPage(1);
                  setFilterSearchValue("");
                  setActiveShortFilter(
                    sortFilterData?.filters?.find((el) => el.id === "N2O").value
                  );
                  setSorting("N2O");
                }}
                divClass="w-full"
                slantClass="bottom-right-slant"
                textColor={showArticle === true ? "#1a1a1a" : "#ff8200"}
                textColorHover="#1a1a1a"
                bgColor={showArticle === true ? "#ff8200" : ""}
                bgColorHover="#ff8200"
                buttonText={newsStaticPageData?.newsText}
              />

              <ButtonSlant
                buttonClass="w-full text-left text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px]  font-bold border-[#FF8200] border-[2px] px-6 py-3 4xl:py-4 4xl:px-8 rounded-md text-map-button font-dela"
                onClick={() => {
                  setShowArticle(false);
                  type = "Event";
                  setCurrentPage(1);
                  setFilterSearchValue("");
                  setActiveShortFilter(
                    sortFilterData?.filters?.find((el) => el.id === "N2O").value
                  );
                  setSorting("N2O");
                }}
                divClass="w-full"
                slantClass="bottom-right-slant"
                textColor={showArticle === false ? "#1a1a1a" : "#ff8200"}
                textColorHover="#1a1a1a"
                bgColor={showArticle === false ? "#ff8200" : ""}
                bgColorHover="#ff8200"
                buttonText={newsStaticPageData?.eventsText}
              />
            </div>
            <div className="relative flex">
              <label className="hidden sm:flex justify-between items-center border border-[#FF8200] px-5 py-2 rounded sm:mr-4 lg:mr-6">
                <input
                  type="search"
                  id="default-search"
                  className="block bg-transparent border-transparent focus-within:outline-0 focus:outline-0 focus-0 text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#FF8200] rounded placeholder:text-[#FF8200] sm-only:w-[5rem] md-only:w-[12rem]"
                  placeholder={newsStaticPageData?.searchText}
                  required
                  appreance="none"
                  onChange={handleSearch}
                  value={filterSearchValue}
                />
                <svg
                  className="w-4 h-4 text-[#FF8200]"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </label>

              <button
                ref={shortingFilterRef}
                onClick={(e) => {
                  setShowShortFilterOptions(!showSortFilterOptions);
                }}
                className="hidden sm:flex items-center justify-center text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#FF8200] border border-[#FF8200] px-5 py-2 rounded hover:text-[#1a1a1a] bottom-right-slant-hover hover:bg-primary hover-svg"
              >
                <svg
                  width="22"
                  height="22"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="lg:ml-3k"
                >
                  <path
                    stroke="#FF8200"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                  />
                </svg>
                {/* <svg
                  width="22"
                  height="22"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="lg:ml-3"
                >
                  <path
                    stroke="#FF8200"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 9l4-4l4 4m-4 -4v14"
                  />
                  <path
                    stroke="#FF8200"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 15l-4 4l-4-4m4 4v-14"
                  />
                </svg> */}
              </button>
              <div className="relative"></div>
            </div>
          </div>
          {/* newest to oldest filter starts here  */}
          {showSortFilterOptions && (
            <div className="hidden sm:flex justify-end mt-4 relative">
              <div className=" z-10 w-screen max-w-[15.5rem] rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 backdrop-blur-lg  lg:max-w-[18rem] xl:max-w-[20rem] 3xl:max-w-[25rem] 4xl:max-w-[26rem]  overflow-auto scrollable custom-scroll-filter ">
                {sortFilterData?.filters?.map((option) => {
                  return (
                    <div
                      className={`flex group items-center justify-between cursor-pointer hover:bg-[#FF8200] rounded ${
                        activeShortFilter === option.value
                          ? "pointer-events-none"
                          : ""
                      }`}
                      onClick={() => {
                        setSorting(option?.id);
                        setActiveShortFilter(option?.value);
                        setShowShortFilterOptions(false);
                        if (currentPage > 1) {
                          setCurrentPage(1);
                        }
                      }}
                    >
                      <div
                        className="cursor-pointer flex justify-between group-hover:text-black  gap-x-2.5 p-2 text-[15px] 2xl:text-[18px] 3xl:text-[22px] 4xl:text-[24px] w-fit font-semibold   hover:opacity-100 rounded-md
                   text-white
                "
                        style={{
                          color:
                            activeShortFilter === option?.value
                              ? "#ff8200"
                              : "",
                        }}
                      >
                        {option?.value}
                      </div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-10 w-10 p-2 object-contain"
                      >
                        <path
                          d="M13.75 11.875L17.5 8.125L13.75 4.375"
                          stroke="#1A1A1A"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                          stroke="#1A1A1A"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {/* newest to oldest filter ends here  */}
          {/* new filter design ends here  */}
          {/* mobile filter starts here  */}
          <div className="sm:hidden">
            <label className="flex justify-between items-center border border-[#FF8200] px-5 py-2 rounded  sm:mr-4 2xl:mr-8">
              <input
                type="search"
                id="default-search"
                className="block bg-transparent border-transparent focus-within:outline-0 focus:outline-0 focus-0 text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#FF8200] rounded w-fit placeholder:text-[#FF8200]"
                placeholder="Search"
                required
                appreance="none"
                onChange={handleSearch}
                value={filterSearchValue}
              />
              <svg
                className="w-4 h-4 text-[#FF8200]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </label>
            <div className="flex my-4 gap-8 justify-between">
              <button
                ref={shortingFilterRefMobile}
                onClick={(e) => {
                  setShowShortFilterOptionsMobile(!showSortFilterOptionsMobile);
                }}
                className="flex items-center justify-between text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#FF8200] border border-[#FF8200] px-5 py-2 rounded w-full"
              >
                {activeShortFilter}
                <svg
                  width="22"
                  height="22"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="lg:ml-3"
                >
                  <path
                    stroke="#FF8200"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                  />
                </svg>
              </button>
            </div>

            {/* mobile newest to oldest starts here  */}
            {showSortFilterOptionsMobile && (
              <div className="relative">
                <div className=" z-10 w-screen max-w-full rounded-[10px] bg-[#1a1a1acc] flex flex-col p-2 left-0 top-[-1rem] backdrop-blur-lg   absolute  overflow-auto scrollable custom-scroll-filter ">
                  {sortFilterData?.filters?.map((el) => {
                    return (
                      <div
                        className={`flex group items-center justify-between cursor-pointer hover:bg-[#FF8200] rounded left-[-11.5rem] ${
                          activeShortFilter === el?.value
                            ? " pointer-events-none"
                            : ""
                        }`}
                        onClick={() => {
                          setSorting(el?.id);
                          setActiveShortFilter(el?.value);
                          setShowShortFilterOptionsMobile(false);
                          if (currentPage > 1) {
                            setCurrentPage(1);
                          }
                        }}
                      >
                        <div
                          className="cursor-pointer flex justify-between group-hover:text-black  gap-x-2.5 p-2 text-[15px]  w-fit font-semibold   hover:opacity-100 rounded-md
                   text-white
                "
                          style={{
                            color:
                              activeShortFilter === el?.value ? "#ff8200" : "",
                          }}
                        >
                          {el.value}
                        </div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-10 w-10 p-2 object-contain"
                        >
                          <path
                            d="M13.75 11.875L17.5 8.125L13.75 4.375"
                            stroke="#1A1A1A"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M2.5 15.625C2.5 13.6359 3.29018 11.7282 4.6967 10.3217C6.10322 8.91518 8.01088 8.125 10 8.125H17.5"
                            stroke="#1A1A1A"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* mobile newest to oldest ends here  */}
          </div>

          {/* mobile filter ends here  */}
        </section>
        {/* filter ends here  */}
        {/* articles cards starts here  */}

        {/* neww video part */}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <section
              style={{ display: showArticle === false ? "" : "none" }}
              className="news-video"
            >
              {newsListData.length > 0 ? (
                <>
                  <section className="articles container">
                    <div className="grid grid-col-1 lg:grid-cols-2 gap-x-8 gap-y-8 my-12">
                      {newsListData.length > 0 &&
                        newsListData
                          .slice(0, 4)
                          .map((data) => (
                            <VideoCard key={data.id} news={data} />
                          ))}
                    </div>
                  </section>
                  {newsListData.length >= 5 && (
                    <HighlightedArticle
                      news={newsListData[4]}
                      newsStaticPageData={newsStaticPageData}
                      fromEvents={true}
                    />
                  )}
                </>
              ) : (
                <div className="flex justify-center items-center h-40">
                  <p className="text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] text-[#ff8200] font-medium">
                    No Events Found
                  </p>
                </div>
              )}

              <section className="articles container">
                <div className="grid grid-col-1 lg:grid-cols-2 gap-x-8 gap-y-8 my-12">
                  {newsListData.length > 5 &&
                    newsListData
                      .slice(5, 9)
                      .map((data) => <VideoCard key={data.id} news={data} />)}
                </div>
              </section>
            </section>

            {/* news Article part */}

            <section
              style={{ display: showArticle ? "" : "none" }}
              className="news-Article"
            >
              {newsListData.length > 0 ? (
                <>
                  <section className="container">
                    <div className="grid grid-col-1 lg:grid-cols-2 gap-x-8 gap-y-8 my-12">
                      {newsListData.length > 0 &&
                        newsListData
                          .slice(0, 4)
                          .map((data) => (
                            <ArticleCard key={data.id} news={data} />
                          ))}
                    </div>
                  </section>
                  {newsListData.length >= 5 && (
                    <HighlightedArticle
                      news={newsListData[4]}
                      newsStaticPageData={newsStaticPageData}
                    />
                  )}
                </>
              ) : (
                <div className="flex justify-center items-center h-40">
                  <p className="text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] text-[#ff8200] font-medium">
                    No News Found
                  </p>
                </div>
              )}

              <section className="container">
                <div className="grid grid-col-1 lg:grid-cols-2 gap-x-8 gap-y-8 my-12">
                  {newsListData.length > 5 &&
                    newsListData
                      .slice(5, 9)
                      .map((data) => <ArticleCard key={data.id} news={data} />)}
                </div>
              </section>
            </section>
            <section className="container mb-20 sm:mb-28 lg:mb-40 flex items-center justify-center pagination text-white text-[16px] 3xl:text-[22px] gap-4 mx-6">
              {totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPaginationValueChange={onPaginationChange}
                  color="#FF8200"
                />
              )}
            </section>
          </>
        )}
        {isLoading ? <> </> : <Footer />}
      </section>
    </>
  );
};

export default NewsList;
