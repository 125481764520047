import { apiUrls } from "../constants/ApiUrls";
import ApiInstance from "./AxiosInstanceService";

const apiUrl = "/api/v1/";

export const getHeaderAndFooterData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.headerAndFooter}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getHomePageData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.homePage}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getAboutPageData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.aboutPage}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getCompanyProfileData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.companyProfile}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getCareersPageData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.careersPage}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getJobsData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.jobs}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getCookieModalData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.cookieModal}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getNewsPageData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.newsPage}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getNewsFilterData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.newsFilter}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getNewsListData = async (language = "en", obj) => {
  try {
    const response = await ApiInstance.post(
      `${apiUrl}${language}${apiUrls.newsList}`,
      obj
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getNewsDetailsData = async (language = "en", newsName) => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.newsDescription}/${newsName}`
    );
    return response?.data;
  } catch (error) {
    console.error("Error fetching API data:", error);

    return error?.response?.data?.data;
  }
};

export const getLabsPageData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.labsPage}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const get404PageData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.pageNotFound}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getLabsFilterData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.labsFilter}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getLabsListData = async (language = "en", obj) => {
  try {
    const response = await ApiInstance.post(
      `${apiUrl}${language}${apiUrls.labsList}`,
      obj
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getLabsDetailsData = async (language = "en", labsName) => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.labsDescription}/${labsName}`
    );
    return response?.data;
  } catch (error) {
    console.error("Error fetching API data:", error);

    return error?.response?.data?.data;
  }
};

export const getProjectsPageData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.projectsPage}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getProjectsFilterData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.projectsFilter}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getProjectDetailsData = async (language = "en", projectName) => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.ProjectDescription}/${projectName}`
    );
    return response?.data;
  } catch (error) {
    console.error("Error fetching API data:", error);

    return error?.response?.data?.data;
  }
};

export const getProjectsListData = async (language = "en", obj) => {
  try {
    const response = await ApiInstance.post(
      `${apiUrl}${language}${apiUrls.projectsList}`,
      obj
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getServicesPageData = async (language = "en", obj) => {
  try {
    const response = await ApiInstance.post(
      `${apiUrl}${language}${apiUrls.servicesPage}`,
      obj
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getContactPageData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.contactPage}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const submitContactFormDetails = async (obj) => {
  try {
    const response = await ApiInstance.post(
      `${apiUrl}${apiUrls.submitContactForm}`,
      obj
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};

export const getLegalPageData = async (language = "en") => {
  try {
    const response = await ApiInstance.get(
      `${apiUrl}${language}${apiUrls.legalPage}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching API data:", error);
    throw error;
  }
};
