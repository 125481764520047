import { useEffect, useRef, useState } from "react";
import NewsEvents from "../components/home/NewsEvents";
import OurServices from "../components/home/OurServices";
import ProjectsShowSection from "../components/home/ProjectsShowSection";
import TopBanner from "../components/home/TopBanner";
import MapSection from "../components/MapSection";
import "../css/pagescss/Home.css";
import "../css/componentscss/Home/WeBelieve.css";
import "../css/componentscss/Home/OurPatners.css";
import { getHomePageData } from "../services/ApiService";
import YoutubeVideo from "../components/YoutubeVideo";
import Buttons from "../components/Buttons";
import {
  getLanguage,
  languageCodes,
  navigateTo,
} from "../constants/GeneralConstants";
import { clientUrls } from "../constants/ClientUrls";
// import Loader from "../components/Loader";
import Lab from "../components/home/Lab";
import { useNavigate, useParams } from "react-router-dom";
import OurPartners from "../components/home/OurPartners";
import Loader from "../components/Loader";
import { useDispatch } from "react-redux";
import { setColor } from "../store/reducers/ColorReducer";
import PageNotFound from "../components/PageNotFound";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import WeBelieve from "../components/home/WeBelieve";
import GlobalTeam from "../components/home/GlobalTeam";
import YoutubeVideoWrapper from "../components/home/YoutubeVideoWrapper";

const Home = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const [homePageData, setHomePageData] = useState(null);

  const [playingVideo, setPlayingVideo] = useState(null);
  const [loader, setLoader] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePlay = (videoId) => {
    setPlayingVideo(videoId);
  };

  useEffect(() => {
    document.title =
      "Side: Global Leading QA, Art, Localization & Player Support Company";
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setColor({
        primary: "#ff8200",
        secondary: "#ff8200",
        tertiary: "#333333",
        menusColor: "#ffffff",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setPlayingVideo(null);
    callHomePageApi(lang);
  }, [lang]);

  const callHomePageApi = async (language) => {
    setLoader(true);
    try {
      let data = await getHomePageData(language);
      document.title = data?.home?.homeSEOTitle;
      setHomePageData(data);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  if (loader) {
    return <Loader />;
  }

  const {
    home,
    projects,
    services,
    news,
    labs,
    labsVideo,
    clients,
    locations,
  } = homePageData;
  let arr = locations.sort((a, b) => {
    return a.locationOrder - b.locationOrder;
  });
  const regions = [...new Set(arr?.map((location) => location.regionName))];

  const {
    latestNewsEventsText,
    viewAllText,

    serviceSection_servicesVideo,
  } = home;

  if (lang && !languageCodes.includes(lang)) {
    return <PageNotFound text="Back To Home" />;
  }

  return (
    <>
      {/* Home Component goes here */}
      <TopBanner homeData={home} />
      <section className="my-24 sm:my-[2rem] lg:my-56 xl:mt-80 1.5xl:mt-80 3xl:mt-96 4xl:mt-[42rem]">
        <ProjectsShowSection
          homeData={home}
          projectsData={projects}
          colors={{
            color: "#ff8200",
          }}
          isBtnMiddle={true}
        />
      </section>

      {/* our partners starts here  */}

      <OurPartners homeData={home} clientsData={clients} lang={lang} />

      <div className="bg-d-orangek md:pb-[3rem] lg:pb-1 bg-containk relative lg:mt-60">
        {getLanguage() != "zh-chs" &&
          serviceSection_servicesVideo?.includes("youtube.com") && (
            <>
              <div className="absolute bottom-[9rem] sm:bottom-[18rem] right-[-6rem] sm:right-0 z-[-1] w-[21rem] xs:w-[22rem] sm:w-[24rem] md:w-[26rem] lg:w-[35rem] xl:w-[40rem] 2xl:w-[45rem] 3xl:w-[55rem] block">
                <img
                  className="w-full h-full float-right"
                  src={home?.serviceSection_servicesBgImage}
                  alt="side-ptw"
                />
              </div>

              <YoutubeVideoWrapper
                serviceSectionServiceVideo={serviceSection_servicesVideo}
                videoId="homePageYoutubeVideoSecondSection"
                onPlay={() => handlePlay("homePageYoutubeVideoSecondSection")}
                isPlaying={playingVideo}
                url={serviceSection_servicesVideo}
                isMobile={isMobile}
                className="w-full h-full absolute top-0 left-0"
              />
            </>
          )}
        {/* </div> */}

        {/* yt video ends here  */}
        <OurServices
          servicesData={services}
          header1={home?.serviceSection_ourText}
          header2={home?.serviceSection_servicesText}
          colors={{ textColor: "home-our-services-text", color: "#FF8200" }}
          lang={lang}
        />
      </div>
      {/* from the lab starts here  */}

      <Lab
        homeData={home}
        labs={labs}
        labsVideo={labsVideo}
        services={services}
        onPlay={handlePlay}
        playingVideo={playingVideo}
        colors={{
          color: "#61e063",
          tertiaryColor: "#1a1a1a",
        }}
      />
      {/* from the lab ends here  */}

      {/* videos ends here  */}
      {/* we believe starts here  */}
      <WeBelieve data={home} />

      {/* we believe ends here  */}
      {/* mobile final global team starts here  */}
      <GlobalTeam home={home} regions={regions} homePageData={homePageData} />
      {/* mobile final global team ends here  */}

      <MapSection homeData={home} locations={locations} />
      <NewsEvents
        header1={""}
        header2={latestNewsEventsText}
        headerButtonText={viewAllText}
        cardsData={news}
        colors={{
          color: "#2AD9FF",
          tertiaryColor: "#333333",
        }}
        url={clientUrls.news}
      />
      <Footer />
    </>
  );
};

export default Home;
