import { useNavigate } from "react-router-dom";
import { clientUrls } from "../../constants/ClientUrls";
import { navigateTo } from "../../constants/GeneralConstants";
import PIL from "../../components/PIL";
import ButtonSlant from "../../components/ButtonSlant";

const HighlightedLabArticle = ({ labs, labsPageData }) => {
  const navigate = useNavigate();
  return (
    <section
      className="bg-[#464646] z-[99] overflow-hidden py-[4rem] relative group news-hover-slant-parent"
      // onClick={(e) =>
      //   navigate(navigateTo(`${clientUrls.labs}/${labs.labsURL}`))
      // }
    >
      <img
        className="absolute right-0 top-[-4.5rem] sm:top-0 sm:h-full z-[-1]"
        src={labsPageData?.placeholderBgImage}
      />

      <div className="container ">
        <div className="grid gird-cols-1 sm:grid-cols-2 lg:flex  gap-x-12 gap-y-4 news-hover-slant">
          <div className="img-partk relative cursor-pointer lg:w-[80%] xl:w-[45%] 3xl:w-[50%]">
            <div className="hidden cursor-pointer absolute top-0 right-0 bg-[#1a1a1a] h-12 w-12 4xl:h-20 4xl:w-20 group-hover:flex items-center justify-center z-[3] rounded-bl-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#61e063"
                viewBox="0 0 24 24"
                stroke-width="3"
                stroke="#61e063"
                className="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                ></path>
              </svg>
            </div>
            {/* desktop img  */}
            <div
              className="relative"
              onClick={(e) =>
                navigate(navigateTo(`${clientUrls.labs}/${labs.labsURL}`))
              }
            >
              <PIL
                lowQaulitySrc={labs.labsImageLowQuality}
                highQualitySrc={labs.labsImageDesktop}
                className="object-fill h-auto w-full rounded-md hidden sm:block"
                alt={labs.title}
              />
            </div>
            {/* mobile img  */}
            <div
              className="relative"
              onClick={(e) =>
                navigate(navigateTo(`${clientUrls.labs}/${labs.labsURL}`))
              }
            >
              <PIL
                lowQaulitySrc={labs.labsImageLowQuality}
                highQualitySrc={labs.labsImageMob}
                className="object-fill w-full h-auto rounded-md sm:hidden"
                alt={labs.title}
              />
            </div>
            {/* <div className="relative">
              <PIL
                lowQaulitySrc={labs?.labsImageLowQuality}
                highQualitySrc={labs?.labsImage}
                className="object-cover"
                alt={labs.title}
              />
            </div> */}
          </div>
          <div className="mt-6 flex flex-col justify-between">
            <div className="flex gap-4 text-[14px] 1.5xl:text-[16px] 2xl:text-[18.6px] 3xl:text-[24px] 4xl:text-[28px]">
              <p className="text-[#61e063]">{labs?.labsPublishedDate}</p>
              {/* <p className="text-[#efefef] uppercase">Press releases</p> */}
            </div>
            <p className="text-[#fff] text-[18px] sm:text-[24px] lg:text-[36px] 1.5xl:text-[40px] 2xl:text-[44px] 2.5xl:text-[46px] 3xl:text-[49px]  3.5xl:text-[50px] 4xl:text-[54px] my-4 leading-[1] font-bold lg:w-[70%] line-clamp-2">
              {labs?.title}
              {/* Placeholder headline with very long words and extraordinary vibes */}
            </p>

            <p className="text-[#fff] text-[16px] 1.5xl:text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32px] line-clamp-2  lg:w-[70%]">
              {labs.labsShortDescription}
            </p>

            <ButtonSlant
              onClick={(e) =>
                navigate(navigateTo(`${clientUrls.labs}/${labs.labsURL}`))
              }
              buttonClass="w-full flex justify-center items-center sm:justify-between text-[18px] lg:text-[16px] xl:text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] cursor-pointer  text-[#61e063] border-[2px] border-[#61e063] px-5 py-2 rounded  font-dela uppercase"
              divClass="mt-4 w-full sm:w-fit"
              slantClass="bottom-right-slant"
              textColorHover="#1A1A1A"
              bgColorHover="#61e063"
              buttonText={labsPageData?.readMoreBtn}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HighlightedLabArticle;
