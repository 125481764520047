export const apiUrls = {
  headerAndFooter: "/headerandfooter",
  homePage: "/homepage",
  aboutPage: "/aboutpage",
  careersPage: "/careerspage",
  projectsPage: "/projectspage",
  projectsList: "/projectslist",
  projectsFilter: "/projectsfilter",
  ProjectDescription: "/projects",
  newsPage: "/newspage",
  labsPage: "/labspage",
  servicesPage: "/servicespage",
  contactPage: "/contactpage",
  submitContactForm: "submitcontact",
  cookieModal: "/cookiemodaldata",
  companyProfile: "/companyprofile",
  newsList: "/newslist",
  newsFilter: "/newsfilter",
  newsDescription: "/news",
  jobs: "/getjobs",
  labsList: "/labslist",
  labsFilter: "/labsfilter",
  labsDescription: "/labs",
  pageNotFound: "/pagenotfound",
  legalPage: "/getleegaldocuments",
};
