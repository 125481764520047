import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setColor } from "../../store/reducers/ColorReducer";
import NewsEvents from "../../components/home/NewsEvents";
import OurServices from "../../components/home/OurServices";
import { clientUrls } from "../../constants/ClientUrls";
import CommonYoutube from "../../components/CommonYoutube";
import "../../css/pagescss/Services.css";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import {
  FormatTextFromGeneralConstants,
  getLanguage,
  navigateTo,
} from "../../constants/GeneralConstants";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import { getServicesPageData } from "../../services/ApiService";
import PIL from "../PIL";
import SwiperSlideCommon from "../SwiperSlideCommon";
import ButtonSlant from "../ButtonSlant";
import { SlantImg } from "../CommonComponents";

const Resolve = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { lang } = useParams();

  const [loader, setLoader] = useState(true);
  const [resolveServicePageData, setResolveServicePageData] = useState([]);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  useEffect(() => {
    callResolvePageAPI(getLanguage());
  }, [lang]);

  const callResolvePageAPI = async (language) => {
    setLoader(true);
    try {
      let data = await getServicesPageData(language, {
        type: "resolve",
      });
      document.title = data?.resolvePageData?.resolveSEOTitle;
      setResolveServicePageData(data);
      dispatch(
        setColor({
          primary: data?.serviceColors[0]?.colorCode,
          secondary: data?.serviceColors[0]?.secondaroyColor,
          tertiary: data?.serviceColors[0]?.tertiaryColor,
        })
      );
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const { labs, subServices, resolvePageData, services, serviceColors } =
    resolveServicePageData;

  if (loader) {
    return <Loader />;
  }
  return (
    <>
      <section>
        {/* new design starts here  */}
        {/* banner starts here  */}
        <section
          className="container h-[19rem] lg:h-[23rem] xl:h-[25rem] 1.5xl:h-[29rem] 2xl:h-[29rem] 2.5xl:h-[35rem] 3xl:h-[38rem] 4xl:h-[45rem]  mt-24 sm:mt-28 4xl:mt-36  mb-16 sm:mb-20 
           lg:mb-20 4xl:mb-32 rounded-lg flex items-center gap-8 3xl:gap-16 relative top-banner-top-left-slantk bg-[#9647FF]"
          // style={{ color: color.tertiary, backgroundColor: color.primary }}
        >
          <div className="absolute top-[-1px] left-[-1px] z-[10] rotate-180">
            <SlantImg className="h-[60px] sm:h-[75px] lg:h-[80px]" />
          </div>
          <div className="pt-12 pl-4 sm:pl-12 lg:pl-16 xl:pl-20 3xl:pl-24 4xl:pl-32 xs-only:pr-4 w-full sm:w-[60%] md:w-[67%] lg:w-[65%]">
            <img
              src={resolvePageData?.bannerResolveImage}
              alt="react-logo"
              className="sm:w-[80%]"
            />
          </div>
          <div className="hidden sm:block w-[30%] sm:w-[40%] lg:w-[35%] h-full relative">
            <img
              src={resolvePageData?.resolveBgImage}
              alt=""
              // alt={`${param}-banner`}
              className="absolute w-full top-0 right-0 h-[19rem] lg:h-full"
            />
          </div>
        </section>
        {/* banner ends here  */}
        {/* paragraph starts here  */}
        <div className="mb-20 sm:mb-28 lg:mb-40">
          <div className="container text-white lg:pl-12 3xl:pl-16">
            {/* deliver para  */}
            <section className="w-full sm:w-[98%] lg:w-[80%] mb-16 sm:mb-20 lg:mb-20 4xl:mb-32 ">
              <h2 className="uppercase text-[32px] lg:text-[38.5px] xl:text-[48px] 2xl:text-[53px] 3xl:text-[68px] 4xl:text-[82px] leading-[1] text-white w-auto mb-4 sm:mb-6">
                {resolvePageData?.deliverMultilingualPlayerSupport_headingText}{" "}
                {resolvePageData?.deliverMultilingualPlayerSupport_heading2Text}
              </h2>
              <p className="mb-4 sm:mb-6 font-18-18-18 leading-[120%] sm:font-medium">
                {FormatTextFromGeneralConstants(
                  resolvePageData?.deliverMultilingualPlayerSupport_paragraphText
                )}
              </p>

              <div className="w-full sm:w-fit cursor-pointer mt-8">
                <ButtonSlant
                  buttonClass="font-18-18-18 px-5 py-2.5 3xl:py-3 4xl:py-4 4xl:px-4 border-[3px] font-dela rounded-md w-full sm:w-fit border-[#9647FF] text-[#9647FF]"
                  buttonText={
                    resolvePageData?.deliverMultilingualPlayerSupport_getInTouchBtn
                  }
                  divClass="w-fit"
                  textColorHover="#1a1a1a"
                  bgColorHover="#9647FF"
                  onClick={(e) => {
                    navigate(navigateTo(clientUrls.contact));
                  }}
                  slantClass="bottom-right-slant"
                />
              </div>
            </section>
          </div>
          {/* at a glance  */}
          <section className="container mb-14 text-white flex flex-col sm:flex-row sm:gap-x-8 lg:gap-x-12 xl:gap-x-12">
            <h2 className="uppercase leading-[1] text-3xl xs-only:mb-4 sm:w-[55%] sm:text-[32px] md:text-4xl  lg:text-[38px] xl:text-5xl 2xl:text-[52px] 3xl:text-[62px] mb-12 sm:mb-0">
              <span className="inline-block">
                {resolvePageData?.resolveText}
              </span>{" "}
              <span className="inline-block">
                {resolvePageData?.atAGlanceText}
              </span>
            </h2>
            <div className="text-[18px] 2xl:text-xl 3xl:text-2xl font-medium leading-[133%] sm:w-[45%]">
              {/* <p className="mb-6">
                  Receive, translate, and respond in any language.
                </p> */}
              <p>
                {FormatTextFromGeneralConstants(
                  resolvePageData?.resolveAtAGlanceParagraph
                )}
              </p>
            </div>
          </section>
        </div>
        <div className="relative">
          <div className="hidden sm:block absolute right-0 top-0 w-[45%] sm:w-[65%] md:w-[70%] lg:w-[45%] 3.5xl:w-[50%] z-[-1]">
            <img
              src={resolvePageData?.serviceTypeBgImage}
              alt="ptw-side"
              className=""
            />
          </div>
          <div className="sm:hidden absolute right-0 z-[-1] top-[10rem]">
            <img src={resolvePageData?.serviceTypeBgImageMob} alt="ptw-side" />
          </div>
          {/* paragraph ends here  */}
          <CommonYoutube url={resolvePageData?.resolveVideo} />
          {/* youtube video ends here */}

          {/* Learn  more */}
          <div className="justify-center flex">
            <ButtonSlant
              buttonClass="font-18-18-18 px-5 py-2.5 3xl:py-3 4xl:py-4 4xl:px-4 border-[3px] font-dela rounded-md w-full sm:w-fit border-[#9647FF] text-[#9647FF]"
              buttonText={resolvePageData?.findOutMoreBtn}
              divClass="w-fit"
              textColorHover="#1a1a1a"
              bgColorHover="#9647FF"
              onClick={(e) => {
                navigate(navigateTo(clientUrls.contact));
              }}
              slantClass="bottom-right-slant"
            />
          </div>
          <section className="pt-20 pb-7">
            {subServices?.map((service, index) => {
              return (
                <SwiperSlideCommon
                  service={service}
                  key={index}
                  color={{
                    primary: serviceColors[0]?.colorCode,
                    tertiary: serviceColors[0]?.tertiaryColor,
                  }}
                  showHeading={true}
                />
              );
            })}
          </section>
        </div>
        {/* benifits comes here  */}
        {/* new design ends here  */}
        {/* <section
          className="container h-auto mt-24 sm:mt-28 4xl:mt-36  mb-16 sm:mb-20  lg:mb-18 4xl:mb-32 rounded-lg top-banner-top-left-slant     flex  items-center "
          style={{
            color: serviceColors[0].tertiary,
            backgroundColor: serviceColors[0].primary,
          }}
        >
          <div className="relative">
            <PIL
              className="h-full"
              alt="lab image"
              lowQaulitySrc={
                resolvePageData?.resolveBgImageLowQuality
                  ? resolvePageData?.resolveBgImageLowQuality
                  : resolvePageData?.resolveBgImage
              }
              highQualitySrc={resolvePageData?.resolveBgImage}
            />
          </div>
        </section> */}

        {/* <div className="container text-white flex flex-col text-center justify-center my-24 sm:mb-20 lg:my-[6rem]">
          <h2 className=" text-[#9647FF] uppercase text-[24px] sm:text-[32px] lg:text-[38.5px] xl:text-[48px] 2xl:text-[53px] 3xl:text-[68px] 4xl:text-[82px] leading-[1]  w-auto mb-4 sm:mb-6">
            {resolvePageData?.deliverMultilingualPlayerSupport_headingText}
          </h2>
          <p className="mb-4 sm:mb-6 text-[18px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px] leading-[120%] sm:font-medium">
            {FormatTextFromGeneralConstants(
              resolvePageData?.deliverMultilingualPlayerSupport_paragraphText
            )}
          </p>
          <div className="grid gap-[2rem]  align-center justify-center grid-col-1 md:grid-cols-3 my-[3rem] sm:mb-[3rem] lg:my-[3.5rem]">
            {challenges.map((c, i) => {
              return (
                <div key={i} className="flex flex-col items-center">
                  <img
                    alt="service"
                    className="inline-block w-[30%]"
                    src={c.challengeLogoURL}
                  />
                  <p className="font-18-18-18 text-white">{c.challengeTitle}</p>
                </div>
              );
            })}
          </div>
          <div className="justify-center flex ">
            <button
              className="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] px-2 py-2 4xl:py-3 4xl:px-4 border-[3px] font-dela rounded-md w-full sm:w-52 2xl:w-64 3xl:w-72 4xl:w-96 bottom-right-slant-hover"
              style={{
                color: isHover
                  ? serviceColors[0].tertiary
                  : serviceColors[0].colorCode,
                borderColor: serviceColors[0].colorCode,
                backgroundColor: isHover ? serviceColors[0].colorCode : "",
              }}
              onClick={(e) => navigate(navigateTo(clientUrls.contact))}
              onMouseEnter={() => {
                setIsHover(true);
              }}
              onMouseLeave={() => {
                setIsHover(false);
              }}
              onTouchStart={() => {
                setIsHover(true);
              }}
              onTouchEnd={() => {
                setIsHover(false);
              }}
            >
              {"Get in Touch"}
            </button>
          </div>
        </div> */}

        <div
          className="bg-no-repeat player-support-bg-1  bg-contain relative my-24 sm:mb-20 lg:my-[6rem]"
          // style={{
          //   backgroundImage: `url(${servicePageData?.serviceTypeBgImage})`,
          // }}
        >
          {/* <div className="hidden sm:block absolute right-0 top-[20rem] 3xl:top-[35rem] 4xl:top-[40rem]">
            <img src={resolvePageData?.glanceBgImageMob} alt="ptw-side" />
          </div>
          <div className="sm:hidden absolute right-0 z-[-1]">
            <img src={resolvePageData?.glanceBgImage} alt="ptw-side" />
          </div>

          <div className="container text-white flex flex-col text-center justify-center">
            <h2 className=" text-[#9647FF] uppercase text-[24px] sm:text-[32px] lg:text-[38.5px] xl:text-[48px] 2xl:text-[53px] 3xl:text-[68px] 4xl:text-[82px] leading-[1]  w-auto mb-4 sm:mb-6">
              {resolvePageData?.resolveAtAGlanceText}
            </h2>
            <p className="mb-12 sm:mb-24 lg:mb-28 text-[18px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px] leading-[120%] text-[#9647FF] sm:font-medium">
              {FormatTextFromGeneralConstants(
                resolvePageData?.receiveTranslateAndRespondInAnyLanguageText
              )}
            </p>
          </div> */}

          {/* paragraph starts here */}

          {/* youtube video starts here */}

          {/* <CommonYoutube url={resolvePageData?.resolveAtAGlanceVideo} /> */}
          {/* youtube video ends here */}

          {/* Learn  more */}
          {/* <div className="justify-center flex">
            <button
              className="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] px-2 py-2 4xl:py-3 4xl:px-4 border-[3px] font-dela rounded-md w-full sm:w-52 2xl:w-64 3xl:w-72 4xl:w-96 bottom-right-slant-hover"
              style={{
                color: isHover
                  ? serviceColors[0].tertiary
                  : serviceColors[0].colorCode,
                borderColor: serviceColors[0].colorCode,
                backgroundColor: isHover ? serviceColors[0].colorCode : "",
              }}
              onClick={(e) => navigate(navigateTo(clientUrls.contact))}
              onMouseEnter={() => {
                setIsHover(true);
              }}
              onMouseLeave={() => {
                setIsHover(false);
              }}
              onTouchStart={() => {
                setIsHover(true);
              }}
              onTouchEnd={() => {
                setIsHover(false);
              }}
            >
              {resolvePageData?.findOutMoreBtn}
            </button>
          </div> */}

          {/* <div className="mb-20 grid gap-[2rem] container text-center align-center justify-center grid-col-1 md:grid-cols-3 my-24 sm:mb-20 lg:my-[6rem]">
            {resolveAtGlance.map((r, i) => {
              return (
                <div className="flex flex-col items-center">
                  <img alt="support" src={r.resolveAtAGlanceLogo} />
                  <p className="text-[#8455CE] font-18-18-18 mb-5">
                    {r.resolveAtAGlanceLogoTitle}
                  </p>
                  <p className="text-white font-18-18-18">
                    {r.resolveAtAGlanceLogoDescription}
                  </p>
                </div>
              );
            })} */}
          {/* <div className="flex flex-col items-center">
              <img
                alt="support"
                src="https://d323sccto6ke4l.cloudfront.net/images/playersupportresolve/agents.png"
              />
              <p className="text-[#8455CE] font-18-18-18 mb-5">
                Maximize productivity of your agents
              </p>
              <p className="text-white font-18-18-18">
                Remove the language barrier and maximize your multilingual
                customer support team to support different languages. Maintain
                quality responses with internal review and optimize costs.
              </p>
            </div>
            <div className="flex flex-col items-center">
              {" "}
              <img
                alt="support"
                src="https://d323sccto6ke4l.cloudfront.net/images/playersupportresolve/control.png"
              />
              <p className="text-[#8455CE] font-18-18-18 mb-5">
                Control the unplanned
              </p>
              <p className="text-white font-18-18-18">
                Manage multi-language support coverage during planned and
                unplanned language spikes, including coverage gaps within the
                team.
              </p>
            </div>
            <div className="flex flex-col items-center">
              {" "}
              <img
                alt="support"
                src="https://d323sccto6ke4l.cloudfront.net/images/playersupportresolve/support-lang.png"
              />
              <p className="text-[#8455CE] font-18-18-18 mb-5">
                Support new languages
              </p>
              <p className="text-white font-18-18-18">
                Test support for new languages, or where volumes don’t warrant
                dedicated agents.
              </p>
            </div> */}
        </div>

        {/* Our patners Section */}
        {/* <OurPartners homeData={servicePageData} clientsData={ourPartners} /> */}
        {/* paragraph ends here */}
        {/* </div> */}
        {/* ----- Player Community Starts Here---------- */}
        {/* <section className="container mb-24 mt-24 sm:mb-16 lg:mb-32 lg:flex justify-between gap-24 2xl:gap-32 3xl:gap-44 text-white">
          <div className="flex flex-col lg:w-[64%] ">
            <h2
              className="uppercase mb-4 xl:mb-12  leading-[1] text-4xl lg:text-[39px] xl:text-5xl 2xl:text-[52px] 3xl:text-[62px]"
              style={{ color: serviceColors[0]?.colorCode }}
            >
              {servicePageData?.lookingAfterSection_heading1Text}
            </h2>
            <p className="mb-6 lg:mb-8 text-[18px] 2xl:text-xl 3xl:text-2xl leading-[133%] font-medium">
              <FormatText
                text={servicePageData?.lookingAfterSection_paragraph1Text}
              />
            </p>

            <p className="mb-8 lg:mb-2 text-[18px] 2xl:text-xl 3xl:text-2xl leading-[133%] font-extrabold">
              <FormatText
                text={servicePageData?.lookingAfterSection_paragraph2Text}
              />
            </p>
            <div className="grid grid-cols-4 sm:grid-cols-7 gap-4  lg:hidden">
              {playersCommunityTop?.map((logo) => {
                return (
                  <div className="border-[0.16px] border-[#ffffff20] flex justify-center items-center py-4">
                    <img
                      src={logo.lookingAfterSection_socialMediaIcon}
                      key={logo.lookingAfterSection_socialMediaTitle}
                      alt="client-logo"
                      className="object-contain"
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="hidden lg:grid grid-cols-2 gap-4 mb-6 lg:w-[36%]">
            {playersCommunityTop?.map((logo) => {
              return (
                <div className="border-[0.16px] border-[#ffffff20] text-center flex justify-center items-center">
                  <img
                    src={logo.lookingAfterSection_socialMediaIcon}
                    key={logo.lookingAfterSection_socialMediaTitle}
                    alt="client-logo"
                    className="object-contain"
                  />
                </div>
              );
            })}
          </div>
        </section> */}
        {/* ----- Player Community ends Here---------- */}
        {/* ---- image container--------- */}
        {/* <section
          className="container h-[17rem] sm:h-[19rem] md:h-[21rem] 1.5md:h-[23rem] lg:h-[24rem] xl:h-[26rem] 1.5xl:h-[28rem] 2xl:h-[31rem] 2.5xl:h-[35rem] 3xl:h-[38rem] 3.5xl:h-[40rem] 4xl:h-[42rem] relative top-banner-top-left-slant mb-20 sm:mb-32 lg:mb-44 rounded-md"
        >
          <img
            className="hidden lg:block w-full h-auto absolute right-0 top-0 lg:-right-2"
            src={servicePageData.innovatingBackgroundImage}
            alt="side-ptw"
          />
          <img
            className="hidden sm:block lg:hidden w-full h-full absolute  top-0 sm:right-2"
            src={servicePageData.innovatingBackgroundImageTab}
            alt="side-ptw"
          />
          <img
            className="block sm:hidden w-[90%] h-full absolute  top-0 right-0"
            src={servicePageData.innovatingBackgroundImageMob}
            alt="side-ptw"
          />
        </section> */}

        {/*------------innovating section--------------------*/}
        {/* <div className="relative">
          <div className="hidden sm:block lg:hidden absolute sm:h-1/2 md:h-2/5 w-auto bottom-60 md:bottom-96 -left-[40rem] -z-[1] ">
            <img
              className="w-4/5 h-auto"
              src={servicePageData?.labsBackgroundImage}
            />
          </div> */}
        {/* <section className="container flex flex-col gap-y-8 lg:flex-row-reverse lg:gap-x-20 lg:2xl:gap-x-32">
            <div className="lg:w-[63%]">
              <h2 className=" uppercase text-[#9647FF] text-4xl leading-[1] mb-8 lg:text-[39px] xl:text-5xl 2xl:text-[52px] 3xl:text-[62px]">
             
                {servicePageData?.innovatingSection_headingText}
              </h2>
              <p className="text-[18px] 2xl:text-xl 3xl:text-2xl leading-[120%] text-white mb-6 font-medium ">
                <FormatText
                  text={servicePageData?.innovatingSection_paragraph1Text}
                />
              </p>
              <p className="text-[18px] leading-[120%] text-white font-medium">
                <FormatText
                  text={servicePageData?.innovatingSection_paragraph2Text}
                />
              </p>
            </div>
            <div className="grid  grid-cols-2 sm:grid-cols-3 gap-4 sm:w-4/5 lg:w-[37%] lg:grid-cols-2">
              {innovatingVision?.map((logo, meta_value) => {
                return (
                  <div className="border-[0.16px] border-[#ffffff20] flex justify-center items-center px-2 py-5 lg:py-1">
                    <img
                      src={logo.innovatingSection_serviceLogo}
                      key={logo.meta_value}
                      alt="client-logo"
                      className="object-contain h-[56px] 3xl:h-[70px]"
                    />
                  </div>
                );
              })}
            </div>
          </section> */}

        {/* <section className="my-24 sm:my-[8rem] lg:mt-[14rem] lg:mb-0">
            <ProjectsShowSection
              homeData={servicePageData}
              projectsData={projects}
              colors={{
                color: serviceColors[0]?.secondaroyColor,
              }}
              isBtnBottom={true}
            />
          </section> */}
        {/* </div> */}
        <div className="relative my-24 sm:mb-20 lg:-mt-20 lg:mb-60">
          <div className="hidden sm:block absolute h-auto w-[90%] xl-only:w-[80%] 2.5xl:w-[85%] 3xl:w-[85%] 3.3xl:w-[80%] 4xl:w-[75%] top-4 sm:top-[22rem] lg:top-[-12rem] -z-[1] sm:left-[-19rem] lg:left-0">
            <img
              className="w-full lg:w-4/5 h-auto"
              alt=""
              src={resolvePageData?.labsBgImage}
            />
          </div>
          <NewsEvents
            // header1={
            //   bannerSection_heading1Text + " " + bannerSection_heading2Text
            // }
            header2={resolvePageData?.fromTheLabText}
            headerButtonText={
              resolvePageData?.visitTheLabText
                ? resolvePageData?.visitTheLabText
                : "Visit The Lab"
            }
            cardsData={labs}
            colors={{ color: serviceColors[0]?.secondaroyColor }}
            url={clientUrls.labs}
            toLabs={true}
          />
        </div>

        <OurServices
          servicesData={services}
          header1={resolvePageData?.exploreText}
          header2={resolvePageData?.moretext}
          colors={{ color: serviceColors[0]?.colorCode }}
          lang={lang}
        />
      </section>

      <Footer />
    </>
  );
};

export default Resolve;
