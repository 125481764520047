import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setColor } from "../../store/reducers/ColorReducer";
import NewsEvents from "../../components/home/NewsEvents";
import OurServices from "../../components/home/OurServices";
import SwiperSlideCommon from "../../components/SwiperSlideCommon";
import { clientUrls } from "../../constants/ClientUrls";
import CommonYoutube from "../../components/CommonYoutube";
import "../../css/pagescss/Services.css";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import {
  FormatTextFromGeneralConstants,
  getLanguage,
  navigateTo,
} from "../../constants/GeneralConstants";
import { Link } from "react-router-dom";
import { getServicesPageData } from "../../services/ApiService";
import Loader from "../../components/Loader";
import PIL from "../../components/PIL";
import ButtonSlant from "../../components/ButtonSlant";
import { SlantImg } from "../../components/CommonComponents";

const ReactPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { lang } = useParams();

  const [loader, setLoader] = useState(true);
  const [reactServicePageData, setReactServicePageData] = useState([]);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  useEffect(() => {
    callReactPageAPI(getLanguage());
  }, [lang]);

  const callReactPageAPI = async (language) => {
    setLoader(true);
    try {
      let data = await getServicesPageData(language, {
        type: "react",
      });
      document.title = data?.reactPageData?.reactSEOTitle;
      setReactServicePageData(data);
      dispatch(
        setColor({
          primary: data?.serviceColors[0]?.colorCode,
          secondary: data?.serviceColors[0]?.secondaroyColor,
          tertiary: data?.serviceColors[0]?.tertiaryColor,
        })
      );
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const {
    benifits,
    keyComponents,
    labs,
    logos,
    reactPageData,
    services,
    serviceColors,
    subServices,
    // servicePageData,
  } = reactServicePageData;

  if (loader) {
    return <Loader />;
  }

  return (
    <>
      <section>
        {/* banner starts here  */}
        <section
          className="container h-[19rem] lg:h-[23rem] xl:h-[25rem] 1.5xl:h-[29rem] 2xl:h-[29rem] 2.5xl:h-[35rem] 3xl:h-[38rem] 4xl:h-[45rem]  mt-24 sm:mt-28 4xl:mt-36  mb-16 sm:mb-20 
           lg:mb-20 4xl:mb-32 rounded-lg flex items-center gap-8 3xl:gap-16 relative top-banner-top-left-slantk bg-[#9647FF]"
          // style={{ color: color.tertiary, backgroundColor: color.primary }}
        >
          <div className="absolute top-[-1px] left-[-1px] z-[10] rotate-180">
            <SlantImg className="h-[60px] sm:h-[75px] lg:h-[80px]" />
          </div>
          <div className="pt-12 pl-4 sm:pl-12 lg:pl-16 xl:pl-20 3xl:pl-24 4xl:pl-32 xs-only:pr-4 w-full sm:w-[60%] md:w-[67%] lg:w-[65%]">
            <img
              src={reactPageData?.bannerReactImage}
              alt="react-logo"
              className="sm:w-[80%]"
            />
          </div>
          <div className="hidden sm:block w-[30%] sm:w-[40%] lg:w-[35%] h-full relative">
            <img
              src={reactPageData?.reactBgImage}
              // src="/images/react-banner-d.png"
              // alt={`${param}-banner`}
              className="absolute w-full top-0 right-0 h-[19rem] lg:h-full"
            />
          </div>
        </section>
        {/* banner ends here  */}
        {/* paragraph starts here  */}
        <section className="mb-20 sm:mb-28 lg:mb-40">
          <div className="container text-white lg:pl-12 3xl:pl-16">
            {/* deliver para  */}
            <section className="w-full sm:w-[98%] lg:w-[75%] mb-16 sm:mb-20 lg:mb-20 4xl:mb-32 ">
              <h2 className="uppercase text-[32px] lg:text-[38.5px] xl:text-[48px] 2xl:text-[53px] 3xl:text-[68px] 4xl:text-[82px] leading-[1] text-white w-auto mb-4 sm:mb-6">
                {reactPageData?.deliverMultilingualPlayerSupport_headingText}{" "}
                {reactPageData?.deliverMultilingualPlayerSupport_heading2Text}
              </h2>
              <p className="mb-4 sm:mb-6 font-18-18-18 leading-[120%] sm:font-medium">
                {FormatTextFromGeneralConstants(
                  reactPageData?.deliverMultilingualPlayerSupport_paragraphText
                )}
              </p>
              <div className="grid gap-[2rem]  align-center justify-center grid-cols-2 sm:grid-cols-3 my-8">
                {logos.map((c, i) => {
                  return (
                    <div key={i} className="flex flex-col items-center">
                      <div className="border-[0.16px] border-[#ffffff20] flex justify-center items-center px-2 py-5 lg:py-5 w-full my-6">
                        <img
                          alt="service"
                          className="inline-block w-[30%] sm:w-auto sm:h-[5rem]"
                          src={c.playerSupportLogoURL}
                        />
                      </div>
                      <p className="text-[12px] 2xl:text-[16px] text-white uppercase leading-[1] text-center">
                        {c.playerSupportTitle}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="w-full sm:w-fit cursor-pointer">
                <ButtonSlant
                  buttonClass="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] px-5 py-2.5 4xl:py-3 4xl:px-4 border-[3px] font-dela rounded-md w-full sm:w-fit border-[#9647FF] text-[#9647FF]"
                  buttonText={
                    reactPageData?.deliverMultilingualPlayerSupport_getInTouchBtn
                  }
                  divClass="w-fit"
                  textColorHover="#1a1a1a"
                  bgColorHover="#9647FF"
                  onClick={(e) => {
                    navigate(navigateTo(clientUrls.contact));
                  }}
                  slantClass="bottom-right-slant"
                />
              </div>
            </section>
            {/* at a glance  */}
            <section className="mb-14 text-white flex flex-col sm:flex-row sm:gap-x-8 lg:gap-x-12 xl:gap-x-12">
              <h2 className="uppercase leading-[1] text-3xl xs-only:mb-4 sm:w-[55%] sm:text-[32px] md:text-4xl  lg:text-[38px] xl:text-5xl 2xl:text-[52px] 3xl:text-[62px] mb-12 sm:mb-0">
                {reactPageData?.reactText} {reactPageData?.atAGlanceText}
              </h2>
              <div className="text-[18px] 2xl:text-xl 3xl:text-2xl font-medium leading-[133%] sm:w-[45%]">
                {/* <p className="mb-6">Embrace the future of Player Support</p> */}
                <p>
                  {FormatTextFromGeneralConstants(
                    reactPageData?.reactAtAGlanceParagraph
                  )}
                </p>
              </div>
            </section>
            <div className="w-full sm:w-fit cursor-pointer">
              <ButtonSlant
                buttonClass="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] px-5 py-2.5 4xl:py-3 4xl:px-4 border-[3px] font-dela rounded-md w-full sm:w-fit border-[#9647FF] text-[#9647FF]"
                buttonText={reactPageData?.findOutMoreText}
                divClass="w-fit"
                textColorHover="#1a1a1a"
                bgColorHover="#9647FF"
                onClick={(e) => {
                  navigate(navigateTo(clientUrls.contact));
                }}
                slantClass="bottom-right-slant"
              />
            </div>
          </div>
        </section>
        {/* paragraph ends here  */}
        <div className="relative">
          <div className="hidden sm:block lg:hidden absolute right-0 top-[24%] w-[45%] sm:w-[65%] md:w-[70%] lg:w-[45%] 3.5xl:w-[50%] z-[-1]">
            <img
              src={reactPageData?.serviceTypeBgImage}
              alt="ptw-side"
              className=""
            />
          </div>
          <section className="relative">
            <div className="hidden lg:block absolute right-0 top-0 w-[45%] sm:w-[65%] md:w-[70%] lg:w-[45%] 3.5xl:w-[50%] z-[-1]">
              <img
                src={reactPageData?.serviceTypeBgImage}
                alt="ptw-side"
                className=""
              />
            </div>
            <div className="sm:hidden absolute right-0 z-[-1] top-[10rem]">
              <img src={reactPageData?.serviceTypeBgImageMob} alt="ptw-side" />
            </div>
            {/* keycomponents comes here  */}
            {keyComponents?.map((service, index) => {
              return (
                <SwiperSlideCommon
                  service={service}
                  key={index}
                  color={{
                    primary: serviceColors[0]?.colorCode,
                    tertiary: serviceColors[0]?.tertiaryColor,
                  }}
                  showHeading={true}
                />
              );
            })}

            <div className="container flex ">
              <ButtonSlant
                buttonClass="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] px-5 py-2.5 4xl:py-3 4xl:px-4 border-[3px] font-dela rounded-md w-full sm:w-fit border-[#9647FF] text-[#9647FF]"
                buttonText={reactPageData?.learnMoreText}
                divClass="w-fit"
                textColorHover="#1a1a1a"
                bgColorHover="#9647FF"
                onClick={(e) => {
                  navigate(navigateTo(clientUrls.contact));
                }}
                slantClass="bottom-right-slant"
              />
            </div>
            {/* youtube video starts here */}
            <section className=" my-24 sm:mb-20 lg:my-[6rem]">
              <CommonYoutube url={reactPageData?.reactVideo} />
            </section>
            {/* youtube video ends here */}
          </section>
          <div className="relative">
            <div className="hidden lg:block absolute h-auto w-[90%] xl-only:w-[80%] 2.5xl:w-[85%] 3xl:w-[85%] 3.3xl:w-[80%] 4xl:w-[75%] top-4 lg:top-[-8rem] -z-[1] ">
              <img className="w-4/5 h-auto" src={reactPageData?.labsBgImage} />
            </div>
            {/* benefits comes here  */}
            {benifits?.map((service, index) => {
              return (
                <SwiperSlideCommon
                  service={service}
                  key={index}
                  color={{
                    primary: serviceColors[0]?.colorCode,
                    tertiary: serviceColors[0]?.tertiaryColor,
                  }}
                  showHeading={true}
                />
              );
            })}
          </div>
          <div className="relative">
            <div className="hidden sm:block lg:hidden absolute h-auto w-[90%] xl-only:w-[80%] 2.5xl:w-[85%] 3xl:w-[85%] 3.3xl:w-[80%] 4xl:w-[75%] top-4 sm:top-[22rem] lg:top-[-12rem] -z-[1] sm:left-[-19rem] lg:left-0">
              <img
                className="w-full lg:w-4/5 h-auto"
                src={reactPageData?.labsBgImage}
                alt=""
              />
            </div>
            <NewsEvents
              // header1={
              //   bannerSection_heading1Text + " " + bannerSection_heading2Text
              // }
              header2={reactPageData?.fromTheLabText}
              headerButtonText={
                reactPageData?.visitTheLabText
                  ? reactPageData?.visitTheLabText
                  : "View Lab"
              }
              cardsData={labs}
              colors={{ color: serviceColors[0]?.secondaroyColor }}
              url={clientUrls.labs}
              toLabs={true}
            />
          </div>

          <OurServices
            servicesData={services}
            header1={reactPageData?.exploreText}
            header2={reactPageData?.moretext}
            colors={{ color: serviceColors[0]?.colorCode }}
            lang={lang}
          />
        </div>

        {/* new design ends here  */}
      </section>

      <Footer />
    </>
  );
};

export default ReactPage;
