import { useEffect } from "react";
import "./ItSupport.css";
const ItSupport = () => {
  useEffect(() => {
    document.title = "IT Support";
    document.getElementById("header").style.display = "none";
  }, []);
  return (
    <>
      <div className="page_height font">
        <div className="it-navbar">
          <a href="/">
            <img
              id="logo-navbar-middle"
              src="https://d1r5h1ay0no5pj.cloudfront.net/side/wp-content/uploads/2024/11/20095710/Side-Logo-1.svg"
              alt="ptw"
            />
          </a>
          <span className="clippath-logo"></span>
        </div>
        <div className="itsupport_page circle-bg">
          <div className="itsupport_page_card">
            <h1>IT Support</h1>

            <p className="text-center">
              Please use the below link to log in to your Side Microsoft account
              and create a ticket.
              <br />A ticket is required for <strong>all</strong> IT Help,
              Support, or Requests.
            </p>
            <br />
            <form
              className="text-center"
              action="https://itsupportptw.zendesk.com"
            >
              <button type="submit">Login and Create IT Request</button>
            </form>
            <br />
            <h2 className="text-center">
              Can't login, forgot password or computer not working?
            </h2>
            <br />
            <p className="text-center">
              Contact your supervisor or HR contact and have them submit a
              ticket on your behalf.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItSupport;
