import React, { useEffect, useState } from "react";
import { clientUrls } from "../../constants/ClientUrls";
import { useNavigate } from "react-router-dom";
import { navigateTo } from "../../constants/GeneralConstants";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ButtonSlant from "../ButtonSlant";

const Location = ({ loc, i }) => {
  const { ref: locationRef, inView: locationView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const navigate = useNavigate();
  return (
    <motion.div
      ref={locationRef}
      initial={{ y: -100, opacity: 0 }}
      animate={{
        y: locationView ? 0 : -100,
        opacity: locationView ? 1 : 0,
      }}
      transition={{
        duration: 0.2,
      }}
      style={{
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
      className=" relative group flex flex-col w-[50%]k sm:w-[44%] text-left items-start  group-hover:bg-[#2AD9FF] rounded-b-lg text-white sm:pr-3 flex-shrink-0 hide-scroll"
      onClick={(e) => {
        sessionStorage.setItem("scrollTo", loc?.locationName?.toLowerCase());
        navigate(navigateTo(clientUrls.contact));
      }}
    >
      <img
        src={loc.locationImage}
        alt={loc.locationName}
        className="w-full h-[10rem] sm:h-56 md:h-[17rem] object-cover rounded-t-lg"
      />

      <div
        style={{
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
        className="flex flex-col justify-center w-full h-[5rem] lg:h-[7rem] text-left items-start bg-[#46464680] group-hover:bg-[#2AD9FF] rounded-b-lg text-white p-3"
      >
        <div>
          <h2 className="uppercase lg:text-[18px] leading-[1] lg:leading-5 group-hover:text-black">
            {loc.locationName}
          </h2>
        </div>
      </div>
      <div className="hidden cursor-pointer absolute top-[5px] right-[5px] bg-[#1a1a1a] h-12 w-12 group-hover:flex items-center justify-center rounded-bl-lg z-[3]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#2AD9FF"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="#2AD9FF"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
          />
        </svg>
      </div>
    </motion.div>
  );
};

const GlobalTeam = ({ home, regions, homePageData }) => {
  const navigate = useNavigate();

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [isHover, setIsHover] = useState(false);

  const handleRegionChange = (region) => {
    const selectedLocation = homePageData?.locations?.filter(
      (loc) => loc.regionName === region
    );
    setSelectedRegion(region);
    setSelectedLocations(selectedLocation);
    setDropdownVisible(false);
  };

  const {
    locationsSection_aGlobalTeamOfCreativesText,
    locationsSection_joinTheTeamText,
    locationsSection_getInTouchText,
    locationsSection_aGlobalTeamText,
    locationsSection_creativesEngineersText,
    aGlobalTeamOfCreativesText,
    aGlobalTeamText,
    creativesAndEngineersText,
  } = home;

  useEffect(() => {
    let arr = homePageData?.locations.sort((a, b) => {
      return a.locationOrder - b.locationOrder;
    });
    const initialLocations = arr.filter(
      (loc) => loc.regionName === homePageData?.locations?.[0].regionName
    );
    setSelectedLocations(initialLocations);
  }, [homePageData?.locations]);

  return (
    <>
      {" "}
      <div className="container mx-auto lg:hidden">
        <h2 className="text-white leading-[1]  common-title-size font-extrabold uppercase">
          <span className="block">
            {aGlobalTeamOfCreativesText
              ? aGlobalTeamOfCreativesText
              : locationsSection_aGlobalTeamOfCreativesText}
          </span>
          <span className="block">
            {aGlobalTeamText
              ? aGlobalTeamText
              : locationsSection_aGlobalTeamText}{" "}
          </span>
          <span className="text-[#2AD9FF]">
            {creativesAndEngineersText
              ? creativesAndEngineersText
              : locationsSection_creativesEngineersText}{" "}
          </span>
        </h2>

        <button
          id="dropdownDefaultButton"
          className="text-[#2AD9FF] border-[2px] border-[#2AD9FF] rounded-lg text-[18px] font-dela px-5 py-2.5 text-center flex items-center uppercase w-full mt-8 mb-2 justify-between lg:hidden"
          type="button"
          onClick={() => setDropdownVisible(!dropdownVisible)}
        >
          {selectedRegion ?? regions[0]}
          {dropdownVisible ? (
            <svg
              width="22"
              height="22"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2 platforms"
            >
              <path
                d="M8.625 6.25L12.375 2.5L16.125 6.25"
                stroke="#2AD9FF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                stroke="#2AD9FF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          ) : (
            <svg
              width="22"
              height="22"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2"
            >
              <path
                d="M8.125 13.75L11.875 17.5L15.625 13.75"
                stroke="#2AD9FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                stroke="#2AD9FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {/* <img src="images/ArrowBendUpRight-blue.png" alt="Arrow" /> */}
        </button>
        {dropdownVisible && (
          <div id="dropdown" className="z-10 divide-y divide-gray-100 relative">
            <ul
              className="py-2 text-[18px] font-dela uppercase text-[#2AD9FF]  rounded-lg shadow w-full border-[2px] border-[#2AD9FF] bg-[#1a1a1a] absolute"
              aria-labelledby="dropdownDefaultButton"
            >
              {regions?.map((region, index) => (
                <li key={index}>
                  <button
                    onClick={() => handleRegionChange(region)}
                    className="block px-4 py-2 text-[18px] font-dela uppercase text-[#2AD9FF]"
                  >
                    {region}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="grid grid-cols-2 gap-4 sm:gap-0 sm:flex flex-wrap sm:overflow-x-auto sm:flex-nowrap hide-scroll md-only:my-[2rem] my-8">
          {selectedLocations?.map((loc, i) => (
            <Location loc={loc} key={loc.post_id} i={i} />
          ))}
        </div>
      </div>
      <div className="flex items-center justify-center flex-col sm:flex-row lg:hidden text-center container">
        <ButtonSlant
          buttonText={locationsSection_joinTheTeamText}
          divClass="sm:mr-4 w-full sm:w-fit my-2 flex items-center justify-center"
          buttonClass="uppercase font-18-18-18 text-[#2AD9FF] font-bold border-[#2AD9FF] border-[2px] px-6 py-3 rounded-md  w-full sm:w-auto font-dela "
          slantClass="bottom-right-slant"
          textColor="#2ad9ff"
          bgColorHover="#2AD9FF"
          textColorHover="#1a1a1a"
          onClick={(e) => navigate(navigateTo(clientUrls.careers))}
        />

        <button
          onClick={(e) => navigate(navigateTo(clientUrls.contact))}
          className="relative font-18-18-18 font-dela cursor-pointer border-none outline-none px-6 py-3.5 rounded-md w-full sm:w-fit"
          onTouchStart={() => {
            setIsHover(true);
          }}
          onTouchEnd={() => {
            setIsHover(false);
          }}
          style={{
            color: isHover ? "#2ad9ff" : "#1a1a1a",
            backgroundColor: isHover ? "#1a1a1a" : "#2ad9ff",
          }}
        >
          {locationsSection_getInTouchText}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 120"
            className="absolute inset-0 w-full h-full"
            preserveAspectRatio="none"
          >
            <path
              d="M10,2 H390 A5,5 0 0 1 395,7 v78 L368,120 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
              fill="none"
              stroke="#2ad9ff"
              stroke-width="4"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </>
  );
};

export default GlobalTeam;
