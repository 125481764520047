import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../css/componentscss/SwiperSlide.css";
import { Link, useNavigate } from "react-router-dom";
import FormatText from "./FormatText";
import "swiper/css/mousewheel"; // Import Swiper's mousewheel CSS
import { Mousewheel } from "swiper/modules";
import { ArrowLeft, ArrowRight } from "./CommonComponents";
import ButtonSlant from "./ButtonSlant";
import useWindowWidth from "./services/useWindowWidth";
const SwiperSlideCommon = ({ service, color, showHeading }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);
  const [isHoverPrev, setIsHoverPrev] = useState(false);
  const [isHoverNext, setIsHoverNext] = useState(false);
  const windowWidth = useWindowWidth();
  const handleNext = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      setActiveIndex((prevIndex) => prevIndex + 1);
      swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      setActiveIndex((prevIndex) => prevIndex - 1);
      swiper.slidePrev();
    }
  };
  const toggleMousewheel = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      if (swiper) {
        if (windowWidth >= 640) {
          swiper.mousewheel.enable();
        } else {
          swiper.mousewheel.disable();
        }
      }
    }
  };
  const isIOS = (userAgent) => {
    return /iPhone|iPad|iPod/i.test(userAgent);
  };
  const forceRepaint = () => {
    if (isIOS(navigator.userAgent)) {
      if (service?.subServices) {
        const ele = document.getElementById(
          service?.subServices[activeIndex]?.subServiceName
            ?.toLowerCase()
            ?.split(" ")
            ?.join("-")
        );
        if (ele) {
          ele.style.display = "none";
          ele.style.color = "";
          setTimeout(() => {
            ele.style.display = "block";
            ele.style.color = color.tertiary;
          }, 10);
        }
      }
    }
  };

  useEffect(() => {
    toggleMousewheel();
    window.addEventListener("resize", toggleMousewheel);

    return () => {
      window.removeEventListener("resize", toggleMousewheel);
    };
  }, []);
  const updateSwiper = () => {
    if (swiperRef.current?.swiper) {
      const swiper = swiperRef.current.swiper;
      setTimeout(() => {
        swiper.update();
      }, 50);
    }
  };
  useEffect(() => {
    forceRepaint();
  }, [activeIndex]);
  return (
    <section className="container mb-16 sm:mb-20">
      {showHeading && (
        <h2 className="text-white text-[36px] sm:text-[42px] md:text-[48px] lg:text-[57.6px] xl:text-[72px] 2xl:text-[80px]  3xl:text-[94px] 4xl:text-[115px] leading-[1] mb-5 sm:mb-16 2xl:mb-20 uppercase">
          {service?.serviceName}
        </h2>
      )}
      <Swiper
        className="custom-swiper mb-4 !overflow-visible hide-scroll"
        loop={false}
        breakpoints={{
          320: {
            slidesPerView: 1.1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 1.7,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2.3,
            spaceBetween: 40,
          },
          1286: {
            slidesPerView: 2.6,
            spaceBetween: 40,
          },
          1530: {
            slidesPerView: 2.7,
            spaceBetween: 40,
          },
          2560: {
            slidesPerView: 3.1,
            spaceBetween: 40,
          },
        }}
        ref={swiperRef}
        speed={800}
        effect="fade"
        allowSlideNext={activeIndex !== service?.subServices?.length - 1}
        allowSlidePrev={activeIndex !== 0}
        onSlideChangeTransitionEnd={updateSwiper}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.activeIndex);
        }}
        modules={[Mousewheel]} // Add the Scrollbar module
        mousewheel={{ forceToAxis: true, sensitivity: 3, thresholdDelta: 1 }}
        freeMode={true}
      >
        {service?.subServices?.map((subService, subIndex) => (
          <SwiperSlide
            key={subIndex}
            className={`!flex flex-col justify-end p-6 rounded-lg 
      ${
        subIndex === activeIndex
          ? "slider-cards-top-left-slant service-slide-active"
          : "service-slide"
      } 
      !h-72 xs:!h-72 lg:!h-64 xl:!h-72 2xl:!h-[22rem] 3xl:!h-[26rem] 4xl:!h-[30rem] backdrop-blur-sm lg:pt-12`}
            style={{
              color: subIndex === activeIndex ? color.tertiary : "",
              backgroundColor: subIndex === activeIndex ? color.primary : "",
            }}
          >
            {/* <div> */}
            <h3 className="text-xl xs:text-[24px] 2xl:text-[26px] 3xl:text-[30px] 4xl:text-[38px] leading-[1] sm:leading-[100%] mb-4 uppercase sm:mt-9 lg:mt-0">
              {subService?.subServiceName}
            </h3>
            <p
              className="text-base xs:text-[18px] 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28.4px] leading-[111%] font-medium overflow-auto services-slide-scroll h-3/5 lg:h-[72%]"
              id={subService?.subServiceName
                ?.toLowerCase()
                .split(" ")
                .join("-")}
              style={{
                color: subIndex === activeIndex ? color.tertiary : "",
              }}
            >
              <FormatText text={subService?.subServiceDescription} />
            </p>

            {subService?.subServiceButtonLink && (
              <Link to={subService?.subServiceButtonLink}>
                <ButtonSlant
                  buttonClass="block text-[18px] 2xl:text-[21.6px] mt-5 3xl:text-[27px] 4xl:text-[32.4px] px-6 py-2 border-[2px] font-dela rounded-md w-full sm:w-fit  hover:!text-black"
                  divClass="mt-5 w-fit"
                  buttonText={subService?.subServiceButtonText}
                  textColorHover="#1a1a1a"
                  bgColorHover={color?.tertiary}
                  slantClass="bottom-right-slant"
                />
              </Link>
            )}
            {/* </div> */}
          </SwiperSlide>
        ))}
        {/* Add two dummy slides */}
        <SwiperSlide className="!flex !h-72 xs:!h-72 lg:!h-64 xl:!h-72 2xl:!h-[22rem] 3xl:!h-[26rem] invisible"></SwiperSlide>
        <SwiperSlide className="!flex !h-72 xs:!h-72 lg:!h-64 xl:!h-72 2xl:!h-[22rem] 3xl:!h-[26rem] invisible"></SwiperSlide>
      </Swiper>

      {/* Section for Previous/Next buttons */}

      <div className="hidden sm:flex gap-4">
        <div
          onMouseEnter={() => {
            setIsHoverPrev(activeIndex === 0 ? false : true);
          }}
          onMouseLeave={() => {
            setIsHoverPrev(false);
          }}
          onTouchStart={() => {
            setIsHoverPrev(activeIndex === 0 ? false : true);
          }}
          onTouchEnd={() => {
            setIsHoverPrev(false);
          }}
        >
          <button
            onClick={handlePrev}
            disabled={activeIndex === 0}
            className={`w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] rounded-md pl-2 group ${
              isHoverPrev ? "arrow-btn-top-left" : ""
            } ${
              activeIndex === 0
                ? "opacity-30 pointer-events-none"
                : "opacity-100"
            }`}
            style={{
              borderColor: color.primary,
              backgroundColor: isHoverPrev ? color.primary : "",
            }}
          >
            <ArrowLeft color={color.primary} hover={isHoverPrev} />
          </button>
        </div>
        <div
          onMouseEnter={() => {
            setIsHoverNext(
              activeIndex === service?.subServices?.length - 1 ? false : true
            );
          }}
          onMouseLeave={() => {
            setIsHoverNext(false);
          }}
          onTouchStart={() => {
            setIsHoverNext(
              activeIndex === service?.subServices?.length - 1 ? false : true
            );
          }}
          onTouchEnd={() => {
            setIsHoverNext(false);
          }}
        >
          <button
            onClick={handleNext}
            disabled={activeIndex === service?.subServices?.length - 1}
            className={`w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] rounded-md pl-2 group ${
              isHoverNext ? "arrow-btn-bottom-right" : ""
            } ${
              activeIndex === service?.subServices?.length - 1
                ? "opacity-30 pointer-events-none"
                : "opacity-100 "
            }`}
            style={{
              borderColor: color.primary,
              backgroundColor: isHoverNext ? color.primary : "",
            }}
          >
            <ArrowRight color={color?.primary} hover={isHoverNext} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default SwiperSlideCommon;
