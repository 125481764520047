import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { setColor } from "../store/reducers/ColorReducer";
import { useDispatch } from "react-redux";
import { getLegalPageData } from "../services/ApiService";
import { getLanguage, navigateTo } from "../constants/GeneralConstants";
import { clientUrls } from "../constants/ClientUrls";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Loader from "./Loader";
import FormatText from "./FormatText";

const Legal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { lang } = useParams();
  const [legalData, setLegalData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [activeLegal, setActiveLegal] = useState("");

  const pathParts = location.pathname.split("/").filter(Boolean);
  const lastParam = pathParts[pathParts.length - 1];

  useEffect(() => {
    callLegalPageData(getLanguage(), lastParam);
  }, [lastParam, lang]);

  const callLegalPageData = async (language, lastParam) => {
    setLoader(true);
    try {
      let data = await getLegalPageData(language);
      if (lastParam === "website-terms-of-usage") {
        setActiveLegal(data?.documents[0]?.policiesTitle);
      } else if (lastParam === "privacy-policy") {
        setActiveLegal(data?.documents[1]?.policiesTitle);
      } else if (lastParam === "modern-slavery-statement") {
        setActiveLegal(data?.documents[2]?.policiesTitle);
      }

      setLegalData(data);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(
      setColor({
        primary: "#ff8200",
        secondary: "#ff8200",
        tertiary: "#333333",
        menusColor: "#ffffff",
      })
    );
  }, [dispatch]);

  const navigateToPage = (title) => {
    if (title === "Website Terms of Usage") {
      navigate(navigateTo(clientUrls.websiteTermsOfUsage));
    } else if (title === "Privacy Policy") {
      navigate(navigateTo(clientUrls.privacyPolicy));
    } else if (title === "Modern Slavery Statement") {
      navigate(navigateTo(clientUrls.modernSlaveryStatement));
    }
  };

  if (loader) {
    return <Loader />;
  }
  const { pageData, documents } = legalData || {};

  return (
    <section className="relative">
      <div className="absolute right-0 top-[-6rem] sm-only:w-[75%] md-only:w-[65%] lg:w-[60%] xl:w-[45%] z-[-1]">
        <img src={pageData?.BgImage} alt="bg img" className="opacity-[20%]" />
      </div>
      <div className="container  relative mb-40 mt-40">
        <div className="flex gap-8 text-[#fff] items-center">
          <button
            className="w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] rounded-md pl-2 group slide-btn-top-left-slant opacity-100 border-[#FF8200] hover:bg-[#FF8200]"
            onClick={() => {
              navigate(navigateTo(clientUrls.home));
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain group-hover:hidden"
            >
              <path
                d="M6.25 11.875L2.5 8.125L6.25 4.375"
                stroke="#FF8200"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                stroke="#FF8200"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 2xl:w-8 2xl:h-8 4xl:w-10 4xl:h-10 object-contain hidden group-hover:block"
            >
              <path
                d="M6.25 11.875L2.5 8.125L6.25 4.375"
                stroke="#1A1A1A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M17.5 15.625C17.5 13.6359 16.7098 11.7282 15.3033 10.3217C13.8968 8.91518 11.9891 8.125 10 8.125H2.5"
                stroke="#1A1A1A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </button>

          <div>
            <p class="text-[16px] 2xl:text-[19.6px] 3xl:text-[25px] 4xl:text-[30.4px] uppercase">
              {pageData?.backToHomeText}
            </p>
          </div>
        </div>
        <div className="w-full mt-16">
          <h2 className="text-[24px] sm:text-[30px] lg:text-[38.5px] xl:text-[48px] 2xl:text-[57.6px] 3xl:text-[72px] leading-[1] text-white uppercase pr-[1rem] ">
            {activeLegal}
          </h2>
        </div>

        <div className="flex flex-col mt-20 xs:flex-col lg:flex-row  gap-12">
          <div className="lg:w-[30%] xl:w-[27%]  md:w-full visible  xs-only:hidden flex-wrap  hidden lg:flex lg:flex-col">
            <div class="flex w-full mt-4 space-between justify-start gap-16">
              <p class="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] font-bold basis-1/2k text-[#FF8200] uppercase">
                {pageData?.legalText}
              </p>
            </div>
            <div class="my-4 border-[#7E818A] border-b-2"></div>
            <div className="flex flex-col gap-4">
              {documents?.map((el) => {
                return (
                  <p
                    class={`cursor-pointer text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white basis-1/2 hover:text-primary ${
                      activeLegal === el?.policiesTitle ? "!text-primary" : ""
                    }`}
                    onClick={() => {
                      // setActiveLegal(el?.policiesTitle);
                      navigateToPage(el?.policiesTitle);
                    }}
                  >
                    {el?.policiesTitle}
                  </p>
                );
              })}

              {/* <p class="text-[16px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-[120%] text-white basis-1/2 flex justify-between">
                CSR Policy (India)
                <svg
                  class="size-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                  />
                </svg>
              </p> */}
            </div>
          </div>
          <div className="w-full lg:w-[70%] xl:w-[73%]">
            <div className="flex flex-col gap-4 text-white">
              <div className="whitespace-pre-wrap text-[18px]  2xl:text-[19.8px] 3xl:text-[21.6px] 4xl:text-[25.2px] leading-normal  text-white cms-style">
                <FormatText
                  text={
                    documents.filter(
                      (el) => el.policiesTitle === activeLegal
                    )[0]?.policiesDescription
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Legal;
