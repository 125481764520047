import React, { useEffect, useRef, useState } from "react";
import "../../css/pagescss/Home.css";
import { useNavigate } from "react-router-dom";
import { getLanguage, navigateTo } from "../../constants/GeneralConstants";
import { clientUrls } from "../../constants/ClientUrls";
import {
  motion,
  useAnimation,
  useMotionValue,
  useScroll,
  useTransform,
} from "framer-motion";
import { useInView } from "react-intersection-observer";

/// Created this component for individual hover color effect///////
const ServiceTile = ({
  service,
  lang,
  isDragged,
  index,
  serviceTilesVariant,
}) => {
  const navigate = useNavigate();
  const [isServiceHover, setIsServiceHover] = useState(false);

  return (
    <motion.div
      className="p-4 w-[60%] sm:w-2/5 md:w-[38%] xl:w-[27%] flex-shrink-0 h-[12rem] sm:h-[14rem] lg:h-[15rem] 2xl:h-[16rem] 3xl:h-[18rem] 4xl:h-[22rem] last-services cursor-pointer"
      key={index}
      onClick={() => {
        if (!isDragged)
          navigate(navigateTo(`${clientUrls.services}/${service.serviceLink}`));
      }}
      onMouseEnter={() => {
        setIsServiceHover(true);
      }}
      onMouseLeave={() => {
        setIsServiceHover(false);
      }}
      // onTouchStart={() => {
      //   setIsServiceHover(true);
      // }}
      // onTouchEnd={() => {
      //   setIsServiceHover(false);
      // }}
      variants={serviceTilesVariant}
    >
      <div
        className={`bg-[#46464680]  cursor-pointer px-4 py-6 4xl:px-8 rounded-[6px] relative group ${
          isServiceHover ? "our-services-slant" : ""
        } h-full backdrop-blur-sm`}
        style={
          {
            // backgroundColor: isServiceHover ? service?.colorCode : "#46464680",
          }
        }
      >
        <motion.div
          className="hidden lg:block absolute left-0 top-0 w-full h-full z-[-1] transition-all rounded-[6px]"
          initial={{ height: "0%", backgroundColor: "#46464680" }}
          animate={{
            height: isServiceHover ? "100%" : "0%",
            backgroundColor: isServiceHover ? service?.colorCode : "#46464680",
          }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
        />
        <div className="hidden lg:block">
          <img
            src={service.serviceIcon}
            alt={service.serviceLink}
            className={`w-16 h-16 sm:w-[72px] sm:h-[72px] lg:w-[88px] lg:h-[88px] 4xl:h-[102px] ${
              getLanguage() === "fr-ca"
                ? ""
                : "sm:mt-2 lg:mt-6 xl:mt-5 2xl:mt-6"
            } `}
          />
        </div>
        <div className="lg:hidden">
          <img
            src={service.serviceIconMobile}
            alt={service.serviceLink}
            className={`w-16 h-16 sm:w-[72px] sm:h-[72px] lg:w-[88px] lg:h-[88px] 4xl:h-[102px] ${
              getLanguage() === "fr-ca"
                ? ""
                : "mt-4 sm:mt-2 lg:mt-6 xl:mt-5 2xl:mt-6 mb-3"
            }`}
          />
        </div>

        <h2 className="uppercase !leading-[1] text-sm xs:text-base sm:text-[18px] lg:text-[24px] 2xl:text-[26px] 4xl:text-[46px]">
          <span className="block">
            {lang === "fr-ca"
              ? service?.serviceName
              : lang === "ko"
              ? (() => {
                  const words = service?.serviceName?.split(" ");
                  return words.map((word, index) => (
                    <React.Fragment key={index}>
                      {index === 0 ? word : ` ${word}`}
                      {index === 0 && <br />}
                    </React.Fragment>
                  ));
                })()
              : service?.serviceName?.split(" ").map((word, index) => (
                  <React.Fragment key={index}>
                    {word}
                    {index < service?.serviceName?.split(" ").length - 1 && (
                      <br />
                    )}
                  </React.Fragment>
                ))}
          </span>
          {/* <span className="block">{service?.serviceName}</span>
          <span className="block"></span> */}
        </h2>
        <div className="lg:hidden cursor-pointer absolute top-[10px] right-[-10px] h-12 w-12 items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#1a1a1a"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke={service?.colorCode}
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
            />
          </svg>
        </div>
        <div
          className={`hidden cursor-pointer absolute top-0 right-0 h-12 w-12 ${
            isServiceHover ? "lg:flex" : ""
          } items-center justify-center`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#1a1a1a"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="#fff"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
            />
          </svg>
        </div>
      </div>
    </motion.div>
  );
};
/// Created this component for individual hover color effect///////

///////// Main OurServices Starts Here ////////////////
const OurServices = ({ servicesData, header1, header2, colors, lang }) => {
  const scrollRef = useRef(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isDragged, setIsDragged] = useState(false);
  const [thumbWidth, setThumbWidth] = useState(20);
  const scrollX = useMotionValue(0);
  const trackAnimation = useAnimation();
  const thumbAnimation = useAnimation();
  const { ref: trackRef, inView: trackInView } = useInView({
    triggerOnce: true,
  });
  const { ref: serviceTileRef, inView: servicesInView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const serviceTileControls = useAnimation();

  useEffect(() => {
    if (servicesInView) {
      serviceTileControls.start("visible");
    }
  }, [servicesInView, serviceTileControls]);

  const servicesContainerVariant = {
    hidden: { y: -100, opacity: 0 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.5,
      },
    },
  };
  const serviceTilesVariant = {
    hidden: { y: -100, opacity: 0 },
    visible: {
      opacity: 1,
      y: 0,
    },
  };
  useEffect(() => {
    const handleResize = () => {
      if (scrollRef.current) {
        const { clientWidth, scrollWidth } = scrollRef.current;
        const newThumbWidth = Math.min(
          (clientWidth / scrollWidth) * clientWidth,
          180
        ); // Ensure thumb width is at least 50px
        setThumbWidth(newThumbWidth);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
        const trackWidth = clientWidth * 0.9; // Track width is 84% of the container
        const maxScrollLeft = scrollWidth - clientWidth;
        const maxThumbLeft = trackWidth - thumbWidth;
        const thumbLeft = (scrollLeft / maxScrollLeft) * maxThumbLeft;
        scrollX.set(thumbLeft);
      }
    };

    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll, {
        passive: true,
      });
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [thumbWidth, scrollX]);

  useEffect(() => {
    // Start the animation on first view
    if (trackInView) {
      trackAnimation.start({
        width: "100%",
        transition: { duration: 1, ease: "easeInOut" },
      });
      thumbAnimation.start({
        width: thumbWidth,
        transition: { duration: 1, ease: "easeInOut" },
      });
    }
  }, [thumbWidth, trackAnimation, thumbAnimation, trackInView]);

  const { ref: ref, inView: isInView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const pullupVariant = {
    initial: { y: 20, opacity: 0 },
    animate: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.3,
      },
    }),
  };

  const handleMouseDown = (e) => {
    setIsDown(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
    setIsDragged(false);
  };

  const handleMouseLeave = () => {
    setIsDown(false);
  };

  const handleMouseUp = () => {
    setIsDown(false);
  };
  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 3; // Adjust this value for smoother drag
    scrollRef.current.scrollLeft = scrollLeft - walk;
    setIsDragged(true);
  };
  return (
    <div className="our-services my-24 sm:mb-20 lg:mb-20 lg:mt-48 xl:mt-56 2xl:mt-48 lg relative">
      {/* <h2 className="font-extrabold lg:text-[60px] md:text-[50px] 2xl:text-[72px] xl:text-[60px] text-[1.25rem] xs:text-[2rem] leading-[1] uppercase overflow-hidden text-primary container mx-auto"> */}
      <motion.h2
        className={`font-extrabold page-main-header-size  leading-[1] uppercase overflow-hidden  container mx-auto`}
        style={{ color: colors.color }}
        ref={ref}
        variants={pullupVariant}
        initial="initial"
        animate={isInView ? "animate" : ""}
        transition={{ duration: 0.3 }}
      >
        <motion.span
          key={1}
          variants={pullupVariant}
          custom={1}
          className="block"
        >
          {header1}
        </motion.span>
        <motion.span
          key={2}
          variants={pullupVariant}
          custom={2}
          className="block"
        >
          {header2}
        </motion.span>
      </motion.h2>
      {/* <h2
        className={`font-extrabold page-main-header-size  leading-[1] uppercase overflow-hidden  container mx-auto`}
        style={{ color: colors.color }}
      >
        <span className="block">{header1}</span>
        <span className="block">{header2}</span>
      </h2> */}

      <div className="text-white body-font ourservices-container ml-auto hide-scroll">
        <div className="py-10 lg:py-12 " ref={serviceTileRef}>
          <motion.div
            ref={scrollRef}
            variants={servicesContainerVariant}
            initial="hidden"
            animate={serviceTileControls}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            className="scroll-container flex flex-nowrap -m-4 text-left align-bottom overflow-x-auto snap-x snap-mandatory cursor-grab active:cursor-grabbing select-none hide-scroll"
          >
            {servicesData?.map((service, index) => (
              <ServiceTile
                service={service}
                lang={lang}
                key={service?.serviceName}
                isDragged={isDragged}
                index={index}
                serviceTilesVariant={serviceTilesVariant}
              />
            ))}

            {/* </div> */}
            {/* </motion.div> */}
          </motion.div>
        </div>
      </div>
      <div className="relative container">
        <motion.div
          className="scroll-track"
          ref={trackRef}
          initial={{ width: 0 }}
          animate={trackAnimation}
        ></motion.div>
        <motion.div
          className="scroll-thumb "
          style={{
            width: thumbWidth,
            x: scrollX,
            backgroundColor: colors?.color,
          }}
          initial={{ width: 0 }}
          animate={thumbAnimation}
        />
      </div>
    </div>
  );
};

export default OurServices;
