import React from "react";
import YoutubeVideo from "../YoutubeVideo";
import { motion } from "framer-motion";

import { useInView } from "react-intersection-observer";

const YoutubeVideoWrapper = ({
  serviceSectionServiceVideo,
  videoId,
  onPlay,
  isPlaying,
  url,
  className,
  isMobile,
}) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const scale = inView ? (!isMobile ? 1.1 : 0.99) : !isMobile ? 0.9 : 0.75;
  return (
    <div
      ref={ref}
      className={`container relative w-full xs-only:!max-w-full xs-only:!w-full h-[16rem] sm:h-[20rem] md:h-[23.5rem] lg:h-[29.2rem] xl:h-[35rem] 1.5xl:h-[37rem] 2xl:h-[39rem] 2.5xl:h-[42rem] 3xl:h-[48rem] 3.5xl:[52rem] 4xl:h-[60rem] mb-24 sm:mb-28 lg:mb-40 homepage-top-youtube ${
        serviceSectionServiceVideo?.includes("vimeo")
          ? "pb-[39%] xl:pb-[47%] 2xl:pb-[50%] 4xl:pb-[56%]"
          : ""
      }`}
    >
      <motion.div
        className="w-full h-full absolute top-0 left-0"
        animate={{
          scale: scale,
        }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 20,
        }}
      >
        <YoutubeVideo
          videoId={videoId}
          onPlay={onPlay}
          isPlaying={isPlaying}
          url={url}
          className={className}
        />
      </motion.div>
    </div>
  );
};

export default YoutubeVideoWrapper;
