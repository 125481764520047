import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getLanguage } from "../constants/GeneralConstants";
import Buttons from "./Buttons";
import { getCookieModalData } from "../services/ApiService";

const CookieModal = ({ onAccept, onReject, onClose }) => {
  const { lang } = useParams();
  const [cookieData, setCookieData] = useState(null);

  useEffect(() => {
    callCookieModalDataAPI(getLanguage());
  }, [lang]);

  const callCookieModalDataAPI = async (language) => {
    try {
      let data = await getCookieModalData(language);
      setCookieData(data?.cookieModalData);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {cookieData?.cookieContent ? (
        <section className="cookies-modal">
          <section className="fixed bottom-[30px] lg:bottom-0 flex items-center justify-center py-6 z-[999] h-[120px] w-full">
            {/* desktop and tablet cookies popup starts here*/}
            <div className="hidden sm:flex flex-wrap items-center justify-between rounded-lg bg-[#1a1a1acc] backdrop-blur-lg px-4 py-8 sm:py-4 lg:py-4  mx-9 sm-only:mx-2">
              <div className="w-full sm:w-[55%] md:w-[56%] lg:w-[52%] flex items-center px-2">
                <img
                  src={cookieData?.cookieIcon}
                  alt="side-ptw"
                  className="me-3 bg-primary p-2 rounded-full mr-4"
                />
                <div className="mb-6 md:mb-0">
                  <p className="font-18-18-18 leading-[1] text-white">
                    {cookieData?.cookieContent}{" "}
                    <a
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        window.open(cookieData?.privacyPolicyPageLink, "_self");
                      }}
                    >
                      {cookieData?.privacyPolicyText}
                    </a>
                  </p>
                </div>
              </div>
              <div className="w-full sm:w-[45%] md:w-[44%] lg:w-[48%] xl:w-[38%] 2xl:w-[39%]">
                <div className="lg:flex items-center lg:space-x-3 sm:justify-end">
                  <button
                    onClick={onAccept}
                    className="relative bg-primary hover:bg-transparent text-[#1a1a1a] hover:text-primary font-18-18-18 font-dela cursor-pointer border-none outline-none px-7 py-3 rounded-md sm:w-[77%] md:w-[70%] lg:w-auto sm:mb-3 lg:mb-0 mx-4 lg:mx-0"
                  >
                    {cookieData?.acceptAllText}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 400 120"
                      className="absolute inset-0 w-full h-full 2xl:hidden"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M10,2 H390 A5,5 0 0 1 395,7 v78 L359,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                        fill="none"
                        stroke="#ff8200"
                        stroke-width="3"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 400 120"
                      className="absolute inset-0 w-full h-full hidden 2xl:block 3xl:hidden"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M10,2 H390 A5,5 0 0 1 395,7 v80 L362,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                        fill="none"
                        stroke="#ff8200"
                        stroke-width="3"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 400 120"
                      className="absolute inset-0 w-full h-full hidden 3xl:block"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M10,2 H390 A5,5 0 0 1 395,7 v80 L370,116 H10 A5,5 0 0 1 5,113 V7 A5,5 0 0 1 10,2 Z"
                        fill="none"
                        stroke="#ff8200"
                        stroke-width="3"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  {/* <button
                    onClick={onAccept}
                    className="relative bg-primary hover:bg-transparent text-[#1a1a1a] hover:text-primary font-18-18-18 font-dela cursor-pointer border-none outline-none px-7 py-2 rounded-md h-[55.2px] sm:w-[77%] md:w-[70%] lg:w-auto sm:mb-3 lg:mb-0 mx-4 lg:mx-0"
                  >
                    {cookieData?.acceptAllText}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 400 50"
                      className="absolute inset-0 w-full h-full"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M10,2 H390 A5,5 0 0 1 395,7 V35 L370,48 H10 A5,5 0 0 1 5,43 V7 A5,5 0 0 1 10,2 Z"
                        fill="none"
                        stroke="#ff8200"
                        stroke-width="2"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button> */}
                  <Buttons
                    className="relative border-primary border-[2px] text-primary hover:text-[#1a1a1a] bottom-right-slant-hover hover:bg-primary font-18-18-18 font-dela cursor-pointer px-7 py-2.5 rounded-md sm:w-[77%] md:w-[70%] lg:w-auto sm:mb-3 lg:mb-0 mx-4 lg:mx-0"
                    buttonText={cookieData?.declineText}
                    onClick={onReject}
                  />
                  <a
                    onClick={onClose}
                    className="absolute right-[7px] top-[15px] lg:static cursor-pointer mx-2"
                  >
                    <img
                      className="me-3"
                      src="/images/cancel.png"
                      alt="Video preview"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>

          {/* desktop and tablet cookies popup ends here*/}
          {/* mobile cookies starts here  */}
          <section className="sm:hidden">
            <div
              id="modal"
              className="footer-main-page fixed inset-0 flex items-center justify-center bg-[#1a1a1a] z-[999] transition-all duration-75"
            >
              <div className="px-4 lg:px-8 py-8 w-[90%] md:w-[50%] lg:w-[40%] 2xl:w-[30%] w-30 pop-bg text-center">
                <img
                  src={cookieData?.cookieIcon}
                  alt="side-ptw"
                  className="p-2 mx-auto w-[108px] sm:w-auto"
                />
                <h2 className="text-[#fff] common-title-size font-bold my-4 leading-[1]">
                  <span className="block">Our</span>
                  <span className="block">Website</span>
                  <span className="block">uses</span>
                  <span className="block">cookies</span>
                </h2>
                <div className="my-4 xs:my-6">
                  <p className="font-18-18-18 leading-[1] text-white">
                    {cookieData?.cookieContent}{" "}
                    <a
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        window.open(cookieData?.privacyPolicyPageLink, "_self");
                      }}
                    >
                      {cookieData?.privacyPolicyText}
                    </a>
                  </p>
                </div>
                <div className="lg:flex items-center sm:justify-end my-4">
                  <Buttons
                    className="block text-center w-full xxs:w-[70%] mx-auto xl:text-lg text-black font-bold bg-primary px-6 py-3 4xl:py-4 4xl:px-8 rounded-md accept-all hover:bg-transparent hover:border-primary border-primary border-[2px] hover:text-primary font-18-18-18 relative accept-btn font-dela sm:mb-3 lg:mb-0 my-4"
                    buttonText={cookieData?.acceptAllText}
                    onClick={onAccept}
                  />
                  <Buttons
                    className="block text-center w-full xxs:w-[70%] mx-auto xl:text-lg text-primary font-bold border-primary border-[2px] px-6 sm:px-10 lg:px-6 py-3 4xl:py-4 4xl:px-8 rounded-md events-view-all hover:bg-primary hover:text-black font-18-18-18 relative font-dela my-4"
                    buttonText={cookieData?.declineText}
                    onClick={onReject}
                  />
                  <button
                    onClick={onClose}
                    className="absolute right-[7px] top-[15px] lg:static cursor-pointer mx-2"
                  >
                    <img
                      className="me-3"
                      src="/images/cancel.png"
                      alt="Video preview"
                    />
                  </button>
                  {/* <a
                    onClick={onClose}
                    className="absolute right-[7px] top-[15px] lg:static cursor-pointer mx-2"
                  >
                    <img
                      className="me-3"
                      src="/images/cancel.png"
                      alt="Video preview"
                    />
                  </a> */}
                </div>
              </div>
            </div>
          </section>
          {/* mobile cookies ends here  */}
        </section>
      ) : null}
    </>
  );
};

export default CookieModal;
