import React, { useEffect } from "react";
import CommonTopBanner from "./CommonTopBanner";
import ParagraphSection from "./ParagraphSection";
import SwiperSlideCommon from "../SwiperSlideCommon";
import WeMakeWaves from "./WeMakeWaves";
import ProjectsShowSection from "../home/ProjectsShowSection";
import NewsEvents from "../home/NewsEvents";
import OurServices from "../home/OurServices";
import Achievements from "./Achievements";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setColor } from "../../store/reducers/ColorReducer";
import { clientUrls } from "../../constants/ClientUrls";
import CommonYoutube from "../CommonYoutube";
import Footer from "../Footer";
import SideSiteSEO from "../seo/SideSiteSEO";

const AudioProduction = ({ data }) => {
  const dispatch = useDispatch();
  const { lang } = useParams();

  useEffect(() => {
    dispatch(
      setColor({
        primary: data?.serviceColors[0]?.colorCode,
        secondary: data?.serviceColors[0]?.secondaroyColor,
        tertiary: data?.serviceColors[0]?.tertiaryColor,
      })
    );
  }, [data, lang]);

  useEffect(() => {
    document.title = data?.servicePageData?.audioProdSEOTitle
      ? data?.servicePageData?.audioProdSEOTitle
      : "Award-Winning Audio Production for Games | Side";
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  const {
    servicePageData,
    awards,
    subServices,
    projects,
    labs,
    services,
    locations,
    type,
  } = data;

  const {
    bannerSection_heading1Text,
    bannerSection_heading2Text,
    fromTheLabText,
    visitTheLabText,
  } = servicePageData;
  return (
    <>
      <SideSiteSEO
        title={
          servicePageData?.audioProdSEOTitle
            ? servicePageData?.audioProdSEOTitle
            : "Award-Winning Audio Production for Games | Side"
        }
        description={
          servicePageData?.audioProdSEODescription
            ? servicePageData?.audioProdSEODescription
            : "Side's multi-award-winning audio services. We offer voice production, sound design, music composition, and more with state-of-the-art facilities globally."
        }
      />
      <section>
        <CommonTopBanner
          servicePageData={servicePageData}
          color={{
            primary: data?.serviceColors[0]?.colorCode,
            tertiary: data?.serviceColors[0]?.tertiaryColor,
          }}
        />
        <div className="mb-28 lg:mb-40">
          <ParagraphSection
            servicePageData={servicePageData}
            color={{
              primary: data?.serviceColors[0]?.colorCode,
              tertiary: data?.serviceColors[0]?.tertiaryColor,
            }}
          />
        </div>
        <div className="relative">
          <div className="absolute -right-[88%] xs:-right-[75%] 1.5xs:-right-[65%] sm:-right-[48%] md:-right-[40%] -top-[3%] sm:top-[10%] md:top-[12%] lg:top-[12%] xl:top-[14%] 2xl:top-[13%] 3xl:top-[16%] lg:-right-[13%] xl:-right-[10%] 2xl:-right-[2%] 3xl:right-0">
            <img
              className="w-[53%] sm:w-1/2 lg:w-[80%] 2xl:w-[95%] 3xl:w-full h-auto"
              src={servicePageData?.serviceTypeBgImage}
              alt="top-bg-1"
            />
          </div>
          {/* youtube video starts here */}
          <CommonYoutube url={servicePageData?.serviceVideo} />
          {/* youtube video ends here */}

          {subServices?.map((service, index) => {
            return (
              <SwiperSlideCommon
                service={service}
                key={index}
                color={{
                  primary: data?.serviceColors[0]?.colorCode,
                  tertiary: data?.serviceColors[0]?.tertiaryColor,
                }}
                showHeading={true}
              />
            );
          })}
        </div>

        <div className="relative pb-20 sm:pb-28 lg:pb-0">
          <div className="hidden sm:block lg:hidden absolute -bottom-[38%] -left-48 h-auto w-full -z-[1] ">
            <img
              className="w-full h-full"
              src={servicePageData?.achivementBgImage}
            />
          </div>
          <WeMakeWaves
            servicePageData={servicePageData}
            locationsData={locations}
          />
        </div>
        <div className="relative lg:pb-80 lg:pt-72">
          <div className="hidden lg:block absolute top-0 h-full w-2/3 -z-[1] ">
            <img
              className="w-full h-full "
              src={servicePageData?.achivementBgImage}
              alt=""
            />
          </div>
          <Achievements
            servicePageData={servicePageData}
            achievements={awards}
            color={data?.serviceColors[0]?.colorCode}
            lang={lang}
          />
        </div>
        <div className="my-24 sm:my-[4rem] lg:mb-[14rem] lg:mt-0">
          <ProjectsShowSection
            homeData={servicePageData}
            projectsData={projects}
            colors={{
              color: data?.serviceColors[0]?.secondaroyColor,
            }}
            // isBtnMiddle={true}
            redirectionFrom={type}
          />
        </div>
        <NewsEvents
          header1={
            bannerSection_heading1Text + " " + bannerSection_heading2Text
          }
          header2={fromTheLabText}
          headerButtonText={visitTheLabText}
          cardsData={labs}
          colors={{
            color: data?.serviceColors[0]?.secondaroyColor,
            tertiaryColor: data?.serviceColors[0]?.tertiaryColor,
          }}
          url={clientUrls.labs}
          toLabs={true}
          type={type}
        />
        <OurServices
          servicesData={services}
          header1={servicePageData?.exploreText}
          header2={servicePageData?.moreText}
          colors={{ color: data?.serviceColors[0]?.colorCode }}
          lang={lang}
        />
      </section>
      <Footer />
    </>
  );
};

export default AudioProduction;
