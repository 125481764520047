import React, { useEffect } from "react";
import CommonTopBanner from "./CommonTopBanner";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setColor } from "../../store/reducers/ColorReducer";
import ParagraphSection from "./ParagraphSection";
import AAACoDev from "./AAACoDev";
import ProjectsShowSection from "../home/ProjectsShowSection";
import NewsEvents from "../home/NewsEvents";
import { clientUrls } from "../../constants/ClientUrls";
import OurServices from "../home/OurServices";
import Footer from "../Footer";
import Achievements from "./Achievements";

const QualityAssurance = ({ data }) => {
  const dispatch = useDispatch();
  const { lang } = useParams();

  useEffect(() => {
    dispatch(
      setColor({
        primary: data?.serviceColors[0]?.colorCode,
        secondary: data?.serviceColors[0]?.secondaroyColor,
        tertiary: data?.serviceColors[0]?.tertiaryColor,
      })
    );
  }, [data, lang]);

  useEffect(() => {
    document.title = data?.servicePageData?.qaSEOTitle
      ? data?.servicePageData?.qaSEOTitle
      : "High Quality Game QA Services | PC & Mobile Game Testing | Side";
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  const {
    servicePageData,
    subServices,
    projects,
    labs,
    services,
    serviceProvided,
    virtualReality,
    achievementAwards,
    type,
  } = data || {};
  return (
    <>
      <section>
        <CommonTopBanner
          servicePageData={servicePageData}
          color={{
            primary: data?.serviceColors[0]?.colorCode,
            tertiary: data?.serviceColors[0]?.tertiaryColor,
          }}
        />
        <div className="-mb-4 sm:mb-28 lg:mb-40">
          <ParagraphSection
            servicePageData={servicePageData}
            color={{
              primary: data?.serviceColors[0]?.colorCode,
              tertiary: data?.serviceColors[0]?.tertiaryColor,
            }}
          />
        </div>
        <div className=" sm:py-5 relative">
          <div className="absolute top-[5rem] sm:top-0 xl-only:top-[-2rem] right-0 z-[-1] sm:h-full w-[75%] sm:w-[54%] lg:w-[65%] xl-only:w-[60%]  ml-auto">
            <img
              src={servicePageData?.serviceTypeBgImage}
              alt="ptw-side"
              className="max-h-full ml-auto"
            />
          </div>
          {/* <div className="absolute right-0 top-[11rem] sm:top-0 lg:top-[-2rem] w-full z-[-1]">
            <img
              src={servicePageData?.serviceTypeBgImage}
              alt="ptw-side"
              className="w-[65%] sm:w-[55%] lg:w-[66%] float-right"
            />
          </div> */}
          <AAACoDev
            gameServices={subServices}
            color={{
              primary: data?.serviceColors[0]?.colorCode,
              tertiary: data?.serviceColors[0]?.tertiaryColor,
            }}
            isQualityAssurance={true}
          />
        </div>
        {/* support provided section start */}
        <section className="container mt-12 sm:mt-28 lg:mt-44  lg:flex gap-16 2xl:gap-12 4xl:gap-24 justify-between">
          <h2 className="uppercase text-[32px] sm:text-4xl lg:text-[39px] xl:text-5xl 2xl:text-[57.6px] 3xl:text-[72px] 4xl:text-[86px] text-white leading-[1] sm:w-[75%] lg:w-2/5 2xl:w-[45%]">
            {servicePageData?.providingSupportHeadingText}
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-8 lg:gap-6 4xl:gap-8 mt-6 sm:mt-8 lg:mt-0 w-[95%] 1.5xs:w-[90%] lg:w-3/5 2xl:w-[55%]">
            {serviceProvided?.map((serviceLogo, i) => {
              return (
                <div
                  key={serviceLogo?.meta_value}
                  className="w-full h-auto  border-[0.16px] border-[#ffffff20] flex justify-center items-center py-8 1.5xs:py-12 sm:py-8 1.5md:py-10 lg:py-8 1.5xl:py-10 3xl:py-12 3.5xl:py-16 4xl:py-20"
                >
                  <img
                    src={serviceLogo?.providingSupportIcons}
                    alt="client logo"
                    className="h-[3rem]"
                  />
                </div>
              );
            })}
          </div>
        </section>
        {/* support provided section end */}
        {/* VR Devices start */}
        <section className="container mt-12 sm:mt-20 lg:mt-28 2xl:mt-40 lg:flex gap-16 2xl:gap-12 4xl:gap-24 justify-between">
          <h2 className="uppercase text-[32px] sm:text-4xl lg:text-[39px] xl:text-5xl 2xl:text-[57.6px] 3xl:text-[72px] 4xl:text-[86px] text-white leading-[1] sm:w-[75%] lg:w-2/5 2xl:w-[45%]">
            {servicePageData?.majorDevicesHeadingText}
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-8 lg:gap-6 4xl:gap-8 mt-6 sm:mt-8 lg:mt-0 w-[95%] 1.5xs:w-[90%] lg:w-3/5 2xl:w-[55%] ">
            {virtualReality?.map((vrLogo, i) => {
              return (
                <div
                  key={vrLogo?.meta_value}
                  className="w-full h-auto  border-[0.16px] border-[#ffffff20] flex justify-center items-center px-2  py-6 xs:py-8  1.5xs:py-12 sm:py-6 md:py-8 sm:px-0  1.5md:py-10 lg:py-8 1.5xl:py-10 3xl:py-12 3.5xl:py-16 4xl:py-20"
                >
                  <img
                    src={vrLogo?.majorVRIcons}
                    alt="vr logo"
                    className="px-3 lg:px-[1.5rem] h-[2rem]"
                  />
                </div>
              );
            })}
          </div>
        </section>
        {/* VR Devices end */}
        <div className="mt-16 sm:mt-24 lg:mt-36 2xl:mt-40">
          <Achievements
            servicePageData={servicePageData}
            achievements={achievementAwards}
            color={data?.serviceColors[0]?.colorCode}
            lang={lang}
          />
        </div>
        <div className="relative">
          <div className="hidden lg:block absolute left-[-27rem] z-[-1] top-[2rem]">
            <img
              src={servicePageData?.exploreMoreBgImage}
              alt="side-ptw"
              className="w-[60%]"
            />
          </div>
          <section className="my-16 sm:my-24 lg:my-48">
            <ProjectsShowSection
              homeData={servicePageData}
              projectsData={projects}
              colors={{
                color: data?.serviceColors[0]?.secondaroyColor,
              }}
              isBtnBottom={true}
              redirectionFrom={type}
              lang={lang}
            />
          </section>
          <NewsEvents
            header1={
              servicePageData?.bannerSection_heading1Text +
              " " +
              servicePageData?.bannerSection_heading2Text
            }
            header2={servicePageData?.fromTheLabText}
            headerButtonText={servicePageData?.visitTheLabText}
            cardsData={labs}
            colors={{
              color: data?.serviceColors[0]?.secondaroyColor,
              tertiaryColor: data?.serviceColors[0]?.tertiaryColor,
            }}
            url={clientUrls.labs}
            toLabs={true}
            type={type}
          />
        </div>

        <section
          className="mt-12 lg:mt-44 relative"
          // style={{
          //   backgroundImage:
          //     window.innerWidth > 1023
          //       ? `url(${servicePageData?.exploreMoreBgImage})`
          //       : "",
          // }}
        >
          <OurServices
            servicesData={services}
            header1={servicePageData?.exploreText}
            header2={servicePageData?.moreText}
            colors={{ color: data?.serviceColors[0]?.colorCode }}
            lang={lang}
          />
        </section>
      </section>
      <Footer />
    </>
  );
};

export default QualityAssurance;
