import {
  getDateFormatFromEpoch,
  navigateTo,
  truncate,
} from "../../constants/GeneralConstants";
import parse from "html-react-parser";
import PIL from "../PIL";
import { clientUrls } from "../../constants/ClientUrls";
import { useNavigate } from "react-router-dom";
import Buttons from "../Buttons";
import ButtonSlant from "../ButtonSlant";

const HighlightedArticle = ({
  news,
  newsStaticPageData,
  fromEvents = false,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="relative group news-hover-slant-parent"
      // onClick={(e) =>
      //   navigate(navigateTo(`${clientUrls.news}/${news.newsURL}`))
      // }
    >
      <section className="bg-[#464646] z-[99] overflow-hidden sm:py-[3rem] relative">
        <img
          className=" absolute h-[60%] top-[-5%] right-[-22%] sm:h-[100%] sm:top-[5%] sm:right-[-26.5%] lg:h-[130%] lg:right-[-10%] lg:top-[12%] opacity-[20%] z-[-1]"
          src="/images/news_slider_d.png"
          alt=""
        />
        <div className="my-12 container  ">
          <div className="grid gird-cols-1 sm:grid-cols-2 lg:flex  gap-x-12 gap-y-4 news-hover-slant">
            {/* <div className="img-partk flex items-center h-full  relative lg:w-[80%] xl:w-[45%] 3xl:w-[50%]"> */}
            <div className="img-partk relative cursor-pointer lg:w-[80%] xl:w-[45%] 3xl:w-[50%]">
              <div className="hidden cursor-pointer absolute top-0 right-0 bg-[#1a1a1a] h-12 w-12 4xl:h-20 4xl:w-20 group-hover:flex items-center justify-center z-[3] rounded-bl-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#ff8200"
                  viewBox="0 0 24 24"
                  stroke-width="3"
                  stroke="#ff8200"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                  ></path>
                </svg>
              </div>
              <div
                className="relative cursor-pointer"
                onClick={(e) =>
                  navigate(navigateTo(`${clientUrls.news}/${news.newsURL}`))
                }
              >
                {/* desktop img  */}
                <PIL
                  lowQaulitySrc={news.newsImageDesktop}
                  highQualitySrc={news.newsImageDesktop}
                  className="object-fill h-auto w-full rounded-md hidden sm:block"
                  alt={news.title}
                />
              </div>
              {/* mobile img  */}
              <div
                className="relative cursor-pointer"
                onClick={(e) =>
                  navigate(navigateTo(`${clientUrls.news}/${news.newsURL}`))
                }
              >
                <PIL
                  lowQaulitySrc={news.newsImageMob}
                  highQualitySrc={news.newsImageMob}
                  className="object-fill h-auto w-full rounded-md sm:hidden"
                  alt={news.title}
                />
              </div>
              {/* </div> */}
            </div>
            <div className="mt-6 flex flex-col justify-between">
              <div className="flex gap-4 text-[14px] 1.5xl:text-[16px] 2xl:text-[18.6px] 3xl:text-[24px] 4xl:text-[28px]">
                {fromEvents ? (
                  <>
                    <p className="text-[#FF8200]">{news?.eventDateRange}</p>
                    {/* <span className="text-[#FF8200]">-</span>
                    <p className="text-[#FF8200]">{news?.eventEndDate}</p> */}
                  </>
                ) : (
                  <p className="text-[#FF8200]">{news?.publishedDate}</p>
                )}
                {/* <p className="text-[#efefef] uppercase">Press releases</p> */}
              </div>
              <p className="text-[#fff] text-[18px] sm:text-[24px] lg:text-[36px] 1.5xl:text-[40px] 2xl:text-[44px] 2.5xl:text-[46px] 3xl:text-[49px]  3.5xl:text-[50px] 4xl:text-[54px] my-4 leading-[1] font-bold lg:w-[70%] line-clamp-2">
                {news.title}
              </p>

              <p className="text-[#fff] text-[16px] 1.5xl:text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32px] line-clamp-2 lg:w-[70%]">
                {news.newsShortDescription}
              </p>

              <ButtonSlant
                buttonText={newsStaticPageData?.readMoreBtn}
                onClick={(e) =>
                  navigate(navigateTo(`${clientUrls.news}/${news.newsURL}`))
                }
                divClass="w-fit"
                buttonClass="w-full sm:w-fit flex justify-center items-center sm:justify-between relative overflow-hidden px-7 py-2 rounded-md border-primary border-[2px] text-primary   font-18-18-18 font-dela cursor-pointer"
                textColorHover="#1a1a1a"
                bgColorHover="#ff8200"
                slantClass="bottom-right-slant"
              />
            </div>
          </div>
        </div>
      </section>

      <img
        className="hidden sm:block lg:hidden absolute top-[74%] right-[1%] w-[45%]"
        src="/images/article-zigzag.svg"
        alt=""
      ></img>
    </div>
  );
};

export default HighlightedArticle;
