import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../css/componentscss/SwiperSlide.css";
import { ArrowLeft, ArrowRight, SlantImg } from "./CommonComponents";

const Testimonials = ({
  testimonialLeftQuotations,
  testimonialRightQuotations,
  testimonials,
  bgcolor,
  textColor,
}) => {
  const [index, setIndex] = useState(0);
  const swiperRef = useRef(null);
  const [isHoverPrev, setIsHoverPrev] = useState(false);
  const [isHoverNext, setIsHoverNext] = useState(false);

  return (
    <>
      {testimonials?.length > 0 ? (
        <section className="mb-20 sm:mb-0">
          <div className="container their-wordsk relative">
            <div className="absolute top-[-1px] left-[-1px] z-[1] rotate-180">
              <SlantImg className="h-[60px] sm:h-[70px]" />
            </div>
            <div className="qutoes-img absolute z-[1] top-[-1.7rem] sm:top-[-3.7rem] lg:top-[-1.7rem] 3xl:top-[-0.7rem] 4xl:top-[-0.4rem] left-[2.7rem]">
              <img src={testimonialLeftQuotations} alt="ptw-art" />
            </div>
            <div className="qutoes-img absolute z-[1] bottom-[1rem] right-[2rem]">
              <img src={testimonialRightQuotations} alt="ptw-art" />
            </div>
            <div
              className="md:blue-div my-10 sm:my-0 we-believe-clipk flex flex-col md:flex-row justify-between items-center relative rounded-md"
              style={{ backgroundColor: bgcolor }}
            >
              <div className="w-full h-[65%] sm:h-auto px-8 md:px-16 py-10 sm-only:pb-24 md-only:pb-24 md:py-12 lg:py-12 2xl:py-22 ">
                <Swiper
                  ref={swiperRef}
                  onSlideChange={(swiper) => {
                    setIndex(swiper.activeIndex);
                  }}
                  speed={1200}
                  effect="fade"
                >
                  {testimonials?.map((testimonial) => {
                    return (
                      <SwiperSlide>
                        <div className="min-h-64 ">
                          <h2
                            className="leading-[1] sm-only:!text-[24px] md-only:!text-[24px] text-[32px] lg:text-[36px] 2xl:text-[43px] 3xl:text-[60px] 4xl:text-[74px] font-bold uppercase"
                            style={{ color: textColor }}
                          >
                            <span className="block">
                              {testimonial.testimonialTitle}
                            </span>
                          </h2>
                          <p
                            className="content py-4 leading-[120%] text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] font-medium"
                            style={{ color: textColor }}
                          >
                            {testimonial.testimonialDescription}
                          </p>
                          <p
                            className="content pt-0 pb-4 leading-[120%] text-[20px] 2xl:text-[23.6px] 3xl:text-[29px] 4xl:text-[34.4px] font-medium italic"
                            style={{ color: textColor }}
                          >
                            {testimonial?.testimonialAuthor}
                          </p>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <div className="flex gap-4">
                  <div
                    onMouseEnter={() => setIsHoverPrev(index !== 0)}
                    onMouseLeave={() => setIsHoverPrev(false)}
                    onTouchStart={() => setIsHoverPrev(index !== 0)}
                    onTouchEnd={() =>
                      setTimeout(() => setIsHoverPrev(false), 300)
                    }
                    onClick={() => {
                      // setIsHoverPrev(true);
                      setTimeout(() => setIsHoverPrev(false), 500);
                    }}
                  >
                    <button
                      onClick={() => {
                        if (swiperRef.current) {
                          swiperRef.current.swiper.slidePrev();
                        }
                      }}
                      disabled={index === 0}
                      className={`border-[2px] rounded-[4px] p-2 ${
                        isHoverPrev ? "arrow-btn-top-left" : ""
                      } group ${
                        index === 0
                          ? "opacity-30 pointer-events-none"
                          : "opacity-100"
                      }`}
                      style={{
                        borderColor: textColor,
                        backgroundColor: isHoverPrev ? textColor : "",
                      }}
                    >
                      <ArrowLeft
                        color={textColor}
                        hover={isHoverPrev}
                        hoverColor={bgcolor}
                      />
                    </button>
                  </div>
                  <div
                    onMouseEnter={() =>
                      setIsHoverNext(index !== testimonials.length - 1)
                    }
                    onMouseLeave={() => setIsHoverNext(false)}
                    onTouchStart={() =>
                      setIsHoverNext(index !== testimonials.length - 1)
                    }
                    onTouchEnd={() =>
                      setTimeout(() => setIsHoverNext(false), 300)
                    }
                    onClick={() => {
                      // setIsHoverNext(true);
                      setTimeout(() => setIsHoverNext(false), 500);
                    }}
                  >
                    <button
                      onClick={() => {
                        if (swiperRef.current) {
                          swiperRef.current.swiper.slideNext();
                        }
                      }}
                      disabled={index === testimonials.length - 1}
                      className={`border-[2px] rounded-[4px] p-2 group ${
                        index === testimonials.length - 1
                          ? "opacity-30 pointer-events-none"
                          : "opacity-100"
                      } ${isHoverNext ? "arrow-btn-bottom-right" : ""}`}
                      style={{
                        borderColor: textColor,
                        backgroundColor: isHoverNext ? textColor : "",
                      }}
                    >
                      <ArrowRight
                        color={textColor}
                        hover={isHoverNext}
                        hoverColor={bgcolor}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}{" "}
    </>
  );
};

export default Testimonials;
