import React, { useState, useEffect } from "react";
import FormatText from "../FormatText";
import { Link, useNavigate } from "react-router-dom";
import ButtonSlant from "../ButtonSlant";
import { SlantImg } from "../CommonComponents";

const AAACoDev = ({
  gameServices,
  color,
  isQualityAssurance,
  isDatasets,
  isArtProduction,
}) => {
  const [activeDev, setActiveDev] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const services = [
    ...new Set(gameServices?.map((service) => service?.serviceName)),
  ];

  useEffect(() => {
    if (gameServices?.length > 0) {
      if (!services?.includes(activeDev)) {
        setActiveDev(services[0]);
      }
    }
  }, [gameServices, services]);
  const activeSubServices = gameServices?.find(
    (service) => service?.serviceName === activeDev
  );
  return (
    <section
      className={`container ${
        isQualityAssurance ? "mt-16" : "mt-32"
      } sm:flex sm:gap-8 lg:gap-16 2xl:gap-24`}
    >
      <div className="sm:hidden relative mb-6">
        <button
          className="w-full px-5 py-3 text-[18px] font-dela flex justify-between border-[3px] rounded-md mb-2"
          style={{ color: color.primary, borderColor: color.primary }}
          onClick={() => {
            setIsDropdownOpen(!isDropdownOpen);
          }}
        >
          <span>{activeDev}</span>
          <span>
            {isDropdownOpen ? (
              <svg
                width="22"
                height="22"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2 platforms"
              >
                <path
                  d="M8.625 6.25L12.375 2.5L16.125 6.25"
                  stroke={color.primary}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M4.875 17.5C6.86412 17.5 8.77178 16.7098 10.1783 15.3033C11.5848 13.8968 12.375 11.9891 12.375 10V2.5"
                  stroke={color.primary}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            ) : (
              <svg
                width="22"
                height="22"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2"
              >
                <path
                  d="M8.125 13.75L11.875 17.5L15.625 13.75"
                  stroke={color.primary}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                  stroke={color.primary}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            )}
          </span>
        </button>
        {isDropdownOpen && (
          <div
            className="w-full flex flex-col gap-4 text-[18px] absolute z-[2] bg-[#1a1a1a] border-[3px] rounded-md px-5 py-3"
            style={{ color: color.primary, borderColor: color.primary }}
          >
            {services?.map((service, i) => (
              <h4
                key={i}
                onClick={(e) => {
                  setActiveDev(e.target.textContent);
                  setIsDropdownOpen(false);
                }}
              >
                {service}
              </h4>
            ))}
          </div>
        )}
      </div>

      <div
        className={`hidden sm:flex flex-col ${
          isDatasets ? "sm:w-[45%] lg:w-[34%]" : "sm:w-[45%] lg:w-[34%] "
        } gap-y-8`}
      >
        {services?.map((service, i) => (
          <ButtonSlant
            key={i}
            buttonClass={` border-[3px] rounded-md text-[18px] 2xl:text-xl break-words 3xl:text-2xl px-4 py-2 2xl:py-3 lg:pl-4 lg:text-left w-full font-dela text-wrap ${
              activeDev === service ? "bottom-right-slant" : ""
            } sm:min-h-12   2xl:min-h-[3.5rem] 3xl:min-h-[4.1rem]`}
            slantClass="bottom-right-slant"
            onClick={() => {
              setActiveDev(service);
            }}
            textColor={activeDev === service ? color?.tertiary : color?.primary}
            textColorHover={color.tertiary}
            bgColor={activeDev === service ? color.primary : "transparent"}
            bgColorHover={color?.primary}
            borderColor={color.primary}
            buttonText={service}
          />
        ))}
      </div>

      <div
        className={`px-8 2xl:px-11 3xl:px-14 relative ${
          isQualityAssurance ? "py-8" : "py-16"
        } 
        // min-h-[18rem]k lg:min-h-[32rem]k xl:min-h-[38rem]k 2xl:min-h-[42rem]k
            sm:w-[55%] lg:w-[66%] rounded-md codev-top-left-slantk  `}
        style={{ backgroundColor: color.primary, color: color.tertiary }}
      >
        <div className="absolute top-[-1px] left-[-1px] z-[1] rotate-180">
          <SlantImg className="h-[60px] sm:h-[70px]" />
        </div>
        {isQualityAssurance ? (
          activeSubServices &&
          activeSubServices?.subServices?.map((subService, i) => {
            return (
              <div key={subService?.subServiceButtonText} className="lg:p-6">
                <p className="common-para-size font-semibold leading-[133.3%] mb-4 sm:mb-8 lg:mb-0 lg:py-5 square-bullets">
                  <FormatText text={subService?.subServiceDescription} />
                </p>
                {subService?.subServiceButtonLink && (
                  <Link to={subService?.subServiceButtonLink}>
                    <ButtonSlant
                      buttonClass="uppercase block common-button-size px-6 py-2 rounded-md font-dela border-[2px] hover:!text-white"
                      slantClass="bottom-right-slant"
                      divClass="w-fit"
                      textColor={color.tertiary}
                      textColorHover="#ffffff"
                      borderColor={color.tertiary}
                      buttonText={subService?.subServiceButtonText}
                      bgColorHover={color.tertiary}
                    />
                  </Link>
                )}
              </div>
            );
          })
        ) : (
          <div
            className={`${
              isArtProduction
              // ? "min-h-[42rem] sm:min-h-[48rem] lg:min-h-[35rem] xl:min-h-[40rem] 2xl:min-h-[45rem]"
              // : "min-h-[35rem] sm:min-h-[40rem]k lg:min-h-[30rem]k xl:min-h-[28rem]k 2xl:min-h-[45rem]k"
            }`}
          >
            {activeSubServices &&
              activeSubServices?.subServices[0]?.subServiceHeadingText && (
                <h2 className="text-2xl 2xl:text-[26.4px] 3xl:text-[31.2px] leading-[1] mb-8 text-wrap break-words">
                  {activeSubServices?.subServices[0]?.subServiceHeadingText}
                </h2>
              )}
            <ul>
              {activeSubServices?.subServices.map((subService, i) => (
                <li
                  key={i}
                  className={`${
                    activeSubServices?.subServices?.length - 1 === i
                      ? ""
                      : "border-b-[1px] "
                  } font-semibold pl-4 pb-4 mb-4 text-[18px] 2xl:text-xl 3xl:text-2xl leading-[120%] text-wrap break-words`}
                  style={{ borderColor: color.tertiary }}
                >
                  {subService?.subServiceButtonLink ? (
                    <Link
                      className="underline"
                      target={(() => {
                        const link = subService.subServiceButtonLink.startsWith(
                          "http"
                        )
                          ? subService.subServiceButtonLink
                          : `https://${subService.subServiceButtonLink}`;

                        const normalizeHostname = (hostname) =>
                          hostname.replace(/^www\./, "");
                        const parsedURL = new URL(link);
                        const hostname = normalizeHostname(parsedURL.hostname);
                        const pathname = parsedURL.pathname;

                        const isExternal =
                          hostname !==
                          normalizeHostname(window.location.hostname);
                        const hasArtInPath = pathname.includes("/art/");

                        return isExternal || hasArtInPath ? "_blank" : "_self";
                      })()}
                      to={subService.subServiceButtonLink}
                    >
                      <FormatText text={subService.subServiceName || "N/A"} />
                    </Link>
                  ) : (
                    <FormatText text={subService.subServiceName || "N/A"} />
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default AAACoDev;
