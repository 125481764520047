import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const LeftScatterAnimation = ({ images }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { ref: inViewRef, inView: deskView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const { ref: inViewRefMobile, inView: mobileView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const handleClickOutside = (event) => {
    if (
      document.getElementById("images-container2") &&
      !document.getElementById("images-container2").contains(event.target)
    ) {
      setHoveredIndex(null);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);
  const stackedPosition = [
    { x: -20, y: 0, rotate: 0 },
    { x: -10, y: 0, rotate: 0 },

    { x: 0, y: 0, rotate: 0 },
  ];
  const scatteredPositions = [
    { x: -200, y: 0, rotate: -5 },
    { x: -60, y: 0, rotate: 5 },
    { x: 100, y: 0, rotate: 10 },
  ];
  const stackedPositionMobile = [
    { x: 0, y: 0, rotate: 0 },
    { x: 0, y: -320, rotate: 0 },

    { x: 0, y: -640, rotate: 0 },
  ];
  const scatteredPositionsMobile = [
    { x: 0, y: 0, rotate: 5 },
    { x: 0, y: -120, rotate: -5 },
    { x: 0, y: -260, rotate: 5 },
  ];
  return (
    <div id="images-container2">
      <div
        className="hidden sm:flex justify-center items-center sm:min-h-[28rem] lg:min-h-96 lg:justify-end   relative "
        ref={inViewRef}
      >
        {images?.[0] &&
          images?.map((src, index) => (
            <motion.img
              key={index}
              src={src}
              alt={`company-${index}`}
              initial={stackedPosition[index]}
              animate={
                deskView ? scatteredPositions[index] : stackedPosition[index]
              }
              transition={{
                duration: 0.5,
                delay: 0.6,
                stiffness: 100,
              }}
              style={{ zIndex: hoveredIndex === index ? 6 : index + 3 }}
              onClick={() => {
                setHoveredIndex(index);
              }}
              // onMouseLeave={() => {
              //   setTimeout(() => {
              //     setHoveredIndex(null);
              //   }, 200);
              // }}
              className="absolute object-contain rounded-sm sm:h-auto w-1/3 sm:w-1/2 cursor-pointer"
            />
          ))}
      </div>
      <div
        ref={inViewRefMobile}
        className={`relative flex sm:hidden flex-col  items-center justify-center -mb-56`}
      >
        {images?.map((src, index) => (
          <motion.img
            key={index}
            src={src}
            alt={`company-${index}`}
            initial={stackedPositionMobile[index]}
            animate={
              mobileView
                ? scatteredPositionsMobile[index]
                : stackedPositionMobile[index]
            }
            transition={{
              duration: 0.5,
              delay: 0.6,
              stiffness: 100,
            }}
            style={{ zIndex: hoveredIndex === index ? 3 : index }}
            className="object-contain rounded-sm w-[90%] xxs:w-[90%] lg:w-[98%] cursor-pointer"
            onClick={() => {
              setHoveredIndex(index);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default LeftScatterAnimation;
