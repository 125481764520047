import { useNavigate } from "react-router-dom";
import PIL from "../../components/PIL";
import { navigateTo } from "../../constants/GeneralConstants";
import { useState } from "react";

const ProjectCard = ({ project }) => {
  const navigate = useNavigate();
  const [clientName, setClientName] = useState(project.clientName);
  return (
    <>
      <div
        onClick={(e) => navigate(navigateTo(`/projects/${project.projectURL}`))}
        className="h-full rounded-[10px] group projects-slant relative"
        onMouseEnter={(e) =>
          setClientName(
            project.publisher ? project.publisher : "Publisher Name"
          )
        }
        onMouseLeave={(e) => setClientName(project.clientName)}
      >
        <div className="w-full h-full  relative">
          <div className="relative aspect-[2/1] cursor-pointer">
            <PIL
              lowQaulitySrc={project.projectImageLowQuality}
              highQualitySrc={project.projectImage}
              className=" sm:h-full w-full object-cover rounded-t-md projects-slant-child"
              alt={project.title}
            />
          </div>
          <div className="absolute top-0 right-0 h-12 w-12 z-[2] hidden group-hover:flex justify-center items-center rounded-bl-md bg-[#1A1A1A]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="55"
              viewBox="0 0 24 24"
              strokeWidth="3"
              stroke="#68FFD2"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
              />
            </svg>
          </div>
          <div className="bg-[#46464680] group-hover:bg-[#68FFD2] rounded-b-md backdrop-blur-sm p-6 pb-2 cursor-pointer">
            <div className="flex justify-between items-center">
              <div className="text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] text-white group-hover:text-[#1A1A1A] uppercase leading-[1] h-[3rem]k line-clamp-2 mr-3">
                <h3 className="line-clamp-1">{project.title}</h3>
              </div>
              <p className="hidden lg:block text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] text-[#dcdcdc] group-hover:text-[#1A1A1A]">
                {project.releaseYears}
              </p>
            </div>
            <div className="flex items-center justify-between gap-4 mt-4 sm:h-[2.25rem] lg:h-auto">
              <p className="lg:hidden text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] text-[#dcdcdc] group-hover:text-[#1A1A1A]">
                {project.releaseYears}
              </p>
              <p className="text-[18px] 2xl:text-[24px] 3xl:text-[27px] 4xl:text-[32px] lg:mb-2.5 text-[#dcdcdc] group-hover:text-[#1A1A1A] leading-[1] text-right">
                {clientName}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectCard;
