import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { setColor } from "../../store/reducers/ColorReducer";
import NewsEvents from "../home/NewsEvents";
import { clientUrls } from "../../constants/ClientUrls";
import OurServices from "../home/OurServices";
import Footer from "../Footer";
import {
  FormatTextFromGeneralConstants,
  getLanguage,
  navigateTo,
} from "../../constants/GeneralConstants";
import { getServicesPageData } from "../../services/ApiService";
import Loader from "../Loader";
import SwiperSlideCommon from "../SwiperSlideCommon";
import ButtonSlant from "../ButtonSlant";
import { SlantImg } from "../CommonComponents";

const PenTesting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { lang } = useParams();

  const [loader, setLoader] = useState(true);
  const [penTestingServicePageData, setPenTestingServicePageData] = useState(
    []
  );
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  useEffect(() => {
    callPentestingPageAPI(getLanguage());
  }, [lang]);

  const callPentestingPageAPI = async (language) => {
    setLoader(true);
    try {
      let data = await getServicesPageData(language, {
        type: "pen-testing",
      });
      document.title = data?.penTestingPageData?.penetrationTestingSEOTitle;
      setPenTestingServicePageData(data);
      dispatch(
        setColor({
          primary: data?.serviceColors[0]?.colorCode,
          secondary: data?.serviceColors[0]?.secondaroyColor,
          tertiary: data?.serviceColors[0]?.tertiaryColor,
        })
      );
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const {
    benifits,
    labs,
    saftey,
    covered,
    penTestingPageData,
    services,
    serviceColors,
  } = penTestingServicePageData;

  if (loader) {
    return <Loader />;
  }
  console.log("data-----", penTestingPageData);
  return (
    <>
      <section>
        {/* new design starts here  */}
        {/* banner starts here  */}
        <section
          className="container h-[19rem] lg:h-[23rem] xl:h-[25rem] 1.5xl:h-[29rem] 2xl:h-[29rem] 2.5xl:h-[35rem] 3xl:h-[38rem] 4xl:h-[45rem]  mt-24 sm:mt-28 4xl:mt-36  mb-16 sm:mb-20 
           lg:mb-20 4xl:mb-32 rounded-lg flex items-center gap-8 3xl:gap-16 relative top-banner-top-left-slantk bg-primary"
          // style={{ color: color.tertiary, backgroundColor: color.primary }}
        >
          <div className="absolute top-[-1px] left-[-1px] z-[10] rotate-180">
            <SlantImg className="h-[60px] sm:h-[75px] lg:h-[80px]" />
          </div>
          <div className="pt-12 pl-4 sm:pl-12 lg:pl-16 xl:pl-20 3xl:pl-24 4xl:pl-32 xs-only:pr-4 w-full sm:w-[60%] md:w-[67%] lg:w-[65%]">
            <h2 className="text-[28px] xs:text-3xl sm:text-[31px] md:text-4xl lg:text-[3rem] xl:text-[60px] 2xl:text-[64px] 3xl:text-[78px] 4xl:text-[98px] !leading-[120%] sm:!leading-[100%] mb-3 lg:mb-10 xl:mb-16 uppercase">
              <span className="block">
                {penTestingPageData?.bannerHeading1Text}
              </span>
              <span className="block">
                {penTestingPageData?.bannerHeading2Text}
              </span>
            </h2>
          </div>
          <div className="hidden sm:block w-[30%] sm:w-[40%] lg:w-[35%] h-full relative">
            <img
              src={penTestingPageData?.penetrationTestingBgImage}
              // src="/images/react-banner-d.png"
              // alt={`${param}-banner`}
              alt=""
              className="absolute w-full top-0 right-0 h-[19rem] lg:h-full"
            />
          </div>
        </section>
        {/* banner ends here  */}
        {/* paragraph starts here  */}
        <section className="mb-20 sm:mb-28 lg:mb-40">
          <div className="container text-white lg:pl-12 3xl:pl-16">
            {/* deliver para  */}
            <section className="w-full sm:w-[98%] lg:w-[75%] mb-16 sm:mb-20 lg:mb-20 4xl:mb-32 ">
              <h2 className="uppercase text-[32px] lg:text-[38.5px] xl:text-[48px] 2xl:text-[53px] 3xl:text-[68px] 4xl:text-[82px] leading-[1] text-white w-auto mb-4 sm:mb-6">
                {penTestingPageData?.certifiedPenetrationTestingText}{" "}
                {penTestingPageData?.andExploitationTestingText}
              </h2>
              <p className="mb-4 sm:mb-6 font-18-18-18 leading-[120%] sm:font-medium">
                {FormatTextFromGeneralConstants(
                  penTestingPageData?.penetrationTestingExploitationTestingParagraph
                )}
              </p>

              <div className="w-full sm:w-fit cursor-pointer mt-10 sm:mt-0">
                <ButtonSlant
                  buttonClass="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] px-5 py-2.5 4xl:py-3 4xl:px-4 border-[3px] font-dela rounded-md w-full sm:w-fit border-primary text-primary"
                  buttonText={penTestingPageData?.getInTouchText}
                  divClass="w-fit"
                  textColorHover="#1a1a1a"
                  bgColorHover="#ff8200"
                  onClick={(e) => {
                    navigate(navigateTo(clientUrls.contact));
                  }}
                  slantClass="bottom-right-slant"
                />
              </div>
            </section>
          </div>
        </section>
        {/* paragraph ends here  */}
        <div className="relative">
          <div className="absolute right-[-3rem] sm:right-0 top-[-3.5rem] sm:top-[-12rem] xl:top-[-10rem] z-[-1] sm-only:right-[-16rem] md-only:right-[-13rem]">
            <img
              src={penTestingPageData?.serviceTypeBgImage}
              alt="ptw-side"
              className="w-[90%] sm-only:w-[65%] md-only:w-[60%] lg:w-[45%] float-right"
            />
          </div>
          {benifits?.map((service, index) => {
            return (
              <SwiperSlideCommon
                service={service}
                key={index}
                color={{
                  primary: serviceColors[0]?.colorCode,
                  tertiary: serviceColors[0]?.tertiaryColor,
                }}
                showHeading={true}
              />
            );
          })}
        </div>
        <div className="container flex  lg:pl-12 3xl:pl-16">
          <Link to={penTestingPageData?.learnMoreURL}>
            <ButtonSlant
              buttonClass="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] px-2 py-2.5 3xl:py-3 4xl:py-4 4xl:px-4 border-[3px] font-dela rounded-md w-full sm:w-52 2xl:w-64 3xl:w-72 4xl:w-96 border-primary text-primary"
              buttonText={penTestingPageData?.learnMoreText}
              divClass="w-fit"
              textColorHover="#1a1a1a"
              bgColorHover="#ff8200"
              slantClass="bottom-right-slant"
            />
          </Link>
        </div>
        {/* our solutions starts here  */}
        <section className="container mt-24 sm:mt-28 4xl:mt-36 mb-20 sm:mb-28 lg:mb-40">
          <h2 className="text-white text-[36px] sm:text-[42px] md:text-[48px] lg:text-[57.6px] xl:text-[72px] 2xl:text-[80px]  3xl:text-[94px] 4xl:text-[115px] leading-[1] mb-5 sm:mb-16 2xl:mb-20">
            {penTestingPageData?.ourSolutionsText}
          </h2>
          <div className=" mb-14 text-white flex flex-col sm:flex-row sm:gap-x-8 lg:gap-x-12 xl:gap-x-12  lg:pl-12 3xl:pl-16">
            <h2 className="uppercase leading-[1] text-3xl xs-only:mb-4 sm:w-[55%] sm:text-[32px] md:text-4xl  lg:text-[38px] xl:text-5xl 2xl:text-[52px] 3xl:text-[62px] mb-12 sm:mb-0">
              <span className="inline-block">
                {penTestingPageData?.ninjaText}
              </span>
              <span className="inline-block text-primary">
                {penTestingPageData?.scanText}
              </span>
            </h2>
            <div className="text-[18px] 2xl:text-xl 3xl:text-2xl font-medium leading-[133%] sm:w-[45%]">
              <p className="mb-6">
                {FormatTextFromGeneralConstants(
                  penTestingPageData?.ninjaScanParagraph
                )}
              </p>
            </div>
          </div>
          <div className=" mb-14 text-white flex flex-col sm:flex-row sm:gap-x-8 lg:gap-x-12 xl:gap-x-12 lg:pl-12 3xl:pl-16">
            <h2 className="uppercase leading-[1] text-3xl xs-only:mb-4 sm:w-[55%] sm:text-[32px] md:text-4xl  lg:text-[38px] xl:text-5xl 2xl:text-[52px] 3xl:text-[62px] mb-12 sm:mb-0">
              <span className="inline-block">
                {penTestingPageData?.ninjaText}
              </span>
              <span className="inline-block text-primary">
                {penTestingPageData?.hackText}
              </span>
            </h2>
            <div className="text-[18px] 2xl:text-xl 3xl:text-2xl font-medium leading-[133%] sm:w-[45%]">
              <p>
                {FormatTextFromGeneralConstants(
                  penTestingPageData?.ninjaHackParagraph
                )}
              </p>
            </div>
          </div>
        </section>
        {/* our solutions ends here  */}
        {/* we've got you covered starts here  */}
        <section className="container mt-24 sm:mt-28 4xl:mt-36 mb-20 sm:mb-28 lg:mb-40">
          <div className=" mb-14 text-white flex flex-col lg:flex-row sm:gap-x-8 lg:gap-x-12 xl:gap-x-12">
            <div>
              <h2 className="uppercase leading-[1] text-3xl xs-only:mb-4 sm:text-[32px] md:text-4xl  lg:text-[38px] xl:text-5xl 2xl:text-[52px] 3xl:text-[62px] mb-12 sm:mb-0">
                <span className="inline-block">
                  {penTestingPageData?.wehaveGotYouCoveredText}{" "}
                </span>
                {/* <span className="inline-block">COVERED</span> */}
              </h2>
              <div className="w-full sm:w-fit cursor-pointer my-12">
                <ButtonSlant
                  buttonClass="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] px-5 py-2.5 4xl:py-3 4xl:px-4 border-[3px] font-dela rounded-md w-full sm:w-fit border-primary text-primary"
                  buttonText={penTestingPageData?.getInTouchText}
                  divClass="w-fit"
                  textColorHover="#1a1a1a"
                  bgColorHover="#ff8200"
                  onClick={(e) => {
                    navigate(navigateTo(clientUrls.contact));
                  }}
                  slantClass="bottom-right-slant"
                />
              </div>
            </div>
            <div className="lg:w-[45%]">
              <div className="grid gap-[2rem]  align-center justify-center grid-cols-2 sm:grid-cols-3 lg:grid-cols-2">
                {covered.map((c, i) => {
                  return (
                    <div className="flex flex-col items-center">
                      <div className="border-[0.16px] border-[#ffffff20] flex justify-center items-center px-2 py-5 lg:py-5 w-full mb-6">
                        <img
                          alt="service"
                          className="inline-block h-[50px]"
                          src={c.coveredImage}
                        />
                      </div>
                      <p className="text-[12px] 2xl:text-[16px] text-white uppercase leading-[1] text-center">
                        {c.coveredTitleText}
                      </p>
                    </div>
                  );
                })}
                {/* <div className="flex flex-col items-center">
                  <div className="border-[0.16px] border-[#ffffff20] flex justify-center items-center px-2 py-5 lg:py-5 w-full mb-6">
                    <img
                      alt="service"
                      className="inline-block w-[30%] sm:w-[35%]"
                      // src={c.challengeLogoURL}
                    />
                  </div>
                  <p className="text-[12px] 2xl:text-[16px] text-white uppercase leading-[1]">
                    PENETRATION TESTING
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <div className="border-[0.16px] border-[#ffffff20] flex justify-center items-center px-2 py-5 lg:py-5 w-full mb-6">
                    <img
                      alt="service"
                      className="inline-block w-[30%] sm:w-[35%]"
                      // src={c.challengeLogoURL}
                    />
                  </div>
                  <p className="text-[12px] 2xl:text-[16px] text-white uppercase leading-[1]">
                    PENETRATION TESTING
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* we've got you covered ends here  */}
        {/* seemlessly starts here  */}
        <section className="container mb-20 sm:mb-28 lg:mb-40">
          <div className=" mb-14 text-white flex flex-col sm:flex-row sm:gap-x-8 lg:gap-x-12 xl:gap-x-12  lg:pl-12 3xl:pl-16">
            <h2 className="uppercase leading-[1] text-3xl xs-only:mb-4 sm:w-[55%] sm:text-[32px] md:text-4xl  lg:text-[38px] xl:text-5xl 2xl:text-[52px] 3xl:text-[62px] mb-12 sm:mb-0">
              <span className="inline-block">
                {penTestingPageData?.seamlesslyIntegrateWithText}
                {penTestingPageData?.qaAndDevopsText}
              </span>
              {/* <span className="inline-block"></span> */}

              {/* <span className="inline-block">SEAMLESSLY</span>
              <span className="inline-block">INTEGRATE WITH</span>
              <span className="inline-block">QA AND DEVOPS</span> */}
            </h2>
            <div className="text-[18px] 2xl:text-xl 3xl:text-2xl font-medium leading-[133%] sm:w-[45%]">
              <p className="mb-6">
                {FormatTextFromGeneralConstants(
                  penTestingPageData?.seamlesslyIntegrateQAAndDevopsParagraph
                )}
              </p>
            </div>
          </div>
        </section>
        {/* seemlessly ends here  */}
        {/* key of uses starts here  */}
        <section className=" mt-24 sm:-mt-8 lg:-mt-10 4xl:mt-36 mb-20 sm:mb-28 lg:mb-40 text-[#fff]">
          <h2 className="container text-white text-[36px] sm:text-[42px] md:text-[48px] lg:text-[57.6px] xl:text-[72px] 2xl:text-[80px]  3xl:text-[94px] 4xl:text-[115px] leading-[1] mb-5 sm:mb-16 2xl:mb-20 uppercase">
            <span className="inline-block">
              {penTestingPageData?.keysToUserSafetyText}
            </span>
            {/* <span className="inline-block">SAFETY</span> */}
          </h2>
          <div className="container text-[18px] 2xl:text-xl 3xl:text-2xl font-medium leading-[133%]">
            <p className="mb-6">
              {FormatTextFromGeneralConstants(
                penTestingPageData?.keysToUserSafetyParagraph
              )}
            </p>
          </div>
          {/* slider comes here  */}
          <section className="mt-20">
            {saftey?.map((service, index) => {
              return (
                <SwiperSlideCommon
                  service={service}
                  key={index}
                  color={{
                    primary: serviceColors[0]?.colorCode,
                    tertiary: serviceColors[0]?.tertiaryColor,
                  }}
                  showHeading={false}
                />
              );
            })}
          </section>

          <div className="container text-[18px] 2xl:text-xl 3xl:text-2xl font-medium leading-[133%]">
            <p className="mb-6">
              {FormatTextFromGeneralConstants(
                penTestingPageData?.keysToUserSafetyParagraph2
              )}
            </p>
          </div>
          <div className="container">
            <Link to={penTestingPageData?.findOutMoreURL}>
              <ButtonSlant
                buttonClass="text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] px-5 py-2.5 4xl:py-3 4xl:px-4 border-[3px] font-dela rounded-md w-full sm:w-fit border-primary text-primary"
                buttonText={penTestingPageData?.findOutMoreText}
                divClass="w-fit"
                textColorHover="#1a1a1a"
                bgColorHover="#ff8200"
                slantClass="bottom-right-slant"
              />
            </Link>
          </div>
        </section>
        {/* key of uses ends here  */}
        <div className="relative my-24 sm:mb-20 lg:my-[6rem]">
          <div className="hidden lg:block absolute left-[-27rem] z-[-1] top-[-15rem]">
            <img
              src={penTestingPageData?.labsBgImage}
              alt="side-ptw"
              className="w-[60%]"
            />
          </div>

          <NewsEvents
            // header1={
            //   servicePageData?.bannerSection_heading1Text +
            //   " " +
            //   servicePageData?.bannerSection_heading2Text
            // }
            header2={penTestingPageData?.fromTheLabText}
            headerButtonText={penTestingPageData?.visitTheLabText}
            cardsData={labs}
            colors={{
              color: serviceColors[0]?.secondaroyColor,
            }}
            url={clientUrls.labs}
            toLabs={true}
          />
        </div>
        {/* new design ends here  */}

        <section className="relative my-24 sm:mb-20 lg:mb-24 lg:mt-72 ">
          <OurServices
            servicesData={services}
            header1={penTestingPageData?.exploreText}
            header2={penTestingPageData?.moretext}
            colors={{ color: serviceColors[0]?.colorCode }}
            lang={lang}
          />
        </section>
      </section>

      <Footer />
    </>
  );
};

export default PenTesting;
