import React, { useState } from "react";
import { motion, AnimatePresence, delay } from "framer-motion";
const LeadershipCard = ({ leader, i, desktop }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <motion.div
      className={`rounded-lg relative  mb-4 w-4/5 sm:w-auto h-[23rem] xxs:h-[24rem] 1.5xs:h-[26rem]  xl:h-[27rem] 2xl:h-[32rem] 3xl:h-[40rem] 3.5xl:h-[44rem] 4xl:h-[48rem] flex flex-col z-20 bg-[#303030]  justify-end  ${
        i % 3 === 0 ? "lg:mt-16" : ""
      } ${i % 2 === 0 ? "sm:mt-40 lg:mt-0" : ""} ${
        i % 3 === 1 ? "lg:mt-48 2.5xl:mt-60" : ""
      } flex-shrink-0 cursor-pointer`}
      onClick={handleToggle}
    >
      <img
        src={leader?.leaderImageURL}
        alt="side-ptw"
        className="object-fill  rounded-t-lg absolute left-[50%] transform-leader w-auto h-[28rem] xxs:h-[29rem] 1.5xs:h-[31rem] 2xl:h-[37rem] 3xl:h-[46rem] 3.5xl:h-[51rem] 4xl:h-[55rem] top-[-10rem] sm:top-[-7rem] md:top-[-10rem] 3xl:top-[-13rem]"
      />
      <motion.div
        initial={{ flexGrow: 0 }}
        animate={{ flexGrow: isOpen ? 1 : 0 }}
        exit={{ flexGrow: 0 }}
        transition={{ duration: 0.2, ease: "easeInOut" }}
        // className={`w-full text-left flex flex-col  bg-[#2AD9FF] rounded-b-lg text-[#004780] p-5 2xl:p-8 z-[2] ${
        //   isOpen ? "" : "h-auto"
        // }`}
        className={`w-full text-left flex flex-col  bg-[#2AD9FF] rounded-b-lg text-[#004780] p-5 pb-3 2xl:p-8 2xl:pb-4 z-[2] ${
          isOpen ? "" : "h-auto"
        } max-h-[23rem] xxs:max-h-[24rem] 1.5xs:max-h-[26rem]  xl:max-h-[27rem] 2xl:max-h-[32rem] 3xl:max-h-[40rem] 3.5xl:max-h-[44rem] 4xl:max-h-[48rem]`}
      >
        <div
          className="flex flex-col justify-between 
        "
        >
          <div>
            <h2 className="text-[20px] xs:text-[24px] sm:text-[20px] md:text-2xl 2xl:text-[28.8px] 3xl:text-[36px] 4xl:text-[43.2px] !leading-[1] mb-4 uppercase">
              {leader?.leaderName}
            </h2>
            <div className="flex justify-between items-end">
              <p className="text-[16px] xs:text-[18px] sm:text-[16px] md:text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] leading-[111%] sm:leading-[1] font-medium overflow-auto hide-scroll pb-1">
                {leader?.leaderJob}
              </p>
              <motion.button
                className="`w-auto h-auto border-[2px] border-[#004780] hover:bg-[#004780]  p-2 rounded-[4px] cursor-pointer group z-[2] slide-btn-bottom-right-slant"
                animate={{
                  rotate: isOpen ? 0 : 180,
                }}
                transition={{ duration: 0.2, ease: "easeInOut" }}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="group-hover:hidden"
                >
                  <path
                    d="M8.125 13.75L11.875 17.5L15.625 13.75"
                    stroke="#004780"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                    stroke="#004780"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hidden group-hover:block"
                >
                  <path
                    d="M8.125 13.75L11.875 17.5L15.625 13.75"
                    stroke="#2AD9FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M4.375 2.5C6.36412 2.5 8.27178 3.29018 9.6783 4.6967C11.0848 6.10322 11.875 8.01088 11.875 10V17.5"
                    stroke="#2AD9FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </motion.button>
            </div>
          </div>

          {isOpen && <hr className="bg-[#004780] h-[1.1px] border-none my-4" />}
        </div>

        <div className="!overflow-y-auto leadership-scroll">
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{
                  y: 50,
                  opacity: 0.3,
                  height: 0,
                }}
                animate={{
                  y: 0,
                  opacity: 1,
                  height: "auto",
                }}
                exit={{
                  y: 50,
                  opacity: 0.3,
                  height: "0",
                }}
                transition={{
                  duration: 0.2,
                  ease: "easeInOut",
                }}
              >
                <p className="text-[#004780] text-base xl:text-lg 2xl:text-xl 3xl:text-[22.5px] 4xl:text-[27px] leading-[120%]  ">
                  {leader?.leaderDescription}{" "}
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default LeadershipCard;
