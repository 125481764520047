import { useState } from "react";
import { ArrowLeft, ArrowRight } from "./CommonComponents";

export const scrollToRef = (ref) => {
  let ele = ref.current;
  if (ele != null) {
    const headerOffset = 100;

    const elementPosition = ele.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    const outerTimeOut = setTimeout(() => {
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      const innerTimeout = setTimeout(() => {
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
        clearTimeout(innerTimeout);
      }, 400);
      clearTimeout(outerTimeOut);
    }, 0);
  }
};
const Pagination = ({
  currentPage,
  totalPages,
  onPaginationValueChange,
  color,
}) => {
  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onPaginationValueChange(pageNumber);
    }
  };
  const [isHoverNext, setIsHoverNext] = useState(false);
  const [isHoverPrev, setIsHoverPrev] = useState(false);
  const renderPageNumbers = () => {
    const pageNumbers = [...Array(totalPages).keys()].map((n) => n + 1);
    const visiblePages = 6;
    const halfVisible = Math.floor(visiblePages / 2);

    if (totalPages <= visiblePages) {
      return pageNumbers.map((pageNumber) => (
        <a
          key={pageNumber}
          className={
            pageNumber === currentPage
              ? "pages-link mx-[0.5rem] xs:mx-[0.7rem] active"
              : "pages-link mx-[0.5rem] xs:mx-[0.7rem]"
          }
          style={{ color: pageNumber === currentPage ? color : "" }}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handlePageClick(pageNumber);
          }}
        >
          {pageNumber}
        </a>
      ));
    }

    if (currentPage <= halfVisible + 1) {
      return [
        ...pageNumbers.slice(0, visiblePages - 1).map((pageNumber) => (
          <a
            key={pageNumber}
            className={
              pageNumber === currentPage
                ? "pages-link mx-[0.5rem] xs:mx-[0.7rem] active"
                : "pages-link mx-[0.5rem] xs:mx-[0.7rem]"
            }
            style={{ color: pageNumber === currentPage ? color : "" }}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handlePageClick(pageNumber);
            }}
          >
            {pageNumber}
          </a>
        )),
        <span key="ellipsis-end">...</span>,
        <a
          key={totalPages}
          className={
            totalPages === currentPage
              ? "pages-link mx-[0.5rem] xs:mx-[0.7rem] active"
              : "pages-link mx-[0.5rem] xs:mx-[0.7rem]"
          }
          style={{ color: totalPages === currentPage ? color : "" }}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handlePageClick(totalPages);
          }}
        >
          {totalPages}
        </a>,
      ];
    }

    if (currentPage >= totalPages - halfVisible) {
      return [
        <a
          key={1}
          className={
            1 === currentPage
              ? "pages-link mx-[0.5rem] xs:mx-[0.7rem] active"
              : "pages-link mx-[0.5rem] xs:mx-[0.7rem]"
          }
          style={{ color: 1 === currentPage ? color : "" }}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handlePageClick(1);
          }}
        >
          1
        </a>,
        <span key="ellipsis-start">...</span>,
        ...pageNumbers
          .slice(totalPages - (visiblePages - 1))
          .map((pageNumber) => (
            <a
              key={pageNumber}
              className={
                pageNumber === currentPage
                  ? "pages-link mx-[0.5rem] xs:mx-[0.7rem] active"
                  : "pages-link mx-[0.5rem] xs:mx-[0.7rem]"
              }
              style={{ color: pageNumber === currentPage ? color : "" }}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handlePageClick(pageNumber);
              }}
            >
              {pageNumber}
            </a>
          )),
      ];
    }

    return [
      <a
        key={1}
        className={
          1 === currentPage
            ? "pages-link mx-[0.5rem] xs:mx-[0.7rem] active"
            : "pages-link mx-[0.5rem] xs:mx-[0.7rem]"
        }
        style={{ color: 1 === currentPage ? color : "" }}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handlePageClick(1);
        }}
      >
        1
      </a>,
      <span key="ellipsis-start">...</span>,
      ...pageNumbers
        .slice(currentPage - halfVisible, currentPage + halfVisible - 1)
        .map((pageNumber) => (
          <a
            key={pageNumber}
            className={
              pageNumber === currentPage
                ? "pages-link mx-[0.5rem] xs:mx-[0.7rem] active"
                : "pages-link mx-[0.5rem] xs:mx-[0.7rem]"
            }
            style={{ color: pageNumber === currentPage ? color : "" }}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handlePageClick(pageNumber);
            }}
          >
            {pageNumber}
          </a>
        )),
      <span key="ellipsis-end">...</span>,
      <a
        key={totalPages}
        className={
          totalPages === currentPage
            ? "pages-link mx-[0.5rem] xs:mx-[0.7rem] active"
            : "pages-link mx-[0.5rem] xs:mx-[0.7rem]"
        }
        style={{ color: totalPages === currentPage ? color : "" }}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handlePageClick(totalPages);
        }}
      >
        {totalPages}
      </a>,
    ];
  };

  return (
    <>
      <div
        className="2xl:h-14 4xl:w-16 4xl:h-16"
        onMouseEnter={() => {
          setIsHoverPrev(currentPage === 1 ? false : true);
        }}
        onMouseLeave={() => {
          setIsHoverPrev(false);
        }}
        onTouchStart={() => {
          setIsHoverPrev(currentPage === 1 ? false : true);
        }}
        onTouchEnd={() => {
          setIsHoverPrev(false);
        }}
      >
        <button
          onClick={() => handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          className={`group w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] rounded-md pl-2  ${
            isHoverPrev ? "arrow-btn-top-left" : ""
          } ${
            currentPage === 1
              ? "opacity-30 pointer-events-none bg-transparent"
              : "opacity-100"
          }`}
          style={{
            borderColor: color,
            backgroundColor: isHoverPrev ? color : "",
          }}
        >
          <ArrowLeft color={color} hover={isHoverPrev} />
        </button>
      </div>
      {renderPageNumbers()}
      <div
        className="2xl:h-14 4xl:w-16 4xl:h-16"
        onMouseEnter={() => {
          setIsHoverNext(currentPage === totalPages ? false : true);
        }}
        onMouseLeave={() => {
          setIsHoverNext(false);
        }}
        onTouchStart={() => {
          setIsHoverNext(currentPage === totalPages ? false : true);
        }}
        onTouchEnd={() => {
          setIsHoverNext(false);
        }}
      >
        <button
          onClick={() => handlePageClick(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`group w-[44px] h-[44px] 2xl:w-14 2xl:h-14 4xl:w-16 4xl:h-16 border-[2px] 4xl:border-[4px] ${
            isHoverNext ? "arrow-btn-bottom-right" : ""
          }  rounded-md pl-2  ${
            currentPage === totalPages
              ? "opacity-30 pointer-events-none bg-transparent"
              : "opacity-100 "
          }`}
          style={{
            borderColor: color,
            backgroundColor: isHoverNext ? color : "",
          }}
        >
          <ArrowRight color={color} hover={isHoverNext} />
        </button>
      </div>
    </>
  );
};

export default Pagination;
