import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setColor } from "../../store/reducers/ColorReducer";
import CommonTopBanner from "./CommonTopBanner";
import ParagraphSection from "./ParagraphSection";
import AAACoDev from "./AAACoDev";
import OurPartners from "../home/OurPartners";
import ProjectsShowSection from "../home/ProjectsShowSection";
import NewsEvents from "../home/NewsEvents";
import { clientUrls } from "../../constants/ClientUrls";
import OurServices from "../home/OurServices";
import Footer from "../Footer";
import FormatText from "../FormatText";
import Buttons from "../Buttons";
import { useNavigate, useParams } from "react-router-dom";

import { navigateTo } from "../../constants/GeneralConstants";
import ButtonSlant from "../ButtonSlant";
import { SlantImg } from "../CommonComponents";

const ArtProduction = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang } = useParams();
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    document.title = data?.servicePageData?.artProdSEOTitle
      ? data?.servicePageData?.artProdSEOTitle
      : "Art Production Services for Games & Apps - 2D, 3D, VR/AR Art | Side";
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  useEffect(() => {
    dispatch(
      setColor({
        primary: data?.serviceColors[0]?.colorCode,
        secondary: data?.serviceColors[0]?.secondaroyColor,
        tertiary: data?.serviceColors[0]?.tertiaryColor,
      })
    );
  }, [data, lang]);
  const {
    servicePageData,
    subServices,
    projects,
    labs,
    services,
    ourClients,
    serviceColors,
    type,
  } = data;
  // console.log(data, "dataaaaaaa");

  const {
    bannerSection_heading1Text,
    bannerSection_heading2Text,
    fromTheLabText,
    visitTheLabText,
    visitOurArtSection_visitOurTitleText,
    visitOurArtSection_visitOurArtPortfolioSiteContentText,
    visitOurArtSection_visitOurArtPortfolioSiteText,
    visitOurArtSection_visitOurArtBgImage,
    visitOurArtSection_visitOurArtBgImageTab,
    visitOurArtSection_visitOurArtBgImageMob,
    visitOurArtPortfolioHeadingText,
    seePortfolioText,
  } = servicePageData;
  return (
    <>
      <div>
        {" "}
        <CommonTopBanner
          servicePageData={servicePageData}
          color={{
            primary: data?.serviceColors[0]?.colorCode,
            tertiary: data?.serviceColors[0]?.tertiaryColor,
          }}
        />
        <div className="sm:mb-20 lg:mb-20">
          <ParagraphSection
            servicePageData={servicePageData}
            color={{
              primary: data?.serviceColors[0]?.colorCode,
              tertiary: data?.serviceColors[0]?.tertiaryColor,
            }}
          />
        </div>
        <div
          className="bg-no-repeat art-production-bg-1 bg-contain overflow-visible sm:pt-2 mb-32 relative"
          // style={{
          //   backgroundImage:
          //     window.innerWidth > 639
          //       ? `url(${servicePageData?.serviceTypeBgImage})`
          //       : "",
          // }}
        >
          {/* <div className="absolute -z-[1] right-0 top-[7rem] sm:top-0">
            <img src={servicePageData?.serviceTypeBgImage} alt="ptw-side" />
          </div> */}
          <div className="absolute right-0 z-[-1] top-[7rem] sm:top-0">
            <img
              src={servicePageData?.serviceTypeBgImage}
              alt="ptw-side"
              className="w-[40%] float-right "
            />
          </div>
          <AAACoDev
            gameServices={subServices}
            color={{
              primary: data?.serviceColors[0]?.colorCode,
              tertiary: data?.serviceColors[0]?.tertiaryColor,
            }}
            isArtProduction={true}
          />
        </div>
        {/* we believe starts here  */}
        <div
          className="container relative we-believe-height md:blue-div my-10 sm:mt-20 sm:mb-36 we-believe-clipk flex flex-col sm:flex-row justify-between items-center lg:mt-0 lg:mb-36 xl:mb-36 2xl:mb-40"
          style={{ backgroundColor: data?.serviceColors[0]?.colorCode }}
        >
          <div className="absolute top-[-1px] left-[-1px] z-[10] rotate-180">
            <SlantImg className="h-[60px] sm:h-[75px] lg:h-[80px]" />
          </div>
          <div className="w-full sm:w-[80%] md:w-[60%] lg:w-[64%] h-[65%] sm:h-auto px-8 sm:px-9 md:px-16 py-[4.2rem] sm:pt-24 md:pt-16 lg:pb-20 lg:pt-28 xl:pt-24 2xl:py-0 ">
            <h2
              className="!leading-[1] text-lg xxs:text-2xl lg:text-4xl 2xl:text-[40px] 3xl:text-[50px] 4xl:text-[60px] font-bold uppercase"
              style={{ color: data?.serviceColors[0]?.tertiaryColor }}
            >
              <span className="block">
                <FormatText text={visitOurArtSection_visitOurTitleText} />
              </span>
            </h2>
            <p
              className="content py-4  leading-[1] sm:leading-[120%] common-para-size font-medium"
              style={{ color: data?.serviceColors[0]?.tertiaryColor }}
            >
              <FormatText
                text={visitOurArtSection_visitOurArtPortfolioSiteContentText}
              />
            </p>

            <ButtonSlant
              buttonClass="border-[2px]  px-4 py-3 uppercase font-dela rounded-md text-xs sm:text-base lg:text-lg 2xl:text-[20px] 3xl:text-[24px] 4xl:text-[28px]"
              borderColor={data?.serviceColors[0]?.tertiaryColor}
              textColor={data?.serviceColors[0]?.tertiaryColor}
              textColorHover={data?.serviceColors[0]?.colorCode}
              bgColorHover={data?.serviceColors[0]?.tertiaryColor}
              divClass="my-2 w-fit"
              slantClass="bottom-right-slant"
              onClick={() =>
                window.open("https://1518studios.com/portfolio", "_blank")
              }
              buttonText={visitOurArtSection_visitOurArtPortfolioSiteText}
            />
          </div>
          <div className="hidden lg:block we-believe-height">
            <img
              src={visitOurArtSection_visitOurArtBgImage}
              alt="side-ptw"
              className=" h-full w-full"
            />
          </div>
          <div className="hidden sm:block lg:hidden sm:w-[auto] we-believe-height">
            <img
              src={visitOurArtSection_visitOurArtBgImageTab}
              alt="side-ptw"
              className=" h-full w-full"
            />
          </div>
          <div className="block sm:hidden h-[35%] w-full">
            <img
              src={visitOurArtSection_visitOurArtBgImageMob}
              alt="side-ptw"
              className="w-full h-full"
            />
          </div>
        </div>
        {/* we believe ends here  */}
        <OurPartners
          homeData={servicePageData}
          clientsData={ourClients}
          lang={lang}
        />
        <div className="relative sm:mt-8 lg:mt-0">
          {/* <div className="absolute bottom-[15rem] right-0 z-[-1] hidden sm:block">
            <img
              src={servicePageData?.visitOurArtPortfolioBgImage}
              alt="side-ptw"
            />
          </div> */}
          <div className="hidden sm:block absolute -left-[50%] sm:left-[-35%] 1.5md:-left-[45%] lg:-left-[18%] 2xl:-left-[24%] 3xl:-left-[25%] sm:-top-24 lg:top-0  z-[-1]">
            <img
              className="w-[70vw] sm:w-[78vw] lg:w-[70vw] 3xl:w-[70vw] 3.3xl:w-[67vw] 4xl:w-[75vw] h-auto"
              src={servicePageData?.visitOurArtPortfolioBgImage}
              alt="side-ptw"
            />
          </div>
          <div className="my-24 sm:my-[8rem] lg:my-[14rem]">
            <ProjectsShowSection
              homeData={servicePageData}
              projectsData={projects}
              colors={{ color: data?.serviceColors[0]?.secondaroyColor }}
              redirectionFrom={type}
            />
          </div>
          {/* visit our art portfolio section start */}
          {/* <section className="container flex text-white mb-32 ">
            <div className="w-full sm:w-[98%] lg:w-[50%] xl:w-[55%] 2xl:w-[55%] 4xl:w-[47%]">
              <h2 className="font-normal  lg:w-auto text-projects-title !leading-[100%]  mb-3 uppercase overflow-hidden">
                {visitOurArtPortfolioHeadingText}
              </h2>

              <button
                className="border-[2px] py-2 px-4 3xl:py-3 3xl:px-5 4xl:py-4 w-full mt-6 sm:w-auto   rounded-[4px] uppercase bottom-right-slant-hover text-lg 3xl:text-[22px] 4xl:text-[26px] font-normal font-dela bottom-right-slant-hover"
                style={{
                  color: isHover
                    ? "#000000"
                    : serviceColors[0]?.secondaroyColor,
                  borderColor: serviceColors[0]?.secondaroyColor,
                  backgroundColor: isHover
                    ? serviceColors[0]?.secondaroyColor
                    : "",
                }}
                onClick={(e) => navigate(navigateTo(clientUrls.contact))}
                onMouseEnter={() => {
                  setIsHover(true);
                }}
                onMouseLeave={() => {
                  setIsHover(false);
                }}
                onTouchStart={() => {
                  setIsHover(true);
                }}
                onTouchEnd={() => {
                  setIsHover(false);
                }}
              >
                {seePortfolioText}
              </button>
            </div>
          </section> */}
          {/* visit our art portfolio section end */}
          <NewsEvents
            header1={`${bannerSection_heading1Text} ${" "} ${bannerSection_heading2Text}`}
            header2={fromTheLabText}
            headerButtonText={visitTheLabText}
            cardsData={labs}
            colors={{
              color: data?.serviceColors[0]?.secondaroyColor,

              tertiaryColor: data?.serviceColors[0]?.tertiaryColor,
            }}
            url={clientUrls.labs}
            toLabs={true}
            type={type}
          />
        </div>
        <OurServices
          servicesData={services}
          header1={servicePageData?.exploreText}
          header2={servicePageData?.moreText}
          colors={{ color: data?.serviceColors[0]?.colorCode }}
          lang={lang}
        />
      </div>
      <Footer />
    </>
  );
};

export default ArtProduction;
