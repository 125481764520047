import { useEffect, useState } from "react";
import TopBannerForOtherPages from "../components/TopBannerForOtherPages";
import { getLanguage, navigateTo } from "../constants/GeneralConstants";
import { getAboutPageData } from "../services/ApiService";
import "../css/pagescss/About.css";
import OurPartners from "../components/home/OurPartners";
import MapSection from "../components/MapSection";
import { useDispatch } from "react-redux";
import { setColor } from "../store/reducers/ColorReducer";
import { clientUrls } from "../constants/ClientUrls";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Timeline from "../components/about/Timeline";
import Loader from "../components/Loader";
import LeftScatterAnimation from "../components/about/LeftScatterAnimation";
import RightScatterAnimation from "../components/about/RightScatterAnimation";
import LeadershipCard from "../components/about/LeadershipCard";
import AboutSwiperSlider from "../components/about/AboutSwiperSlider";
import PIL from "../components/PIL";
import FormatText from "../components/FormatText";
import SideSiteSEO from "../components/seo/SideSiteSEO";
import ButtonSlant from "../components/ButtonSlant";
import Testimonials from "../components/Testimonials";
import GlobalTeam from "../components/home/GlobalTeam";
import { SlantImg } from "../components/CommonComponents";

const About = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const [aboutPageData, setAboutPageData] = useState(null);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  useEffect(() => {
    dispatch(
      setColor({
        primary: "#2AD9FF",
        secondary: "#FFF647",
        tertiary: "#004780",
      })
    );
  }, [dispatch]);
  useEffect(() => {
    callAboutPageData(getLanguage());
  }, [lang]);

  const callAboutPageData = async (language) => {
    setLoader(true);
    try {
      let data = await getAboutPageData(language);
      document.title = data?.about?.aboutSEOTitle;
      setAboutPageData(data);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const {
    about,
    aboutCompanyImages,
    achievementAwards,
    achievementsImages,
    leadership,
    // locations,
    testimonials,
    timeLine,
    values,
    clients,
    locations,
  } = aboutPageData || {};
  /* we can this part later   */

  let arr = locations?.sort((a, b) => {
    return a.locationOrder - b.locationOrder;
  });
  const regions = [...new Set(arr?.map((location) => location.regionName))];

  useEffect(() => {
    const initialLocations = locations?.filter(
      (loc) => loc.regionName === locations?.[0].regionName
    );
    setSelectedLocations(initialLocations);
  }, [locations]);

  if (loader) {
    return <Loader />;
  }

  const handleRegionChange = (region) => {
    const selectedLocation = locations.filter(
      (loc) => loc.regionName === region
    );
    setSelectedRegion(region);
    setSelectedLocations(selectedLocation);
    setDropdownVisible(false);
  };

  /* we can this part later   */
  return (
    <>
      <SideSiteSEO
        title={
          aboutPageData?.aboutSEOTitle
            ? aboutPageData?.aboutSEOTitle
            : "About Us | Side - We Make Waves"
        }
        description={
          aboutPageData?.aboutSEODescription
            ? aboutPageData?.aboutSEODescription
            : "Discover Side's 30+ years of experience & passionate teams delivering award-winning video game support services worldwide."
        }
      />
      <section className="about-page">
        {/* top banner section starts here  */}
        <section className="bg-[#2AD9FF] text-[#004780] relative">
          <div className="hidden lg:block w-[35%] 3xl:w-auto h-full absolute top-0 right-0">
            {/* <img
              src={about?.bannerBgImage}
              alt="banner_img_desk"
              className=" h-full w-full "
            /> */}
            <PIL
              lowQaulitySrc={about?.bannerBgImage}
              highQualitySrc={about?.bannerBgImage}
              className={" h-full w-full "}
              alt={"Side About"}
            />
          </div>
          <div className="hidden sm:block lg:hidden h-full absolute top-0 right-0">
            {/* <img
              src={about?.bannerBgImageTab}
              alt="banner-img_tab"
              className=" h-full w-full "
            /> */}
            <PIL
              lowQaulitySrc={about?.bannerBgImageTab}
              highQualitySrc={about?.bannerBgImageTab}
              className={" h-full w-full "}
              alt={"Side About"}
            />
          </div>
          <div className="block sm:hidden w-full absolute bottom-0">
            <PIL
              className={"h-full w-full"}
              alt={"banner_img_mob"}
              lowQaulitySrc={about?.bannerBgImageMob}
              highQualitySrc={about?.bannerBgImageMob}
            />
          </div>
          <div className="absolute right-0 -bottom-[1px] z-[10]">
            <SlantImg className="h-[60px] sm:h-[75px] lg:h-auto" />
          </div>
          <div className="container relative mx-auto h-[40rem] xxs:h-[42rem] xs:h-[47rem] sm:h-[30rem] md:h-[35rem] lg:h-[31rem] xl:h-[35rem] 2xl:h-[38rem] 2.5xl:h-[42rem] 3xl:h-[50rem] 4xl:h-[54rem] mb-24 sm:mb-8  lg:mb-28 rounded-lg  flex flex-col sm:flex-row items-center gap-8 3xl:gap-16">
            <div
              className={`w-full sm:w-[65%] ${
                getLanguage() === "fr-ca" ? "pt-24" : "pt-[7rem]"
              } 2xl:pt-[5rem]`}
            >
              <h3 className="text-sm sm:text-base md:text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] uppercase">
                {about?.aboutText}
              </h3>
              <h2
                className={`${
                  getLanguage() === "fr-ca" ? "text-[30px]" : "text-[36px]"
                } sm:text-[42px] md:text-[48px] lg:text-[57.6px] xl:text-[64px] 2xl:text-[78px] 3xl:text-[100px] 4xl:text-[121.6px] leading-[100%] sm:leading-[90%] mb-7 lg:mb-10 xl:mb-16 uppercase`}
              >
                {lang === "zh-chs" ? (
                  <>
                    <span className="block">{about?.whatText}</span>
                    <span className="block">
                      {about?.makesText}
                      {about?.usUniqueText}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="block">
                      {about?.whatText} {about?.makesText}
                    </span>
                    <span className="block">{about?.usUniqueText}</span>
                  </>
                )}
              </h2>
              <div className="flex gap-4 xxs:gap-6  justify-between">
                <div className="min-w-[4.5rem] xs:min-w-[5.5rem]">
                  <h3 className="text-[24px] sm:text-[22px] md:text-[24px] lg:text-[32px] xl:text-[36px] 2xl:text-[43.2px] 3xl:text-[54px] 4xl:text-[64px] leading-[89%] tracking-[-3%] mb-3">
                    {about?.statsSection_studiosValue}
                  </h3>
                  <p className="text-sm xxs:text-[17px] sm:text-[16px] md:text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32px] font-medium">
                    {about?.statsSection_studiosLabel}
                  </p>
                </div>
                <div className="min-w-[7.5rem]">
                  <h3 className="text-[24px] sm:text-[22px] md:text-[24px] lg:text-[32px] xl:text-[36px] 2xl:text-[43.2px] 3xl:text-[54px] 4xl:text-[64px] leading-[89%] tracking-[-3%] mb-3">
                    {about?.statsSection_titlesOfThePastYearsValue}
                  </h3>
                  <p className="text-sm xxs:text-[17px] sm:text-[16px] md:text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32px] font-medium leading-[1]">
                    {about?.statsSection_titlesOfThePastYearsLabel}
                  </p>
                </div>
                <div className="min-w-24">
                  <h3 className="text-[24px] sm:text-[22px] md:text-[24px] lg:text-[32px] xl:text-[36px] 2xl:text-[43.2px] 3xl:text-[54px] 4xl:text-[64px] leading-[89%] tracking-[-3%] mb-3">
                    {about?.statsSection_yearsOfXPValue}
                  </h3>
                  <p className="text-sm xxs:text-[17px] sm:text-[16px] md:text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32px] font-medium leading-[1]">
                    {about?.statsSection_yearsOfXPLabel}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* top banner section ends here  */}
        {/* 2 columns section starts here  */}
        <div className="container text-white mb-20 sm:mb-28 lg:mb-40 flex justify-between items-center flex-col-reverse lg:flex-row">
          <div className="w-full lg:w-[40%] p-2">
            <h2 className="uppercase text-[32px] lg:text-[36px] 2xl:text-[43px] 3xl:text-[60px] 4xl:text-[74px] leading-[1] text-white mb-4 sm:mb-6 w-[21.5rem] sm:w-auto lg:w-[25rem] xl:w-[34rem] 2xl:w-[40rem] 3xl:w-[48rem]">
              {about?.companyProfileSection_headingText}
            </h2>
            <p className="mb-4 sm:mb-6 text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] !leading-[120%] sm:font-medium">
              <FormatText text={about?.companyProfileSection_descriptionText} />
            </p>
            <div className="flex justify-center sm:justify-start">
              {/* <button
                onClick={() => navigate(navigateTo(clientUrls.companyProfile))}
                className="relative overflow-hidden px-7 uppercase text-[12px] xxs:text-sm xs:text-[16px] lg:text-lg 2xl:text-[21px] 3xl:text-2xl text-[#2AD9FF] font-bold border-[#2AD9FF] border-[2px]  xs:px-6 py-3 4xl:py-4 4xl:px-8 rounded-md bottom-right-slant-hover hover:bg-[#2AD9FF] hover:text-black text-map-button1 font-dela w-max"
                onTouchStart={(e) => {
                  e.currentTarget.style.backgroundColor = "#2ad9ff";
                  e.currentTarget.style.color = "#1a1a1a";
                }}
                onTouchEnd={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                  e.currentTarget.style.color = "#2AD9FF";
                }}
              >
                {about?.companyProfileSection_viewOurCompanyProfileText}
              </button> */}
              <ButtonSlant
                onClick={() => navigate(navigateTo(clientUrls.companyProfile))}
                buttonClass="relative overflow-hidden px-7 uppercase text-[12px] xxs:text-sm xs:text-[16px] lg:text-lg 2xl:text-[21px] 3xl:text-2xl text-[#2AD9FF] font-bold border-[#2AD9FF] border-[2px]  xs:px-6 py-3 4xl:py-4 4xl:px-8 rounded-md  text-map-button1 font-dela w-full sm:w-max"
                textColor="#2AD9FF"
                textColorHover="#1a1a1a"
                bgColorHover="#2AD9FF"
                buttonText={
                  about?.companyProfileSection_viewOurCompanyProfileText
                }
                divClass="w-full sm:w-fit"
                slantClass="bottom-right-slant"
              />
            </div>
          </div>
          <div className="w-full lg:w-[60%] self-center sm:ml-8 lg:ml-0 lg:mr-12">
            <LeftScatterAnimation images={aboutCompanyImages} />
          </div>
        </div>
        {/* 2 columns section ends here  */}
        {/* we work section starts here  */}
        <section className="mb-20 sm:mb-28 lg:mb-40">
          <div className="container">
            {/* <h2 className="uppercase text-[30px] lg:text-[38.5px] xl:text-[48px] 2xl:text-[57.6px] 3xl:text-[72px] leading-[1] text-white">
              We work with
              <span className="text-[#2AD9FF]"> the best</span>
            </h2> */}
          </div>
        </section>
        {/* we work section start here  */}
        <OurPartners
          homeData={about}
          clientsData={clients}
          aboutPage={true}
          lang={lang}
        />
        {/* we work section ends here  */}

        {/* location section */}
        <MapSection
          homeData={about}
          locations={locations}
          aboutPage={true}
          lang={lang}
        />
        {/* mobile final global team starts here  */}

        {/* mobile final global team ends here  */}
        <GlobalTeam
          home={about}
          regions={regions}
          homePageData={aboutPageData}
        />
        {/* values section */}
        <div className="my-28">
          <AboutSwiperSlider
            service={values}
            color={{
              primary: "#2AD9FF",
              tertiary: "#004780",
            }}
            headerTitle={about?.valuesText}
          />
        </div>
        {/* voice scroll section here  */}
        {/* 2col unlocked starts here  */}
        <div className="container text-white mb-20 sm:mb-28 lg:mb-40 flex justify-between flex-col lg:flex-row">
          <div className="w-full lg:w-[56%] xl:w-[60%] p-2 self-center lg:ml-0 xl:ml-4">
            <RightScatterAnimation images={achievementsImages} />
          </div>
          <div className="w-full lg:w-[40%]">
            <h2 className="text-[32px] lg:text-[36px] 2xl:text-[43px] 3xl:text-[60px] 4xl:text-[74px] leading-[1] text-white w-[21.5rem] sm:w-auto xl:w-[34rem] 2xl:w-[40rem] 3xl:w-[48rem] mb-4 sm:mb-6">
              {about?.achievementsSection_weHaveUnlockedSomeText}
              {lang === "zh-chs" ? "" : " "}
              <span className="text-[#2AD9FF]">
                {about?.achievementsSection_epicAchievementsText}
              </span>
            </h2>
            <p className="mb-4 sm:mb-6 text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] leading-[120%] sm:font-medium">
              {about?.achievementsSection_descriptionText}
            </p>
            <div className="flex gap-16">
              <div>
                <h3 className="text-[32px] lg:text-[36px] 2xl:text-[43px] 3xl:text-[60px] 4xl:text-[74px] leading-[89%] tracking-[-3%] mb-3">
                  {about?.achievementsSection_titlesValue}
                </h3>
                <p className="mb-4 sm:mb-6 text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] leading-[120%] sm:font-medium">
                  {about?.achievementsSection_titlesLabel}
                </p>
              </div>
              <div>
                <h3 className="text-[32px] lg:text-[36px] 2xl:text-[43px] 3xl:text-[60px] 4xl:text-[74px] leading-[89%] tracking-[-3%] mb-3">
                  {about?.achievementsSection_peopleValue}
                </h3>
                <p className="mb-4 sm:mb-6 text-[18px] 2xl:text-[21.6px] 3xl:text-[27px] 4xl:text-[32.4px] leading-[120%] sm:font-medium">
                  {about?.achievementsSection_peopleLabel}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* 2col unlocked ends here  */}
        {/* award section starts here  */}
        <section className="bg-awardsk relative bg-right-top bg-auto pt-16">
          <div className="absolute top-0 sm-only:top-[21rem] md-only:top-[23rem] right-0 z-[-1] h-[60%] sm:h-[37%] lg:h-[57%] xl:h-auto 2xl:h-[56%]">
            <img
              src={about?.achivementBgImage}
              alt="side-ptw"
              className=" h-[60%] sm:h-[55%] lg:h-[57%] xl:h-auto 2xl:h-[56%]"
            />
          </div>
          <div className="container mb-20 sm:mb-28 lg:mb-40 flex xs-only:flex-nowrap xs-only:overflow-auto hide-scroll gap-2 justify-between sm:grid sm:grid-cols-2 sm:gap-x-12 lg:grid-cols-3 sm:gap-y-8 sm:flex-wrap">
            {achievementAwards?.map((achievement) => {
              return (
                <div
                  key={achievement?.meta_value}
                  className="flex flex-col xs-only:flex-shrink-0 bg-[#ffffff05] text-white xs-only:w-[80%] p-8 backdrop-blur-[2px] sm:backdrop-blur-[8px] "
                >
                  <img
                    className="w-full h-auto mb-2 xs:mb-6 object-contain"
                    src={achievement?.achievementImage}
                    alt="achievement_img"
                  />
                  <p className="text-center text-xs xs:text-[18px] 2xl:text-xl xs:mb-2">
                    {achievement?.achievementTitle}
                  </p>
                  <h3 className="text-center text-base xs:text-xl 2xl:text-2xl">
                    {achievement?.achievementYear}
                  </h3>
                </div>
              );
            })}
          </div>
          {/* award section ends here */}
          {/* leadership section starts here  */}
          <section className="container mb-20 sm:mb-28">
            <h2 className="text-white text-[36px] sm:text-[64px] md:text-[72px] lg:text-[57.6px] xl:text-[72px] 2xl:text-[86.4px]  3xl:text-[108px] 4xl:text-[129.6px] leading-[1] mb-5  2xl:mb-8 uppercase">
              {about?.leadershipText}
            </h2>

            <div className="flex gap-8 flex-nowrap overflow-x-auto overflow-y-visible hide-scroll sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-8 md:gap-x-16 mt-12 sm:mt-8 lg:mt-0 mb-[7rem] md:mb-0 pt-[11rem] sm:pt-[10rem] 3xl:pt-[15rem]">
              {/* <div className="flex gap-8 flex-nowrap overflow-x-auto overflow-y-visible hide-scroll sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-8 md:gap-16 mt-12 sm:mt-8 lg:mt-0 mb-[7rem] md:mb-0 pt-[10rem] lg:pt-32 2xl:pt-[25rem]"> */}
              {leadership?.map((leader, i) => {
                return <LeadershipCard leader={leader} i={i} key={i} />;
              })}
            </div>
            {/* <div className="hidden lg:grid lg:grid-cols-3 lg:gap-x-16 lg:justify-between">
              {leadership?.slice(6, 9).map((leader, i) => {
                return (
                  <LeadershipCard
                    leader={leader}
                    i={i === 0 ? i : 2}
                    key={i}
                    desktop={true}
                  />
                );
              })}
            </div> */}

            {/* on hover leadership section starts here  */}
            <div className="hidden group rounded-lg relative  my-4 events-card flexl flex-col z-20 bg-[#2AD9FF] h-[40%]">
              {/* <img
              src="/images/Avatar.png"
              alt="side-ptw"
              className="object-fill rounded-t-lg absolute  left-[50%] transform-leader"
            /> */}
            </div>
            {/* onhover ends here  */}
          </section>
        </section>
        {/* leadership section ends here  */}
        {/* their words starts here  */}

        <section className="mt-20 sm:mt-28 lg:mt-40 mb-40">
          <Testimonials
            testimonialLeftQuotations={about?.testimonialLeftQuotations}
            testimonialRightQuotations={about?.testimonialRightQuotations}
            testimonials={testimonials}
            bgcolor="#FFF647"
            textColor="#004780"
          />
        </section>
        {/* their ends here  */}
        {/* About time line starts here */}
        <div className="mt-20 sm:mt-28 lg:mt-40 mb-40">
          <Timeline
            aboutData={aboutPageData?.about}
            timelineData={[
              ...timeLine,
              {
                post_id: 4835,
                historyTitle: "SIDE UK Ltd. Incorporates",
                // historyYear: "1995",
                historyMonth: "JANUARY",
                descriptionText:
                  'We begin life in London as Vocal Suite, and officially become "SIDE" on May 31, 2002.',
                isActive: "No",
              },
            ]}
          />
        </div>
        {/* About time line ends here */}
        {/* of life starts here  */}
        <section className="bg-lifek lg:pb-10 relative">
          <div className="absolute top-[-2rem] sm:top-[-1rem] sm-only:left-[-1rem] md-only:left-[-1rem] left-0 z-[-1] h-[15rem] sm:h-full lg:h-[82%] xl:h-[90%]">
            <img
              src={about?.bottomBgImage}
              alt="side-ptw"
              className="h-[15rem] sm:h-full lg:h-[82%] xl:h-[90%]"
            />
          </div>
          <div className="container mb-20 sm:mb-28 lg:mb-40 pl-4">
            <h2 className="text-white text-[30px] xxs:text-[36px] sm:text-[42px] md:text-[48px] lg:text-[57.6px] xl:text-[72px] 2xl:text-[86.4px]  3xl:text-[108px] 4xl:text-[129.6px] leading-[1] mb-5 sm:mb-8 uppercase w-[75%] sm:w-[60%]">
              {about?.experienceOurSideOfLifeText}
            </h2>

            <ButtonSlant
              buttonClass="text-[18px] 2xl:text-[21.6px]  3xl:text-[27px] uppercase px-8 py-2  border-[3px] border-[#FFF647]   font-dela rounded-md w-full sm:w-auto  bg-[#1a1a1a]"
              slantClass="bottom-right-slant"
              textColor="#fff647"
              divClass="w-fit"
              textColorHover="#1a1a1a"
              bgColorHover="#fff647"
              onClick={(e) => navigate(navigateTo(`${clientUrls.careers}`))}
              buttonText={about?.joinTheTeamText}
            />
          </div>
        </section>
        {/* of life ends here  */}
      </section>
      {/* <TopBannerForOtherPages pageName="About" /> */}
      <Footer />
    </>
  );
};

export default About;
